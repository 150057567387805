import { DateISO, FormGroup, Text, TextArea } from '@agro-club/agroclub-shared'
import { FormikProps } from 'formik'
import { endpoints } from 'modules/endpoints'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { DatePicker } from 'views/components/DatePicker/DatePicker'
import { FormInput } from 'views/components/form/FormInput'
import FormSelectShared from 'views/components/FormSelectShared'
import { SharedModalInputs } from 'views/components/SharedModal'
import { ShortFormControls } from 'views/styled/common'

export type SdizFormFields = {
  sdiz_number: string | null
  address: string | null
  product: string | null
  ttn_number: string | null
  ttn_date: DateISO | undefined
}

const TextAreaDescription = styled(Text).attrs({
  typography: 'bodySmall',
  color: 'secondary',
})`
  margin-top: 4px;
`

type Props = {
  formik: FormikProps<SdizFormFields>
}

export const SdizCommonFields: FC<Props> = ({ formik }) => {
  const { t } = useTranslation('sdiz')

  return (
    <SharedModalInputs>
      <FormSelectShared
        formik={formik}
        fieldName="address"
        getLabel={(dto) => dto.address}
        searchField="address"
        label={t('common:address')}
        endpoint={endpoints.sdizAddresses()}
      />
      <FormSelectShared
        formik={formik}
        fieldName="product"
        getLabel={(dto) => dto.name}
        label={t('product')}
        endpoint={endpoints.LogisticsKR.products()}
        searchField="search"
      />
      <FormGroup error={formik.errors.ttn_number} label={t('ttnNumbers')}>
        <TextArea
          {...formik.getFieldProps('ttn_number')}
          borderRadius="form"
          invalid={!!formik.errors.ttn_number}
          height={80}
        />
        <TextAreaDescription>{t('ttnFieldDescription')}</TextAreaDescription>
      </FormGroup>
      <ShortFormControls>
        <FormGroup label={t('ttnDate')} error={formik.errors.ttn_date}>
          <DatePicker
            value={formik.values.ttn_date}
            onChange={(data) => formik.setFieldValue('ttn_date', data['date'])}
            error={!!formik.errors.ttn_date}
            placeholder={t('common:datePlaceholder')}
          />
        </FormGroup>
        <FormInput label={t('sdizNumber')} formik={formik} field="sdiz_number" />
      </ShortFormControls>
    </SharedModalInputs>
  )
}
