import { BidContractFilter } from 'modules/domain/bid/api'
import { BidStatus, BidTypes } from 'modules/domain/bid/types'
import { urlMaker } from 'modules/utils/urlMaker'

export const contractsEndpoints = {
  contracts: urlMaker('/contracts/'),
  contractComments: (id: string) => `/contracts/${id}/comments/`,
  contractDeals: (id: string) => `contracts/${id}/deals/`,
  contractsWithType: (bid_type: BidTypes) => `/contracts/?bid_type=${bid_type}`,
  closeContract: (idContract: string) => `/contracts/${idContract}/close/`,
  changeStatusContract: (idContract: string) => `/contracts/${idContract}/change_status/`,
  contractClosedReasons: () => `/contracts/closed_reasons/`,
  contractStatuses: () => `/contracts/statuses/`,
  bidContractFiles: (userId: string, fileId?: string) => `/contracts/${userId}/files/${fileId ? `${fileId}/` : ''}`,
  contractDocumentTypes: (contractId: string) => `/contracts/${contractId}/document_types/`,
  contractDocuments: (contractId: string, dealId?: string) =>
    `/contracts/${contractId}/documents/${dealId ? `?deal_id=${dealId}` : ''}`,
  contractDeleteDocument: (contractId: string, documentId: string) =>
    `/contracts/${contractId}/documents/${documentId}/`,
  contractPaymentTypes: () => `/contracts/payment-types/`,
  contractsTotals: (
    {
      bid_type = BidTypes.sale,
      matchable = false,
      match_bid_id = '',
      status = BidStatus.published,
    } = {} as BidContractFilter,
  ) => `/contracts/totals/?bid_type=${bid_type}&matchable=${matchable}&match_bid_id=${match_bid_id}&status=${status}`,
}
