import { useTranslation } from 'react-i18next'

import { ListRequestParams } from 'modules/domain/types'
import { FC } from 'react'
import { ACheckboxV2 } from 'views/components/Analytics'

type Props = {
  listRequestParams: ListRequestParams
  updateFilterState: (ListRequestParams) => void
  filterKey: string
}

export const MatchesUserStatusFilter: FC<Props> = ({
  listRequestParams: activeRequestParams,
  updateFilterState,
  filterKey,
}) => {
  const { t } = useTranslation('dashboard')

  const updateChecked = (val: boolean) => {
    updateFilterState({ [filterKey]: val ? true : undefined })
  }

  return (
    <ACheckboxV2
      onChange={(e) => updateChecked(e.target.checked)}
      checked={activeRequestParams.filter[filterKey] ? JSON.parse(activeRequestParams.filter[filterKey]) : undefined}
      label={t(filterKey)}
      id={`userStatusFilter-${filterKey}`}
    />
  )
}
