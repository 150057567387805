import { AntTag, Icons, Progress, Spinner, Text } from '@agro-club/agroclub-shared'
import { Company } from 'modules/domain/company/types'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { generatePath, useNavigate } from 'react-router'
import styled from 'styled-components'
import { KeyEntityIcon, USCompanyIrelyIcon } from 'views/components/TooltipIcons/TooltipIcons'
import { ItemCard } from 'views/styled/ItemCardStyles'
import CompanyRoutes from '../routes'

type Props = {
  setActiveCard: (id: Company) => void
  branches: Company[]
  activeCard: Company | null
  progress: Progress
}

const BranchesWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px 0;
  max-height: calc(100dvh - 370px);
  overflow-y: auto;
`

const BranchCard = styled(ItemCard)`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 4px 0;
`
const Tags = styled.div`
  display: flex;
  gap: 0 8px;
`
export const WrapperName = styled.div`
  display: flex;
  align-items: center;
  gap: 0 4px;
`
export const CompanySpinner = styled(Spinner)`
  margin: 0 auto;
`

export const BranchList: FC<Props> = ({ branches, progress, activeCard, setActiveCard }) => {
  const { t } = useTranslation('company')
  const navigate = useNavigate()

  const onBranchClick = (branch: Company) => {
    setActiveCard(branch)
    if (branch.parent?.id) {
      navigate(generatePath(CompanyRoutes.DetailsWithBranch, { id: branch.parent.id, branchId: branch.id }))
    } else {
      navigate(generatePath(CompanyRoutes.Details, { id: branch.id }))
    }
  }

  if (progress === Progress.WORK || !branches || !activeCard) {
    return <CompanySpinner />
  }

  return (
    <BranchesWrapper>
      {branches.map((branch) => (
        <BranchCard activeCard={activeCard.id === branch.id} key={branch.id} onClick={() => onBranchClick(branch)}>
          <WrapperName>
            <Text typography="accentLarge">{branch.name}</Text>
            <KeyEntityIcon isVisible={branch.is_significant} size="large" />
            <USCompanyIrelyIcon isVisible={branch.synced_with_accounting} size="large" />
          </WrapperName>

          <Text typography="bodyMedium">{branch.address}</Text>
          <Tags>
            {branches.length > 1 && (
              <AntTag color={branch.branch_count ? 'blue' : 'white'}>
                {t(branch.branch_count ? 'main' : 'branch')}
              </AntTag>
            )}

            {!!branch.employees_count && (
              <AntTag icon={<Icons.IconRetailer height={16} width={16} />} color="white">
                {branch.employees_count}
              </AntTag>
            )}
            {!!branch.offers_count && (
              <AntTag icon={<Icons.IconDescription height={16} width={16} />} color="white">
                {branch.offers_count}
              </AntTag>
            )}
          </Tags>
        </BranchCard>
      ))}
    </BranchesWrapper>
  )
}
