export enum CallType {
  in = 'in',
  out = 'out',
  missed = 'missed',
}

export type Call = {
  id: number
  call_type: CallType
  call_start: string
  conversation_duration: number
  record_link: string
  record_transcription: string | null
  coordinator_id: number
  coordinator_name: string
  coordinator_login: string
  client_id: number | null
  client_name: string | null
  client_phone: string
  client_company: string | null
  client_company_id: number | null
}
