import { AntTag, Progress, Spinner, Text } from '@agro-club/agroclub-shared'
import useFormatDate from 'hooks/useFormatDate'
import { ExportDocuments } from 'modules/domain/export/types'
import { ListRequestParams } from 'modules/domain/types'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { BorderedItem } from 'views/components/BorderedItem/BorderedItem'
import { HrWithHeader } from 'views/components/Hr/Hr'
import { KeyValue } from 'views/components/KeyValue/KeyValue'
import { CustomScroll } from 'views/styled/common'
import { FileItem } from '../../components/FileItem'
import { useGetFilteredFiles } from './useGetFilteredFiles'

const SpinnerOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.082);
  border-radius: 8px;
`

const ScrollableWrapper = styled.div`
  position: relative;
  overflow-y: auto;
  max-height: 400px;

  ${CustomScroll}
`

const NoFilterBorderItem = styled(BorderedItem).attrs({ hover: false, justifyContent: 'center' })`
  padding: 12px;
`
const BorderedFileItem = styled(BorderedItem).attrs({ hover: false })`
  padding: 8px 16px;
`

const FlexColumn = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 4px;
`

const FileInfoWrapper = styled.div`
  display: flex;
  align-items: center;
  column-gap: 10px;
`
type FileItemProps = {
  file: ExportDocuments
}

const Item: FC<FileItemProps> = ({ file }) => {
  const { t } = useTranslation('export')
  const formatDate = useFormatDate()
  return (
    <BorderedFileItem>
      <FlexColumn>
        <FileItem file={file} />
        <FileInfoWrapper>
          <AntTag color="white">{file.document_type}</AntTag>
          <KeyValue columnGap="small" horizontal valueTypo="bodyMedium" label={t('loaded')}>
            {formatDate(file.uploaded_at)}
          </KeyValue>
          <KeyValue columnGap="small" horizontal valueTypo="bodyMedium" label={t('contractNumber')}>
            №{file.contract_number}
          </KeyValue>
        </FileInfoWrapper>
      </FlexColumn>
    </BorderedFileItem>
  )
}

type FileListProps = {
  listRequestParams: ListRequestParams
}

export const FileList: FC<FileListProps> = ({ listRequestParams }) => {
  const { t } = useTranslation('export')
  const { progress, files } = useGetFilteredFiles({ filters: listRequestParams.filter })

  return (
    <div>
      <HrWithHeader marginBottom="4px" text={t('searchResults')} forForm={false} />

      <ScrollableWrapper>
        {files?.length ? (
          files.map((file) => <Item key={file.id} file={file} />)
        ) : (
          <NoFilterBorderItem hover={false} justifyContent="center">
            <Text style={{ whiteSpace: 'pre-line' }} color="secondary">
              {t('noFilters')}
            </Text>
          </NoFilterBorderItem>
        )}
      </ScrollableWrapper>
      {progress === Progress.WORK && (
        <SpinnerOverlay>
          <Spinner />
        </SpinnerOverlay>
      )}
    </div>
  )
}
