import { FC } from 'react'
import { Route, Routes } from 'react-router'
import { MatchesList } from './MatchesList'

export const MatchesPage: FC = () => {
  return (
    <Routes>
      <Route index element={<MatchesList />}></Route>
    </Routes>
  )
}
