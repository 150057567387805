import { useFormik } from 'formik'
import { WarehouseDTO } from 'modules/domain/types'
import * as managers from 'modules/domain/userAddress/managers'
import { SellerWarehouseForm } from './SellerWarehouseForm'
import { useAFormHandler } from 'analytics/hooks'
import { FC } from 'react'
import { RequestError } from 'modules/errors'
import { EditWarehouseProps } from './types'

export const SellerWarehouseEditModal: FC<EditWarehouseProps> = ({
  onSuccess,
  bid,
  onClose,
  userAddress,
  userId,
  dealId,
}) => {
  const { formProgress, formHandler } = useAFormHandler('editSellerWarehouse', { user_id: userId, id: dealId })
  const address = userAddress ? userAddress : (bid?.address as WarehouseDTO)
  const ownerId = userId ? userId : bid?.owner.id
  const addressId = userAddress && userId ? userAddress.id : bid?.address.id

  const formik = useFormik<WarehouseDTO>({
    initialValues: {
      gates_height: address.gates_height,
      loader_type: address.loader_type,
      scales_capacity: address.scales_capacity,
      scales_length: address.scales_length,
      scales_remoteness: address.scales_remoteness,
      schedule_from: address.schedule_from,
      schedule_to: address.schedule_to,
      overload: address.overload,
      vehicle_type: address.vehicle_type,
      volume_per_day: address.volume_per_day,
      works_on_weekend: address.works_on_weekend,
      car_delivery: address.car_delivery,
      railway_delivery: address.railway_delivery,
      weather_conditions: address.weather_conditions,
    },
    onSubmit: formHandler(
      async () => {
        await managers.editItem(String(ownerId), String(addressId), formik.values)
      },
      {
        onSuccess: async () => {
          await onSuccess()
          onClose()
        },
        onError: (err) => {
          const error = err as RequestError
          const { errors } = error
          formik.setErrors(errors)
        },
      },
    ),
  })

  return <SellerWarehouseForm formik={formik} progress={formProgress} onClose={onClose} />
}
