import { t } from 'i18next'
import { ListRequestParams } from 'modules/domain/types'
import { endpoints } from 'modules/endpoints'
import { FC } from 'react'
import { ABaseTableFilter } from './ABaseTableFilter'

interface Props {
  listRequestParams: ListRequestParams
  updateFilterState: any
  fieldName?: string
  multiple?: boolean
  showSearch?: boolean
  placeholder?: string
}

export const CropsFilter: FC<Props> = ({
  listRequestParams,
  updateFilterState,
  fieldName,
  multiple = true,
  showSearch = true,
  placeholder = t('bid:filterByCrops'),
}) => {
  const name = fieldName ?? 'product_id'

  const commonProps = {
    onChange: (product_id) => updateFilterState({ [name]: product_id }),
    value: listRequestParams.filter[name],
    placeholder: placeholder,
    getLabel: (label) => label.title,
    endpoint: endpoints.productChoices(),
    searchField: 'search',
    simpleApiFormat: true,
    multiple: multiple,
    showSearch: showSearch,
    id: 'cropsFilter',
  }

  return <ABaseTableFilter {...commonProps} />
}
