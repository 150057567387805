import { AntCalendar, Icons, Input, Progress, SERVER_DATE_FORMAT, Text } from '@agro-club/agroclub-shared'
import { ResponsiveModal } from 'views/components/ResponsiveModal/ResponsiveModal'
import { SharedModalContent } from 'views/components/SharedModal'
import { FC, useEffect, useRef, useState } from 'react'
import { refetchFunc } from 'modules/domain/common/hooks'
import { useNotification } from 'hooks/useNotification'
import { apiClient } from 'modules/utils/httpClient'
import { AButton } from 'views/components/Analytics'
import { useAFormHandler } from 'analytics/hooks'
import { Deal } from 'modules/domain/deal/types'
import { useTranslation } from 'react-i18next'
import { endpoints } from 'modules/endpoints'
import useLanguage from 'hooks/useLanguage'
import { DayType } from './WeeklyCalendar'
import styled from 'styled-components'
import { useFormik } from 'formik'
import { format } from 'date-fns'

type Props = {
  calendarList: DayType[]
  refetch: refetchFunc
  onClose: () => void
  isSaleBid: boolean
  countField: string
  title: string
  deal: Deal
}

const Controls = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 0 8px;
  input {
    width: 72px;
  }
`

const Content = styled.div`
  max-width: 380px;
  margin-top: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`

type FormType = {
  norm_date: string
  loading_count: string | null
  unloading_count: string | null
}

export const VolumeTrackerModal: FC<Props> = ({ onClose, title, deal, countField, refetch, calendarList }) => {
  const locale = useLanguage()
  const { t } = useTranslation('deal')
  const inputRef = useRef<HTMLInputElement>(null)

  const notify = useNotification()

  const { formProgress, formHandler } = useAFormHandler('updateVolumeTracker', { id: deal.id })
  const [activeNormId, setActiveNormId] = useState<string>()
  const [initialValue, setInitialValue] = useState<string | null>(null)

  const formik = useFormik<FormType>({
    initialValues: {
      norm_date: '',
      loading_count: null,
      unloading_count: null,
    },

    onSubmit: formHandler(
      async () => {
        const data = {
          norm_date: formik.values.norm_date,
          [countField]: formik.values[countField],
        }

        if (activeNormId) {
          await apiClient.patch(endpoints.dealCalendar(deal.id, activeNormId), data)
        } else {
          await apiClient.post(endpoints.dealCalendar(deal.id), data)
        }
      },
      {
        onSuccess: () => {
          refetch()
          setInitialValue(formik.values[countField])
        },

        onError: (error) => {
          const { errors } = error
          formik.setErrors(errors)
          notify(Progress.ERROR, { title: errors[countField] })
        },
      },
    ),
  })

  const countFieldValue = formik.values[countField]

  const calendarChange = (date) => {
    inputRef.current?.focus()
    const day = calendarList.find((day) => day.norm_date === date['date'])
    formik.setFieldValue('norm_date', date['date'])

    if (day?.id) {
      setActiveNormId(day.id.toString())
      formik.setFieldValue(countField, day[countField])
      setInitialValue(day[countField])

      return
    }
    setActiveNormId(undefined)
    formik.setFieldValue(countField, '')
    setInitialValue('')
  }

  const [isDomReady, setIsDomReady] = useState(false)

  useEffect(() => {
    setIsDomReady(true)
    if (isDomReady) {
      calendarChange({ date: format(new Date(), SERVER_DATE_FORMAT) })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDomReady])

  const closeChangeMode = () => {
    formik.setFieldValue(countField, initialValue)
    inputRef.current?.focus()
  }

  return (
    <ResponsiveModal
      title={title}
      onClose={onClose}
      id="addUser"
      size="small"
      withFooter={false}
      contentStyle={{ maxWidth: '428px' }}
    >
      <SharedModalContent>
        <AntCalendar
          onChange={calendarChange}
          locale={locale}
          disabledDate={(current: Date) => {
            return current < new Date(new Date().setHours(0, 0, 0, 0))
          }}
        />
        <Content>
          <Text typography="bodyLarge" color="secondary">
            {t('truck_count_per_day')}
          </Text>
          <Controls>
            <Input
              {...formik.getFieldProps(countField)}
              ref={inputRef}
              invalid={!!formik.errors[countField]}
              type="number"
            />

            <AButton
              onClick={() => formik.submitForm()}
              progress={formProgress}
              Icon={Icons.IconDone}
              id="saveValuePerDay"
              color="secondary"
              size="big"
              disabled={(!countFieldValue && countFieldValue !== 0) || countFieldValue === initialValue}
            />
            <AButton
              progress={formProgress}
              onClick={closeChangeMode}
              Icon={Icons.IconClose}
              id="cancelValuePerDay"
              color="secondary"
              size="big"
              disabled={countFieldValue === initialValue}
            />
          </Controls>
        </Content>
      </SharedModalContent>
    </ResponsiveModal>
  )
}
