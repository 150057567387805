import { KeyValue, Value } from 'views/components/KeyValue/KeyValue'
import { ASharedModal, SharedModalContent, SharedModalTitle } from 'views/components/SharedModal'
import { Text } from '@agro-club/agroclub-shared'
import formatNumber from 'modules/utils/numbers/formatNumber'
import { getPriceTypeLabel } from 'modules/domain/bid/utils'
import formatPrice from 'modules/utils/numbers/formatPrice'
import { defaultTheme } from '@agro-club/agroclub-shared'
import { AnalyticsPlaces } from '../analyticsPlaces'
import { Deal } from 'modules/domain/deal/types'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { FC } from 'react'
import { DASH } from 'modules/constants'
import { isVisibleCredit } from 'env'
import { AMobileModal } from 'views/components/MobileComponents/Modal/AMobileModal'
import { useMediaQuery } from 'react-responsive'

type Props = {
  deal: Deal
  close: () => void
}

const HistoryContentWrapper = styled.div`
  display: grid;
  grid-template-columns: 154px 154px;
  grid-gap: 12px 53px;
  @media (max-width: ${defaultTheme.queries.mobile}) {
    grid-template-columns: 1fr 1fr;
    grid-gap: 16px;
  }
`

export const DealHistoryModal: FC<Props> = ({ deal, close }) => {
  const { t } = useTranslation(['deal', 'bid'])

  const saleBidLabel = getPriceTypeLabel(deal.sale_bid.price_type, t)
  const purchaseBidLabel = getPriceTypeLabel(deal.purchase_bid.price_type, t)

  const contentJSX = (
    <SharedModalContent>
      <HistoryContentWrapper>
        <Value typography="accentLarge">{t('historyModal.priceOnSign')}</Value>
        <Value typography="accentLarge">{t('historyModal.forNow')}</Value>
        <KeyValue label={saleBidLabel}>{formatPrice(deal.sale_price_on_contract_signed)}</KeyValue>
        <KeyValue label={saleBidLabel}>{formatPrice(deal.sale_price)}</KeyValue>
        <KeyValue label={purchaseBidLabel}>{formatPrice(deal.purchase_price_on_contract_signed)}</KeyValue>
        <KeyValue label={purchaseBidLabel}>{formatPrice(deal.purchase_price)}</KeyValue>
        <KeyValue label={t('historyModal.priceDelivery')}>
          {formatPrice(deal.price_logistics_on_contract_signed)}
        </KeyValue>
        <KeyValue label={t('historyModal.priceDelivery')}>{formatPrice(deal.price_logistics)}</KeyValue>
        <KeyValue label={t('historyModal.quantity')}>{formatNumber(deal.quantity_on_contract_signed)}</KeyValue>
        <KeyValue label={t('historyModal.quantity')}>{formatNumber(deal.quantity)}</KeyValue>
        <KeyValue label={t('historyModal.turnover_days')}>{deal.turnover_days_on_contract_signed}</KeyValue>
        {isVisibleCredit && (
          <>
            <KeyValue label={t('historyModal.turnover_days')}>
              {deal.purchase_bid.company?.turnover_days ?? DASH}
            </KeyValue>
            <KeyValue label={t('historyModal.affectable_turnover_days')}>
              {deal.affectable_turnover_days_on_contract_signed ?? DASH}
            </KeyValue>
            <KeyValue label={t('historyModal.affectable_turnover_days')}>
              {deal.purchase_bid.company?.affectable_turnover_days ?? DASH}
            </KeyValue>
          </>
        )}
      </HistoryContentWrapper>
    </SharedModalContent>
  )
  const isMobile = useMediaQuery({
    query: `(max-width: ${defaultTheme.queries.mobile})`,
  })
  return isMobile ? (
    <AMobileModal
      header={<Text typography="titleH4">{t('historyModal.title')}</Text>}
      onClose={close}
      id={AnalyticsPlaces.HISTORY_MODAL}
    >
      {contentJSX}
    </AMobileModal>
  ) : (
    <ASharedModal id={AnalyticsPlaces.HISTORY_MODAL} onClose={close} size="small">
      <SharedModalTitle>{t('historyModal.title')}</SharedModalTitle>
      {contentJSX}
    </ASharedModal>
  )
}
