import { useDisplayClearBtn } from 'hooks/useDisplayClearBtn'
import { endpoints } from 'modules/endpoints'
import { TableFiltersProps } from 'modules/types'
import { useTranslation } from 'react-i18next'
import { AClearFiltersButton } from 'views/components/Analytics'
import { FiltersAndSearchWrapper, FiltersWrapper } from 'views/components/CommonTableComponents/CommonTableComponents'
import { SearchFilter } from 'views/components/SearchFilter/SearchFilter'
import { CropsFilter } from 'views/components/TableFilters'
import { ABaseTableFilter } from 'views/components/TableFilters/ABaseTableFilter'
import { defaultListRequestParams } from 'views/components/TableFilters/data'
import { ADateRangePicker } from 'views/components/TableFilters/DateRangePicker/ADateRangePicker'

export const Filters = ({ listRequestParams, updateFilterState, setListRequestParams }: TableFiltersProps) => {
  const { t } = useTranslation()

  const displayClearBtn = useDisplayClearBtn({
    listRequestParams,
  })

  return (
    <FiltersAndSearchWrapper>
      <FiltersWrapper>
        <ADateRangePicker
          onChange={(value) =>
            updateFilterState({ contract_date_after: value.startDate, contract_date_before: value.endDate })
          }
          value={[listRequestParams.filter.contract_date_after, listRequestParams.filter.contract_date_before]}
          id="dateFilter"
        />
        <CropsFilter listRequestParams={listRequestParams} updateFilterState={updateFilterState} />
        <ABaseTableFilter
          onChange={(status) => updateFilterState({ status })}
          value={listRequestParams.filter.status}
          endpoint={endpoints.dealStatuses()}
          placeholder={t('shipmentsControl:status')}
          getLabel={(dto) => dto.label}
          simpleApiFormat
          id="status"
        />
        <ABaseTableFilter
          onChange={(sale_city) => updateFilterState({ sale_city })}
          value={listRequestParams.filter.sale_city}
          endpoint={endpoints.brRoutesSaleCities()}
          id="cityFilter"
          placeholder={t('routes:origin')}
          searchField="search"
          simpleApiFormat
        />
        <ABaseTableFilter
          onChange={(purchase_city) => updateFilterState({ purchase_city })}
          value={listRequestParams.filter.purchase_city}
          endpoint={endpoints.brRoutesPurchaseCities()}
          placeholder={t('routes:destination')}
          id="cityFilter"
          searchField="search"
          simpleApiFormat
        />
        {displayClearBtn && (
          <AClearFiltersButton onClick={() => setListRequestParams({ ...defaultListRequestParams })} />
        )}
      </FiltersWrapper>
      <SearchFilter listRequestParams={listRequestParams} updateFilterState={updateFilterState} />
    </FiltersAndSearchWrapper>
  )
}
