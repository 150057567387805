import { useTranslation } from 'react-i18next'

import { VehicleType } from '../../../types'
import { ListRequestParams } from 'modules/domain/types'
import { ASelectFilter } from 'views/components/Analytics'
import { FC } from 'react'

type Props = {
  listRequestParams: ListRequestParams
  updateFilterState: (filterObj: Record<string, any>) => void
}

export const CarFilter: FC<Props> = ({ listRequestParams, updateFilterState }) => {
  const { t } = useTranslation('logisticsKR')

  const statusOptions = Object.keys(VehicleType).map((vehicle_type) => ({
    value: vehicle_type,
    label: t(`vehicle_type.${vehicle_type}`),
  }))

  return (
    <ASelectFilter
      placeholder={t('cars.filters.car_type')}
      options={statusOptions}
      value={listRequestParams.filter.car_type || []}
      mode="multiple"
      onChange={(car_type) => updateFilterState({ car_type })}
      showSearch={false}
      id="carFilter"
    />
  )
}
