import { useProgress } from 'hooks/useProgress'
import { ExportDocuments } from 'modules/domain/export/types'
import { endpoints } from 'modules/endpoints'
import { apiClient } from 'modules/utils/httpClient'
import { useEffect, useMemo, useState } from 'react'
import { ListResponse } from 'types/api'

type Props = {
  filters: Record<string, any>
}

export const useGetFilteredFiles = ({ filters }: Props) => {
  const [files, setFiles] = useState<ExportDocuments[]>([])
  const [progress, getDocs] = useProgress<void>(async () => {
    const res = await apiClient.get<ListResponse<ExportDocuments>>(endpoints.exportAllDocs(), {
      ...filters,
      page_size: 1000,
    })
    setFiles(res.results)
  })

  const hasFilters = useMemo(() => Object.values(filters).filter(Boolean).length > 0, [filters])

  useEffect(() => {
    if (hasFilters) {
      getDocs()
    } else {
      setFiles([])
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters, hasFilters])

  return { progress, files }
}
