import { Icons, Text } from '@agro-club/agroclub-shared'
import { isUS } from 'env'
import { refetchFunc } from 'modules/domain/common/hooks'
import { ProfileType, User } from 'modules/domain/user/types'
import { FC, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { AButton } from 'views/components/Analytics'
import { Card } from 'views/components/Card/Card'
import { Column } from '../styled'
import { UserUserRespCoordinatorModal } from './UserUserRespCoordinatorModal'

type Props = {
  user: User
  refetch: refetchFunc
}

export const UserResponsibleCoordinator: FC<Props> = ({ user, refetch }) => {
  const { t } = useTranslation('user')
  const [showModal, setShowModal] = useState(false)

  if (
    user.profile?.profile_type === ProfileType.customer ||
    user.profile?.profile_type === ProfileType.seller ||
    user.profile?.profile_type === ProfileType.manager
  ) {
    let tradersOnly = false
    if (isUS || user.profile?.profile_type === ProfileType.customer) {
      tradersOnly = true
    }
    const translateKey = tradersOnly ? 'assignedTrader' : 'assignedCoordinator'
    let tagsJsx

    if (!user.profile.responsible_coordinator) {
      tagsJsx = (
        <div style={{ marginTop: '4px' }}>
          <AButton
            id="addTraderForUser"
            Icon={Icons.IconAdd}
            intent="approve"
            size="medium"
            onClick={() => setShowModal(true)}
          >
            {t('common:add')}
          </AButton>
        </div>
      )
    } else {
      tagsJsx = (
        <Card.Container bordered>
          {user.profile.responsible_coordinator.full_name}
          <Card.EditButton onClick={() => setShowModal(true)} />
        </Card.Container>
      )
    }

    return (
      <>
        <Column>
          <Text color="secondary">{t(`${translateKey}.title`)}</Text>
          {tagsJsx}
        </Column>
        {showModal && (
          <UserUserRespCoordinatorModal
            tradersOnly={tradersOnly}
            user={user}
            onClose={() => setShowModal(false)}
            refetch={refetch}
          />
        )}
      </>
    )
  }

  return null
}
