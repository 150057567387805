import { RuMarginsforDeals } from 'modules/domain/deal/types'
import formatNumber from 'modules/utils/numbers/formatNumber'
import formatPrice from 'modules/utils/numbers/formatPrice'
import { AntTooltip, Text } from '@agro-club/agroclub-shared'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { FC } from 'react'
import { MarginProps } from './CommonMarginComponent'

const Wrapper = styled.div`
  display: flex;
  gap: 0 4px;
`

export const RuMarginComponent: FC<MarginProps> = ({ status, margins, label }) => {
  const { t } = useTranslation('deal')
  const ruMargins = margins as RuMarginsforDeals

  return (
    <div>
      <Text typography="bodyMedium" color="secondary">
        {label}
      </Text>
      <Text typography="bodyMedium">
        <Wrapper>
          <span>{formatPrice(Math.round(ruMargins.margins[status]), true, false)}</span>
          <AntTooltip placement="top" variant="secondary" tooltipContent={t('weighted_average_percentage')}>
            / {formatNumber(ruMargins.weighted_average_margins[status])}
          </AntTooltip>
          ({t('contributionMarginShort')})
        </Wrapper>
        <Wrapper>
          <span>{formatPrice(Math.round(ruMargins.margins_no_credit[status]), true, false)}</span>
          <AntTooltip placement="top" variant="secondary" tooltipContent={t('weighted_average_percentage')}>
            / {formatNumber(ruMargins.weighted_average_margins_no_credit[status])}
          </AntTooltip>
          ({t('marginLowerCase')})
        </Wrapper>
      </Text>
    </div>
  )
}
