import { FC, ReactNode } from 'react'
import styled from 'styled-components'
import { BorderedItem } from 'views/components/BorderedItem/BorderedItem'

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
`

export const NotFound: FC<{ children: ReactNode }> = ({ children }) => (
  <BorderedItem hover={false}>
    <Container>{children}</Container>
  </BorderedItem>
)
