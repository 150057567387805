import { Icons, Progress, Spinner, Text } from '@agro-club/agroclub-shared'
import { DASH } from 'modules/constants'
import { USBid } from 'modules/domain/bid/types'
import formatPrice from 'modules/utils/numbers/formatPrice'
import { formatQuantity } from 'modules/utils/numbers/formatQuantity'
import { labelTarif } from 'modules/utils/numbers/formatTarif'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { generatePath } from 'react-router'
import styled from 'styled-components'
import { AButton } from 'views/components/Analytics'
import { BorderedItem } from 'views/components/BorderedItem/BorderedItem'
import { Card } from 'views/components/Card/Card'
import { DeliveryPeriodData } from 'views/components/DealCard/DeliveryPeriod'
import { KeyValue, KeyValueProps } from 'views/components/KeyValue/KeyValue'
import { BidTypeTag, USContractTypeTag } from 'views/components/Tags'
import BidRoutes from 'views/pages/Bid/routes'
import { NotFound } from '../../components/NotFound'

const commonKeyValueParams: Omit<KeyValueProps, 'label'> = {
  labelTypo: 'bodySmall',
  valueTypo: 'accentLarge',
  labelColor: 'primary',
}

const ItemContainer = styled.div`
  display: flex;
  flex-direction: column;
  color: ${({ theme }) => theme.color.textPrimary900};
  width: 100%;
  row-gap: 4px;
  padding: 8px 12px;
`

const ItemTagWrapper = styled.div`
  display: flex;
  column-gap: 8px;
`

const ItemHeaderWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

const DeliveryInfoWrapper = styled.div`
  display: flex;
`

const SpinnerWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 435px;
`

type ItemProps = {
  offer: USBid
}

const Item: FC<ItemProps> = ({ offer }) => {
  const { t } = useTranslation('bid')
  const season = offer.season ? `${offer.season.name},` : ''
  const productInfo = `${offer.product.title}, ${season} ${offer.symbol}, ${formatQuantity(offer.quantity)}`
  const futuresPrice = offer.price_periods?.[0] ? formatPrice(offer.futures_price) : DASH
  const priceType = offer.price_type && <Text>{t(`usDeliveryOptions.${offer.price_type}`)},</Text>

  return (
    <BorderedItem padding={false}>
      <ItemContainer>
        <ItemHeaderWrapper>
          <ItemTagWrapper>
            <BidTypeTag bid={offer} />
            <USContractTypeTag bid={offer} />
          </ItemTagWrapper>

          <AButton
            id="bid"
            Icon={Icons.IconOpen}
            color="secondary"
            background="none"
            href={generatePath(BidRoutes.Details, { id: offer.id })}
            target="_blank"
          />
        </ItemHeaderWrapper>

        <Text typography="accentMedium">{productInfo}</Text>
        <Card.Grid cols={3} maxColumnWidth={'72px'} columnGap={16}>
          <KeyValue label={labelTarif(t('usShortBid.basis'))} {...commonKeyValueParams}>
            {formatPrice(offer.basis)}
          </KeyValue>
          <KeyValue label={labelTarif(t('usShortBid.futures'))} {...commonKeyValueParams}>
            {futuresPrice}
          </KeyValue>
          <KeyValue label={labelTarif(t('usShortBid.cash'))} {...commonKeyValueParams}>
            {formatPrice(offer.price)}
          </KeyValue>
        </Card.Grid>
        <DeliveryInfoWrapper>
          {priceType}&nbsp;
          <DeliveryPeriodData data={offer} />
        </DeliveryInfoWrapper>
      </ItemContainer>
    </BorderedItem>
  )
}

type OffersListProps = {
  progress: Progress
  offers: USBid[] | undefined
}

export const OffersList: FC<OffersListProps> = ({ offers, progress }) => {
  const { t } = useTranslation('accountManagement')

  if (progress === Progress.WORK)
    return (
      <SpinnerWrapper>
        <Spinner />
      </SpinnerWrapper>
    )

  return (
    <div>
      {offers?.length ? (
        <>
          {offers.map((offer) => (
            <Item key={offer.id} offer={offer} />
          ))}
        </>
      ) : (
        <NotFound>
          <Text typography="bodyLarge">{t('drawer.offersNotFound')}</Text>
        </NotFound>
      )}
    </div>
  )
}
