import { AntButtonWithDropdown, Icons, Text } from '@agro-club/agroclub-shared'
import { AccountManagementContact } from 'modules/domain/accountManagement/types'
import { endpoints } from 'modules/endpoints'
import { apiClient } from 'modules/utils/httpClient'
import { FC } from 'react'
import styled from 'styled-components'

const ChildrenWrapper = styled.div`
  width: 124px;
  text-align: start;
`

const BlackPhoneIcon = styled(Icons.IconPhone)`
  width: 16px;
  height: 16px;

  path {
    fill: black;
  }
`

type Props = {
  contacts: AccountManagementContact[]
  companyId: string
}

export const PhoneButtonDropdown: FC<Props> = ({ contacts, companyId }) => {
  if (!contacts.length) return null

  const sendCallLogs = (phone) =>
    apiClient.post(endpoints.dashboardCallLogs(), { client_phone: phone, company: companyId })

  const onClick = (phone) => {
    sendCallLogs(phone)
    return (window.location.href = `tel:${phone}`)
  }

  const hasDropdownOptions = contacts.length > 1
  const options = hasDropdownOptions
    ? contacts.slice(1).map((contact, index) => ({
        label: (
          <div>
            <Text typography="bodyMedium">{contact.phone}</Text>
            <Text typography="bodyMedium" color="secondary">
              {contact.fullname}
            </Text>
          </div>
        ),
        key: index,
        icon: <BlackPhoneIcon />,
        onClick: () => onClick(contact.phone),
      }))
    : undefined

  return (
    <AntButtonWithDropdown
      disabled={!hasDropdownOptions}
      trigger={['hover']}
      options={options}
      onClick={() => onClick(contacts?.[0]?.phone)}
    >
      <ChildrenWrapper>{contacts?.[0]?.phone}</ChildrenWrapper>
    </AntButtonWithDropdown>
  )
}
