import { startOfWeek, endOfWeek, addDays, format, isToday, isSameWeek } from 'date-fns'
import { defaultTheme, SERVER_DATE_FORMAT, Text } from '@agro-club/agroclub-shared'
import { DASH, RU_SHORT_DATE_FORMAT } from 'modules/constants'
import { FC, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import _ from 'lodash'

export type DayType = {
  id?: number
  norm_date: string
  loading_count: number | null
  unloading_count: number | null
}

type Props = {
  calendarList: DayType[]
  countField: string
}

const CalendarWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(7, 50px);
  gap: 8px;
  margin-top: 8px;
`
const CalendarDay = styled.div<{ isCurrentDay?: boolean; isCurrentWeek?: boolean }>`
  padding: 6px 9px;
  border-radius: 8px;
  background: ${({ isCurrentWeek }) =>
    isCurrentWeek ? defaultTheme.color.primary8a : defaultTheme.color.backgroundPrimary};
  height: 48px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border: ${({ isCurrentDay }) => (isCurrentDay ? `1px solid ${defaultTheme.color.primary600}` : 'none')};
`
const ExpandButton = styled(Text)`
  margin-top: 12px;
  cursor: pointer;
  text-decoration: underline;
  text-decoration-style: dashed;
  text-underline-offset: 4px;
`

const fillWeeks = (countField: string, data?: DayType[]) => {
  if (!data) return []
  const filteredData = data.filter((item) => item[countField] !== null)

  if (filteredData.length === 0) {
    const activeDate = new Date()
    filteredData.push({
      loading_count: null,
      unloading_count: null,
      norm_date: format(activeDate, SERVER_DATE_FORMAT),
    })
  }

  const minDate = new Date(Math.min(...filteredData.map((item) => new Date(item.norm_date).getTime())))
  const maxDate = new Date(Math.max(...filteredData.map((item) => new Date(item.norm_date).getTime())))

  const result: DayType[] = []
  let activeDate = startOfWeek(minDate, { weekStartsOn: 1 })
  const endDate = endOfWeek(maxDate, { weekStartsOn: 1 })

  while (activeDate <= endDate) {
    const formattedDate = format(activeDate, SERVER_DATE_FORMAT)
    const existingItem = filteredData.find((item) => item.norm_date === formattedDate)

    result.push(
      existingItem || {
        loading_count: null,
        unloading_count: null,
        norm_date: formattedDate,
      },
    )

    activeDate = addDays(activeDate, 1)
  }

  return result
}

export const WeeklyCalendar: FC<Props> = ({ calendarList, countField }) => {
  const { t } = useTranslation('deal')

  const [showAllDays, setShowAllDays] = useState(false)
  const [extendedCalendarList, setExtendedCalendarList] = useState<DayType[]>([])

  useEffect(() => {
    setExtendedCalendarList(fillWeeks(countField, calendarList))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [calendarList])

  const isDateInCurrentWeek = (date: Date): boolean => {
    return isSameWeek(date, new Date(), { weekStartsOn: 1 })
  }

  const displayedDays = showAllDays
    ? extendedCalendarList
    : extendedCalendarList.filter((day) => isSameWeek(new Date(day.norm_date), new Date(), { weekStartsOn: 1 }))

  return (
    <div>
      <CalendarWrapper>
        {displayedDays.map((day) => (
          <CalendarDay
            isCurrentWeek={isDateInCurrentWeek(new Date(day.norm_date))}
            isCurrentDay={isToday(new Date(day.norm_date))}
            key={day.norm_date}
          >
            <Text typography="bodySmall" color="secondary">
              {format(new Date(day.norm_date), RU_SHORT_DATE_FORMAT)}
            </Text>
            <Text typography={isToday(new Date(day.norm_date)) ? 'accentMedium' : 'bodyMedium'}>
              {!_.isNil(day[countField]) ? day[countField] : DASH}
            </Text>
          </CalendarDay>
        ))}
      </CalendarWrapper>
      {extendedCalendarList.length > 7 && (
        <ExpandButton typography="bodyLarge" onClick={() => setShowAllDays(!showAllDays)}>
          {showAllDays ? t('collapse') : t('expand')}
        </ExpandButton>
      )}
    </div>
  )
}
