import { ListResponse } from '@agro-club/agroclub-shared/dist/cjs/types/networkTypes'
import { endpoints } from 'modules/endpoints'
import { apiClient } from 'modules/utils/httpClient'
import { UserAddress } from '../userAddress/types'
import { BidSources, BidStatus, BidTypes, USBid } from './types'

const SOY_SLUG = 'soybeans-2-yellow'
const CORN_SLUG = 'corn-2-yellow'
const WHEAT_SLUG = 'wheat-hard-red-winter-kansas-city'

export type UserOffer = { best: Array<USBid | undefined>; closest: Array<USBid | undefined> }

const getUsTopOffers = async (
  address: UserAddress,
  rangeDate: any,
  abortController: AbortController | null,
): Promise<UserOffer> => {
  const commonParams = {
    status: BidStatus.published,
    bid_type: BidTypes.purchase,
    latitude: String(address?.latitude) || '',
    longitude: String(address?.longitude) || '',
    ...rangeDate,
  }

  const signal = abortController ? abortController.signal : undefined

  const offers = [
    // we get full page to allow backend to adjust sorting, but we need only 1 top result
    apiClient.get<ListResponse<USBid>>(
      endpoints.bid(),
      { ...commonParams, sort: 'distance', source: BidSources.BARCHART, product: SOY_SLUG, commission: 'exclude' },
      { signal },
    ),
    apiClient.get<ListResponse<USBid>>(
      endpoints.bid(),
      { ...commonParams, sort: 'distance', source: BidSources.BARCHART, product: CORN_SLUG, commission: 'exclude' },
      { signal },
    ),
    apiClient.get<ListResponse<USBid>>(
      endpoints.bid(),
      { ...commonParams, sort: 'distance', source: BidSources.BARCHART, product: WHEAT_SLUG, commission: 'exclude' },
      { signal },
    ),
    apiClient.get<ListResponse<USBid>>(
      endpoints.bid(),
      { ...commonParams, sort: '-price_exw', product: SOY_SLUG },
      { signal },
    ),
    apiClient.get<ListResponse<USBid>>(
      endpoints.bid(),
      { ...commonParams, sort: '-price_exw', product: CORN_SLUG },
      { signal },
    ),
    apiClient.get<ListResponse<USBid>>(
      endpoints.bid(),
      { ...commonParams, sort: '-price_exw', product: WHEAT_SLUG },
      { signal },
    ),
  ]
  const [soyClosest, cornClosest, wheatClosest, soyBest, cornBest, wheatBest] = await Promise.all(offers)
  return {
    //Opt.chainings are needed to prevent the application from crashing when cancelling a request
    best: [cornBest.results?.[0], soyBest.results?.[0], wheatBest.results?.[0]].filter(Boolean),
    closest: [cornClosest.results?.[0], soyClosest.results?.[0], wheatClosest.results?.[0]].filter(Boolean),
  }
}

export default getUsTopOffers
