import { Icons } from '@agro-club/agroclub-shared'
import { TaskStatus } from 'modules/domain/task/types'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { Completed } from '../styled'
import { CompleteTaskButton, SetTaskToWorkButton } from './StatusButtons'

interface Props {
  taskId: string
  currentStatus: TaskStatus | undefined
  refetch: () => void
}

export const StatusSwitcher: FC<Props> = ({ taskId, currentStatus, refetch }) => {
  const { t } = useTranslation('my_tasks')

  switch (currentStatus) {
    case TaskStatus.new:
      return <SetTaskToWorkButton taskId={taskId} refetch={refetch} />
    case TaskStatus.in_progress:
      return <CompleteTaskButton taskId={taskId} refetch={refetch} />
    case TaskStatus.complete:
      return (
        <Completed>
          {t('detail.completed')}&nbsp;
          <Icons.IconCheck width={20} height={20} />
        </Completed>
      )
    default:
      return null
  }
}
