const main = 'matches'
const relativeRoutes = {
  relativePage: `${main}/*`,
  relativeList: `${main}`,
}

const routes = {
  List: `/${main}`,
}

const MatchesRoutes = { ...relativeRoutes, ...routes }

export default MatchesRoutes
