import { FC } from 'react'
import { FiltersAndSearchWrapper, FiltersWrapper } from 'views/components/CommonTableComponents/CommonTableComponents'
import { useDisplayClearBtn } from 'hooks/useDisplayClearBtn'

import { useTranslation } from 'react-i18next'
import { BooleanFilter } from 'views/components/BooleanFilter/BooleanFilter'
import { endpoints } from 'modules/endpoints'
import { FiltersProps, useFilters } from 'hooks/useFilter'
import { CarrierStatusFilter } from './CarrierStatusFilter'
import { SearchFilter } from 'views/components/SearchFilter/SearchFilter'
import { ADateRangePicker } from 'views/components/TableFilters/DateRangePicker/ADateRangePicker'
import { ABaseTableFilter } from 'views/components/TableFilters/ABaseTableFilter'
import { AClearFiltersButton } from 'views/components/Analytics'

interface Props extends FiltersProps {
  onClearFilters: () => void
  updateFilterState: ReturnType<typeof useFilters>['updateFilterState']
}

export const CarriersKRFilters: FC<Props> = ({ updateFilterState, listRequestParams, onClearFilters }) => {
  const { t } = useTranslation('logisticsKR')

  const displayClearBtn = useDisplayClearBtn({
    listRequestParams,
  })

  return (
    <FiltersAndSearchWrapper>
      <FiltersWrapper>
        <ADateRangePicker
          id="dateFilter"
          value={[listRequestParams.filter.date_joined_after, listRequestParams.filter.date_joined_before]}
          onChange={(value) =>
            updateFilterState({ date_joined_after: value.startDate, date_joined_before: value.endDate })
          }
        />
        <CarrierStatusFilter updateFilterState={updateFilterState} listRequestParams={listRequestParams} />
        <BooleanFilter
          id="is_in_charter"
          value={listRequestParams.filter.is_in_charter}
          onChange={(value) => updateFilterState({ is_in_charter: value })}
          placeholder={t('logisticsKR:cars.filters.is_in_charter')}
        />
        <ABaseTableFilter
          onChange={(working_regions) => updateFilterState({ working_regions })}
          value={listRequestParams.filter.working_regions}
          placeholder={t('carriers.region')}
          getLabel={(label) => label.name}
          endpoint={endpoints.provinces()}
          simpleApiFormat
          multiple
          id="regionFilter"
        />
        {displayClearBtn && <AClearFiltersButton onClick={onClearFilters} />}
      </FiltersWrapper>
      <SearchFilter updateFilterState={updateFilterState} listRequestParams={listRequestParams} />
    </FiltersAndSearchWrapper>
  )
}
