import { AntCollapse, Progress } from '@agro-club/agroclub-shared'
import { WrapperCollapse } from 'views/components/Board/styled'
import { BidsPanel } from '../../Board/BidsColumn/BidsPanel'
import { Bid, BidStatus } from 'modules/domain/bid/types'
import { FC, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'

type Props = {
  setActiveBid: (arg: Bid) => void
  activeBid?: Bid
  publishedBids: Bid[]
  progressPublishedBids: Progress
  archivedBids: Bid[]
  progressArchivedBids: Progress
  refetchPublishedBids: () => void
}

export const BidsColumn: FC<Props> = ({
  setActiveBid,
  activeBid,
  publishedBids,
  progressPublishedBids,
  archivedBids,
  progressArchivedBids,
  refetchPublishedBids,
}) => {
  const { t } = useTranslation()

  const [activePanel, setActivePanel] = useState<BidStatus>()

  const { bidId, statusBid } = useParams()

  const scrollToActiveCard = () => {
    const el = document.getElementById(`${bidId}`)
    el?.scrollIntoView({ block: 'center', inline: 'center' })
  }

  useEffect(() => {
    if (!statusBid || statusBid === BidStatus.published) {
      setActivePanel(BidStatus.published)
      return
    }

    if (statusBid === BidStatus.archived) {
      setActivePanel(BidStatus.archived)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (!statusBid && publishedBids.length) {
      setActiveBid(publishedBids[0])
      return
    }

    let active: Bid | undefined = undefined

    if (publishedBids.length && statusBid === BidStatus.published) {
      active = publishedBids.find((bid) => bid.id == bidId)
    }

    if (archivedBids.length && statusBid === BidStatus.archived) {
      active = archivedBids.find((bid) => bid.id == bidId)
    }

    if (active) {
      setActiveBid(active)
      scrollToActiveCard()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [publishedBids, archivedBids])

  if (!activePanel) {
    return
  }
  return (
    <WrapperCollapse>
      <AntCollapse defaultActiveKey={activePanel} noMarginTop accordion>
        <BidsPanel
          refetch={refetchPublishedBids}
          setActiveBid={setActiveBid}
          namePanel={t('bid:bids')}
          key={BidStatus.published}
          colorCount={'orange'}
          activeBid={activeBid}
          bids={publishedBids}
          progress={progressPublishedBids}
        />
        <BidsPanel
          namePanel={t('bid:archived_bids')}
          setActiveBid={setActiveBid}
          key={BidStatus.archived}
          activeBid={activeBid}
          colorCount={'grey'}
          bids={archivedBids}
          progress={progressArchivedBids}
        />
      </AntCollapse>
    </WrapperCollapse>
  )
}
