import { FC } from 'react'
import { useTranslation } from 'react-i18next'

import { ClearFiltersButton } from '@agro-club/agroclub-shared'
import { useAnalyticsClickHandler } from 'analytics/hooks'

interface Props {
  onClick: () => void
  children?: React.ReactNode
}

const AClearFiltersButton: FC<Props> = ({ onClick }) => {
  const { t } = useTranslation('common')

  const handleClick = useAnalyticsClickHandler('clearFilters')

  return (
    <ClearFiltersButton
      onClick={handleClick(onClick, {
        filter: 'clearFilters',
      })}
      data-test-id="clear_filters"
    >
      {t('resetAllFilters')}
    </ClearFiltersButton>
  )
}

export default AClearFiltersButton
