import { FC } from 'react'
import {
  LogisticsKRLoadingWarehouse,
  LogisticsKRUnloadingWarehouse,
  isUnloadingWarehouse,
} from 'views/pages/LogisticsKR/types'
import { useTranslation } from 'react-i18next'
import formatNumber from 'modules/utils/numbers/formatNumber'
import { removeSeconds } from 'views/pages/LogisticsKR/helpers'
import { formatQuantity } from 'modules/utils/numbers/formatQuantity'
import { formatDistance } from 'modules/utils/numbers/formatDistance'
import { DASH } from 'modules/constants'

interface Props {
  warehouse: LogisticsKRLoadingWarehouse | LogisticsKRUnloadingWarehouse
}

export const WarehouseParams: FC<Props> = ({ warehouse }) => {
  const { t } = useTranslation('logisticsKR')
  const warehouseYNU = (value) => t(`warehouse:yesNoUnknown:${value}`)

  const yesNoUnknown = (field: boolean) => {
    switch (field) {
      case true:
        return t('warehouse:yesNoUnknown:yes')
      case false:
        return t('warehouse:yesNoUnknown:no')
      default:
        return t('warehouse:yesNoUnknown:unknown')
    }
  }

  if (isUnloadingWarehouse(warehouse)) {
    const unloadingParams = [
      warehouse.gates_height ? `${formatNumber(warehouse.gates_height)} ${t('common:m')}` : DASH,
      warehouse.vehicle_type?.map((type) => t(`vehicle_type.${type}`))?.join(', '),
      warehouse.is_dump_truck ? t('car:is_dump_truck') : t('car:is_not_dump_truck'),
      warehouse.unloading_type ? t(`directions.${warehouse.unloading_type}`) : DASH,
      warehouse.volume_per_day ? `${warehouse.volume_per_day} ${t('common:tDay')}` : DASH,
      warehouseYNU(warehouse.weather_conditions),
      warehouse.overload ? t(`address:overLoadValues.${warehouse.overload}`) : DASH,
      `${removeSeconds(warehouse.schedule_from)} - ${removeSeconds(warehouse.schedule_to)}`,
      yesNoUnknown(warehouse.works_on_weekend),
      yesNoUnknown(warehouse.car_delivery),
      yesNoUnknown(warehouse.railway_delivery),
    ].join(', ')

    return <div>{unloadingParams}</div>
  }

  const loadingParams = [
    warehouse.gates_height ? `${formatNumber(warehouse.gates_height)} ${t('common:m')}` : DASH,
    warehouse.loader_type ? warehouse.loader_type.map((type) => t(`vehicle_type.${type}`)) : DASH,
    formatQuantity(warehouse.scales_capacity),
    warehouse.scales_remoteness ? formatDistance(warehouse.scales_remoteness) : DASH,
    warehouse.scales_length ? `${warehouse.scales_length} ${t('common:m')}` : DASH,
    warehouse.volume_per_day ? `${warehouse.volume_per_day} ${t('common:tDay')}` : DASH,
    warehouseYNU(warehouse.weather_conditions),
    warehouse.vehicle_type.map((type) => t(`vehicle_type.${type}`))?.join(', '),
    warehouse.overload ? t(`address:overLoadValues.${warehouse.overload}`) : DASH,
    `${removeSeconds(warehouse.schedule_from)} - ${removeSeconds(warehouse.schedule_to)}`,
    yesNoUnknown(warehouse.works_on_weekend),
    yesNoUnknown(warehouse.car_delivery),
    yesNoUnknown(warehouse.railway_delivery),
  ].join(', ')

  return <div>{loadingParams}</div>
}
