import { FC } from 'react'

import { defaultTheme } from '@agro-club/agroclub-shared'
import { isBrazil } from 'env'
import { BidTypes } from 'modules/domain/bid/types'
import { CommentType } from 'modules/domain/comment/types'
import { DealStatusTypes } from 'modules/domain/deal/types'
import { getDealBidAndContract, isDealClosed } from 'modules/domain/deal/utils'
import { endpoints } from 'modules/endpoints'
import { useTranslation } from 'react-i18next'
import { useMediaQuery } from 'react-responsive'
import { Card } from 'views/components/Card/Card'
import AComments from 'views/components/Comments/AComments'
import { containsFilledDatePeriod } from 'views/components/DatePeriodFormatted'
import { DealPartyChangeData } from '../../hooks/useDealPartyChange'
import { BidDetailProduct } from '../BidDetailBlock/BidDetailProduct/BidDetailProduct'
import { useDealContext } from '../DealContext'
import { BidDetailAddress } from './BidDetailAddress'
import { BidDetailCompanyUser } from './BidDetailCompanyUser'
import { BidDetailSample } from './BidDetailSample'
import { BidDetailTitleTags } from './BidDetailTitleTags'
import { ContractPaymentDetail } from './ContractPaymentDetail'
import { BidDetailTitle } from './DetailTitle/BidDetailTitle'
import { TurnoverDays } from './TurnoverDays'
import { BidVolumeTracker } from './BidVolumeTracker/BidVolumeTracker'

export type BidDetailBlockProps = {
  bidType: BidTypes
  partyChanger: DealPartyChangeData
}

export const BidDetailBlockDefault: FC<BidDetailBlockProps> = ({ bidType, partyChanger }) => {
  const { t } = useTranslation(['deal', 'bid', 'address'])
  const { deal, dealRefetch } = useDealContext()
  const { bid, contract } = getDealBidAndContract(deal, bidType)

  const isPurchase = bidType === BidTypes.purchase
  let turnoverDays: number | undefined
  let actualTurnoverDays: number | undefined
  const turnoverDate = deal.purchase_bid.company.turnover_sync_date
  let actualTurnoverDate: string | undefined
  if (isPurchase) {
    turnoverDays = deal.purchase_bid.company.turnover_days
    actualTurnoverDays = deal.customer_actual_turnover_days
    actualTurnoverDate = deal.customer_actual_turnover_sync_date
  } else {
    turnoverDays = deal.purchase_bid.company.affectable_turnover_days
    actualTurnoverDays = deal.seller_actual_turnover_days
    actualTurnoverDate = deal.seller_actual_turnover_sync_date
  }

  const sampleIsVisible =
    ![DealStatusTypes.DRAFT, DealStatusTypes.NEW].includes(deal.status) && !isPurchase && !isBrazil
  const readOnlyBidDetail = containsFilledDatePeriod(bid) || deal.status === DealStatusTypes.DRAFT || !!contract

  const contractOrBid = contract || bid
  const isMobile = useMediaQuery({
    query: `(max-width: ${defaultTheme.queries.mobile})`,
  })

  return (
    <Card.Container bordered={!isMobile}>
      <Card.GapableContent>
        <BidDetailTitle bidType={bidType} refetch={dealRefetch} deal={deal} partyChanger={partyChanger}>
          <BidDetailTitleTags deal={deal} bidType={bidType} />
        </BidDetailTitle>
        <TurnoverDays
          plan={turnoverDays}
          actual={actualTurnoverDays}
          planDate={turnoverDate}
          actualDate={actualTurnoverDate}
        />
        <BidDetailProduct bid={bid} contract={contract} readOnly={readOnlyBidDetail} refetch={dealRefetch} />
        {sampleIsVisible && <BidDetailSample bid={bid} />}
        {contract && <ContractPaymentDetail contract={contract} />}
        <BidDetailCompanyUser bid={contractOrBid} />
        <BidDetailAddress deal={deal} bidType={bidType} refetch={dealRefetch} readOnly={!!contract} />
        <BidVolumeTracker bidType={bidType} />
      </Card.GapableContent>

      <Card.HorizontalDivider />

      <Card.GapableContent>
        <Card.Title>{t('common:comments')}</Card.Title>
        <div>
          <AComments
            maxHeight={300}
            isClosed={isDealClosed(deal)}
            id={bid?.owner?.id}
            type={CommentType.user}
            url={endpoints.userComments(bid?.owner?.id)}
            eventPostFix={isPurchase ? 'buyer' : 'seller'}
          />
        </div>
      </Card.GapableContent>
    </Card.Container>
  )
}
