import { urlMaker } from 'modules/utils/urlMaker'

export const warehousesEndpoints = {
  warehouses: urlMaker('/warehouses/'),
  warehousesActive: () => '/warehouses/?status=active',
  warehousesArchived: () => '/warehouses/?status=archived',
  warehousesShortList: () => `/warehouses/short-list/`,
  warehouseMovements: urlMaker('/warehouses/movements/'),
  warehouseMovementsForDeal: (dealId: string) =>
    `/warehouses/movements/?movement_type=income&page_size=1000&sort=-created&source_deal=${dealId}`,
  warehouseMovementsById: (warehouseId: string) => `/warehouses/movements/?warehouse=${warehouseId}`,
  warehouseChangeStatus: (id: string) => `/warehouses/${id}/change_status/`,
}
