import {
  defaultTheme,
  Icons,
  SectionTable,
  SectionTableBodyRow,
  SectionTableHeadRow,
  Typography,
  SectionTableBodyCell as UISectionTableBodyCell,
  SectionTableHeadCell as UISectionTableHeadCell,
} from '@agro-club/agroclub-shared'
import { generatePath } from 'react-router-dom'
import styled from 'styled-components'

import { FC } from 'react'
import { ARouterLink } from 'views/components/Analytics'
import UserRoutes from 'views/pages/User/routes'
import { SignStatus } from './types'

export const DetailPageTitle = styled.div`
  ${Typography.titleH3}
  color: ${defaultTheme.color.secondary900};
  margin-bottom: 4px;
`

export const DetailPageTitleWithTag = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`

export const DrawerShippingWrapper = styled.div``

export const TableWrapper = styled.div`
  border: 1px solid ${(props) => props.theme.color.secondary50};
  border-radius: 8px;
  margin-bottom: 8px;
`

export const SectionTableWithoutBorder = styled(SectionTable)`
  border-collapse: collapse;
`

export const SectionTableHeadRowWithPadding = styled(SectionTableHeadRow)`
  & > td:first-child {
    padding-left: 8px;
  }
`

export const HeadCell = styled(UISectionTableHeadCell)`
  ${Typography.bodyLarge};
  color: ${defaultTheme.color.textSecondary60a};

  &,
  &&:first-child,
  &&:last-child {
    padding: 11px 12px;
  }

  white-space: nowrap;
`
export const BodyCell = styled(UISectionTableBodyCell)`
  ${Typography.bodyLarge};
  color: ${defaultTheme.color.textPrimary900};

  &,
  &:first-child,
  &:last-child {
    padding: 11px 12px;
  }
`
export const SecondaryBodyCell = styled(UISectionTableBodyCell)`
  &,
  &:first-child,
  &:last-child {
    ${Typography.accentMedium}
    color: ${defaultTheme.color.textPrimary900};
    padding: 5px 12px;
  }
`

export const ButtonsBodyCell = styled(UISectionTableBodyCell)`
  &,
  &:first-child,
  &:last-child {
    padding: 5px 12px 5px 0;
  }
`

export const ButtonsWrapper = styled.div`
  position: absolute;
  right: 12px;
  top: 12px;
`

export const TableRow = styled(SectionTableBodyRow)`
  position: relative;
  border-top: 1px solid ${(props) => props.theme.color.secondary50};
`

export const SecondRow = styled(SectionTableBodyRow)`
  & > * {
    ${Typography.accentLarge};
    padding-top: 3px;
  }
`

export const StepsColumn = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
`

export const StepContainer = styled.div`
  display: flex;
`

export const StatusContainer = styled.div`
  display: flex;
  flex-direction: column;
`
export const StatusTitleContainer = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 4px;
`

export const StatusTitle = styled.div<{ status: string }>`
  ${Typography.bodyLarge}
  color: ${({ status }) =>
    status === SignStatus.INACTIVE ? defaultTheme.color.textSecondary60a : defaultTheme.color.secondary900};
  margin-left: 8px;
`

export const StatusDescription = styled.div<{ status: string }>`
  ${Typography.bodyMedium}
  color: ${({ status }) => {
    switch (status) {
      case SignStatus.INACTIVE:
        return defaultTheme.color.textTertiary30a

      case SignStatus.ACTIVE:
        return defaultTheme.color.textSecondary60a

      default:
        return defaultTheme.color.textPrimary900
    }
  }}
`

export const AccentDescription = styled.div<{ status: string }>`
  ${Typography.accentMedium};
  color: ${({ status }) => {
    switch (status) {
      case SignStatus.INACTIVE:
        return defaultTheme.color.textTertiary30a

      case SignStatus.ACTIVE:
        return defaultTheme.color.textSecondary60a

      default:
        return defaultTheme.color.textPrimary900
    }
  }};
`

export const StatusDescriptionContainer = styled.div<{ isLastItem: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  row-gap: 4px;
  margin-left: 8px;
  padding-left: 24px;
  border-left: ${({ isLastItem }) => !isLastItem && `1px solid ${defaultTheme.color.secondary100}`};
`

export const IconCheckMark = styled(Icons.IconCheckMark)<{ status: string }>`
  path {
    ${({ status }) => status === SignStatus.ACTIVE && `fill: ${defaultTheme.color.accentNotify600}`}
    ${({ status }) => status === SignStatus.DONE && `fill: ${defaultTheme.color.icons600}`}
  }
`

export const IconAccount = styled(Icons.IconAccount)<{ status: string }>`
  path {
    ${({ status }) => status === SignStatus.ACTIVE && `fill: ${defaultTheme.color.accentNotify600}`}
    ${({ status }) => status === SignStatus.DONE && `fill: ${defaultTheme.color.icons600}`}
  }
`

export const IconSign = styled(Icons.IconSign)<{ status: string }>`
  path {
    ${({ status }) => status === SignStatus.ACTIVE && `fill: ${defaultTheme.color.accentNotify600}`}
    ${({ status }) => status === SignStatus.DONE && `fill: ${defaultTheme.color.icons600}`}
  }
`
export const IconSignFin = styled(Icons.IconSignFin)<{ $isSigned: boolean }>`
  path {
    fill: ${({ $isSigned }) => ($isSigned ? defaultTheme.color.icons600 : defaultTheme.color.accentNotify600)};
  }
`

export const SignActiveStatusDescription = styled(StatusDescription)`
  margin-bottom: 4px;
`

export const UserStyledLink = styled(ARouterLink)`
  ${Typography.bodyLarge};
  display: block;
  color: ${defaultTheme.color.primary600};
  cursor: pointer;
  word-break: break-word;

  &:hover {
    text-decoration: underline;
  }
`

interface UserLinkProps {
  id: string
  name: string
}

export const UserLink: FC<UserLinkProps> = ({ id, name }) => {
  return (
    <UserStyledLink eventName="user" itemId={id} to={generatePath(UserRoutes.Details, { id: id || '0' })}>
      {name}
    </UserStyledLink>
  )
}

export const TitleWithButtons = styled.div`
  display: flex;
  column-gap: 16px;

  button {
    margin-top: -4px;
  }
  @media (max-width: ${defaultTheme.queries.mobile}) {
    justify-content: space-between;
  }
`

export const Container = styled.div`
  && > div > div:before {
    background-color: ${(props) => props.theme.color.secondary50};
  }
`

export const StyledRatingItem = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 4px;
`

export const RatingItemWrapper = styled.div`
  display: flex;
  align-items: center;
  column-gap: 10px;
`

export const RatingItemContainer = styled.div`
  display: flex;
  align-items: center;
  column-gap: 8px;
`

export const RatingItemTitle = styled.div`
  ${Typography.bodyLarge}
  color: ${defaultTheme.color.textPrimary900};
`

export const RatingItemSubTitle = styled.div`
  ${Typography.bodyMedium}
  color: ${defaultTheme.color.textSecondary60a};
`

export const RatedBy = styled.div`
  ${Typography.bodyMedium}
  color: ${defaultTheme.color.textPrimary900};
`

export const RatedByLink = styled(ARouterLink)`
  ${Typography.bodyMedium}
  color: ${defaultTheme.color.textForLink};
`

export const StyledIcon = styled.div`
  display: flex;
  width: 35px;
  height: 24px;
  padding-right: 11px;

  path {
    transition: fill 0.3s ease;
  }

  &:hover {
    path {
      fill: ${defaultTheme.color.secondary900};
    }
  }
`

export const StyledRatingsList = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 13px;
`

export const TooltipContent = styled.div`
  ${Typography.bodyMedium}
  white-space: pre-wrap;
`

export const SpinnerWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 415px;
`
