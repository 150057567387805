import { AntSwitch } from '@agro-club/agroclub-shared'
import { useAFormHandler } from 'analytics/hooks'
import { isBrazil, isUS } from 'env'
import { useFormik } from 'formik'
import { Company } from 'modules/domain/company/types'
import { endpoints } from 'modules/endpoints'
import { RequestError } from 'modules/errors'
import { apiClient } from 'modules/utils/httpClient'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import ADefaultModalFooter from 'views/components/DefaultModalFooter/ADefaultModalFooter'
import { ASharedModal, SharedModalContent, SharedModalInputs, SharedModalTitle } from 'views/components/SharedModal'
import { AnalyticsPlaces } from '../analyticsPlaces'
import { CompanyWorldFields } from './AddWorldCompany'
import { CommonFields } from './CommonFields'

const SwitchFormWrapper = styled.div`
  display: flex;
  align-items: center;
  column-gap: 4px;
`

type Props = {
  refreshCompany: (arg: boolean) => void
  onClose: () => void
  company: Company
}

export const EditWorldCompany: FC<Props> = ({ onClose, refreshCompany, company }) => {
  const { t } = useTranslation('company')
  const { formProgress, formHandler } = useAFormHandler()

  const formik = useFormik<CompanyWorldFields>({
    initialValues: {
      payment_delay_days: company.payment_delay_days,
      state_reg_number: company.state_reg_number,
      prepayment: company.prepayment,
      name: company.name,
      inn: company.inn,
      soy_restriction: company.soy_restriction,
      commercial: company.commercial,
      sync_with_accounting: company.synced_with_accounting,
    },
    onSubmit: formHandler(() => apiClient.put<CompanyWorldFields>(endpoints.company(company.id), formik.values), {
      onSuccess: () => {
        refreshCompany(true)
        onClose()
      },
      onError: (err) => {
        const error = err as RequestError
        const { errors } = error
        formik.setErrors(errors)
      },
    }),
  })

  return (
    <ASharedModal id={AnalyticsPlaces.DETAILS_MODAL} onClose={onClose}>
      <SharedModalTitle>{t('commonInfo')}</SharedModalTitle>
      <SharedModalContent>
        <SharedModalInputs>
          {isUS && (
            <SwitchFormWrapper>
              <AntSwitch
                checked={formik.values.sync_with_accounting}
                onChange={(value) => formik.setFieldValue('sync_with_accounting', value)}
                disabled={company.synced_with_accounting}
              />
              {t('irelySync')}
            </SwitchFormWrapper>
          )}
          <CommonFields.NameCompany formik={formik} />
          <CommonFields.PersonalNumber formik={formik} />
          {isBrazil && <CommonFields.RegNumber formik={formik} />}

          <CommonFields.PrepaymentState formik={formik} />
          <CommonFields.PaymentDelayDays formik={formik} />
          {isBrazil && <CommonFields.SoyRestriction formik={formik} />}
          {isUS && <CommonFields.CommercialField formik={formik} />}
        </SharedModalInputs>
      </SharedModalContent>

      <ADefaultModalFooter
        confirmButtonText={t('common:save')}
        onConfirm={formik.handleSubmit}
        progress={formProgress}
        onClose={onClose}
      />
    </ASharedModal>
  )
}
