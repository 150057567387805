const main = 'sdiz-documents'

const relativeRoutes = {
  relativePage: `${main}/*`,
  relativeList: `${main}`,
}

export const SdizRoutes = {
  ...relativeRoutes,
  List: `/${main}`,
}
