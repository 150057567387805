import { Icons } from '@agro-club/agroclub-shared'
import { useFetchDataWithAbort } from 'hooks/useFetchDataWithAbort'
import { BidStatus, BidTypes, USBid } from 'modules/domain/bid/types'
import { endpoints } from 'modules/endpoints'
import { FC, useState } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { ListResponse } from 'types/api'
import { AButton } from 'views/components/Analytics'
import { Pagination } from 'views/components/Pagination/Pagination'
import { SingleValueFilter } from 'views/components/SingleValueFilter/SingleValueFilter'
import { BidCreateModal } from 'views/pages/Bid/BidModals/BidCreateModal'
import { OffersList } from './OffersList'

export const ACC_MANAG_OFFERS_PAGE_SIZE = 3

const NewOfferButton = styled(AButton).attrs({ Icon: Icons.IconAdd, color: 'approve', size: 'big' })`
  width: 100%;
`
const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 16px;
  margin-top: 8px;
`

const ListWithFiltersWrapper = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 12px;
`

const FiltersWrapper = styled.div`
  display: flex;
  column-gap: 8px;
`

type Props = {
  companyId: string
}

export const Offers: FC<Props> = ({ companyId }) => {
  const { t } = useTranslation('bid')
  const [isOpen, setIsOpen] = useState(false)
  const [bidTypeFilter, setBidTypeFilter] = useState<BidTypes | undefined>(undefined)
  const [page, setPage] = useState(1)

  const {
    data: offers,
    progress: offersProgress,
    refetch: offersRefetch,
  } = useFetchDataWithAbort<ListResponse<USBid>>(
    endpoints.bid(),
    {
      page,
      page_size: ACC_MANAG_OFFERS_PAGE_SIZE,
      company_id: companyId,
      status: BidStatus.published,
      bid_type: bidTypeFilter,
    },
    [page, companyId, bidTypeFilter],
  )

  return (
    <Wrapper>
      <NewOfferButton id="addOffers" onClick={() => setIsOpen(true)}>
        {t('bid:newOffer')}
      </NewOfferButton>
      <ListWithFiltersWrapper>
        <FiltersWrapper>
          <SingleValueFilter isActive={!bidTypeFilter} onClick={() => setBidTypeFilter(undefined)}>
            {t('common:all')}
          </SingleValueFilter>
          <SingleValueFilter
            isActive={bidTypeFilter === BidTypes.purchase}
            onClick={() => setBidTypeFilter(BidTypes.purchase)}
          >
            {t('bidTypes.purchase')}
          </SingleValueFilter>
          <SingleValueFilter isActive={bidTypeFilter === BidTypes.sale} onClick={() => setBidTypeFilter(BidTypes.sale)}>
            {t('bidTypes.sale')}
          </SingleValueFilter>
        </FiltersWrapper>
        <OffersList progress={offersProgress} offers={offers?.results} />
      </ListWithFiltersWrapper>
      <Pagination
        total={offers?.count ?? 0}
        currPage={page}
        pageSize={ACC_MANAG_OFFERS_PAGE_SIZE}
        setCurrPage={setPage}
        disableMargin
      />

      {isOpen && <BidCreateModal companyId={companyId} onSuccess={offersRefetch} onClose={() => setIsOpen(false)} />}
    </Wrapper>
  )
}
