import { REACT_APP_CURRENCY_SYMBOL } from 'env'
import { DASH } from 'modules/constants'
import { LogisticRequest } from 'modules/domain/logisticsKR/types'
import { ColumnData } from 'modules/types'
import formatNumber from 'modules/utils/numbers/formatNumber'
import { useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { DriverCell } from 'views/pages/LogisticsKR/LogisticRequests/components/DriverCell'
import { LoadUploadCell } from 'views/pages/LogisticsKR/LogisticRequests/components/LoadUploadCell/LoadUploadCell'
import { useTtnFilesContext } from '../../../TtnFilesContext'
import { CarToTripCell } from './CarToTripCell'

export const useRegularColumns = (
  request: LogisticRequest,
  refetchApprovingTable: () => void,
  refetchTripsTable: () => void,
): ColumnData[] => {
  const { t } = useTranslation('logisticsKR')
  const { ttnListRefetch, deletedFileId } = useTtnFilesContext()

  const onSuccess = useCallback(() => {
    refetchTripsTable()
    ttnListRefetch(true)
  }, [refetchTripsTable, ttnListRefetch])

  const onCell = () => ({
    style: {
      verticalAlign: 'top',
    },
  })

  return useMemo(
    () => [
      {
        title: t('details.tableHeaders.load_date'),
        width: 192,
        dataIndex: 'load_date',
        onCell,
        render: (_, trip) => (
          <LoadUploadCell externallyDeletedDocId={deletedFileId} onSuccess={onSuccess} trip={trip} loadType="load" />
        ),
      },
      {
        title: t('details.tableHeaders.unload_date'),
        width: 192,
        dataIndex: 'unload_date',
        onCell,
        render: (_, trip) => (
          <LoadUploadCell externallyDeletedDocId={deletedFileId} onSuccess={onSuccess} trip={trip} loadType="unload" />
        ),
      },
      {
        title: t('details.tableHeaders.ttn'),
        width: 70,
        onCell,
        dataIndex: 'ttn_number',
      },
      {
        title: t('details.tableHeaders.driver'),
        dataIndex: 'driver',
        onCell,
        render: (driver, data) => <DriverCell driver={driver} logistics_request={data} />,
      },
      {
        title: t('details.tableHeaders.logistician'),
        width: 100,
        onCell,
        dataIndex: 'logistician',
        render: (logistician) => (logistician ? logistician.short_name || logistician.full_name : DASH),
      },
      {
        title: t('details.tableHeaders.trip_type'),
        width: 90,
        dataIndex: 'trip_type',
        onCell,
        render: (trip_type) => trip_type.map((type) => t(`details.trip_types.${type}`)).join(', '),
      },
      {
        title: t('details.tableHeaders.price_logistics', { currency: REACT_APP_CURRENCY_SYMBOL }),
        width: 100,
        dataIndex: 'price_logistics',
        align: 'right',
        onCell,
        render: (price_logistics) => formatNumber(price_logistics, true),
      },
      {
        title: '',
        width: 48,
        dataIndex: 'car',
        onCell,
        render: (car, trip) => (
          <CarToTripCell trip={trip} request={request} refetchTripsReconciliation={refetchApprovingTable} />
        ),
      },
    ],
    [deletedFileId, onSuccess, refetchApprovingTable, request, t],
  )
}
