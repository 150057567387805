import { ListRequestParams } from 'modules/domain/types'
import { endpoints } from 'modules/endpoints'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { ABaseTableFilter } from './ABaseTableFilter'

type Props = {
  listRequestParams: ListRequestParams
  updateFilterState: (filterObj: Record<string, string>) => void
}

export const MarketZoneFilter: FC<Props> = ({ listRequestParams, updateFilterState }) => {
  const { t } = useTranslation()
  return (
    <ABaseTableFilter
      onChange={(market_zone) => updateFilterState({ market_zone })}
      value={listRequestParams.filter.market_zone}
      placeholder={t('address:market_zone')}
      id="marketZoneFilter"
      getLabel={(zone) => zone.name}
      endpoint={endpoints.marketZones()}
      searchField="search"
      simpleApiFormat
      multiple
      allowClear
    />
  )
}
