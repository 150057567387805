import { AntCollapse, Progress } from '@agro-club/agroclub-shared'
import { WrapperCollapse } from 'views/components/Board/styled'
import { Bid, BidStatus } from 'modules/domain/bid/types'
import { apiClient } from 'modules/utils/httpClient'
import { endpoints } from 'modules/endpoints'
import { FC, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import { BidsPanel } from './BidsPanel'

type Props = {
  setActiveBid: (arg: Bid) => void
  activeBid?: Bid
  userId: string
}

export const BidsColumn: FC<Props> = ({ userId, setActiveBid, activeBid }) => {
  const { t } = useTranslation()
  const [archivedBids, setArchivedBids] = useState<Bid[]>([])
  const [publishedBids, setPublishedBids] = useState<Bid[]>([])
  const [potentialBids, setPotentialBids] = useState<Bid[]>([])
  const [progress, setProgress] = useState(Progress.IDLE)

  const [activePanel, setActivePanel] = useState<BidStatus>()
  const { bidId, statusBid } = useParams()

  const scrollToActiveCard = () => {
    const el = document.getElementById(`${bidId}`)
    el?.scrollIntoView({ block: 'center', inline: 'center' })
  }

  const fetchBids = () => {
    setProgress(Progress.WORK)
    const publishedBids = apiClient.get<Bid[]>(endpoints.userBids(userId, BidStatus.published))
    const archivedBids = apiClient.get<Bid[]>(endpoints.userBids(userId, BidStatus.archived))
    const potentialBids = apiClient.get<Bid[]>(endpoints.userPotentialBidList(userId))

    Promise.all([publishedBids, archivedBids, potentialBids])
      .then((values) => {
        setProgress(Progress.SUCCESS)
        setPublishedBids(values[0])
        setArchivedBids(values[1])
        setPotentialBids(values[2])
      })
      .catch((e) => {
        setProgress(Progress.ERROR)
        console.error(e)
      })
  }

  useEffect(() => {
    fetchBids()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (!statusBid || statusBid === BidStatus.published) {
      setActivePanel(BidStatus.published)
      return
    }

    if (statusBid === BidStatus.archived) {
      setActivePanel(BidStatus.archived)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (!statusBid && publishedBids.length) {
      setActiveBid(publishedBids[0])
      return
    }

    let active: Bid | undefined = undefined

    if (publishedBids.length && statusBid === BidStatus.published) {
      active = publishedBids.find((bid) => bid.id == bidId)
    }

    if (archivedBids.length && statusBid === BidStatus.archived) {
      active = archivedBids.find((bid) => bid.id == bidId)
    }

    if (active) {
      setActiveBid(active)
      scrollToActiveCard()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [publishedBids, archivedBids])

  if (!activePanel) {
    return
  }

  return (
    <WrapperCollapse>
      <AntCollapse defaultActiveKey={activePanel} noMarginTop accordion>
        <BidsPanel
          refetch={fetchBids}
          setActiveBid={setActiveBid}
          namePanel={t('bid:bids')}
          key={BidStatus.published}
          colorCount={'orange'}
          activeBid={activeBid}
          bids={publishedBids}
          progress={progress}
        />
        <BidsPanel
          namePanel={t('bid:archived_bids')}
          setActiveBid={setActiveBid}
          key={BidStatus.archived}
          activeBid={activeBid}
          colorCount={'grey'}
          bids={archivedBids}
          progress={progress}
        />
        <BidsPanel
          namePanel={t('bid:potential_bids')}
          key={BidStatus.potential}
          activeBid={activeBid}
          bids={potentialBids}
          colorCount={'grey'}
          progress={progress}
        />
      </AntCollapse>
    </WrapperCollapse>
  )
}
