import { defaultTheme, ExternalLink, Icons, Text } from '@agro-club/agroclub-shared'
import { ExportDocuments } from 'modules/domain/export/types'
import { FC } from 'react'
import styled from 'styled-components'
import { TruncatedValue } from 'views/components/TruncatedValue/TruncatedValue'

const FileIconWithLinkWrapper = styled.div`
  display: flex;
  column-gap: 8px;
  align-items: center;

  svg {
    width: 18px;
    height: 18px;
    path {
      fill: ${defaultTheme.color.secondaryPlaceholder3};
    }
  }
`

type FileItemProps = {
  file: ExportDocuments
  maxWidthLink?: string
}

export const FileItem: FC<FileItemProps> = ({ file, maxWidthLink }) => {
  return (
    <FileIconWithLinkWrapper>
      <Icons.IconClip />
      <ExternalLink href={file.uploaded_file} target="_blank" style={{ maxWidth: maxWidthLink }}>
        <Text typography="bodyMedium">
          <TruncatedValue hint={file.original_filename}>{file.original_filename}</TruncatedValue>
        </Text>
      </ExternalLink>
    </FileIconWithLinkWrapper>
  )
}
