import { AntdTabsProps, AntTag, Icons, Text } from '@agro-club/agroclub-shared'
import useFormatDate from 'hooks/useFormatDate'
import _ from 'lodash'
import { PAGE_SIZE_1K } from 'modules/constants'
import { CommentType } from 'modules/domain/comment/types'
import { useSingleEntity, useTableData } from 'modules/domain/common/hooks'
import { SlugLabelPair } from 'modules/domain/common/types'
import { Company } from 'modules/domain/company/types'
import { ListRequestParams } from 'modules/domain/types'
import { endpoints } from 'modules/endpoints'
import { FC, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { generatePath, useNavigate } from 'react-router'
import { useParams } from 'react-router-dom'
import styled from 'styled-components'
import { AAntdTabs, AButton } from 'views/components/Analytics'
import { Card } from 'views/components/Card/Card'
import AComments from 'views/components/Comments/AComments'
import APageWrapper from 'views/components/PageWrapper/APageWrapper'
import { defaultListRequestParams } from 'views/components/TableFilters/data'
import { KeyEntityIcon, USCompanyIrelyIcon } from 'views/components/TooltipIcons/TooltipIcons'
import * as Layout from 'views/layouts/NewLayout'
import SpinnerLayout from 'views/layouts/SpinnerLayout/SpinnerLayout'
import { USBoard } from 'views/pages/User/UserDetail/USBoard/USBoard'
import * as Header from 'views/ui/Header/Header'
import { AddCompanyControl } from '../AddCompanyControl'
import { CompanyCommercialTag } from '../CompanyCommercialTag'
import CompanyRoutes from '../routes'
import { BranchList } from './BranchList'
import { GeneralInfo } from './GeneralInfo/GeneralInfo'

export const Wrapper = styled.div`
  display: grid;
  grid-template-columns: 476px 1095px;
  gap: 0 16px;
  margin-top: 8px;
  overflow-x: auto;
  height: 100%;
`
const InfoBlock = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 12px 0;
`
const Tags = styled.div`
  display: flex;
  gap: 0 8px;
`
const NameBlock = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`
/* TODO: навести порядок с высотами в antCollapse, надо переделать во всем проекте*/
const WrapperBoard = styled.div`
  .ant-collapse {
    max-height: calc(100dvh - 100px);
  }
  .ant-collapse-content-box {
    max-height: calc(100dvh - 390px);
  }
`

const TagsWrapper = styled.div`
  display: flex;
  align-items: center;
  column-gap: 8px;
`

enum CompanyTabs {
  bids = 'bids',
  generalInfoAndUsers = 'generalInfoAndUsers',
  comments = 'comments',
}

export const UsCompanyDetail: FC = () => {
  const { t } = useTranslation('company')
  const { id, branchId } = useParams()
  const navigate = useNavigate()

  const [, company, refetchCompany] = useSingleEntity<Company>(endpoints.company(id))

  const [listRequestParams] = useState<ListRequestParams>({ ...defaultListRequestParams, pageSize: PAGE_SIZE_1K })

  const {
    progress: branchesProgress,
    data: branches = [],
    refetch: refetchBranches,
  } = useTableData<Company>(endpoints.companyBranches(id), listRequestParams, true)

  const [, reasons] = useSingleEntity<SlugLabelPair[]>(endpoints.dealsCloseReasons())

  const [addBranchModal, setAddBranchModal] = useState(false)
  const [activeBranch, setActiveBranch] = useState<Company | null>(null)
  const [activeTab, setActiveTab] = useState<CompanyTabs>(CompanyTabs.bids)
  const formatDate = useFormatDate()

  const refetch = () => {
    refetchCompany()
    refetchBranches()
  }

  useEffect(() => {
    if (!activeBranch && branches.length > 0) {
      setActiveBranch(branches.find((branch) => branch.id == branchId) || branches[0])
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [branches, branchId])

  useEffect(() => {
    if (!activeBranch) return
    const foundBranch = branches.find((branch) => branch.id === activeBranch.id)

    if (foundBranch && !_.isEqual(activeBranch, foundBranch)) {
      setActiveBranch(foundBranch)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeBranch, branches])

  useEffect(() => {
    if (company?.parent?.id) {
      navigate(generatePath(CompanyRoutes.DetailsWithBranch, { id: company.parent.id, branchId: company.id }))
    }
  }, [company, navigate])

  if (!company || !reasons || company.parent) {
    return <SpinnerLayout />
  }

  return (
    <APageWrapper page="companyDetail" place="companyDetailPage">
      <Layout.WrapperContent>
        <Layout.Header>
          <Layout.TopHeader>
            <Header.Breadcrumbs
              routes={[
                { path: CompanyRoutes.List, breadcrumbName: t('list.heading') },
                { breadcrumbName: company.name },
              ]}
            />
          </Layout.TopHeader>
        </Layout.Header>

        <Wrapper>
          <Card.Container>
            <Card.Grid rowGap={16}>
              <InfoBlock>
                <TagsWrapper>
                  <CompanyCommercialTag commercial={company.commercial} />
                  <USCompanyIrelyIcon isVisible={company.synced_with_accounting} size="large" />
                  <KeyEntityIcon isVisible={company.is_significant} size="large" />
                </TagsWrapper>

                <NameBlock>
                  <Text typography="titleH3">{company.name}</Text>
                  <Text typography="bodyMedium">{t('dateCreated', { date: formatDate(company.created, true) })}</Text>
                </NameBlock>

                <Tags>
                  <AntTag icon={<Icons.IconGrain height={16} width={16} />} color="white">
                    {t('branchCount', { branch: company.branch_count })}
                  </AntTag>

                  <AntTag icon={<Icons.IconRetailer height={16} width={16} />} color="white">
                    {t('usersCount', { users: company.employees_count_with_branches })}
                  </AntTag>

                  <AntTag icon={<Icons.IconDescription height={16} width={16} />} color="white">
                    {t('bidsCount', { bids: company.offers_count_with_branches })}
                  </AntTag>
                </Tags>
              </InfoBlock>

              <AButton
                onClick={() => setAddBranchModal(true)}
                Icon={Icons.IconAdd}
                color="approve"
                id="addBranch"
                size="big"
              >
                {t('addBranch')}
              </AButton>

              <BranchList
                setActiveCard={setActiveBranch}
                progress={branchesProgress}
                activeCard={activeBranch}
                branches={branches}
              />
            </Card.Grid>
          </Card.Container>
          {activeBranch && (
            <Card.Container>
              <AAntdTabs
                id="tabs"
                items={
                  [
                    {
                      count: activeBranch.offers_count,
                      label: t('bids'),
                      key: CompanyTabs.bids,
                      children: (
                        // The key is needed to re-render the entire component when the company changes
                        <WrapperBoard key={activeBranch.id}>
                          <USBoard reasons={reasons} companyId={activeBranch.id} />
                        </WrapperBoard>
                      ),
                    },
                    {
                      label: t('generalInfoAndUsers'),
                      key: CompanyTabs.generalInfoAndUsers,
                      children: <GeneralInfo company={activeBranch} refetch={refetch} />,
                    },
                    {
                      label: t('comments'),
                      key: CompanyTabs.comments,
                      children: (
                        <AComments
                          url={endpoints.companyComments(activeBranch.id)}
                          type={CommentType.company}
                          id={activeBranch.id}
                          maxHeight={400}
                        />
                      ),
                    },
                  ] as AntdTabsProps['items']
                }
                onChange={(key) => setActiveTab(key as CompanyTabs)}
                activeKey={activeTab}
              />
            </Card.Container>
          )}
        </Wrapper>
      </Layout.WrapperContent>

      {addBranchModal && (
        <AddCompanyControl
          setAddCompanyModalOpen={setAddBranchModal}
          parentCompanyId={company.id}
          refreshCompanies={refetch}
        />
      )}
    </APageWrapper>
  )
}
