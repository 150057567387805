import { useAProgress } from 'hooks/useAProgress'
import { endpoints } from 'modules/endpoints'
import { apiClient } from 'modules/utils/httpClient'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { ResponsiveModal } from 'views/components/ResponsiveModal/ResponsiveModal'
import { SharedModalContent } from 'views/components/SharedModal'

type Props = {
  onClose: () => void
  sdizId: string
  onSuccess: () => void
}

export const RemoveSdizModal: FC<Props> = ({ onClose, sdizId, onSuccess }) => {
  const { t } = useTranslation(['sdiz', 'common'])

  const [progress, remove] = useAProgress(() => apiClient.delete(endpoints.sdiz(sdizId)), {
    eventName: 'removeSdiz',
    onSuccess,
  })

  return (
    <ResponsiveModal
      id="removeSdizModal"
      onClose={onClose}
      title={t('removeSdiz')}
      onConfirm={remove}
      confirmButtonText={t('common:yes_remove')}
      progress={progress}
      size="small"
    >
      <SharedModalContent>{t('removeSdizConfirm')}</SharedModalContent>
    </ResponsiveModal>
  )
}
