import { apiClient } from 'modules/utils/httpClient'
import { User } from 'modules/domain/user/types'
import { useTranslation } from 'react-i18next'
import { endpoints } from 'modules/endpoints'
import { FC } from 'react'
import { useFormik } from 'formik'
import { useAFormHandler } from 'analytics/hooks'
import { ASharedModal, SharedModalTitle } from 'views/components/SharedModal'
import ADefaultModalFooter from 'views/components/DefaultModalFooter/ADefaultModalFooter'
import FormSelectShared from 'views/components/FormSelectShared'
import styled from 'styled-components'
import { refetchFunc } from 'modules/domain/common/hooks'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 8px;
`

type Props = {
  onClose: () => void
  refetch: refetchFunc
  user: User
  tradersOnly: boolean
}

type Fields = {
  responsible_coordinator?: string | number
}

export const UserUserRespCoordinatorModal: FC<Props> = ({ onClose, user, refetch, tradersOnly }) => {
  const { t } = useTranslation('user')
  const { formProgress, formHandler } = useAFormHandler('setTrader')
  const translateKey = tradersOnly ? 'assignedTrader' : 'assignedCoordinator'

  const formik = useFormik<Fields>({
    initialValues: {
      responsible_coordinator: user.profile?.responsible_coordinator?.id.toString() || undefined,
    },
    onSubmit: formHandler(
      async () => {
        await apiClient.post(endpoints.userSetRespCoordinator(user.id), formik.values)
        await refetch()
      },
      {
        onSuccess: onClose,
        onError: (error) => {
          const { errors } = error
          formik.setErrors(errors)
        },
      },
    ),
  })

  return (
    <ASharedModal size="small" onClose={onClose} id="TraderModal">
      <SharedModalTitle>{t(`${translateKey}.modalHeader`)}</SharedModalTitle>
      <Container>
        <FormSelectShared
          formik={formik}
          endpoint={endpoints.executorsByType(tradersOnly ? 'traders' : 'deal-coordinators')}
          fieldName="responsible_coordinator"
          searchField="search"
          placeholder={t(`${translateKey}.placeholder`)}
          getLabel={(dto) => dto.full_name}
          hideLabel
          disableRightPadding
        />
      </Container>
      <ADefaultModalFooter
        onConfirm={() => formik.handleSubmit()}
        confirmButtonText={t('common:save')}
        onClose={onClose}
        disabled={!formik.dirty}
        progress={formProgress}
      />
    </ASharedModal>
  )
}
