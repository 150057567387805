import { Icons } from '@agro-club/agroclub-shared'
import { Sdiz } from 'modules/domain/sdiz/types'
import { FC, useState } from 'react'
import styled from 'styled-components'
import { AButton } from 'views/components/Analytics'
import { EditSdizModal } from '../Modals/EditSdizModal'
import { RemoveSdizModal } from '../Modals/RemoveSdizModal'

const Wrapper = styled.div`
  display: flex;
  column-gap: 4px;
`

type Props = {
  sdiz: Sdiz
  refetch: () => void
}

export const ActionCell: FC<Props> = ({ sdiz, refetch }) => {
  const [editModal, setEditModal] = useState(false)
  const [removeModal, setRemoveModal] = useState(false)

  return (
    <Wrapper>
      <AButton id="sdizEdit" color="secondary" Icon={Icons.IconEdit} size="small" onClick={() => setEditModal(true)} />
      {editModal && <EditSdizModal onSuccess={refetch} sdiz={sdiz} onClose={() => setEditModal(false)} />}

      <AButton
        id="sdizRemove"
        color="destruct"
        Icon={Icons.IconRemove}
        size="small"
        onClick={() => setRemoveModal(true)}
      />
      {removeModal && <RemoveSdizModal onSuccess={refetch} sdizId={sdiz.id} onClose={() => setRemoveModal(false)} />}
    </Wrapper>
  )
}
