import { useAFormHandler } from 'analytics/hooks'
import { useFormik } from 'formik'
import { Sdiz } from 'modules/domain/sdiz/types'
import { endpoints } from 'modules/endpoints'
import { RequestError } from 'modules/errors'
import { apiClient } from 'modules/utils/httpClient'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { ResponsiveModal } from 'views/components/ResponsiveModal/ResponsiveModal'
import { SdizCommonFields, SdizFormFields } from './SdizCommonFields'

type Props = {
  onClose: () => void
  onSuccess: () => void
  sdiz: Sdiz
}

export const EditSdizModal: FC<Props> = ({ onClose, onSuccess, sdiz }) => {
  const { t } = useTranslation('sdiz')
  const { formProgress, formHandler } = useAFormHandler()

  const formik = useFormik<SdizFormFields>({
    initialValues: {
      sdiz_number: sdiz.sdiz_number,
      address: sdiz.address.id,
      product: sdiz.product.id,
      ttn_number: sdiz.ttn_number,
      ttn_date: sdiz.ttn_date,
    },

    onSubmit: formHandler((value) => apiClient.put(endpoints.sdiz(sdiz.id), value), {
      onSuccess: () => {
        onSuccess()
        onClose()
      },

      onError: (err) => {
        const { errors } = err as RequestError
        formik.setErrors(errors)
      },
    }),
  })

  return (
    <ResponsiveModal
      id="editSdiz"
      onClose={onClose}
      title={t('editSdiz')}
      onConfirm={() => formik.submitForm()}
      progress={formProgress}
      disabled={!formik.dirty}
    >
      <SdizCommonFields formik={formik} />
    </ResponsiveModal>
  )
}
