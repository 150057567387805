import { AntdTable } from '@agro-club/agroclub-shared'
import { useTableData } from 'modules/domain/common/hooks'
import { ListRequestParams } from 'modules/domain/types'
import { FC, useEffect } from 'react'

import { TableWrapper } from './styled'

export type Props = {
  endpoint: string
  columns: any[]
  requestParams: ListRequestParams
  setRequestParams: (value: ListRequestParams) => void
  showHeader?: boolean
  setCount?: (count: number | undefined) => void
}

//TODO Использование осталось только в рейтинге(его мб выпилим)
export const RightBlockTable: FC<Props> = ({
  endpoint,
  columns,
  requestParams,
  setRequestParams,
  showHeader = true,
  setCount,
}) => {
  const { progress, data = [], total } = useTableData(endpoint, requestParams, true)

  useEffect(() => {
    if (data.length && setCount) {
      setCount(data.length)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data])

  if (!data.length) {
    return null
  }

  return (
    <TableWrapper>
      <AntdTable
        data={data}
        rowKey="id"
        columns={columns}
        progress={progress}
        page={requestParams.page}
        pageSize={requestParams.pageSize}
        total={total}
        onPageChange={(page) => setRequestParams({ ...requestParams, page })}
        outerBordered
        showHeader={showHeader}
        rowHoverGray
      />
    </TableWrapper>
  )
}
