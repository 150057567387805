import { FiltersAndSearchWrapper, FiltersWrapper } from 'views/components/CommonTableComponents/CommonTableComponents'
import { ABaseTableFilter } from 'views/components/TableFilters/ABaseTableFilter'
import { AClearFiltersButton } from 'views/components/Analytics'
import { useDisplayClearBtn } from 'hooks/useDisplayClearBtn'
import { ListRequestParams } from 'modules/domain/types'
import { useTranslation } from 'react-i18next'
import { endpoints } from 'modules/endpoints'
import { FC } from 'react'
import styled from 'styled-components'
import { BidStatusFilter } from 'views/components/TableFilters/BidStatusFilter'

interface Props {
  setListRequestParams: (params: any) => void
  listRequestParams: ListRequestParams
  isFiltersLoaded?: boolean
  updateFilterState?: any
  userId: string
}
const WrapperAddressFilter = styled.div`
  width: 400px;
  .ant-select-filter,
  .ant-select-selector {
    max-width: 100% !important;
  }
`

export const Filters: FC<Props> = ({ userId, updateFilterState, listRequestParams, setListRequestParams }) => {
  const { t } = useTranslation('bid')
  const displayClearBtn = useDisplayClearBtn({
    listRequestParams,
  })
  return (
    <FiltersAndSearchWrapper style={{ marginTop: '16px' }}>
      <FiltersWrapper>
        <ABaseTableFilter
          placeholder={t('list.tableHeaders.product')}
          onChange={(product_id) => updateFilterState({ product_id })}
          value={listRequestParams.filter.product_id}
          getLabel={(label) => label.title}
          endpoint={endpoints.products()}
          showSearch={false}
          simpleApiFormat
          multiple
          id="productFilter"
        />

        <BidStatusFilter listRequestParams={listRequestParams} updateFilterState={updateFilterState} />

        <WrapperAddressFilter>
          <ABaseTableFilter
            onChange={(address) => updateFilterState({ address })}
            placeholder={t('form.userAddressPlaceholder')}
            endpoint={endpoints.userAddress(userId)}
            value={listRequestParams.filter.address}
            getLabel={(label) => label.address}
            dropdownMatchSelectWidth
            showSearch={false}
            simpleApiFormat
            fullWidth
            id="addressFilter"
          />
        </WrapperAddressFilter>

        {displayClearBtn && (
          <AClearFiltersButton onClick={() => setListRequestParams({ ...listRequestParams, filter: {} })} />
        )}
      </FiltersWrapper>
    </FiltersAndSearchWrapper>
  )
}
