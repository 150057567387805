import { defaultTheme, Typography } from '@agro-club/agroclub-shared'
import { css } from 'styled-components'

export const EntityVerifyActionStyle = css`
  display: flex;
  align-items: flex-start;
  cursor: pointer;
  ${Typography.bodySmall};

  svg {
    width: 32px;
    height: 32px;
    margin-right: 6px;
  }

  &:hover {
    color: ${defaultTheme.color.secondary900};

    svg > path {
      fill: ${defaultTheme.color.secondary600};
    }
  }

  & > div {
    display: flex;
    align-items: center;
  }
`
