import { FC } from 'react'

import styled from 'styled-components'
import { defaultTheme } from '@agro-club/agroclub-shared'
import { GeneralInfo } from 'views/pages/Deal/DealDetail/DealDetailBlock/GeneralInfo'
import { NumbersInfo } from 'views/pages/Deal/DealDetail/DealDetailBlock/NumbersInfo/NumbersInfo'

const GeneralAndNumbersWrapper = styled.div`
  position: relative;
  display: flex;
  column-gap: 16px;

  padding: 16px;
  border: 1px solid ${defaultTheme.color.secondary50};
  border-radius: 8px;
  background-color: ${defaultTheme.color.backgroundPrimary};

  & > :last-child {
    margin-top: auto;
  }

  @media (max-width: ${defaultTheme.queries.mobile}) {
    flex-direction: column;
    border: 1px solid ${defaultTheme.color.secondary100};
    padding: 0px;
  }
`

export const DealDetailBlock: FC = () => {
  return (
    <GeneralAndNumbersWrapper>
      <GeneralInfo />
      <NumbersInfo />
    </GeneralAndNumbersWrapper>
  )
}
