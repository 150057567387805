import { urlMaker } from 'modules/utils/urlMaker'

export const productsEndpoints = {
  productsChoicesForSms: () => `/products/choices/sms-notifications/`,
  products: urlMaker('/products/'),
  productById: (id: string) => `/products/${id}/`,
  productChoices: () => '/products/choices/',
  productChoicesForSmsNotifications: () => '/products/choices/sms-notifications/',
  potentialBid: urlMaker('/products/product-stocks/'),
}
