import { DealStatusTypes, Margins, MarginsforDeals, RuMarginsforDeals } from 'modules/domain/deal/types'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { isRussia } from 'env'
import { FC } from 'react'
import { GMVComponent } from './GMVComponent'
import { CommonMarginComponent } from './CommonMarginComponent'
import { RuMarginComponent } from './RuMarginComponent'
import { RuMarginShipmentComplete } from './RuMarginShipmentComplete'

type MarginType = {
  status: DealStatusTypes
  margins: MarginsforDeals | RuMarginsforDeals
  revenues?: Margins
  sum?: number | undefined
  sum2?: number | undefined
}

export const MoneyBlock = styled.div`
  padding: 4px 16px 0 0;
  display: flex;
  flex-direction: column;
`
const ShipmentComplete = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`

const MarginComponent = isRussia ? RuMarginComponent : CommonMarginComponent

export const DataMargin: FC<MarginType> = ({ status, margins, revenues }) => {
  const { t } = useTranslation('deal')

  const props = {
    status,
    margins,
  }

  const marginComponents = {
    [DealStatusTypes.CONTRACT_SIGNED]: <MarginComponent label={t('signed')} {...props} />,
    [DealStatusTypes.CONFIRMED_BATCH]: <MarginComponent label={t('gmv')} {...props} />,
    [DealStatusTypes.BEST_BATCH]: <MarginComponent label={t('gmv')} {...props} />,
    [DealStatusTypes.PAYMENT_SHIPMENT]: <MarginComponent label={t('margin_on_way')} {...props} />,
    [DealStatusTypes.PICK_UP_COMPLETE]: <MarginComponent label={t('margin_on_way')} {...props} />,
    [DealStatusTypes.SHIPMENT_COMPLETE]: isRussia ? (
      <RuMarginShipmentComplete margins={margins} />
    ) : (
      <ShipmentComplete>
        <MarginComponent label={t('fact')} {...props} />
        <MarginComponent label={t('was')} {...props} />
      </ShipmentComplete>
    ),
    default: <MarginComponent label={t('margin')} {...props} />,
  }

  return (
    <MoneyBlock>
      <GMVComponent revenues={revenues} status={status} />
      {marginComponents[status] || marginComponents.default}
    </MoneyBlock>
  )
}
