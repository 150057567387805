import { FormGroup } from '@agro-club/agroclub-shared'
import { QuestionTooltip } from '../QuestionTooltip/QuestionTooltip'
import { FormikProps } from 'formik'
import { useTranslation } from 'react-i18next'
import { FC } from 'react'
import { YesNoUnknownRadio } from './YesNoUnknownRadio'
import { WarehouseDTO } from 'modules/domain/types'

type Props = {
  formik: FormikProps<WarehouseDTO>
}

export const WeatherControl: FC<Props> = ({ formik }) => {
  const { t } = useTranslation('address')
  return (
    <FormGroup
      label={
        <div style={{ display: 'flex', gap: '4px' }}>
          {t(`fields.weather_conditions`)}
          <QuestionTooltip
            size="compact"
            variant="secondary"
            tooltipContent={<span style={{ whiteSpace: 'pre-line' }}>{t(`fields.weather_conditionsHint`)}</span>}
          />
        </div>
      }
    >
      <YesNoUnknownRadio
        onChange={(value) => formik.setFieldValue('weather_conditions', value)}
        value={formik.values.weather_conditions}
      />
    </FormGroup>
  )
}
