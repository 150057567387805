import { SERVER_DATE_FORMAT, Text } from '@agro-club/agroclub-shared'
import { addMonths, format, lastDayOfMonth } from 'date-fns'
import { useGetOfferPrices } from 'modules/domain/bid/useGetOfferPrices'
import { Address } from 'modules/domain/types'
import { FC, useState } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { HrWithHeader } from 'views/components/Hr/Hr'
import { ADateRangePicker } from 'views/components/TableFilters/DateRangePicker/ADateRangePicker'
import { BestBidsTable } from './BestBidsTable'
import { ClosestBidsTable } from './ClosestBidsTable'

const DeliveryPeriodWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 8px 0 16px 0;
`

type Props = {
  address: Address
  companyId: string
}

export const ShowPrices: FC<Props> = ({ address, companyId }) => {
  const { t } = useTranslation('bid')
  const defaultRangePickerValue = {
    start_date: '',
    end_date: format(lastDayOfMonth(addMonths(new Date(), 1)), SERVER_DATE_FORMAT),
  }
  const [rangeDate, setRangeDate] = useState<any>(defaultRangePickerValue)

  const { offersProgress, offers } = useGetOfferPrices(address, rangeDate, companyId)

  return (
    <div>
      <DeliveryPeriodWrapper>
        <Text typography="bodyMedium">{t('form.deliveryPeriod')}</Text>
        <ADateRangePicker
          value={[rangeDate.start_date, rangeDate.end_date]}
          onChange={(value) => setRangeDate({ start_date: value.startDate, end_date: value.endDate })}
          id="dateFilter"
          allowEmpty={[true, true]}
          place="ShowPrice"
        />
      </DeliveryPeriodWrapper>

      <HrWithHeader text={t('usShortBid.ourOffers')} forForm={false} />
      <BestBidsTable offers={offers.best} progress={offersProgress} />

      <HrWithHeader text={t('usShortBid.closestBuyer')} forForm={false} />
      <ClosestBidsTable offers={offers.closest} progress={offersProgress} />
    </div>
  )
}
