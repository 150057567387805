import { convertDistrictDtoToOptionType, useDistrict } from '../RegionDistrictControls/useDistrict'
import { Progress } from '@agro-club/agroclub-shared'
import { ASelectFilter } from '../Analytics'
import { FC } from 'react'

interface Props {
  value: string[] | string | undefined
  onChange: (value: any) => void
  region: string[] | string
  progress?: Progress
  placeholder?: string
}

export const DistrictFilter: FC<Props> = ({ value, region, onChange, placeholder }) => {
  const {
    valueArr,
    districts,
    placeholder: defaultPlaceholder,
    progress,
    debouncedSearch,
    handleDistrictChange,
  } = useDistrict(value, region, onChange)

  return (
    <ASelectFilter
      options={convertDistrictDtoToOptionType(districts)}
      loading={progress === Progress.WORK}
      id="districtFilter"
      onChange={handleDistrictChange}
      placeholder={placeholder ?? defaultPlaceholder}
      onSearch={debouncedSearch}
      groupField="parent_name"
      value={valueArr}
      mode="multiple"
    />
  )
}
