import { useSingleEntity, useTableData } from 'modules/domain/common/hooks'
import { Export, ExportDocuments } from 'modules/domain/export/types'
import { ListRequestParams } from 'modules/domain/types'
import { endpoints } from 'modules/endpoints'
import { formatQuantity } from 'modules/utils/numbers/formatQuantity'
import { FC, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import styled from 'styled-components'
import APageWrapper from 'views/components/PageWrapper/APageWrapper'
import { defaultListRequestParams } from 'views/components/TableFilters/data'
import * as Layout from 'views/layouts/NewLayout'
import SpinnerLayout from 'views/layouts/SpinnerLayout/SpinnerLayout'
import * as Header from 'views/ui/Header/Header'
import { ContractStatusTag, DeliveryBasisTag } from '../components/Tags'
import ExportRoutes from '../routes'
import { Controls } from './Controls'
import { ExportAccordion } from './ExportAccordion/ExportAccordion'
import { ExportLeftBlock } from './ExportLeftBlock/ExportLeftBlock'

export const WrapperButton = styled.div`
  display: flex;
  gap: 0 16px;
  margin-bottom: 3px;
`

export const ExportDetail: FC = () => {
  const { t } = useTranslation('export')
  const { id } = useParams()

  const [, exportData, refetch] = useSingleEntity<Export>(endpoints.export(id))
  const [listRequestParams, setListRequestParams] = useState<ListRequestParams>({
    ...defaultListRequestParams,
    pageSize: 5,
  })

  const {
    progress: historyProgress,
    refetch: refetchHistory,
    data: historyData = [],
    total: historyTotal,
  } = useTableData(endpoints.exportHistory(id), listRequestParams, true)
  const [, exportDocuments, exportDocumentsRefetch] = useSingleEntity<ExportDocuments[]>(endpoints.exportDocuments(id))

  const refetchAll = async () => {
    await refetch()
    await refetchHistory()
    await exportDocumentsRefetch()
  }

  if (!exportData || !exportDocuments) {
    return <SpinnerLayout />
  }

  return (
    <APageWrapper page="exportDetail" place="exportDetailPage">
      <Layout.WrapperContent>
        <Layout.Header>
          <Layout.TopHeader>
            <Header.Breadcrumbs
              routes={[
                { path: ExportRoutes.List, breadcrumbName: t('title') },
                {
                  breadcrumbName: t('export_contract', {
                    id: exportData.number,
                  }),
                },
              ]}
            />
            <Controls exportData={exportData} refetch={refetchAll} />
          </Layout.TopHeader>
          <Layout.DetailPageName>
            <Header.InlineContainer>
              <div>{exportData.product.title},</div>
              <div>{formatQuantity(Number(exportData.quantity))}</div>
              <ContractStatusTag status={exportData.status} />
              <DeliveryBasisTag deliveryBasis={exportData.delivery_basis} />
            </Header.InlineContainer>
          </Layout.DetailPageName>
        </Layout.Header>
        <Layout.ContentTwoColumns>
          <ExportLeftBlock exportData={exportData} exportDocs={exportDocuments} docsRefetch={exportDocumentsRefetch} />
          <ExportAccordion
            setListRequestParams={setListRequestParams}
            listRequestParams={listRequestParams}
            historyProgress={historyProgress}
            exportDocuments={exportDocuments}
            historyTotal={historyTotal}
            historyData={historyData}
            exportData={exportData}
            exportDocsRefetch={exportDocumentsRefetch}
          />
        </Layout.ContentTwoColumns>
      </Layout.WrapperContent>
    </APageWrapper>
  )
}
