import { FormattedEventsCount } from 'modules/socket/types'
import { useTranslation } from 'react-i18next'
import { useEffect, useState } from 'react'
import { getOptionCount } from '../utils'
import { OptionItem, OptionItemForRender } from '../types'
import MenuLabel from '../Components/MenuLabel'
import MenuIcon from '../Components/MenuIcon'

const useMenuOptions = (eventsCount: FormattedEventsCount, deviceOptions: OptionItem[], isMobile?: boolean) => {
  const [patchedOptions, setPatchedOptions] = useState<OptionItemForRender[]>([])
  const { t } = useTranslation('mobileMenu')

  const getOptions = (count: typeof eventsCount, options: OptionItem[], isChildrenOpt?: boolean) => {
    const patchedOptions = options.map((option) => {
      const optionCount = option.eventModel ? getOptionCount(count, option.eventModel) : 0

      const formattedOption = {
        key: option.key,
        label: isMobile ? (
          t(option.translateKey)
        ) : (
          <MenuLabel isChildrenOpt={isChildrenOpt} count={count} option={option} />
        ),
        icon: optionCount ? <MenuIcon showBadge={!isChildrenOpt && optionCount > 0} icon={option.icon} /> : option.icon,
      }

      if (option.children?.length) {
        return {
          ...formattedOption,
          children: getOptions(count, option.children, true),
        }
      }

      return formattedOption
    })

    return patchedOptions
  }

  useEffect(() => {
    setPatchedOptions(getOptions(eventsCount, deviceOptions))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [eventsCount, deviceOptions.length])

  return { menuOptions: patchedOptions }
}

export default useMenuOptions
