import { AntSkeleton, Checkbox, DateISO, FormGroup, Progress, Text } from '@agro-club/agroclub-shared'
import { DistrictFormControl } from 'views/components/RegionDistrictControls/DistrictFormControl'
import { SharedModalContent, SharedModalInputs } from 'views/components/SharedModal/styled'
import ADefaultModalFooter from 'views/components/DefaultModalFooter/ADefaultModalFooter'
import { TaskManagementForm } from './components/TaskManagementForm'
import FormSelectShared from 'views/components/FormSelectShared'
import { FC, ReactNode, useEffect, useState } from 'react'
import { useEntityTaskStat } from './useEntityTaskStat'
import { FormItemWrapper } from 'views/styled/common'
import { apiClient } from 'modules/utils/httpClient'
import { BoardItem } from './components/BoardItem'
import { useAFormHandler } from 'analytics/hooks'
import { InfoBoard, StatBoard } from './styled'
import { useTranslation } from 'react-i18next'
import { endpoints } from 'modules/endpoints'
import styled from 'styled-components'
import { useFormik } from 'formik'
import _ from 'lodash'
import { HrWithHeader } from 'views/components/Hr/Hr'

type ResponseUsersTaskStats = {
  do_not_call: string
  total_tasks: string
  with_tasks: string
  without_tasks: string
  with_responsible: string
}

export type ResponseCoordinatorsTaskStats = {
  self_created_tasks: string
  total_tasks: string
}

type Fields = {
  regions: string[]
  districts: string[]
  exclude_existing_tasks: boolean
  exclude_do_not_call: boolean
  exclude_with_responsible: boolean
  assignees: string[]
  team: string | null
  tasks_per_day: string
  other_task_per_day: string | null
  start_deadline_date: DateISO | undefined
}
type Props = {
  onClose: () => void
  children: ReactNode
  refetch: () => void
}
const LeftInfo = styled.div`
  display: flex;
  gap: 0 24px;
`

const StyledPreload = styled(AntSkeleton.Button)`
  height: 22px;
`

export const Preload = <StyledPreload active size="small" style={{ width: 582, borderRadius: 4 }} />

export const DistributeBlock: FC<Props> = ({ onClose, children, refetch }) => {
  const { t } = useTranslation('my_tasks')
  const [usersStat, setUsersStat] = useState<ResponseUsersTaskStats>()
  const [coordinatorsStat, setCoordinatorsStat] = useState<ResponseCoordinatorsTaskStats>()

  const { formProgress, formHandler } = useAFormHandler('distributeTasks')

  const [usersStatProgress, getUsersStat] = useEntityTaskStat<ResponseUsersTaskStats>(
    setUsersStat,
    endpoints.getUsersTaskStats,
  )
  const [coordinatorsStatProgress, getCoordinatorsStat] = useEntityTaskStat<ResponseCoordinatorsTaskStats>(
    setCoordinatorsStat,
    endpoints.getCoordinatorTaskStats,
  )

  const formik = useFormik<Fields>({
    initialValues: {
      regions: [],
      districts: [],
      exclude_existing_tasks: false,
      exclude_do_not_call: false,
      exclude_with_responsible: false,
      assignees: [],
      team: null,
      tasks_per_day: '50',
      start_deadline_date: undefined,
      other_task_per_day: null,
    },

    onSubmit: formHandler(
      async () => {
        const data = {
          regions: _.concat(formik.values.regions, formik.values.districts),
          exclude_existing_tasks: formik.values.exclude_existing_tasks,
          exclude_do_not_call: formik.values.exclude_do_not_call,
          exclude_with_responsible: formik.values.exclude_with_responsible,
          assignees: formik.values.assignees,
          start_deadline_date: formik.values.start_deadline_date,
          tasks_per_day:
            formik.values.tasks_per_day === 'other' ? formik.values.other_task_per_day : formik.values.tasks_per_day,
        }
        await apiClient.post(endpoints.distributeTasks(), data)
      },
      {
        onSuccess: () => {
          refetch()
          onClose()
        },
        onError: (error) => {
          const { errors } = error
          formik.setErrors(errors)
        },
      },
    ),
  })

  useEffect(() => {
    getUsersStat({
      region: formik.values.regions.join(','),
      district: formik.values.districts.join(','),
      exclude_existing_tasks: formik.values.exclude_existing_tasks,
      exclude_do_not_call: formik.values.exclude_do_not_call,
      exclude_with_responsible: formik.values.exclude_with_responsible,
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    formik.values.exclude_existing_tasks,
    formik.values.exclude_do_not_call,
    formik.values.exclude_with_responsible,
    formik.values.districts,
    formik.values.regions,
  ])

  useEffect(() => {
    if (formik.values.assignees.length) {
      getCoordinatorsStat({
        assignee: formik.values.assignees,
      })
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formik.values.assignees])

  const isWorkCoordProgress = coordinatorsStatProgress === Progress.WORK
  const hasCoordStats = coordinatorsStat && usersStat

  return (
    <>
      <SharedModalContent>
        <SharedModalInputs>
          {children}
          <HrWithHeader text={t('task_distribution.filters_by_seller')} />
          <FormSelectShared
            onClear={() => formik.setFieldValue('districs', [])}
            placeholder={t('task_distribution.region')}
            label={t('task_distribution.region')}
            endpoint={endpoints.provinces()}
            searchField="name"
            fieldName="regions"
            simpleApiFormat
            formik={formik}
            multiple
          />

          <DistrictFormControl
            onChange={(value) => formik.setFieldValue('districts', value)}
            label={t('task_distribution.district')}
            disabled={!formik.values.regions.length}
            value={formik.values.districts}
            region={formik.values.regions}
          />
          <FormGroup label={t('task_distribution.exclude_from_result')}>
            <FormItemWrapper>
              <Checkbox
                onChange={(_value, isChecked) => formik.setFieldValue('exclude_existing_tasks', isChecked)}
                isChecked={formik.values.exclude_existing_tasks}
                label={t('task_distribution.with_tasks')}
              />
              <Checkbox
                onChange={(_value, isChecked) => formik.setFieldValue('exclude_do_not_call', isChecked)}
                isChecked={formik.values.exclude_do_not_call}
                label={t('task_distribution.do_not_disturb')}
              />
              <Checkbox
                onChange={(_value, isChecked) => formik.setFieldValue('exclude_with_responsible', isChecked)}
                isChecked={formik.values.exclude_with_responsible}
                label={t('task_distribution.with_responsibleShort')}
              />
            </FormItemWrapper>
          </FormGroup>

          <InfoBoard>
            {usersStatProgress === Progress.WORK
              ? Preload
              : usersStat && (
                  <StatBoard>
                    <div>
                      <LeftInfo>
                        <BoardItem title={t('task_distribution.no_tasks')} value={usersStat.without_tasks} />
                        <BoardItem
                          disabled={formik.values.exclude_existing_tasks}
                          title={t('task_distribution.with_tasks')}
                          value={usersStat.with_tasks}
                        />
                        <BoardItem
                          disabled={formik.values.exclude_with_responsible}
                          title={t('task_distribution.with_responsible')}
                          value={usersStat.with_responsible}
                        />
                      </LeftInfo>
                      <BoardItem
                        title={t('task_distribution.do_not_disturb')}
                        disabled={formik.values.exclude_do_not_call}
                        value={usersStat.do_not_call}
                      />
                    </div>

                    <BoardItem title={t('task_distribution.total_tasks')} value={usersStat.total_tasks} />
                  </StatBoard>
                )}
          </InfoBoard>
          <HrWithHeader text={t('task_distribution.executors_and_parameters')} />

          <TaskManagementForm formik={formik} teamField="team" assigneeField="assignees">
            {!!formik.values.assignees.length && (
              <InfoBoard>
                {isWorkCoordProgress
                  ? Preload
                  : hasCoordStats && (
                      <>
                        <StatBoard>
                          <BoardItem
                            title={t('task_distribution.their_tasks_description')}
                            value={coordinatorsStat.total_tasks}
                          />
                          <BoardItem
                            value={coordinatorsStat.total_tasks + usersStat.total_tasks}
                            title={t('task_distribution.total')}
                          />
                        </StatBoard>
                        <Text typography="bodyMedium" color="secondary">
                          {t('task_distribution.new_task_description')}
                        </Text>
                      </>
                    )}
              </InfoBoard>
            )}
          </TaskManagementForm>
        </SharedModalInputs>
      </SharedModalContent>
      <ADefaultModalFooter
        onConfirm={() => formik.submitForm()}
        confirmButtonText={t('common:save')}
        progress={formProgress}
        onClose={onClose}
      />
    </>
  )
}
