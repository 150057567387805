import { AntTag, ColumnsType, Icons } from '@agro-club/agroclub-shared'
import useFormatDate from 'hooks/useFormatDate'
import { Call, CallType } from 'modules/domain/userCalls/types'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { CallTranscriptButton } from './CallTranscriptButton'

const Tag = styled(AntTag)`
  svg {
    margin-right: 0;
  }
`

const CallTypeTags = {
  [CallType.in]: (
    <Tag color="green">
      <Icons.IconPhoneIn width={16} height={16} />
    </Tag>
  ),
  [CallType.out]: (
    <Tag color="blue">
      <Icons.IconPhoneOut width={16} height={16} />
    </Tag>
  ),
  [CallType.missed]: (
    <Tag color="red">
      <Icons.IconPhoneMissed width={16} height={16} />
    </Tag>
  ),
}

export const useColumns = (): ColumnsType<Call> => {
  const { t } = useTranslation('accountManagement')
  const formatDate = useFormatDate()

  return useMemo(
    () => [
      {
        title: t('type'),
        dataIndex: 'call_type',
        width: 55,
        render: (call_type) => CallTypeTags[call_type],
      },
      {
        title: t('date'),
        dataIndex: 'call_start',
        width: 140,
        render: (call_start) => formatDate(call_start, true),
      },
      {
        title: t('common:responsible'),
        dataIndex: 'coordinator_name',
        autoWidth: 268,
      },
      {
        title: '',
        dataIndex: 'action',
        width: 40,
        render: (_, call) => <CallTranscriptButton call={call} />,
      },
    ],
    [formatDate, t],
  )
}
