import { defaultListRequestParams } from 'views/components/TableFilters/data'
import { MatchesColumn } from './MatchesColumn/MatchesColumn'
import { WrapperBoard } from 'views/components/Board/styled'
import { SlugLabelPair } from 'modules/domain/common/types'
import { ListRequestParams } from 'modules/domain/types'
import { DealsColumn } from './DealsColumn/DealsColumn'
import { BidsColumn } from './BidsColumn/BidsColumn'
import { useBidsData } from './useBidsData'
import { Filters } from './Filters'
import { FC, useEffect, useState } from 'react'
import { UserDeal } from 'modules/domain/userBid/types'
import { Progress } from '@agro-club/agroclub-shared'
import { apiClient } from 'modules/utils/httpClient'
import { ListResponse } from 'types/api'
import { endpoints } from 'modules/endpoints'
import { DealStatusTypes } from 'modules/domain/deal/types'
import axios from 'axios'

type Props = {
  reasons: SlugLabelPair[]
  companyId?: string
  userId?: string
}

export const USBoard: FC<Props> = ({ userId, reasons, companyId }) => {
  const [listRequestParams, setListRequestParams] = useState<ListRequestParams>({
    ...defaultListRequestParams,
  })

  const { publishedBids, archivedBids, bidsProgress, fetchBids, activeBid, setActiveBid } = useBidsData(
    listRequestParams,
    userId,
    companyId,
  )

  const [deals, setDeals] = useState<UserDeal[]>([])
  const [progress, setProgress] = useState(Progress.IDLE)

  const fetchDeals = () => {
    if (!activeBid?.id) return

    const controller = new AbortController()
    const signal = controller.signal
    setProgress(Progress.WORK)

    apiClient
      .get<ListResponse<UserDeal>>(endpoints.matchesForBids(activeBid.id), undefined, { signal })
      .then((response) => {
        setProgress(Progress.SUCCESS)
        setDeals(response.results)
      })
      .catch((e) => {
        if (!axios.isCancel(e)) {
          setProgress(Progress.ERROR)
        }
      })

    return controller
  }

  useEffect(() => {
    const controller = fetchDeals()

    return () => {
      controller?.abort()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeBid])

  return (
    <div>
      {companyId && <Filters listRequestParams={listRequestParams} setListRequestParams={setListRequestParams} />}
      <WrapperBoard>
        <BidsColumn
          progressPublishedBids={bidsProgress}
          progressArchivedBids={bidsProgress}
          refetchPublishedBids={fetchBids}
          publishedBids={publishedBids}
          archivedBids={archivedBids}
          setActiveBid={setActiveBid}
          activeBid={activeBid}
        />
        <MatchesColumn
          activeBid={activeBid}
          reasons={reasons}
          refetchMatches={fetchDeals}
          matches={deals.filter((match) => match.status === DealStatusTypes.DRAFT)}
          progress={progress}
        />
        <DealsColumn
          activeBid={activeBid}
          reasons={reasons}
          refetchDeals={fetchDeals}
          deals={deals.filter((deal) => deal.status !== DealStatusTypes.DRAFT)}
          progressDeals={progress}
        />
      </WrapperBoard>
    </div>
  )
}
