import { useTranslation } from 'react-i18next'

import { ListRequestParams } from 'modules/domain/types'
import { FC } from 'react'
import { ASelectFilter } from 'views/components/Analytics'
import { CarStatuses } from '../../../types'

type Props = {
  listRequestParams: ListRequestParams
  updateFilterState: (filterObj: Record<string, any>) => void
}

export const CarsStatusFilter: FC<Props> = ({ listRequestParams, updateFilterState }) => {
  const { t } = useTranslation('logisticsKR')

  const statusOptions = Object.keys(CarStatuses).map((status) => ({
    value: status,
    label: t(`statuses.${status}`),
  }))

  return (
    <ASelectFilter
      placeholder={t('cars.filters.status')}
      options={statusOptions}
      value={listRequestParams.filter.status}
      onChange={(status) => updateFilterState({ status })}
      showSearch={false}
      id="userStatusFilter"
    />
  )
}
