import { defaultSelectStyle, Icons, Progress, Spinner } from '@agro-club/agroclub-shared'
import { useAProgress } from 'hooks/useAProgress'
import { endpoints } from 'modules/endpoints'
import { apiClient } from 'modules/utils/httpClient'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import styled from 'styled-components'
import { AButton, ASelectFilter } from 'views/components/Analytics'
import { Pagination } from 'views/components/Pagination/Pagination'
import { SpinnerWrapper } from 'views/pages/LogisticsKR/components/styled'
import { FileTtnItem } from 'views/pages/LogisticsKR/LogisticRequests/RequestDetails/components/FilesTtn/FileTtnItem'
import { useTtnFilesContext } from '../../TtnFilesContext'
import { ADateRangePicker } from 'views/components/TableFilters/DateRangePicker/ADateRangePicker'
import { TripTtnDocType } from 'views/pages/LogisticsKR/types'

const SpinnerWrapperStyled = styled(SpinnerWrapper)`
  height: 335px;
`
const MarginWrapper = styled.div`
  margin-top: 12px;
`

const FiltersWrapper = styled.div`
  display: flex;
  column-gap: 12px;
  margin-bottom: 8px;
`

type Props = {
  refetchTripsTable: () => void
}

export const FilesTtn: FC<Props> = ({ refetchTripsTable }) => {
  const { id } = useParams()
  const { t } = useTranslation('logisticsKR')
  const { ttnListProgress, ttnList, ttnListRefetch, ttnListTotal, ttnListParams, setTtnListParams, setDeletedFileId } =
    useTtnFilesContext()

  const [downloadProgress, downloadHandler] = useAProgress(
    async () =>
      await apiClient.get(endpoints.LogisticsKR.downloadAllTtn(id), ttnListParams.filter, {
        responseType: 'blob',
      }),
    { eventName: 'downloadAllTtnFiles' },
  )

  const updateFilterState = (filterObj: Record<string, any>) => {
    setTtnListParams((prev) => ({
      ...prev,
      page: 1,
      filter: {
        ...prev?.filter,
        ...filterObj,
      },
    }))
  }
  const ttnFilterOptions = [
    {
      value: 'true',
      label: t('hasVat.withVat'),
    },
    {
      value: 'false',
      label: t('hasVat.noVat'),
    },
  ]

  const ttnTypeOptions = [
    {
      value: TripTtnDocType.load,
      label: t('ttnType.load'),
    },
    {
      value: TripTtnDocType.unload,
      label: t('ttnType.unload'),
    },
  ]

  const handleChangePage = (page: number) => setTtnListParams((prev) => ({ ...prev, page }))
  const isFilterVisible = !!ttnListTotal || Object.values(ttnListParams.filter).filter(Boolean).length > 0

  return (
    <div>
      {isFilterVisible && (
        <FiltersWrapper>
          <ADateRangePicker
            value={[ttnListParams.filter?.load_date_after, ttnListParams.filter?.load_date_before]}
            onChange={(value) =>
              updateFilterState({ load_date_after: value.startDate, load_date_before: value.endDate })
            }
            id="dateFilter"
            allowEmpty={[true, true]}
            place="TTNList"
          />
          <ASelectFilter
            onChange={(val) => {
              updateFilterState({ has_vat: val })
            }}
            placeholder={t('details.accordion.has_vat')}
            value={ttnListParams.filter.has_vat}
            options={ttnFilterOptions}
            showSearch={false}
            id="ttnType"
            place="TTNList"
            style={{ ...defaultSelectStyle, minWidth: 0 }}
          />
          <ASelectFilter
            onChange={(val) => {
              updateFilterState({ document_type: val })
            }}
            placeholder={t('details.accordion.document_type')}
            value={ttnListParams.filter.document_type}
            options={ttnTypeOptions}
            showSearch={false}
            id="hasVat"
            place="TTNList"
          />
        </FiltersWrapper>
      )}
      {!!ttnListTotal && (
        <AButton
          id="downloadAllTtn"
          onClick={downloadHandler}
          progress={downloadProgress}
          intent="secondary"
          Icon={Icons.IconDownload}
        >
          {t('details.accordion.downloadFiles')}
        </AButton>
      )}
      <MarginWrapper>
        {ttnListProgress === Progress.WORK ? (
          <SpinnerWrapperStyled>
            <Spinner />
          </SpinnerWrapperStyled>
        ) : (
          ttnList?.map((file) => (
            <FileTtnItem
              refetchTripsTable={refetchTripsTable}
              refetch={ttnListRefetch}
              file={file}
              key={file.id}
              setDeletedTtnIdForSync={setDeletedFileId}
            />
          ))
        )}
      </MarginWrapper>
      <Pagination total={ttnListTotal} currPage={ttnListParams.page} setCurrPage={handleChangePage} />
    </div>
  )
}
