import { ListRequestParams } from 'modules/domain/types'
import { FC, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { RegionDistrictFilter } from 'views/components/TableFilters'
import { defaultListRequestParams } from 'views/components/TableFilters/data'

type Props = {
  updateFilterState: (filterObj: Record<string, any>) => void
  listRequestParams: ListRequestParams
}

export const MatchesRegionFilter: FC<Props> = ({ listRequestParams, updateFilterState }) => {
  const { t } = useTranslation('matches')

  const [sellerParams, setSellerParams] = useState(defaultListRequestParams)
  const [customerParams, setCustomerParams] = useState(defaultListRequestParams)

  const updateLocalState = (filterObj: Record<string, any>, prefix) => {
    const updateObj: Record<string, any> = {}
    Object.entries(filterObj).map(([key, val]) => {
      updateObj[`${prefix}${key}`] = val
    })
    updateFilterState(updateObj)
  }

  const saleRegion = listRequestParams.filter.sale_region
  const saleDistrict = listRequestParams.filter.sale_district

  useEffect(() => {
    setSellerParams((prev) => ({ ...prev, filter: { region: saleRegion, district: saleDistrict } }))
  }, [saleDistrict, saleRegion])

  const purchaseRegion = listRequestParams.filter.purchase_region
  const purchaseDistrict = listRequestParams.filter.purchase_district

  useEffect(() => {
    setCustomerParams((prev) => ({ ...prev, filter: { region: purchaseRegion, district: purchaseDistrict } }))
  }, [purchaseRegion, purchaseDistrict])

  return (
    <>
      <RegionDistrictFilter
        listRequestParams={sellerParams}
        updateFilterState={(filterObj) => updateLocalState(filterObj, 'sale_')}
        regionPlaceholder={t('filters.saleState')}
        districtPlaceholder={t('filters.saleDistrict')}
      />
      <RegionDistrictFilter
        listRequestParams={customerParams}
        updateFilterState={(filterObj) => updateLocalState(filterObj, 'purchase_')}
        regionPlaceholder={t('filters.purchaseState')}
        districtPlaceholder={t('filters.purchaseDistrict')}
      />
    </>
  )
}
