import { defaultTheme, Spinner as SpinnerComponent, Typography } from '@agro-club/agroclub-shared'
import styled, { keyframes } from 'styled-components'

export const FiltersWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 8px 16px;
  margin-bottom: 16px;

  //TODO ^ WTF Кажется этим стилям тут не место. Разобраться как время будет
  .ant-picker {
    border-radius: 9px !important;
    background: ${defaultTheme.color.backgroundPrimary} !important;

    &:hover {
      border-color: ${defaultTheme.color.primary600} !important;
    }
  }

  .ant-picker-clear {
    background-color: ${defaultTheme.color.backgroundPrimary};
  }
`

export const SearchWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  // for mobile version
  margin-bottom: 16px;
`

export const FiltersAndSearchWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  @media (max-width: ${defaultTheme.queries.mobile}) {
    display: block;
  }
`

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: auto;

  th[class^='HeadCell-'] {
    ${Typography.bodyLarge}
  }
`

const appear = keyframes`
  to {
    opacity: 1;
  }
`

export const SpinnerWrapper = styled.div`
  margin: 20px auto;
  display: flex;
  justify-content: center;
  opacity: 0;
  animation: ${appear} 0.3s;
  animation-fill-mode: forwards;
  animation-delay: 0.3s;
`

export function Spinner() {
  return (
    <SpinnerWrapper>
      <SpinnerComponent />
    </SpinnerWrapper>
  )
}
