import { urlMaker } from 'modules/utils/urlMaker'

export const specificationsEndpoints = {
  specifications: urlMaker('/specifications'),
  specificationComments: (id: string) => `/specifications/${id}/comments/`,
  specificationFiles: (id: string) => `/specifications/${id}/files/`,
  deleteSpecificationFiles: (itemId: string, id: string) => `/specifications/${itemId}/files/${id}/`,
  specificationChangeStatus: (id: string) => `/specifications/${id}/change_status/`,
  specificationDeals: (id: string) => `/specifications/${id}/deals/`,
}
