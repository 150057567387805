import { BoardColumn, BoardContent, BoardHeader, BoardScrollWrapper } from 'views/components/Board/CardsBoard'
import { PreloadCard, PreloadCount } from 'views/components/Board/Preload'
import { formatQuantity } from 'modules/utils/numbers/formatQuantity'
import { NoDataCard } from 'views/components/Board/NoDataCard'
import { KeyValue } from 'views/components/KeyValue/KeyValue'
import { Progress, Text } from '@agro-club/agroclub-shared'
import { Count } from 'views/components/Board/styled'
import { Position } from 'modules/domain/bid/types'
import { useTranslation } from 'react-i18next'
import { PositionCard } from './PositionCard'
import { PreloadMargin } from '../Column'
import { FC } from 'react'

type Props = {
  onSelectPosition: (position: Position | null) => void
  progressRemainingQuantity: Progress
  selectedPositionId: string | null
  remainingQuantity?: number
  borderRadius?: string
  cards?: Position[]
  progress: Progress
  topMargin: number
  isBest?: boolean
  title: string
}

export const Column: FC<Props> = ({
  progressRemainingQuantity,
  selectedPositionId,
  remainingQuantity,
  onSelectPosition,
  borderRadius,
  topMargin,
  progress,
  title,
  cards,
}) => {
  const { t } = useTranslation('deal')
  return (
    <BoardColumn borderRadius={borderRadius}>
      <BoardHeader>
        <Text as="span" typography="titleH4">
          {title}
          {progress === Progress.WORK ? (
            <PreloadCount />
          ) : (
            <Count color={cards?.length ? 'orange' : 'grey'}>{cards?.length}</Count>
          )}
        </Text>
        {progressRemainingQuantity === Progress.WORK ? (
          <PreloadMargin />
        ) : (
          <KeyValue
            horizontal
            columnGap="small"
            label={t('common:quantity')}
            labelTypo="bodyMedium"
            valueTypo="bodyMedium"
          >
            {remainingQuantity ? formatQuantity(remainingQuantity) : 0}
          </KeyValue>
        )}
      </BoardHeader>

      <BoardScrollWrapper topMargin={topMargin}>
        <BoardContent>
          {progress === Progress.WORK ? (
            <PreloadCard />
          ) : cards?.length ? (
            cards.map((item) => (
              <PositionCard
                key={item.id}
                position={item}
                onSelectPosition={onSelectPosition}
                selectedPositionId={selectedPositionId}
              />
            ))
          ) : (
            <NoDataCard />
          )}
        </BoardContent>
      </BoardScrollWrapper>
    </BoardColumn>
  )
}
