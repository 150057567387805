import { ColumnsType } from '@agro-club/agroclub-shared'
import { Sdiz } from 'modules/domain/sdiz/types'
import { ListRequestParams } from 'modules/domain/types'
import { Dispatch, SetStateAction, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { CellRenders } from 'views/components/Table/renderers'
import { ActionCell } from './ActionCell'

export const useColumns = (dataUpdate: Dispatch<SetStateAction<ListRequestParams>>): ColumnsType<Sdiz> => {
  const { t } = useTranslation('sdiz')
  return useMemo(
    () => [
      {
        title: t('ttnDate'),
        width: 140,
        dataIndex: 'ttn_date',
        render: CellRenders.date,
      },
      {
        title: t('ttnNumbers'),
        width: 140,
        dataIndex: 'ttn_number',
      },
      {
        title: t('sdizNumber'),
        width: 170,
        dataIndex: 'sdiz_number',
      },
      {
        title: t('address'),
        dataIndex: 'address',
        autoWidth: 600,
        render: (address) => address.address,
      },
      {
        title: t('product'),
        dataIndex: 'product',
        width: 400,
        render: (product) => product.name,
      },
      {
        title: t('action'),
        dataIndex: 'action',
        width: 86,
        render: (_, data) => <ActionCell sdiz={data} refetch={() => dataUpdate((prev) => ({ ...prev }))} />,
      },
    ],
    [t, dataUpdate],
  )
}
