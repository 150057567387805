import PermissionCheck from 'views/components/PermissionCheck/PermissionCheck'
import { AntSwitch, Progress, Text } from '@agro-club/agroclub-shared'
import { QuantityLoadPerDayForm } from './QuantityLoadPerDayForm'
import { useSingleEntity } from 'modules/domain/common/hooks'
import { KeyValue } from 'views/components/KeyValue/KeyValue'
import { DealStatusTypes } from 'modules/domain/deal/types'
import { DayType, WeeklyCalendar } from './WeeklyCalendar'
import { VolumeTrackerModal } from './VolumeTrackerModal'
import { apiClient } from 'modules/utils/httpClient'
import { BidTypes } from 'modules/domain/bid/types'
import { useDealContext } from '../../DealContext'
import { useAProgress } from 'hooks/useAProgress'
import { Card } from 'views/components/Card/Card'
import { useTranslation } from 'react-i18next'
import { isStatusAfter } from '../../helpers'
import { endpoints } from 'modules/endpoints'
import styled from 'styled-components'
import { FC, useState } from 'react'
import _ from 'lodash'
import { isRussia } from 'env'

type Props = {
  bidType: BidTypes
}

const Header = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
`
const SwitchWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 0 8px;
  margin-top: 12px;
`
const LabelSwitch = styled(Text)`
  padding-top: 3px;
`

export const BidVolumeTracker: FC<Props> = ({ bidType }) => {
  const { t } = useTranslation('deal')

  const { deal, dealRefetch } = useDealContext()

  const [isEdit, setIsEdit] = useState(false)

  const isSaleBid = bidType === BidTypes.sale

  let countField
  let permissionScope
  let labelKey
  let useOneValue

  if (isSaleBid) {
    countField = 'loading_count'
    useOneValue = 'use_one_loading_value'
    permissionScope = ['deals.can_change_loading_count']
    labelKey = 'loading_count'
  } else {
    countField = 'unloading_count'
    useOneValue = 'use_one_unloading_value'
    permissionScope = ['deals.can_change_unloading_count']
    labelKey = 'unloading_count'
  }

  const isShowTracker = isRussia && isStatusAfter(deal.status, DealStatusTypes.AGREED)

  const isUseOneValue = deal.trip_count_norm?.[useOneValue]

  const [progress, submit] = useAProgress(
    async () => {
      deal.trip_count_norm?.id &&
        (await apiClient.put(endpoints.dealChangeQuantityLoadPerDay(deal.id, deal.trip_count_norm.id), {
          ...deal.trip_count_norm,
          [useOneValue]: !isUseOneValue,
        }))
      await dealRefetch()
    },
    { eventName: 'changeTypeSchedule', scope: 'change' },
  )

  const [, calendarList, refetchCalendarList] = useSingleEntity<DayType[]>(
    !isUseOneValue ? endpoints.dealCalendar(deal.id) : undefined,
  )

  const truckCountText = _.isNil(deal.trip_count_norm?.[countField])
    ? t('no_trucks_count')
    : t('truck', { count: deal.trip_count_norm?.[countField] })

  const allRefetch = async () => {
    await refetchCalendarList()
    await dealRefetch()
  }

  if (!isShowTracker) return null

  return (
    <div>
      <Card.Container bordered>
        <Header>
          <KeyValue label={t(labelKey)}>
            {isUseOneValue
              ? truckCountText
              : calendarList && <WeeklyCalendar calendarList={calendarList} countField={countField} />}
          </KeyValue>

          <PermissionCheck scope={permissionScope}>
            {!isEdit ? (
              <Card.EditButton
                id="editValuePerDay"
                onClick={() => setIsEdit(true)}
                disabled={progress === Progress.WORK}
              />
            ) : null}
          </PermissionCheck>

          {isEdit &&
            (isUseOneValue ? (
              <QuantityLoadPerDayForm
                onClose={() => setIsEdit(false)}
                countField={countField}
                refetch={dealRefetch}
                deal={deal}
              />
            ) : (
              calendarList && (
                <VolumeTrackerModal
                  onClose={() => setIsEdit(false)}
                  refetch={allRefetch}
                  calendarList={calendarList}
                  countField={countField}
                  isSaleBid={isSaleBid}
                  title={t(labelKey)}
                  deal={deal}
                />
              )
            ))}
        </Header>

        <PermissionCheck scope={permissionScope}>
          <SwitchWrapper>
            <AntSwitch
              disabled={isEdit}
              loading={progress === Progress.WORK}
              checked={isUseOneValue}
              onChange={submit}
              size="small"
            />
            <LabelSwitch typography="bodyMedium">{t('useOneValue')}</LabelSwitch>
          </SwitchWrapper>
        </PermissionCheck>
      </Card.Container>
    </div>
  )
}
