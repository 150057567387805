import { AccountManagement } from 'modules/domain/accountManagement/types'
import { ListRequestParams } from 'modules/domain/types'
import { endpoints } from 'modules/endpoints'
import { Dispatch, FC, SetStateAction } from 'react'
import { useColumns } from './useColumns'

import { useState } from 'react'
import ATableFullPage from 'views/components/Table/ATableFullPage'
import { AccountManagementDrawer } from '../AccountManagementDrawer/AccountManagementDrawer'

type Props = {
  listRequestParams: ListRequestParams
  listParamsUpdated: Dispatch<SetStateAction<ListRequestParams>>
  isFiltersLoaded: boolean
}

export const AccountManagementTable: FC<Props> = ({ listRequestParams, listParamsUpdated, isFiltersLoaded }) => {
  const [rowData, setRowData] = useState<AccountManagement | null>(null)

  const columns = useColumns(listRequestParams.sort)

  return (
    <>
      <ATableFullPage
        columns={columns}
        rowHoverGray
        listRequestParams={listRequestParams}
        listParamsUpdated={listParamsUpdated}
        isFiltersLoaded={isFiltersLoaded}
        onRow={(rowData) => ({
          onClick: (e) => {
            e.stopPropagation()
            setRowData(rowData)
          },
        })}
        dataEndpoint={endpoints.dashboardCallsUS()}
      />
      {rowData && (
        <AccountManagementDrawer
          listRequestParams={listRequestParams}
          listParamsUpdated={listParamsUpdated}
          rowData={rowData}
          onClose={() => setRowData(null)}
        />
      )}
    </>
  )
}
