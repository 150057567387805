import { urlMaker } from 'modules/utils/urlMaker'

export const usersEndpoints = {
  users: urlMaker('/users/'),
  coordinatorsShort: urlMaker('/users/coordinators/'),
  brokers: urlMaker('/users/brokers/'),
  profile: () => '/users/me/',
  myTasks: () => '/users/me/tasks/',
  blockedReasons: () => '/users/blocked-reasons/',
  userCoordinatorTypes: urlMaker('/users/coordinator-types/'),
  doNotCallReasons: () => '/users/do-not-call-reasons/',
  dispatchingLogisticians: () => '/users/logisticians/',
  userFiles: (userId: string, fileId?: string) => `/users/${userId}/files/${fileId ? `${fileId}/` : ''}`,
  getUserById: (userId: string) => `/users/${userId}/`,
  userBidList: (userId: string) => `/users/${userId}/bids/`,
  userOpenBids: (userId: string) => `/users/${userId}/bids/?status=published`,
  userBidsForDeal: (userId: string) => `/users/${userId}/bids-for-deal/`,
  userBidListWithPagination: (userId: string, topBid?: string) =>
    `/users/${userId}/bids/with-pagination/${topBid ? `?top_bid=${topBid}` : ''}`,
  userBids: (userId: string, statusBid: string) => `/users/${userId}/bids/?status=${statusBid}`,
  userComments: (userId: string) => `/users/${userId}/comments/`,
  userAddress: (userId: string, addressId?: string) => `/users/${userId}/addresses/${addressId ? `${addressId}/` : ''}`,
  userCompanies: (userId: string) => `/users/${userId}/companies/`,
  userLegalTypes: (userId: string) => `/users/${userId}/legal-types/`,
  blockUser: (userId: string) => `/users/${userId}/block/`,
  userTasks: (userId: string) => `/users/${userId}/tasks/`,
  userContacts: (userId: string, contactId?: string) => `/users/${userId}/contacts/${contactId ? `${contactId}/` : ''}`,
  userCars: (userId: string) => `/users/${userId}/cars/`,
  userVerifyCoordinator: (userId: string) => `/users/${userId}/verify-as-coordinator/`,
  userVerifySecurity: (userId: string) => `/users/${userId}/verify-as-security/`,
  userVerifyKyc: (userId: string) => `/users/${userId}/verify-as-kyc/`,
  keyUser: (userId: string) => `/users/${userId}/mark-significant/`,
  addUserToCompany: (userId: string) => `/users/${userId}/add-company/`,
  activeMarketUsers: (profileType: string) => `/users/?is_active=true&profile_type=${profileType}`,
  specificationForPurchase: (ownerId: string, productSlug: string) =>
    `/users/${ownerId}/specifications/?status=open&status=closed&product=${productSlug}`,
  userDocumentTypes: (id: string) => `/users/${id}/document_types/`,
  userDocumentFiles: (id: string) => `/users/${id}/documents/`,
  userDeleteDocumentFile: (id: string, document_id: string) => `/users/${id}/documents/${document_id}/`,
  userDocuments: (id: string) => `/users/${id}/documents/`,
  userSpecifications: (id: string) => `/users/${id}/specifications/`,
  userSpecification: (userId: string, itemId: string) => `/users/${userId}/specifications/${itemId}/`,
  userFilesManagement: (userId: string, specificationId: string) =>
    `/users/${userId}/specifications/${specificationId}/files/`,
  newUserTasks: (userId: string) => `/users/${userId}/user-tasks/`, //TODO rename after remove old endpoint
  userRatingByType: (userId: string) => `users/${userId}/ratings-by-type/`,
  userRatingByDeal: (userId: string) => `users/${userId}/ratings-by-deal/`,
  userProductTags: (userId: string, id?: string) => `users/${userId}/product-tags/${id ? `${id}/` : ''}`,
  userProductsForNotifications: (userId: string) => `/users/${userId}/sms-notification-settings/`,
  marketingProductSms: (userId: string) => `/users/${userId}/marketing-product-sms/`,
  userPotentialBidList: (userId: string) => `/users/${userId}/product-stocks/`,
  blackListUser: (userId) => `/users/${userId}/mark-black-listed/`,
  samplesForUser: (id: string) => `/users/${id}/samples/`,
  userFeature: () => `/users/user-feature/`,
  userCalls: (userId: string) => `/users/${userId}/calls/`,
  executorsByType: (type: string) => `/users/${type}/`,
  userSetRespCoordinator: (userId: string) => `users/${userId}/set-coordinator/`,
}
