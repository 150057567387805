import { FC } from 'react'
import { Route, Routes } from 'react-router'
import { ReportPage } from './ReportPage'
import { DPReportUrl } from './reportsData'

export const ReportsPage: FC = () => {
  return (
    <Routes>
      <Route index element={<ReportPage reportUrl={DPReportUrl} />}></Route>
    </Routes>
  )
}
