import { FC, useMemo } from 'react'

import { useTranslation } from 'react-i18next'
import { FiltersAndSearchWrapper, FiltersWrapper } from 'views/components/CommonTableComponents/CommonTableComponents'
import { TableFiltersProps } from 'modules/types'
import { endpoints } from 'modules/endpoints'
import { defaultListRequestParams } from 'views/components/TableFilters/data'
import { useDisplayClearBtn } from 'hooks/useDisplayClearBtn'
import { CallType } from 'modules/domain/userCalls/types'
import { SearchFilter } from 'views/components/SearchFilter/SearchFilter'
import { AClearFiltersButton, ASelectFilter } from 'views/components/Analytics'
import { ADateRangePicker } from 'views/components/TableFilters/DateRangePicker/ADateRangePicker'
import { ABaseTableFilter } from 'views/components/TableFilters/ABaseTableFilter'

const CallsFilters: FC<TableFiltersProps> = ({ setListRequestParams, listRequestParams, updateFilterState }) => {
  const { t } = useTranslation('calls')
  const displayClearBtn = useDisplayClearBtn({
    listRequestParams,
  })

  const options = useMemo(
    () =>
      Object.keys(CallType).map((type) => ({
        value: type,
        label: t(`callTypeFilter.${type}`),
      })),
    [t],
  )

  return (
    <FiltersAndSearchWrapper>
      <FiltersWrapper>
        <ADateRangePicker
          id="dateFilter"
          value={[listRequestParams.filter.call_start_after, listRequestParams.filter.call_start_before]}
          onChange={(value) =>
            updateFilterState({ call_start_after: value.startDate, call_start_before: value.endDate })
          }
        />
        <ASelectFilter
          id="callTypeFilter"
          options={options}
          placeholder={t('call_type')}
          value={listRequestParams.filter.call_type}
          onChange={(call_type) => updateFilterState({ call_type })}
          showSearch={false}
        />
        <ABaseTableFilter
          id="coordinatorNameFilter"
          placeholder={t('coordinator')}
          value={listRequestParams.filter.coordinator}
          endpoint={endpoints.accounts()}
          onChange={(coordinator) => updateFilterState({ coordinator })}
          searchField="search"
        />
        {displayClearBtn && (
          <AClearFiltersButton
            onClick={() => setListRequestParams && setListRequestParams({ ...defaultListRequestParams })}
          />
        )}
      </FiltersWrapper>
      <SearchFilter listRequestParams={listRequestParams} updateFilterState={updateFilterState} />
    </FiltersAndSearchWrapper>
  )
}

export default CallsFilters
