import { ListRequestParams } from 'modules/domain/types'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { ABaseTableFilter } from 'views/components/TableFilters/ABaseTableFilter'
import { endpoints } from 'modules/endpoints'

type Props = {
  listRequestParams: ListRequestParams
  updateFilterState: (value: Record<string, unknown>) => void
}

export const AssigneeDocumentsTeamFilter: FC<Props> = ({ updateFilterState, listRequestParams }) => {
  const { t } = useTranslation()
  return (
    <ABaseTableFilter
      id="assigneeFilter"
      onChange={(assignee) => updateFilterState({ assignee: assignee })}
      value={listRequestParams.filter.assignee}
      getLabel={(label) => label.full_name}
      endpoint={endpoints.taskDocsTeamAssignees()}
      placeholder={t('common:assignee')}
      simpleApiFormat
      showSearch={false}
    />
  )
}
