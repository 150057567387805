import { isRussia, isUS } from 'env'
import { formatBool } from 'helpers/formatBool'
import { useDisplayClearBtn } from 'hooks/useDisplayClearBtn'
import { UIBidTypes } from 'modules/domain/bid/types'
import { ListRequestParams } from 'modules/domain/types'
import { useTranslation } from 'react-i18next'
import { AddressFilter } from 'views/components/AddressFilter/AddressFilter'
import { AClearFiltersButton, ASelectFilter } from 'views/components/Analytics'
import { FiltersAndSearchWrapper, FiltersWrapper } from 'views/components/CommonTableComponents/CommonTableComponents'
import { SearchFilter } from 'views/components/SearchFilter/SearchFilter'
import { CropsFilter, TeamsFilter } from 'views/components/TableFilters'
import { BidStatusFilter } from 'views/components/TableFilters/BidStatusFilter'
import { ADateRangePicker } from 'views/components/TableFilters/DateRangePicker/ADateRangePicker'
import { FileFilter } from 'views/components/TableFilters/FileFilter'
import { MarketZoneFilter } from 'views/components/TableFilters/MarketZoneFilter'
import { CommonRegionFilter } from 'views/components/TableFilters/RegionsFilters/CommonRegionFilter'
import { ExecutorFilter } from 'views/pages/Bid/BidFilters/ExecutorFilter'
import { SourceFilter } from './SourceFilter'

interface Props {
  listRequestParams: ListRequestParams
  activeTab: string
  updateFilterState: (filterObj: Record<string, any>) => void
  clearAllTabsState: () => void
}

const BidFilters = ({ listRequestParams, activeTab, updateFilterState, clearAllTabsState }: Props) => {
  const { t } = useTranslation(['bid', 'market_zone'])
  const displayClearBtn = useDisplayClearBtn({
    listRequestParams,
  })

  const optionsFiles = [
    {
      value: 'true',
      label: formatBool(true, t('common:thereIs')),
    },
    {
      value: 'false',
      label: formatBool(false, t('common:thereIs')),
    },
  ]
  const firmOptions = [
    {
      value: 'true',
      label: t('firm'),
    },
    {
      value: 'false',
      label: t('soft'),
    },
  ]
  const samplesOptions = [
    {
      value: 'true',
      label: t('samples:thereAreSapmples'),
    },
    {
      value: 'false',
      label: t('samples:noSapmples'),
    },
  ]

  const isShowDateRangeFilter = !isRussia && activeTab !== UIBidTypes.potential
  const isShowFirmFilter = isUS && activeTab === UIBidTypes.sale
  return (
    <FiltersAndSearchWrapper>
      <FiltersWrapper>
        {isShowDateRangeFilter && (
          <ADateRangePicker
            value={[listRequestParams.filter.start_date, listRequestParams.filter.end_date]}
            onChange={(value) => updateFilterState({ start_date: value.startDate, end_date: value.endDate })}
            id="dateFilter"
            picker="month"
          />
        )}

        <CropsFilter listRequestParams={listRequestParams} updateFilterState={updateFilterState} />

        {activeTab !== UIBidTypes.potential && (
          <BidStatusFilter listRequestParams={listRequestParams} updateFilterState={updateFilterState} />
        )}
        {isShowFirmFilter && (
          <ASelectFilter
            onChange={(is_firm) => updateFilterState({ is_firm })}
            value={listRequestParams.filter.is_firm}
            placeholder={t('offerType')}
            options={firmOptions}
            showSearch={false}
            id="firmFilter"
          />
        )}

        {activeTab !== UIBidTypes.potential && (
          <FileFilter
            listRequestParams={listRequestParams}
            updateFilterState={updateFilterState}
            options={optionsFiles}
          />
        )}

        <TeamsFilter listRequestParams={listRequestParams} updateFilterState={updateFilterState} />

        <ExecutorFilter
          listRequestParams={listRequestParams}
          updateFilterState={updateFilterState}
          placeholder={t('filterCreatedBy')}
        />
        {isUS && <MarketZoneFilter listRequestParams={listRequestParams} updateFilterState={updateFilterState} />}
        <CommonRegionFilter listRequestParams={listRequestParams} updateFilterState={updateFilterState} />

        {activeTab === UIBidTypes.sale && (
          <ASelectFilter
            onChange={(has_samples) => updateFilterState({ has_samples })}
            value={listRequestParams.filter.has_samples}
            placeholder={t('samples:samples')}
            options={samplesOptions}
            showSearch={false}
            id="samplesFilter"
          />
        )}
        <SourceFilter listRequestParams={listRequestParams} updateFilterState={updateFilterState} />

        {activeTab !== UIBidTypes.potential && (
          <AddressFilter
            filter={listRequestParams.filter}
            onChange={(searchParams) => updateFilterState(searchParams)}
          />
        )}

        {displayClearBtn && <AClearFiltersButton onClick={() => clearAllTabsState()} />}
      </FiltersWrapper>
      <SearchFilter listRequestParams={listRequestParams} updateFilterState={updateFilterState} />
    </FiltersAndSearchWrapper>
  )
}

export default BidFilters
