import { Progress } from '@agro-club/agroclub-shared'
import { ButtonBackground, ButtonColors, SVGIcon } from '@agro-club/agroclub-shared/dist/cjs/ui/buttons/Buttons/types'
import { ReactNode, useRef } from 'react'
import styled from 'styled-components'
import { AButton } from 'views/components/Analytics'

type ButtonSize = 'small' | 'medium' | 'big' | 'biggest' | 'bigRound'

const FileInput = styled.input`
  display: none;
`

export interface UploadFileButtonProps {
  progress?: Progress
  onUpload(files: File | File[]): void
  accept?: string
  size?: ButtonSize
  icon?: SVGIcon
  color?: ButtonColors
  background?: ButtonBackground
  text?: ReactNode
  multiple?: boolean
}

export const InternalUploadFileButton = ({
  progress,
  onUpload,
  accept,
  text,
  icon,
  color = 'secondary',
  background = 'default',
  size = 'medium',
  multiple,
}: UploadFileButtonProps) => {
  const ref = useRef<HTMLInputElement>(null)

  const addFile = () => {
    ref.current?.click()
  }

  const onChange = (event): void => {
    const files = multiple ? [...event.target.files] : event.target.files[0]

    onUpload(files)
  }

  return (
    <div>
      <AButton
        id="addFile"
        size={size}
        color={color}
        background={background}
        Icon={icon}
        onClick={addFile}
        progress={progress}
      >
        {text}
      </AButton>
      <FileInput multiple={multiple} type="file" ref={ref} onChange={onChange} accept={accept} />
    </div>
  )
}
