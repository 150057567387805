import { EventModel, FormattedEventsCount } from 'modules/socket/types'
import { AuthContext } from 'modules/context/AuthContext'
import { useCallback, useContext } from 'react'
import { useLocation } from 'react-router-dom'
import { OptionItem, OptionItemForRender } from './types'

export const filterOptionsByPerms = (options: OptionItem[], permissions): OptionItem[] => {
  const filtered: OptionItem[] = []
  options.forEach((option) => {
    if (option.children) {
      const children = filterOptionsByPerms(option.children, permissions)
      if (children.length > 0) {
        filtered.push({ ...option, children })
      }
    }
    if (!option.access) return

    const { permission, extra } = option.access

    if (permission ? permissions.includes(permission) && extra : extra) {
      filtered.push(option)
    }
  })
  return filtered
}

export const useFilteredOptionsByPerms = (options: OptionItem[]): OptionItem[] => {
  const { profile } = useContext(AuthContext)

  return filterOptionsByPerms(options, profile?.permissions)
}

export const getOptionCount = (count: FormattedEventsCount, eventModel: EventModel | EventModel[]) => {
  if (Array.isArray(eventModel)) {
    return eventModel.reduce((acc, event) => (count[event] ? acc + count[event] : acc), 0)
  } else {
    return count[eventModel]
  }
}

export const getActiveRoute = (options: OptionItemForRender[], isCurrent: (path: string) => boolean): string[] => {
  for (let i = 0; i < options.length; i++) {
    const option = options[i]
    let subOption: any = null

    const isActive = option.children
      ? option.children.some((opt) => {
          subOption = opt
          return isCurrent(opt?.key ?? '')
        })
      : isCurrent(option.key)

    if (isActive) {
      if (subOption) return [subOption.key, option.key]
      else return [option.key]
    }
  }

  return []
}

export const useNavigation = () => {
  const location = useLocation()

  const isCurrent = useCallback(
    (path?: string) => {
      return path ? location.pathname.startsWith(path) : false
    },
    [location.pathname],
  )

  return {
    isCurrent,
  }
}
