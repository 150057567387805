import { FC } from 'react'
import { useTranslation } from 'react-i18next'

import { ListRequestParams } from 'modules/domain/types'
import ASelectFilter from 'views/components/Analytics/ASelectFilter'
import { isUS } from 'env'

interface options {
  value: string
  label: string
}

interface Props {
  listRequestParams: ListRequestParams
  updateFilterState: any
  disabled?: boolean
}

export const SourceFilter: FC<Props> = ({ listRequestParams, updateFilterState, disabled }) => {
  const { t } = useTranslation('bid')

  if (!isUS) return null

  const optionsSource: options[] = [
    {
      value: 'internal',
      label: t('internal'),
    },
    {
      value: 'barchart',
      label: t('barchart'),
    },
    {
      value: 'developer',
      label: t('dev'),
    },
  ]

  return (
    <ASelectFilter
      onChange={(source) => updateFilterState({ source })}
      value={listRequestParams.filter.source}
      placeholder={t('filterBySource')}
      options={optionsSource}
      showSearch={false}
      disabled={disabled}
      id="sourceFilter"
    />
  )
}
