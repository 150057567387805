import { DealStatusTypes, MarginsforDeals, RuMarginsforDeals } from 'modules/domain/deal/types'
import formatPrice from 'modules/utils/numbers/formatPrice'
import { Text } from '@agro-club/agroclub-shared'
import styled from 'styled-components'
import { FC } from 'react'

const Wrapper = styled.div`
  display: flex;
  gap: 0 4px;
`

export type MarginProps = {
  status: DealStatusTypes
  margins: MarginsforDeals | RuMarginsforDeals
  label: string
}

export const CommonMarginComponent: FC<MarginProps> = ({ status, margins, label }) => {
  const marginValue = formatPrice(Math.round(margins.margins[status]), true, false)

  return (
    <Wrapper>
      <Text typography="bodyMedium" color="secondary">
        {label}
      </Text>
      <Text typography="bodyMedium">
        <Wrapper>
          <span>{marginValue}</span>
        </Wrapper>
      </Text>
    </Wrapper>
  )
}
