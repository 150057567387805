import { Text } from '@agro-club/agroclub-shared'
import { t } from 'i18next'
import { DASH } from 'modules/constants'
import { AccountManagement } from 'modules/domain/accountManagement/types'
import { FC } from 'react'
import { generatePath } from 'react-router-dom'
import styled from 'styled-components'
import { ARouterLink } from 'views/components/Analytics'
import { Card } from 'views/components/Card/Card'
import { KeyValue } from 'views/components/KeyValue/KeyValue'
import { PhoneButtonDropdown } from 'views/components/PhoneButtonDropdown/PhoneButtonDropdown'
import UserRoutes from 'views/pages/User/routes'

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`

const CardContainer = styled(Card.Container).attrs({
  intent: 'backgroundQuarternary',
  borderRadius: 'normal',
  disableShadow: true,
})`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 8px 16px;
`

const Name = styled(Text).attrs({
  typography: 'titleH3',
})`
  margin-bottom: 4px;
`

const Address = styled(Text).attrs({
  typography: 'bodyLarge',
})`
  margin-bottom: 12px;
`

const PhoneWrapper = styled.div`
  width: 188px;
`

type CompanyInfoSectionProps = {
  callsData: AccountManagement
}

export const CompanyInfoSection: FC<CompanyInfoSectionProps> = ({ callsData }) => {
  return (
    <Wrapper>
      <Name>{callsData.name}</Name>
      <Address>{callsData.main_address?.address}</Address>
      <CardContainer>
        <KeyValue label={t('common:contact')}>
          {callsData.contacts.length ? (
            <ARouterLink
              eventName="contact"
              to={generatePath(UserRoutes.Details, { id: callsData.contacts[0].user_id })}
            >
              {callsData.contacts[0].fullname.trim() || DASH}
            </ARouterLink>
          ) : (
            DASH
          )}
        </KeyValue>
        <PhoneWrapper>
          <PhoneButtonDropdown contacts={callsData.contacts} companyId={callsData.id} />
        </PhoneWrapper>
      </CardContainer>
    </Wrapper>
  )
}
