import { isUS } from 'env'
import { isDealClosed } from 'modules/domain/deal/utils'
import { FC } from 'react'
import { LineSeparator } from 'views/ui/Header/Header'
import * as Header from '../../../../ui/Header/Header'
import { useDealContext } from '../DealContext'
import { BidCloningButton } from './BidCloningModal/BidCloningButton'
import { CloseDeal } from './CloseDeal'
import { MovePosition } from './MovePosition'
import { SetTask } from './SetTask'
import { StatusSelect } from './StatusSelect'

export const DealManagement: FC = () => {
  const { deal, dealRefetch } = useDealContext()

  if (isDealClosed(deal)) {
    return null
  }

  return (
    <Header.ButtonWrapper>
      <StatusSelect />
      {!isUS && (
        <>
          <LineSeparator />
          <SetTask deal={deal} />
        </>
      )}
      <LineSeparator />
      <BidCloningButton deal={deal} />
      <MovePosition deal={deal} refetch={dealRefetch} />
      <CloseDeal deal={deal} />
    </Header.ButtonWrapper>
  )
}
