import { FC } from 'react'
import styled from 'styled-components'
import { Text } from '@agro-club/agroclub-shared'
import formatPrice from 'modules/utils/numbers/formatPrice'
import { useTranslation } from 'react-i18next'
import { DealStatusTypes, Margins } from 'modules/domain/deal/types'

const Wrapper = styled.div`
  display: flex;
  gap: 0 4px;
`
type Props = {
  status: DealStatusTypes
  revenues?: Margins
}

export const GMVComponent: FC<Props> = ({ revenues, status }) => {
  const { t } = useTranslation('deal')
  if (!revenues) return null
  return (
    <Wrapper>
      <Text typography="bodyMedium" color="secondary">
        {t('revenue')}
      </Text>
      <Text typography="bodyMedium">{formatPrice(Math.round(revenues[status]), true, false)}</Text>
    </Wrapper>
  )
}
