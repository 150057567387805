import { useAFormHandler } from 'analytics/hooks'
import { useFormik } from 'formik'
import { endpoints } from 'modules/endpoints'
import { RequestError } from 'modules/errors'
import { apiClient } from 'modules/utils/httpClient'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { ResponsiveModal } from 'views/components/ResponsiveModal/ResponsiveModal'
import { SdizCommonFields, SdizFormFields } from './SdizCommonFields'

type Props = {
  onClose: () => void
  onSuccess: () => void
}

export const AddSdizModal: FC<Props> = ({ onClose, onSuccess }) => {
  const { t } = useTranslation('sdiz')
  const { formProgress, formHandler } = useAFormHandler()

  const formik = useFormik<SdizFormFields>({
    initialValues: {
      sdiz_number: null,
      address: null,
      product: null,
      ttn_number: '',
      ttn_date: undefined,
    },

    onSubmit: formHandler((value) => apiClient.post(endpoints.sdiz(), value), {
      onSuccess: () => {
        onSuccess()
        onClose()
      },

      onError: (err) => {
        const { errors } = err as RequestError
        formik.setErrors(errors)
      },
    }),
  })

  return (
    <ResponsiveModal
      id="addSdiz"
      onClose={onClose}
      title={t('addSdiz')}
      onConfirm={formik.submitForm}
      progress={formProgress}
    >
      <SdizCommonFields formik={formik} />
    </ResponsiveModal>
  )
}
