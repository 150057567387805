import { endpoints } from 'modules/endpoints'
import { useState } from 'react'
import { ListRequestParams } from 'modules/domain/types'
import { defaultListRequestParams } from 'views/components/TableFilters/data'
import { useHistoryColumns } from './useHistoryColumns'
import { useParams } from 'react-router-dom'
import { AntdTable } from '@agro-club/agroclub-shared'
import { useTableData } from 'modules/domain/common/hooks'

export const HistoryTable = () => {
  const { id: bidId } = useParams()
  const [listRequestParams, setListRequestParams] = useState<ListRequestParams>({
    ...defaultListRequestParams,
    pageSize: 5,
  })

  const { progress, data = [], total } = useTableData(endpoints.bidHistory(bidId), listRequestParams, true)

  const historyColumns = useHistoryColumns()
  return (
    <AntdTable
      data={data}
      columns={historyColumns}
      progress={progress}
      page={listRequestParams.page}
      pageSize={listRequestParams.pageSize}
      pagination={{ showTotal: undefined, size: 'small' }}
      total={total}
      onPageChange={(page) => setListRequestParams({ ...listRequestParams, page })}
      outerBordered
      rowHoverGray
    />
  )
}
