import { bidsEndpoints } from './endpoints/bid'
import { commonEndpoints } from './endpoints/common'
import { companiesEndpoints } from './endpoints/companies'
import { contractsEndpoints } from './endpoints/contracts'
import { dashboardEndpoints } from './endpoints/dashboard'
import { dealsEndpoints } from './endpoints/deal'
import { exportEndpoints } from './endpoints/export'
import { logisticsEndpoints } from './endpoints/logistics'
import { LogisticsKR } from './endpoints/logisticsKR'
import { productsEndpoints } from './endpoints/products'
import { specificationsEndpoints } from './endpoints/specifications'
import { tasksEndpoints } from './endpoints/task'
import { teamsEndpoints } from './endpoints/teams'
import { usersEndpoints } from './endpoints/users'
import { vatsEndpoints } from './endpoints/vats'
import { warehousesEndpoints } from './endpoints/warehouses'

export const endpoints = {
  ...commonEndpoints,
  ...usersEndpoints,
  ...dealsEndpoints,
  ...companiesEndpoints,
  ...productsEndpoints,
  ...bidsEndpoints,
  ...tasksEndpoints,
  ...dashboardEndpoints,
  ...teamsEndpoints,
  ...logisticsEndpoints,
  ...vatsEndpoints,
  ...specificationsEndpoints,
  ...contractsEndpoints,
  ...exportEndpoints,
  ...warehousesEndpoints,
  LogisticsKR,
}
