import { AntTagColor, Panel, Progress } from '@agro-club/agroclub-shared'
import { Bid, BidStatus } from 'modules/domain/bid/types'
import { FC } from 'react'
import { NoDataCard } from 'views/components/Board/NoDataCard'
import { PreloadCard, PreloadCount } from 'views/components/Board/Preload'
import { Count, WrapperCards } from 'views/components/Board/styled'
import { BidCard } from './BidCard'

type Props = {
  setActiveBid?: (arg: Bid) => void
  colorCount: AntTagColor
  progress: Progress
  namePanel: string
  activeBid?: Bid
  key: BidStatus
  bids: Bid[]
  refetch?: () => void
}

export const BidsPanel: FC<Props> = ({
  namePanel,
  bids,
  colorCount,
  setActiveBid,
  activeBid,
  progress,
  refetch,
  ...props
}) => {
  const isLoading = progress === Progress.WORK
  const preloadWrapper = isLoading && <PreloadCard />
  const preloadCount = isLoading && <PreloadCount />

  return (
    <Panel
      {...props}
      header={
        <>
          {namePanel}
          {preloadCount || <Count color={colorCount}>{bids.length || 0}</Count>}
        </>
      }
    >
      <WrapperCards>
        {!isLoading &&
          bids.map((bid) => (
            <BidCard
              refetch={refetch}
              setActiveBid={() => setActiveBid?.(bid)}
              activeCard={bid.id === activeBid?.id}
              key={bid.id}
              bid={bid}
            />
          ))}

        {preloadWrapper}
        {!bids.length && !preloadWrapper && <NoDataCard />}
      </WrapperCards>
    </Panel>
  )
}
