import { defaultTheme, Text } from '@agro-club/agroclub-shared'
import { DASH } from 'modules/constants'
import { Position } from 'modules/domain/bid/types'
import formatNumber from 'modules/utils/numbers/formatNumber'
import { formatQuantity } from 'modules/utils/numbers/formatQuantity'
import { labelTarif } from 'modules/utils/numbers/formatTarif'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { SelectableWrapper } from 'views/components/Card/Wrapper'
import { DeliveryPeriodData } from 'views/components/DealCard/DeliveryPeriod'
import { KeyValue } from 'views/components/KeyValue/KeyValue'
import {
  CoordinatorVerifiedUserIcon,
  KeyEntityIcon,
  KycVerifiedUserIcon,
  SecurityVerifiedUserIcon,
  VerifiedUserIcon,
} from 'views/components/TooltipIcons/TooltipIcons'
import { StatusIcons } from '../styled'

const Content = styled.div`
  padding: 12px;
  display: flex;
  flex-direction: column;
  gap: 8px 0;
`
const PriciesBlock = styled.div`
  width: 248px;
  display: flex;
  justify-content: space-between;
`

export const WrapperCard = styled(SelectableWrapper)`
  &:hover {
    background-color: ${defaultTheme.color.primary8a};
  }
  transition: all 0.3s ease;
`
type Props = {
  position: Position
  onSelectPosition: (position: Position | null) => void
  selectedPositionId: string | null
}

export const PositionCard: FC<Props> = ({ position, onSelectPosition, selectedPositionId }) => {
  const { t } = useTranslation('deal')
  const { bid } = position
  const statusCompany = () => {
    return (
      <StatusIcons>
        <KeyEntityIcon isVisible={bid.owner.profile?.is_significant} />
        <VerifiedUserIcon isVisible={bid.owner.profile?.is_verified} />
        <CoordinatorVerifiedUserIcon
          isVisible={bid.owner.profile?.is_verified_by_coordinator && !bid.owner.profile?.is_verified}
        />
        <SecurityVerifiedUserIcon
          isVisible={bid.owner.profile?.is_verified_by_security && !bid.owner.profile?.is_verified}
        />
        <KycVerifiedUserIcon isVisible={bid.owner.profile?.is_verified_by_kyc && !bid.owner.profile?.is_verified} />
      </StatusIcons>
    )
  }
  return (
    <WrapperCard
      selected={position.id === selectedPositionId}
      onClick={() => onSelectPosition(selectedPositionId === position.id ? null : position)}
    >
      <Content>
        <div>
          <Text typography="accentLarge">{bid.company.name}</Text>
          {statusCompany()}
        </div>
        <Text typography="bodyLarge">
          {bid.product.title}, {formatQuantity(Number(position.remaining_quantity))}, {bid.season?.name}
        </Text>
        <PriciesBlock>
          <KeyValue label={labelTarif(t('basis'))} labelTypo="bodySmall" valueTypo="accentLarge">
            {formatNumber(bid.basis) || DASH}
          </KeyValue>
          <KeyValue label={labelTarif(t('futures'))} labelTypo="bodySmall" valueTypo="accentLarge">
            {formatNumber(bid.futures_price) || DASH}
          </KeyValue>
          <KeyValue label={labelTarif(t('cash'))} labelTypo="bodySmall" valueTypo="accentLarge">
            {formatNumber(bid.price) || DASH}
          </KeyValue>
        </PriciesBlock>

        <div>
          <DeliveryPeriodData data={{ start_date: bid.start_date, end_date: bid.end_date }} />
        </div>
        <Text typography="bodyLarge">{bid.address.short_address}</Text>
      </Content>
    </WrapperCard>
  )
}
