import { CompanyLink } from 'views/components/CompanyLink/CompanyLink'
import { useSingleEntity } from 'modules/domain/common/hooks'
import { endpoints } from 'modules/endpoints'
import { InfoBlock } from './styled'
import { FC } from 'react'
import { Company } from 'modules/domain/company/types'
import { AntSkeleton, Progress } from '@agro-club/agroclub-shared'

type Props = {
  userId: string
}

export const USCompanyInfo: FC<Props> = ({ userId }) => {
  const [progress, companies] = useSingleEntity<{ company: Company; is_default: boolean }[]>(
    endpoints.userCompanies(userId),
  )

  return (
    <InfoBlock>
      {progress === Progress.WORK || !companies?.length ? (
        <AntSkeleton.Button active size="large" style={{ width: 400, borderRadius: 4 }} />
      ) : (
        companies?.map(({ company }) => <CompanyLink key={company.id} company={company} />)
      )}
    </InfoBlock>
  )
}
