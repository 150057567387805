import { Progress, Text } from '@agro-club/agroclub-shared'
import { UserOffer } from 'modules/domain/bid/getUsTopOffers'
import { USBid } from 'modules/domain/bid/types'
import formatNumber from 'modules/utils/numbers/formatNumber'
import formatPrice from 'modules/utils/numbers/formatPrice'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { generatePath } from 'react-router'
import styled from 'styled-components'
import { ARouterLink } from 'views/components/Analytics'
import { Spinner } from 'views/components/CommonTableComponents/CommonTableComponents'
import { DatePeriodFormatted } from 'views/components/DatePeriodFormatted'
import BidRoutes from 'views/pages/Bid/routes'
import {
  DoubleRows,
  FirstRow,
  HeaderGridRow,
  HeaderWrapper,
  NoOffers,
  SecondRow,
  TableText,
  TableWrapper,
} from './styled'

const COLUMNS_GAP = '28px'

const FirstRowWrapper = styled.div`
  display: flex;
  column-gap: 4px;
  align-items: center;
`

type ItemRowProps = {
  offer: USBid
  columnsWidth: string
}

export const ItemRow: FC<ItemRowProps> = ({ offer, columnsWidth }) => {
  const firstRow = (
    <FirstRowWrapper>
      <ARouterLink bodysize="bodyMedium" eventName="bid" to={generatePath(BidRoutes.Details, { id: offer.id })}>
        {offer.product.title}, {offer.symbol},
      </ARouterLink>
      <TableText>
        <DatePeriodFormatted period={{ start_date: offer.start_date, end_date: offer.end_date }} />
      </TableText>
    </FirstRowWrapper>
  )

  return (
    <>
      <DoubleRows>
        <FirstRow>{firstRow}</FirstRow>
        <SecondRow columnsWidth={columnsWidth} columnGap={COLUMNS_GAP}>
          <TableText>{formatPrice(offer.futures_price)}</TableText>
          <TableText>{formatPrice(offer.price_exw_basis)}</TableText>
          <TableText>{formatPrice(offer.price_exw)}</TableText>
          <TableText>{formatNumber(offer.distance)}</TableText>
          <TableText>{formatPrice(offer.sale_price_delivered_basis)}</TableText>
          <TableText>{formatPrice(offer.sale_price_delivered)}</TableText>
        </SecondRow>
      </DoubleRows>
    </>
  )
}

type TableProps = {
  offers: UserOffer['best']
  progress: Progress
}

export const BestBidsTable: FC<TableProps> = ({ offers, progress }) => {
  const { t } = useTranslation('bid')

  const columns = [
    { title: '', subTitle: t('usShortBid.futures'), width: '48px' },
    { title: t('common:fob'), subTitle: t('usShortBid.basis'), width: '44px' },
    { title: t('common:fob'), subTitle: t('usShortBid.cash'), width: '44px' },
    { title: t('usShortBid.delivery'), subTitle: t('distance'), width: '56px' },
    { title: t('usShortBid.delivery'), subTitle: t('usShortBid.basis'), width: '44px' },
    { title: t('usShortBid.delivery'), subTitle: t('usShortBid.cash'), width: '44px' },
  ]
  const formattedWidth = columns.map((col) => col.width).join(' ')
  return (
    <TableWrapper>
      <HeaderWrapper>
        <HeaderGridRow columnsWidth={formattedWidth} columnGap={COLUMNS_GAP}>
          {columns.map(({ title, subTitle }, index) => (
            <div key={index}>
              {title && (
                <Text typography="bodySmall" color="tertiary">
                  {title}
                </Text>
              )}
              <Text typography="bodyMedium" color="secondary">
                {subTitle}
              </Text>
            </div>
          ))}
        </HeaderGridRow>
      </HeaderWrapper>

      <>
        {progress === Progress.WORK ? (
          <Spinner />
        ) : (
          <>
            {offers.length ? (
              <>
                {offers.map((offer) => (
                  <ItemRow key={offer?.id} offer={offer as USBid} columnsWidth={formattedWidth} />
                ))}
              </>
            ) : (
              <NoOffers>{t('ui:AntTable.not_found')}</NoOffers>
            )}
          </>
        )}
      </>
    </TableWrapper>
  )
}
