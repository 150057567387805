import { Icons } from '@agro-club/agroclub-shared'
import { User } from 'modules/domain/user/types'
import formatNumber from 'modules/utils/numbers/formatNumber'
import {
  BlockedUserIcon,
  CoordinatorVerifiedUserIcon,
  KeyEntityIcon,
  KycVerifiedUserIcon,
  NotVerifiedUserIcon,
  SecurityVerifiedUserIcon,
  UserHasDealsIcon,
  VerifiedUserIcon,
} from 'views/components/TooltipIcons/TooltipIcons'
import { Rating, WrapperIcons } from 'views/ui/VerifyUserStatus/styled'

type Props = {
  user: User
}

const VerifyUserStatus = ({ user }: Props) => {
  const isVerifyCoordinator = user.profile?.is_verified_by_coordinator
  const isVerifySecurity = user.profile?.is_verified_by_security
  const isVerifyKyc = user.profile?.is_verified_by_kyc
  const isHasShippedDeals = user.profile?.has_shipped_deals
  const isSignificant = user.profile?.is_significant
  const isVerified = user.profile?.is_verified
  const isActive = user.is_active
  const rating = user.rating

  if (!isActive) {
    return (
      <WrapperIcons>
        <BlockedUserIcon size="large" />
      </WrapperIcons>
    )
  }

  return (
    <WrapperIcons>
      <KeyEntityIcon isVisible={isSignificant} size="large" />
      <VerifiedUserIcon isVisible={isVerified} size="large" />
      <UserHasDealsIcon isVisible={isHasShippedDeals} size="large" />
      <CoordinatorVerifiedUserIcon isVisible={!isVerified && isVerifyCoordinator} size="large" />
      <SecurityVerifiedUserIcon isVisible={!isVerified && isVerifySecurity} size="large" />
      <KycVerifiedUserIcon isVisible={!isVerified && isVerifyKyc} size="large" />
      <NotVerifiedUserIcon isVisible={!isVerifySecurity && !isVerifyCoordinator && !isVerifyKyc} size="large" />
      {rating > 0 && (
        <Rating>
          <Icons.IconStar />
          {formatNumber(rating, true, { fractionDigits: 1 })}
        </Rating>
      )}
    </WrapperIcons>
  )
}

export default VerifyUserStatus
