import { useDisplayClearBtn } from 'hooks/useDisplayClearBtn'
import { endpoints } from 'modules/endpoints'
import { TableFiltersProps } from 'modules/types'
import { useTranslation } from 'react-i18next'
import { AClearFiltersButton } from 'views/components/Analytics'
import { FiltersAndSearchWrapper, FiltersWrapper } from 'views/components/CommonTableComponents/CommonTableComponents'
import { MonthFilter } from 'views/components/MonthFilter/MonthFilter'
import { SearchFilter } from 'views/components/SearchFilter/SearchFilter'
import { TeamsFilter } from 'views/components/TableFilters'
import { ABaseTableFilter } from 'views/components/TableFilters/ABaseTableFilter'
import { defaultListRequestParams } from 'views/components/TableFilters/data'
import { AssigneeDocumentsTeamFilter } from 'views/pages/Contracts/ContractsList/AssigneeDocumentsTeamFilter/AssigneeDocumentsTeamFilter'

export const ContractFilters = ({ listRequestParams, updateFilterState, setListRequestParams }: TableFiltersProps) => {
  const { t } = useTranslation('contracts')
  const displayClearBtn = useDisplayClearBtn({
    listRequestParams,
  })

  return (
    <FiltersAndSearchWrapper>
      <FiltersWrapper>
        <MonthFilter
          placeholder={t('filters.period')}
          value={listRequestParams.filter.month}
          updateFilterState={updateFilterState}
        />
        <ABaseTableFilter
          onChange={(status) => updateFilterState({ status })}
          value={listRequestParams.filter.status}
          placeholder={t('filters.status')}
          getLabel={(label) => label.label}
          endpoint={endpoints.dealsContractStatuses()}
          simpleApiFormat
          id="userStatusFilter"
          showSearch={false}
        />
        <AssigneeDocumentsTeamFilter listRequestParams={listRequestParams} updateFilterState={updateFilterState} />
        <ABaseTableFilter
          onChange={(created_by) => updateFilterState({ created_by })}
          value={listRequestParams.filter.created_by}
          placeholder={t('filters.createdBy')}
          getLabel={(label) => label.full_name}
          endpoint={endpoints.coordinatorsShort()}
          searchField="search"
          id="createdByFilter"
        />
        <TeamsFilter
          listRequestParams={listRequestParams}
          updateFilterState={updateFilterState}
          endpoint={endpoints.allTeams()}
        />
        {displayClearBtn && (
          <AClearFiltersButton onClick={() => setListRequestParams({ ...defaultListRequestParams })} />
        )}
      </FiltersWrapper>
      <SearchFilter listRequestParams={listRequestParams} updateFilterState={updateFilterState} />
    </FiltersAndSearchWrapper>
  )
}
