import { MobileModalFooter } from '../MobileComponents/Modal/MobileModalFooter'
import ADefaultModalFooter from '../DefaultModalFooter/ADefaultModalFooter'
import { Progress, Text, defaultTheme } from '@agro-club/agroclub-shared'
import { AMobileModal } from '../MobileComponents/Modal/AMobileModal'
import { ASharedModal, SharedModalTitle } from '../SharedModal'
import { useMediaQuery } from 'react-responsive'
import { CSSProperties, FC, ReactNode } from 'react'

type Props = {
  size?: 'small' | 'large' | 'medium' | 'extraLarge'
  confirmButtonText?: string
  onConfirm?: () => void
  headerHeight?: number
  children: ReactNode
  onClose: () => void
  progress?: Progress
  disabled?: boolean
  title: ReactNode
  withFooter?: boolean
  id: string
  contentStyle?: CSSProperties
}

export const ResponsiveModal: FC<Props> = ({
  confirmButtonText,
  headerHeight,
  onConfirm,
  progress,
  children,
  disabled,
  onClose,
  withFooter = true,
  title,
  size,
  id,
  ...otherProps
}) => {
  const isMobile = useMediaQuery({
    query: `(max-width: ${defaultTheme.queries.mobile})`,
  })

  return (
    <>
      {isMobile ? (
        <AMobileModal
          header={<Text typography="titleH4">{title}</Text>}
          headerHeight={headerHeight}
          onClose={onClose}
          id={id}
        >
          {children}
          <MobileModalFooter
            progress={progress}
            confirmButtonText={confirmButtonText}
            onConfirm={onConfirm}
            disabled={disabled}
          />
        </AMobileModal>
      ) : (
        <ASharedModal id={id} onClose={onClose} size={size} {...otherProps}>
          <SharedModalTitle>{title}</SharedModalTitle>
          {children}
          {withFooter && (
            <ADefaultModalFooter
              confirmButtonText={confirmButtonText}
              onConfirm={onConfirm}
              progress={progress}
              onClose={onClose}
              disabled={disabled}
            />
          )}
        </ASharedModal>
      )}
    </>
  )
}
