import { urlMaker } from 'modules/utils/urlMaker'

export const vatsEndpoints = {
  calls: urlMaker('/vats/calls/'),
  makeCall: urlMaker('/vats/make_call/'),
  accounts: urlMaker('/vats/vats_accounts/'),
  callStatistics: urlMaker('/vats/call-statistics/vats-accounts/'),
  callStatisticsAverage: urlMaker('/vats/call-statistics/average/'),
  callStatisticsGeneral: urlMaker('/vats/call-statistics/general/'),
  dashboardCallLogs: () => '/vats/register-call/',

  //
  //Twilio
  whatsAppTwilio: () => '/twilio/messages/',
  whatsAppDetailTwilio: (coordinator_id: string, phone: string, date: string) =>
    `twilio/messages-details/?coordinator_id=${coordinator_id}&client_phone=${phone}&date_sent=${date}`,
}
