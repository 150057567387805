import { useDisplayClearBtn } from 'hooks/useDisplayClearBtn'
import { ListRequestParams } from 'modules/domain/types'
import { endpoints } from 'modules/endpoints'
import { Dispatch, FC, SetStateAction } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { AClearFiltersButton, ASearch } from 'views/components/Analytics'
import { ABaseTableFilter } from 'views/components/TableFilters/ABaseTableFilter'
import { defaultListRequestParams } from 'views/components/TableFilters/data'
import { ADateRangePicker } from 'views/components/TableFilters/DateRangePicker/ADateRangePicker'

const FiltersWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 8px 16px;
`

type Props = {
  listRequestParams: ListRequestParams
  setListRequestParams: Dispatch<SetStateAction<ListRequestParams>>
}

export const ExportFileSearchFilters: FC<Props> = ({ listRequestParams, setListRequestParams }) => {
  const { t } = useTranslation('export')

  const displayClearBtn = useDisplayClearBtn({
    listRequestParams,
  })

  const updateFilterState = (filterObj: Record<string, any>) => {
    setListRequestParams((prev) => ({
      ...prev,
      filter: {
        ...prev.filter,
        ...filterObj,
      },
    }))
  }

  return (
    <>
      <ASearch value={listRequestParams.filter.search} onChange={(search) => updateFilterState({ search })} />

      <FiltersWrapper>
        <ADateRangePicker
          id="filterRangeDate"
          value={[listRequestParams.filter.contract_date_after, listRequestParams.filter.contract_date_before]}
          onChange={(value) =>
            updateFilterState({ contract_date_before: value.endDate, contract_date_after: value.startDate })
          }
        />
        <ABaseTableFilter
          id="contractNumber"
          value={listRequestParams.filter.contract_id}
          onChange={(contract_id) => updateFilterState({ contract_id })}
          getLabel={(dto) => dto.number}
          endpoint={endpoints.exportShortList()}
          placeholder={t('contractNumber')}
          searchField="search"
          simpleApiFormat
        />
        <ABaseTableFilter
          id="productFilter"
          value={listRequestParams.filter.product_id}
          onChange={(product_id) => updateFilterState({ product_id })}
          getLabel={(dto) => dto.title}
          endpoint={endpoints.productChoices()}
          placeholder={t('bid:filterByCrops')}
          searchField="search"
          simpleApiFormat
        />
        <ABaseTableFilter
          id="exportPurchasersFilter"
          value={listRequestParams.filter.purchaser}
          onChange={(purchaser) => updateFilterState({ purchaser })}
          getLabel={(dto) => dto.name}
          endpoint={endpoints.exportPurchasers()}
          placeholder={t('buyer')}
          searchField="search"
          simpleApiFormat
        />
        <ABaseTableFilter
          id="shipOwnerFilter"
          value={listRequestParams.filter.ship_owner}
          onChange={(ship_owner) => updateFilterState({ ship_owner })}
          getLabel={(dto) => dto.name}
          endpoint={endpoints.exportShipOwners()}
          placeholder={t('shipowner')}
          searchField="search"
          simpleApiFormat
        />
        <ABaseTableFilter
          id="exportBrokerFilter"
          value={listRequestParams.filter.export_broker}
          onChange={(export_broker) => updateFilterState({ export_broker })}
          getLabel={(dto) => dto.name}
          endpoint={endpoints.exportBrokers()}
          placeholder={t('exportBroker')}
          searchField="search"
          simpleApiFormat
        />
        <ABaseTableFilter
          id="freightBrokerFilter"
          value={listRequestParams.filter.freight_broker}
          onChange={(freight_broker) => updateFilterState({ freight_broker })}
          getLabel={(dto) => dto.name}
          endpoint={endpoints.exportFreightBrokers()}
          placeholder={t('freightBroker')}
          searchField="search"
          simpleApiFormat
        />
        <ABaseTableFilter
          id="insuranceFilter"
          value={listRequestParams.filter.insurance}
          onChange={(insurance) => updateFilterState({ insurance })}
          getLabel={(dto) => dto.name}
          endpoint={endpoints.exportInsurances()}
          placeholder={t('insurance')}
          searchField="search"
          simpleApiFormat
        />
        {displayClearBtn && (
          <AClearFiltersButton onClick={() => setListRequestParams({ ...defaultListRequestParams, filter: {} })} />
        )}
      </FiltersWrapper>
    </>
  )
}
