import { t } from 'i18next'
import { ListRequestParams } from 'modules/domain/types'
import { ASelectFilter } from '../Analytics'
import { FC } from 'react'

interface options {
  value: string
  label: string
}

interface Props {
  listRequestParams: ListRequestParams
  updateFilterState: any
  options: options[]
  disabled?: boolean
}

export const FileFilter: FC<Props> = ({ listRequestParams, updateFilterState, options, disabled }) => {
  return (
    <ASelectFilter
      onChange={(has_files) => updateFilterState({ has_files })}
      value={listRequestParams.filter.has_files}
      placeholder={t('bid:filterByFile')}
      options={options}
      showSearch={false}
      disabled={disabled ?? false}
      id="fileFilter"
    />
  )
}
