import { defaultTheme, Icons } from '@agro-club/agroclub-shared'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { EntityVerifyActionStyle } from './styled'

export const StyledWrapper = styled.div<{ isVerified: boolean }>`
  ${EntityVerifyActionStyle};
  color: ${(props) => (props.isVerified ? defaultTheme.color.secondary900 : defaultTheme.color.secondary600)};
  pointer-events: ${(props) => (props.isVerified ? 'none' : 'auto')};

  svg > path {
    fill: ${(props) => (props.isVerified ? defaultTheme.color.accentApprove600 : defaultTheme.color.secondary300)};
  }
`

type Props = {
  isVerified: boolean
  onClick: () => void
}

export const SignificantVerifyAction: FC<Props> = ({ isVerified, onClick }) => {
  const { t } = useTranslation('user')

  return (
    <StyledWrapper isVerified={isVerified} onClick={onClick}>
      <div>
        <Icons.IconDollar />
        <span>{t('key_user')}</span>
      </div>
    </StyledWrapper>
  )
}
