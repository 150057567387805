import { Routes, Route } from 'react-router-dom'

import { CompanyDetail } from 'views/pages/Company/CompanyDetail/CompanyDetail'

import CompanyList from './CompanyList'
import CompanyRoutes from './routes'
import { UsCompanyDetail } from './UsCompanyDetail/UsCompanyDetail'
import { isUS } from 'env'

const Detail = isUS ? UsCompanyDetail : CompanyDetail

function CompanyPage() {
  return (
    <Routes>
      <Route index element={<CompanyList />} />
      <Route path={CompanyRoutes.relativeDetail} element={<Detail />} />
      <Route path={CompanyRoutes.relativeDetailWithBranch} element={<Detail />} />
    </Routes>
  )
}

export default CompanyPage
