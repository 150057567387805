import { ResponsesColumn } from './ResponsesColumn/ResponsesColumn'
import { MatchesColumn } from './MatchesColumn/MatchesColumn'
import { WrapperBoard } from 'views/components/Board/styled'
import { SlugLabelPair } from 'modules/domain/common/types'
import { BidsColumn } from './BidsColumn/BidsColumn'
import { Bid } from 'modules/domain/bid/types'
import { FC, useState } from 'react'

type Props = {
  reasons: SlugLabelPair[]
  userId: string
}

export const Board: FC<Props> = ({ userId, reasons }) => {
  const [activeBid, setActiveBid] = useState<Bid>()

  return (
    <WrapperBoard>
      <BidsColumn userId={userId} setActiveBid={setActiveBid} activeBid={activeBid} />
      <MatchesColumn activeBid={activeBid} reasons={reasons} />
      <ResponsesColumn activeBid={activeBid} reasons={reasons} />
    </WrapperBoard>
  )
}
