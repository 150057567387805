import { t } from 'i18next'
import { ListRequestParams } from 'modules/domain/types'
import { endpoints } from 'modules/endpoints'
import { BaseTableFilterProps } from '@agro-club/agroclub-shared'
import { ABaseTableFilter } from './ABaseTableFilter'
import { FC } from 'react'
import { isUS } from 'env'

interface Props extends Omit<BaseTableFilterProps, 'value' | 'placeholder' | 'endpoint' | 'onChange'> {
  listRequestParams: ListRequestParams
  updateFilterState: any
  placeholder?: string
  filter?: any
}

export const RegionsFilter: FC<Props> = ({
  listRequestParams,
  updateFilterState,
  placeholder,
  filter,
  ...otherProps
}) => {
  const defaultPlaceHolder = isUS ? t('regionFilter:usPlaceholder') : t('regionFilter:placeholder')

  return (
    <ABaseTableFilter
      onChange={(region) => {
        if (region.length) {
          updateFilterState({ region })
        } else {
          updateFilterState({ region, district: [] })
        }
      }}
      value={listRequestParams.filter.region}
      placeholder={placeholder ?? defaultPlaceHolder}
      endpoint={endpoints.provinces()}
      searchField="name"
      simpleApiFormat
      filter={filter}
      id="regionFilter"
      {...otherProps}
    />
  )
}
