import { Bid, BidStatus } from 'modules/domain/bid/types'
import { Progress } from '@agro-club/agroclub-shared'
import { endpoints } from 'modules/endpoints'
import { apiClient } from 'modules/utils/httpClient'
import { useEffect, useState } from 'react'
import { ListResponse } from 'types/api'
import { ListRequestParams } from 'modules/domain/types'

export const useBidsData = (listRequestParams: ListRequestParams, userId?: string, companyId?: string) => {
  const [activeBid, setActiveBid] = useState<Bid>()

  const [archivedBids, setArchivedBids] = useState<Bid[]>([])
  const [publishedBids, setPublishedBids] = useState<Bid[]>([])
  const [bidsProgress, setBidsProgress] = useState<Progress>(Progress.IDLE)

  const fetchBids = async () => {
    setActiveBid(undefined)
    setBidsProgress(Progress.WORK)
    try {
      if (userId) {
        const [publishedBids, archivedBids] = await Promise.all([
          apiClient.get<Bid[]>(endpoints.userBids(userId, BidStatus.published)),
          apiClient.get<Bid[]>(endpoints.userBids(userId, BidStatus.archived)),
        ])
        setPublishedBids(publishedBids)
        setArchivedBids(archivedBids)
      } else if (companyId) {
        const [publishedBids, archivedBids] = await Promise.all([
          apiClient.get<ListResponse<Bid>>(endpoints.publishedBidsForCompany(companyId), {
            ...listRequestParams.filter,
            page_size: 1000,
          }),
          apiClient.get<ListResponse<Bid>>(endpoints.archivedBidsForCompany(companyId), {
            ...listRequestParams.filter,
            page_size: 1000,
          }),
        ])
        setPublishedBids(publishedBids.results)
        setArchivedBids(archivedBids.results)
      }
    } catch (error) {
      console.error(error)
    } finally {
      setBidsProgress(Progress.IDLE)
    }
  }

  useEffect(() => {
    fetchBids()

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userId, companyId, listRequestParams])

  return {
    activeBid,
    setActiveBid,
    publishedBids,
    archivedBids,
    bidsProgress,
    fetchBids,
  }
}
