import { urlMaker } from 'modules/utils/urlMaker'

export const logisticsEndpoints = {
  //
  //New Logistics
  sdiz: urlMaker('/new-logistics/sdizes/'),
  sdizAddresses: () => '/new-logistics/sdiz-addresses/',

  //
  //Logistics
  carDrivers: (carId: string, driverId?: string) =>
    `/logistics/cars/${carId}/drivers/${driverId ? `${driverId}/` : ''}`,
  allDrivers: (driverId: string) => `/logistics/drivers/${driverId}/`,
  carTrips: (carId: string) => `/logistics/cars/${carId}/trips/`,
  carrierDocumentTypes: (id: string) => `logistics/carriers/${id}/document_types/`,
  carrierDocumentFiles: (id: string) => `logistics/carriers/${id}/documents/`,
  carrierDeleteDocumentFile: (id: string, document_id: string) => `/logistics/carriers/${id}/documents/${document_id}/`,
  carChangeStatus: (carId: string) => `/logistics/cars/${carId}/change_status/`,
  car: urlMaker('/logistics/cars/'),
  logisticsDeal: urlMaker('/logistics/deals/'),
  trip: urlMaker('/logistics/trips/'),
  carrier: urlMaker('/logistics/carriers/'),
  carriersDocuments: (id: string) => `/logistics/carriers/${id}/documents/`,
  carFiles: (carId: string, fileId?: string) => `/logistics/cars/${carId}/files/${fileId ? `${fileId}/` : ''}`,
  relevantCars: (dealId: string) => `/logistics/deals/${dealId}/relevant-cars/`,
  involvedCars: (dealId: string) => `/logistics/deals/${dealId}/involved-cars/`,
  carDeals: (carId: string) => `/logistics/cars/${carId}/deals/`,
  logisticStatuses: '/logistics/deals/statuses/',
  logisticChangeStatus: (id: string) => `/logistics/deals/${id}/change_status/`,
  trailers: urlMaker('/logistics/trailers/'),
  carrierTrailers: (carrierId: string) => `/logistics/carriers/${carrierId}/trailers/`,
  archiveTrailers: (id: string) => `/logistics/trailers/${id}/change_status/`,
  tripComments: (tripId: string) => `/logistics/trips/${tripId}/comments/`,
  tripsDocuments: (id: string) => `/logistics/trips/${id}/documents/`,
  tripsDeleteDocumentFile: (id: string, document_id: string) => `/logistics/trips/${id}/documents/${document_id}/`,
  tripsDocumentTypes: (id: string) => `logistics/trips/${id}/document_types/`,
  tripsDocumentFiles: (id: string) => `logistics/trips/${id}/documents/`,
  dealPlannedCloseDate: (id: string) => `logistics/deals/${id}/set-planned-close-date/`,
  brRoutesList: () => '/logistics/reference-routes/',
  brRoutesSaleCities: () => '/logistics/reference-routes/regions?sale_cities=true',
  brRoutesSaleRegions: () => '/logistics/reference-routes/regions?sale_regions=true',
  brRoutesPurchaseCities: () => '/logistics/reference-routes/regions?purchase_cities=true',
  brRoutesUploadFile: () => '/logistics/reference-routes/upload/',
}
