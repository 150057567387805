import { defaultTheme, Text } from '@agro-club/agroclub-shared'
import styled from 'styled-components'
import { EntityVerifyActionStyle } from 'views/components/EntityVerifyActions/styled'

export const VerifiedByAll = styled.div`
  ${EntityVerifyActionStyle};
  pointer-events: none;

  svg > path {
    margin-right: 10px;
    fill: ${defaultTheme.color.primary600};
  }
`

export const BlockUser = styled.div<{ withSeparator?: boolean; isActive: boolean }>`
  ${EntityVerifyActionStyle};
  position: relative;
  pointer-events: ${(props) => (props.isActive ? 'auto' : 'none')};
  color: ${(props) => (props.isActive ? defaultTheme.color.secondary600 : defaultTheme.color.secondary900)};

  svg > path {
    fill: ${(props) => (props.isActive ? defaultTheme.color.secondary300 : defaultTheme.color.accentDestruct800)};
  }

  &::before {
    content: '';
    position: absolute;
    display: ${({ withSeparator }) => (withSeparator ? 'block' : 'none')};
    width: 1px;
    height: 100%;
    left: -24px;
    background: ${defaultTheme.color.textQuarternary18a};
  }
`

export const Verified = styled.div<{ isVerified: boolean | undefined }>`
  ${EntityVerifyActionStyle};
  color: ${(props) => (props.isVerified ? defaultTheme.color.secondary900 : defaultTheme.color.secondary600)};
  pointer-events: ${(props) => (props.isVerified ? 'none' : 'auto')};

  svg > path {
    fill: ${(props) => (props.isVerified ? defaultTheme.color.accentNotify600 : defaultTheme.color.secondary300)};
  }
`

export const WrapperCollapse = styled.div`
  .ant-collapse-content-box {
    padding-bottom: 15px;
  }
`

export const VerifiedDate = styled(Text).attrs({ color: 'secondary', typography: 'bodyMedium' })`
  margin-left: 38px;
`
