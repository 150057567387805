const main = 'reports'
const relativeRoutes = {
  relativePage: `${main}/*`,
  relativeList: `${main}`,
}

const routes = {
  List: `/${main}`,
}

const ReportsRoutes = { ...relativeRoutes, ...routes }

export default ReportsRoutes
