import { urlMaker } from 'modules/utils/urlMaker'

export const teamsEndpoints = {
  teams: () => '/teams/',
  allTeams: () => '/teams/?set=all',
  tasksTeamsChoices: () => '/teams/choices',
  teamsMembers: urlMaker('/teams/members/'),
  teamsPlans: urlMaker('/teams/plans/'),
  teamsPlansById: (id: string) => `/teams/${id}/plans/`,
  teamsMembersById: (id: string) => `/teams/${id}/with-members/`,
  teamsOverallByMonth: (date: string) => `/teams/plans/overall-by-month/?schedule_year=${date}`,
}
