import { Card } from 'views/components/Card/Card'

import { isFactoringAvailable } from 'env'
import { refetchFunc } from 'modules/domain/common/hooks'
import { Company } from 'modules/domain/company/types'
import { useTranslation } from 'react-i18next'
import { Fragment } from 'react/jsx-runtime'
import { Hr } from 'views/components/Hr/Hr'
import { CardRow } from 'views/pages/Company/CompanyDetail/styled'
import { DetailedFactoringInfo } from './DetailedFactoringInfo'
import { FactoringHeader } from './FactoringHeader'

interface Props {
  company: Company
  refetchCompany: refetchFunc
}

export const CompanyFactoring = ({ company, refetchCompany }: Props) => {
  const { t } = useTranslation('company')

  if (!isFactoringAvailable) return null

  return (
    <Card.Container>
      <Card.GapableContent>
        <CardRow>
          <Card.Title>{t('factoring')}</Card.Title>
          <FactoringHeader company={company} refetchCompany={refetchCompany} />
        </CardRow>
        {!!company.factoring_requests?.length && (
          <Card.Container bordered>
            <Card.GapableContent noPaddingForEmpty={false}>
              {company.factoring_requests.map((request, index) => (
                <Fragment key={request.id}>
                  {index !== 0 && <Hr margin="-2px 0" />}
                  <DetailedFactoringInfo key={request.id} factoringRequest={request} refetchCompany={refetchCompany} />
                </Fragment>
              ))}
            </Card.GapableContent>
          </Card.Container>
        )}
      </Card.GapableContent>
    </Card.Container>
  )
}
