import { Progress, Text } from '@agro-club/agroclub-shared'
import { UserOffer } from 'modules/domain/bid/getUsTopOffers'
import { USBid } from 'modules/domain/bid/types'
import formatNumber from 'modules/utils/numbers/formatNumber'
import formatPrice from 'modules/utils/numbers/formatPrice'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { generatePath } from 'react-router'
import styled from 'styled-components'
import { ARouterLink } from 'views/components/Analytics'
import { Spinner } from 'views/components/CommonTableComponents/CommonTableComponents'
import { CompanyLink } from 'views/components/CompanyLink/CompanyLink'
import { DatePeriodFormatted } from 'views/components/DatePeriodFormatted'
import BidRoutes from 'views/pages/Bid/routes'
import {
  DoubleRows,
  FirstRow,
  HeaderGridRow,
  HeaderWrapper,
  NoOffers,
  SecondRow,
  TableText,
  TableWrapper,
} from './styled'

const COLUMNS_GAP = '16px'

const FirstRowWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`

type ItemRowProps = {
  offer: USBid
  columnsWidth: string
}

export const ItemRow: FC<ItemRowProps> = ({ offer, columnsWidth }) => {
  const firstRow = (
    <FirstRowWrapper>
      <ARouterLink bodysize="bodyMedium" eventName="bid" to={generatePath(BidRoutes.Details, { id: offer.id })}>
        {offer.product.title}, {offer.symbol}
      </ARouterLink>
      <CompanyLink company={offer.company} target="_blank" bodysize="bodyMedium" />
      <TableText>{offer.address.address}</TableText>
    </FirstRowWrapper>
  )

  return (
    <>
      <DoubleRows>
        <FirstRow>{firstRow}</FirstRow>
        <SecondRow columnsWidth={columnsWidth} columnGap={COLUMNS_GAP}>
          <TableText>
            <DatePeriodFormatted period={{ start_date: offer.start_date, end_date: offer.end_date }} />
          </TableText>
          <TableText>{formatNumber(offer.distance)}</TableText>
          <TableText>{formatPrice(offer.futures_price)}</TableText>
          <TableText>{formatPrice(offer.basis)}</TableText>
          <TableText>{formatPrice(offer.price)}</TableText>
        </SecondRow>
      </DoubleRows>
    </>
  )
}

type TableProps = {
  offers: UserOffer['closest']
  progress: Progress
}

export const ClosestBidsTable: FC<TableProps> = ({ offers, progress }) => {
  const { t } = useTranslation('bid')

  const columns = [
    { title: t('usShortBid.delivery'), width: '156px' },
    { title: t('distance'), width: '56px' },
    { title: t('usShortBid.futures'), width: '56px' },
    { title: t('usShortBid.basis'), width: '44px' },
    { title: t('usShortBid.cash'), width: '44px' },
  ]
  const formattedWidth = columns.map((col) => col.width).join(' ')

  return (
    <TableWrapper>
      <HeaderWrapper>
        <HeaderGridRow columnsWidth={formattedWidth} columnGap={COLUMNS_GAP}>
          {columns.map(({ title }, index) => (
            <Text key={index} typography="bodyMedium" color="secondary">
              {title}
            </Text>
          ))}
        </HeaderGridRow>
      </HeaderWrapper>

      <>
        {progress === Progress.WORK ? (
          <Spinner />
        ) : (
          <>
            {offers.length ? (
              <>
                {offers.map((offer) => (
                  <ItemRow key={offer?.id} offer={offer as USBid} columnsWidth={formattedWidth} />
                ))}
              </>
            ) : (
              <NoOffers>{t('ui:AntTable.not_found')}</NoOffers>
            )}
          </>
        )}
      </>
    </TableWrapper>
  )
}
