import { FC } from 'react'
import { useFormik } from 'formik'
import { endpoints } from 'modules/endpoints'
import { apiClient } from 'modules/utils/httpClient'

import { LogisticsKRLoadingWarehouse } from 'views/pages/LogisticsKR/types'
import { SellerWarehouseForm } from 'views/components/WarehouseModal/SellerWarehouseForm'
import { useAFormHandler } from 'analytics/hooks'
import { refetchFunc } from 'modules/domain/common/hooks'

interface Props {
  onClose?: () => void
  setIsOpen: (val: boolean) => void
  loading_warehouse: LogisticsKRLoadingWarehouse
  refetch: refetchFunc
}

export const LoadingAddressModal: FC<Props> = ({ onClose, setIsOpen, loading_warehouse, refetch }) => {
  const { formProgress, formHandler } = useAFormHandler('editLoadingWarehouse', { id: String(loading_warehouse.id) })

  const handleClose = () => {
    onClose?.()
    setIsOpen(false)
  }

  const formik = useFormik({
    initialValues: {
      gates_height: loading_warehouse.gates_height,
      loader_type: loading_warehouse.loader_type,
      scales_capacity: loading_warehouse.scales_capacity,
      scales_remoteness: loading_warehouse.scales_remoteness,
      scales_length: loading_warehouse.scales_length,
      volume_per_day: loading_warehouse.volume_per_day,
      vehicle_type: loading_warehouse.vehicle_type,
      overload: loading_warehouse.overload,
      schedule_from: loading_warehouse.schedule_from,
      schedule_to: loading_warehouse.schedule_to,
      works_on_weekend: loading_warehouse.works_on_weekend,
      car_delivery: loading_warehouse.car_delivery,
      railway_delivery: loading_warehouse.railway_delivery,
      weather_conditions: loading_warehouse.weather_conditions,
    },
    onSubmit: formHandler(
      async () => await apiClient.put(endpoints.LogisticsKR.loadingWarehouse(loading_warehouse.id), formik.values),
      {
        onSuccess: async () => {
          await refetch()
          setIsOpen(false)
        },
        onError: (error) => {
          const { errors } = error
          formik.setErrors(errors)
        },
      },
    ),
  })

  return <SellerWarehouseForm formik={formik} onClose={handleClose} progress={formProgress} boolDeliveryRadio />
}
