import { defaultTheme, Progress } from '@agro-club/agroclub-shared'
import { useLocalStorage } from 'hooks/useLocalStorage'
import { useNotification } from 'hooks/useNotification'
import { Position, PositionType } from 'modules/domain/bid/types'
import { refetchFunc, useSingleEntity, useTableData } from 'modules/domain/common/hooks'
import { Margins, MarginsforDeals } from 'modules/domain/deal/types'
import { ListRequestParams } from 'modules/domain/types'
import { endpoints } from 'modules/endpoints'
import { FC, useEffect, useMemo, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { BestColumn } from './BestColumn'
import { Column } from './Column'
import { CreateBatchForm } from './CreateBatchForm'
import { InfoBlock } from './InfoBlock'

export const Wrapper = styled.div`
  background: ${defaultTheme.color.primary8a};
  padding: 16px 16px 0 16px;
  border-radius: 0 8px 0 0;
  min-height: max-content;
  margin-left: -24px;
  max-height: 100%;
  display: flex;
  gap: 0 16px;
`
const TwoColumns = styled.div`
  display: flex;
`
const PositionsColumns = styled.div`
  background: ${defaultTheme.color.backgroundSecondary};
  border-radius: 8px;
`
const WrapperFormBlock = styled.div`
  max-width: 640px;
  margin: 0 8px 8px 8px;
`

export type BestCard = {
  id: string
}

const createParams = (positionType: PositionType) => ({
  page: 1,
  pageSize: 10000,
  filter: {
    position_type: positionType,
  },
})

const defaultTopMargin = 300

type Props = {
  listRequestParams: ListRequestParams
  revenues?: Margins
  margins?: MarginsforDeals
  progressMargin: Progress
  refetch: refetchFunc
}

export const BatchesBlock: FC<Props> = ({ refetch, listRequestParams, margins, revenues, progressMargin }) => {
  const { t } = useTranslation('deal')
  const notify = useNotification()

  const [isShowPositionInfo, setIsShowPositionInfo] = useLocalStorage('isShowPositionInfo', true)

  const onClosePositionInfo = () => {
    setIsShowPositionInfo(false)
  }

  const [infoBlockHeight, setInfoBlockHeight] = useState(0)
  const refBlock = useRef<HTMLDivElement | null>(null)

  useEffect(() => {
    if (refBlock.current) {
      const resizeObserver = new ResizeObserver((entries) => {
        for (const entry of entries) {
          setInfoBlockHeight(entry.contentRect.height)
        }
      })
      resizeObserver.observe(refBlock.current)

      return () => {
        resizeObserver.disconnect()
      }
    }
  }, [])

  const calculateTopMargin = (infoBlockHeight: number) => {
    return defaultTopMargin + infoBlockHeight
  }

  const [longPosition, setLongPosition] = useState<Position | null>(null)
  const [shortPosition, setShortPosition] = useState<Position | null>(null)
  const isMatchProducts = longPosition?.bid.product.id === shortPosition?.bid.product.id

  useEffect(() => {
    if ((longPosition || shortPosition) && isShowPositionInfo) onClosePositionInfo()

    if (longPosition && shortPosition && !isMatchProducts) notify(Progress.ERROR, { title: t('productDoNotMatch') })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [longPosition, shortPosition])

  const longParams = useMemo(() => createParams(PositionType.LONG), [])
  const shortParams = useMemo(() => createParams(PositionType.SHORT), [])

  const {
    progress: progressLong,
    data: longPositions = [],
    refetch: refetchLong,
  } = useTableData<Position>(endpoints.positions(), longParams, true)

  const {
    progress: progressShort,
    data: shortPositions = [],
    refetch: refetchShort,
  } = useTableData<Position>(endpoints.positions(), shortParams, true)

  const [progressTotals, totals, refetchTotals] = useSingleEntity<
    { position_type: PositionType; remaining_quantity: number }[]
  >(endpoints.totalsPositions())
  const shortRemainingQuantity = totals?.find((total) => total.position_type === PositionType.SHORT)?.remaining_quantity
  const longRemainingQuantity = totals?.find((total) => total.position_type === PositionType.LONG)?.remaining_quantity

  const clearBids = () => {
    setLongPosition(null)
    setShortPosition(null)
  }

  const handleRefetch = () => {
    refetchLong()
    refetchShort()
    refetchTotals()
    refetch()
  }

  return (
    <Wrapper>
      <PositionsColumns>
        <TwoColumns>
          <Column
            topMargin={calculateTopMargin(infoBlockHeight)}
            selectedPositionId={longPosition?.id || null}
            progressRemainingQuantity={progressTotals}
            remainingQuantity={longRemainingQuantity}
            onSelectPosition={setLongPosition}
            borderRadius="8px 0 0 8px"
            title={t('longPosition')}
            progress={progressLong}
            cards={longPositions}
          />
          <Column
            topMargin={calculateTopMargin(infoBlockHeight)}
            selectedPositionId={shortPosition?.id || null}
            progressRemainingQuantity={progressTotals}
            remainingQuantity={shortRemainingQuantity}
            onSelectPosition={setShortPosition}
            borderRadius="0 8px 0 8px"
            title={t('shortPosition')}
            progress={progressShort}
            cards={shortPositions}
          />
        </TwoColumns>
        <WrapperFormBlock ref={refBlock}>
          {longPosition && shortPosition && isMatchProducts ? (
            <CreateBatchForm
              longPosition={longPosition}
              shortPosition={shortPosition}
              clearPositions={clearBids}
              refetch={handleRefetch}
            />
          ) : null}
          {isShowPositionInfo && <InfoBlock text={t('infoPosition')} onClose={onClosePositionInfo} />}
        </WrapperFormBlock>
      </PositionsColumns>
      <BestColumn
        refetch={handleRefetch as refetchFunc}
        listRequestParams={listRequestParams}
        progressMargin={progressMargin}
        revenues={revenues}
        margins={margins}
      />
    </Wrapper>
  )
}
