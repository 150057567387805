import { FormGroup, Input, Radio, RadioItem } from '@agro-club/agroclub-shared'
import { CompanyCommercial, Prepayment } from 'modules/domain/company/types'
import { FormikFieldsType } from 'modules/domain/types'
import { getPersonalNumberLabel } from 'modules/utils/numbers/formatPersonalNumber'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { FormItemWrapper, ShortFormControl } from 'views/styled/common'
import { ShortNumberWrapper } from '../CompanyDetail/CompanyGeneral/CompanyRuDetailsModal'

type Props = {
  formik: FormikFieldsType
}

const NameCompany: FC<Props> = ({ formik }) => {
  const { t } = useTranslation('company')
  return (
    <FormGroup label={t('common:company')} error={formik.errors.name} required>
      <Input {...formik.getFieldProps('name')} invalid={!!formik.errors.name} required />
    </FormGroup>
  )
}
const PersonalNumber: FC<Props> = ({ formik }) => {
  return (
    <ShortFormControl>
      <FormGroup label={getPersonalNumberLabel()} error={formik.errors.inn}>
        <Input {...formik.getFieldProps('inn')} invalid={!!formik.errors.inn} />
      </FormGroup>
    </ShortFormControl>
  )
}

const RegNumber: FC<Props> = ({ formik }) => {
  const { t } = useTranslation('company')
  return (
    <ShortFormControl>
      <FormGroup label={t('common:ie')} error={formik.errors.state_reg_number}>
        <Input {...formik.getFieldProps('state_reg_number')} invalid={!!formik.errors.state_reg_number} />
      </FormGroup>
    </ShortFormControl>
  )
}

const PrepaymentState: FC<Props> = ({ formik }) => {
  const { t } = useTranslation('company')
  return (
    <FormGroup label={t('form.fields.prepayment')} error={formik.errors.prepayment}>
      <FormItemWrapper>
        <Radio
          value={formik.values.prepayment}
          onChange={(val) => {
            formik.setFieldValue('prepayment', val)
          }}
        >
          <RadioItem value={Prepayment.yes} label={t('form.prepaymentValues.yes')} />
          <RadioItem value={Prepayment.no} label={t('form.prepaymentValues.no')} />
          <RadioItem value={Prepayment.unknown} label={t('form.prepaymentValues.unknown')} />
        </Radio>
      </FormItemWrapper>
    </FormGroup>
  )
}

const PaymentDelayDays: FC<Props> = ({ formik }) => {
  const { t } = useTranslation('company')
  return (
    <FormGroup label={t('form.fields.payment_delay_days')} error={formik.errors.payment_delay_days}>
      <ShortNumberWrapper>
        <Input
          {...formik.getFieldProps('payment_delay_days')}
          invalid={!!formik.errors.payment_delay_days}
          type="number"
          min={0}
        />
      </ShortNumberWrapper>
    </FormGroup>
  )
}

const SoyRestriction: FC<Props> = ({ formik }) => {
  const { t } = useTranslation('company')
  return (
    <FormGroup label={t('soyRestriction')} error={formik.errors.soy_restriction}>
      <FormItemWrapper>
        <Radio
          value={formik.values.soy_restriction}
          onChange={(val) => {
            formik.setFieldValue('soy_restriction', val)
          }}
        >
          <RadioItem value={true} label={t('form.soyMoratorium.yes')} />
          <RadioItem value={false} label={t('form.soyMoratorium.no')} />
        </Radio>
      </FormItemWrapper>
    </FormGroup>
  )
}

const CommercialField: FC<Props> = ({ formik }) => {
  const { t } = useTranslation('company')
  return (
    <FormGroup label={t('commercial')} error={formik.errors.commercial}>
      <FormItemWrapper>
        <Radio
          value={formik.values.commercial}
          onChange={(val) => {
            formik.setFieldValue('commercial', val)
          }}
        >
          <RadioItem value={CompanyCommercial.yes} label={t('common:yes')} />
          <RadioItem value={CompanyCommercial.no} label={t('common:no')} />
          <RadioItem value={CompanyCommercial.unknown} label={t('common:notSpecified')} />
        </Radio>
      </FormItemWrapper>
    </FormGroup>
  )
}

export const CommonFields = {
  NameCompany,
  PersonalNumber,
  RegNumber,
  PrepaymentState,
  PaymentDelayDays,
  SoyRestriction,
  CommercialField,
}
