import { urlMaker } from 'modules/utils/urlMaker'

export const companiesEndpoints = {
  company: urlMaker('/companies/'),
  companyDadata: () => '/companies/suggest/',
  legalTypes: () => '/companies/legal-types/',
  companyBranches: (companyId: string) => `/companies/${companyId}/descendants/?include-self=true`,
  companyComments: (companyId: string) => `/companies/${companyId}/comments/`,
  employee: (companyId: string) => `/companies/${companyId}/employees/`,
  activeEmployees: (companyId: string) => `/companies/${companyId}/employees/?is_active=true`,
  companyExtraData: (companyId: string, id?: string) => `/companies/${companyId}/extra-data/${id ? `${id}/` : ''}`,
  companyContracts: (id: string) => `/companies/${id}/contracts/`,
  contractFiles: (companyId: string, contractId: string) => `/companies/${companyId}/contracts/${contractId}/files/`,
  deleteContractFile: (companyId: string, contractId: string, fileId: string) =>
    `/companies/${companyId}/contracts/${contractId}/files/${fileId}/`,
  companyAddress: (companyId: string, userId?: string) =>
    `/companies/${companyId}/addresses/${userId ? `?owner_id=${userId}` : ''}`,
  marketZones: urlMaker('companies/market-zones/'),
  companyActs: (companyId: string) => `companies/${companyId}/reconciliation-acts/`,
  companyAct1C: (companyId: string) => `companies/${companyId}/reconciliation-acts/request-upload/`,
  removeAct: (companyId: string, fileId: string) => `companies/${companyId}/reconciliation-acts/${fileId}/`,
  removeAllActs: (companyId: string) => `companies/${companyId}/reconciliation-acts/delete-all-acts/`,
  requestFactoring: (companyId: string) => `/companies/${companyId}/factoring/`,
  changeFactoringStatus: (companyId: string, factoringId?: string) =>
    `/companies/${companyId}/factoring/${factoringId}/change_status/`,
  keyCompany: (companyId: string) => `/companies/${companyId}/mark-significant/`,
}
