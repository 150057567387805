import { useTranslation } from 'react-i18next'
import { format } from 'date-fns'

import useDateFnsLocale from 'hooks/useDateFnsLocale'
import { getMonthList } from 'modules/utils/helpers'
import { MONTH_NAME_YEAR, YEAR_MONTH } from 'modules/constants'
import capitalizeFirstLetter from 'helpers/capitalizeFirstLetter'
import { ASelectFilter } from '../Analytics'
import { FC } from 'react'

type Props = {
  value: any
  handleChangeFilter?: (value: any, filterName: string) => void
  updateFilterState?: (filterObj: Record<string, any>) => void
  allowClear?: boolean
  placeholder?: string
}

export const MonthFilter: FC<Props> = ({
  value,
  handleChangeFilter,
  updateFilterState,
  allowClear = true,
  placeholder,
}) => {
  const { t } = useTranslation()
  const locale = useDateFnsLocale()
  const dateOptions = getMonthList().map((date) => ({
    value: format(date, YEAR_MONTH),
    label: capitalizeFirstLetter(format(date, MONTH_NAME_YEAR, { locale })),
  }))

  const handler = (month) => {
    if (handleChangeFilter) {
      handleChangeFilter(month, 'month')
      return
    }
    updateFilterState?.({ month })
  }

  const commonProps = {
    placeholder: placeholder || t('common:month'),
    onChange: handler,
    id: 'monthFilter',
    allowClear,
    options: dateOptions,
    showSearch: false,
    value,
  }
  return <ASelectFilter {...commonProps} />
}
