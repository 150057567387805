import { Icons } from '@agro-club/agroclub-shared'
import { useFilters } from 'hooks/useFilter'
import { ListRequestParams } from 'modules/domain/types'
import { endpoints } from 'modules/endpoints'
import { FC, useState } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { AButton } from 'views/components/Analytics'
import APageWrapper from 'views/components/PageWrapper/APageWrapper'
import { SearchFilter } from 'views/components/SearchFilter/SearchFilter'
import ATableFullPage from 'views/components/Table/ATableFullPage'
import { defaultListRequestParams } from 'views/components/TableFilters/data'
import * as Layout from 'views/layouts/NewLayout'
import * as Header from 'views/ui/Header/Header'
import { useColumns } from './Columns/useColumns'
import { AddSdizModal } from './Modals/AddSdizModal'

const SearchWrapper = styled.div`
  max-width: 600px;
`

export const SdizList: FC = () => {
  const { t } = useTranslation('sdiz')
  const [addSdizModal, setAddSdizModal] = useState(false)
  const [listRequestParams, setListRequestParams] = useState<ListRequestParams>(defaultListRequestParams)
  const { isFiltersLoaded, updateFilterState } = useFilters({
    listRequestParams,
    setListRequestParams,
  })

  const columns = useColumns(setListRequestParams)

  const sdizRefetch = () => setListRequestParams((prev) => ({ ...prev }))
  return (
    <APageWrapper page="Sdiz" place="SdizList">
      <Layout.WrapperContent>
        <Layout.Header>
          <Layout.TopHeader>
            <Header.Breadcrumbs routes={[{ breadcrumbName: t('title') }]} />
            <AButton
              id="addSdiz"
              color="approve"
              Icon={Icons.IconAdd}
              size="medium"
              onClick={() => setAddSdizModal(true)}
            >
              {t('common:add')}
            </AButton>
          </Layout.TopHeader>
          <Layout.PageName>{t('pageTitle')}</Layout.PageName>
          <SearchWrapper>
            <SearchFilter listRequestParams={listRequestParams} updateFilterState={updateFilterState} />
          </SearchWrapper>
        </Layout.Header>

        <ATableFullPage
          listParamsUpdated={setListRequestParams}
          listRequestParams={listRequestParams}
          dataEndpoint={endpoints.sdiz()}
          isFiltersLoaded={isFiltersLoaded}
          columns={columns}
          rowHoverGray
        />
      </Layout.WrapperContent>
      {addSdizModal && <AddSdizModal onSuccess={sdizRefetch} onClose={() => setAddSdizModal(false)} />}
    </APageWrapper>
  )
}
