import { format, isSameDay, parseISO } from 'date-fns'
import { FC, useState } from 'react'
import { AAntdTabs } from 'views/components/Analytics'
import { Bank, DispatchingItemFormik } from '../../types'
import { FormikProps } from 'formik'
import { PaymentData } from './PaymentData'
import styled from 'styled-components'

interface Props {
  formik: FormikProps<DispatchingItemFormik>
  banks: Bank[]
}

export const Tab = styled.div`
  padding-top: '12px';
`

const getActiveTab = (payments: DispatchingItemFormik['payments']) => {
  const today = new Date()
  const dates = Object.keys(payments)
  const found = dates.find((date) => isSameDay(today, parseISO(date)))
  if (found) {
    return found
  } else {
    return dates[0]
  }
}

export const Payments: FC<Props> = ({ formik, banks }) => {
  const [activeTab, setActiveTab] = useState<string>(getActiveTab(formik.values.payments))
  const items = Object.keys(formik.values.payments).map((date) => {
    return {
      label: <Tab>{format(parseISO(date), 'dd.MM')}</Tab>,
      key: date,
      children: <PaymentData key={date} formik={formik} dateString={date} banks={banks} />,
    }
  })
  return <AAntdTabs items={items} onChange={setActiveTab} activeKey={activeTab} id="paymentTabs" />
}
