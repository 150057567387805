import { AccountManagement } from 'modules/domain/accountManagement/types'
import { ListRequestParams } from 'modules/domain/types'
import { Dispatch, FC, SetStateAction } from 'react'
import styled from 'styled-components'
import { AAntDrawer } from 'views/components/Analytics'
import { CustomScroll } from 'views/styled/common'
import { CompanyInfoSection } from './CompanyInfoSection'
import { Tabs } from './Tabs/Tabs'

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 16px;

  height: 100%;
  overflow-y: auto;
  ${CustomScroll}
`

type Props = {
  rowData: AccountManagement
  listParamsUpdated: Dispatch<SetStateAction<ListRequestParams>>
  listRequestParams: ListRequestParams
  onClose: () => void
}

export const AccountManagementDrawer: FC<Props> = ({ rowData, listParamsUpdated, listRequestParams, onClose }) => {
  return (
    <AAntDrawer id="dasboard-calls-drawer-us" width={494} closable open placement="right" onClose={onClose}>
      <Wrapper>
        <CompanyInfoSection callsData={rowData} />

        <Tabs
          listRequestParams={listRequestParams}
          listParamsUpdated={listParamsUpdated}
          companyId={rowData.id}
          companyAddress={rowData.main_address}
          companyMemberTypes={rowData.company_member_types}
        />
      </Wrapper>
    </AAntDrawer>
  )
}
