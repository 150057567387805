import { FC } from 'react'
import { RequestType } from '../../../types'
import { useTranslation } from 'react-i18next'
import { ListRequestParams } from 'modules/domain/types'
import { ASelectFilter } from 'views/components/Analytics'

type Props = {
  listRequestParams: ListRequestParams
  updateFilterState: any
  disabled?: boolean
}

export const RequestsTypeFilter: FC<Props> = ({ updateFilterState, listRequestParams, disabled }) => {
  const { t } = useTranslation('logisticBids')
  const optionsStatus = [
    {
      value: RequestType.internal,
      label: t('filters.labels.internal'),
    },
    {
      value: RequestType.external,
      label: t('filters.labels.external'),
    },
  ]
  return (
    <ASelectFilter
      placeholder={t('filters.placeholders.bids_type')}
      options={optionsStatus}
      value={listRequestParams.filter.source}
      onChange={(source) => updateFilterState({ source })}
      disabled={disabled}
      showSearch={false}
      id="requestTypeFilter"
    />
  )
}
