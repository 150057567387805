import { refetchFunc } from 'modules/domain/common/hooks'
import { DealCondition, DealStatusTypes } from 'modules/domain/deal/types'
import { ListRequestParams } from 'modules/domain/types'
import { FC, Fragment, useEffect, useState } from 'react'
import { CardsBoard } from 'views/components/Board/CardsBoard'
import { BatchesBlock } from './Batches/BatchesBlock'
import { Column } from './Column'
import { useDealBoardColumns } from './hooks'
import { AnimatedBatchesBlock } from './styled'
import { useFetchMarginsAndRevenues } from './useFetchMarginsAndRevenues'

type Props = {
  setListRequestParams?: (params: ListRequestParams) => void
  listRequestParams: ListRequestParams
  typeDeals: DealCondition
  isFiltersLoaded: boolean
  activeTab: string
}

export const Board: FC<Props> = ({
  setListRequestParams,
  listRequestParams,
  isFiltersLoaded,
  typeDeals,
  activeTab,
}) => {
  const columns = useDealBoardColumns()
  const { progress, margins, revenues, refetch } = useFetchMarginsAndRevenues(
    listRequestParams,
    isFiltersLoaded,
    typeDeals,
  )
  const [isBatchBlockVisible, setIsBatchBlockVisible] = useState(false)
  const [isBatchBlockRendered, setIsBatchBlockRendered] = useState(false)

  useEffect(() => {
    if (isBatchBlockVisible) {
      setIsBatchBlockRendered(true)
    } else {
      const timer = setTimeout(() => setIsBatchBlockRendered(false), 150)
      return () => clearTimeout(timer)
    }
  }, [isBatchBlockVisible])

  const toggleBatchBlock = () => {
    setIsBatchBlockVisible((prev) => !prev)
  }

  const handleRefetch = () => {
    setListRequestParams?.({
      ...listRequestParams,
    })
    refetch()
  }
  return (
    <CardsBoard>
      {columns.map((column) => {
        return (
          <Fragment key={column.statusDeal}>
            <Column
              onChangeBatch={toggleBatchBlock}
              listRequestParams={listRequestParams}
              isFiltersLoaded={isFiltersLoaded}
              isOpenBatchBlock={isBatchBlockVisible}
              progressMargin={progress}
              key={column.statusDeal}
              typeDeals={typeDeals}
              activeTab={activeTab}
              revenues={revenues}
              margins={margins}
              column={column}
            />
            {column.statusDeal === DealStatusTypes.CONTRACT_SIGNED && isBatchBlockRendered && (
              <AnimatedBatchesBlock isVisible={isBatchBlockVisible}>
                <BatchesBlock
                  progressMargin={progress}
                  refetch={handleRefetch as refetchFunc}
                  listRequestParams={listRequestParams}
                  revenues={revenues}
                  margins={margins}
                />
              </AnimatedBatchesBlock>
            )}
          </Fragment>
        )
      })}
    </CardsBoard>
  )
}
