import { FC, SetStateAction } from 'react'
import { useTranslation } from 'react-i18next'
import { SelectFilter } from '@agro-club/agroclub-shared'
import { useAnalyticsChangeHandler } from 'analytics/hooks'
import { DashboardTab } from '../types'
import { ListRequestParams } from 'modules/domain/types'

type Props = {
  activeTab: DashboardTab
  listRequestParams: ListRequestParams
  tabSetter: React.Dispatch<SetStateAction<ListRequestParams>>
}

const sortFieldsByTab: Partial<Record<DashboardTab, string[]>> = {
  matches: ['deal_margin_percent'],
}

export const DashboardSortFilter: FC<Props> = ({ activeTab, listRequestParams, tabSetter }) => {
  const { t } = useTranslation('dashboard')
  const analyticsChangeHandler = useAnalyticsChangeHandler('dashboardSortFilter')

  const fields = sortFieldsByTab[activeTab]

  if (!fields || !fields.length) {
    return null
  }

  const options = fields
    .map((field) =>
      ['', '-'].map((direction) => {
        const label = t(`sortFilter.${field}`)
        return {
          value: `${direction}${field}`,
          label: `${label}, ${direction === '-' ? t('common:desc') : t('common:asc')}`,
        }
      }),
    )
    .flat()

  const value = listRequestParams.sort

  const onChange = analyticsChangeHandler(
    (sort) => {
      tabSetter({ ...listRequestParams, sort })
    },
    {
      value: { from: value },
    },
  )

  return (
    <SelectFilter
      placeholder={t('sortFilter.placeholder')}
      options={options}
      onChange={onChange}
      value={value}
      showSearch={false}
    />
  )
}
