import { defaultTheme, Typography } from '@agro-club/agroclub-shared'
import { FC, ReactNode } from 'react'
import styled from 'styled-components'

//TODO Копия ClickableTag с минимальными изменениями в стилях. Подумать над общим компонентом
const Container = styled.div<{ isActive?: boolean; activeBackground?: string }>`
  ${Typography.buttonLarge}
  width: max-content;
  cursor: pointer;
  border-radius: 8px;
  padding: 6px 8px;
  color: ${defaultTheme.color.primary600};
  border: 1px solid;
  border-color: ${({ isActive }) => (isActive ? 'transparent' : defaultTheme.color.primary16a)};
  background-color: ${({ isActive, activeBackground }) =>
    isActive ? activeBackground ?? defaultTheme.color.primary16a : defaultTheme.color.backgroundSecondary};
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  transition: 0.2s background-color;
  &:hover {
    background-color: ${({ activeBackground }) => activeBackground ?? defaultTheme.color.primary16a};
  }
`
type SingleValueFilterProps = {
  isActive?: boolean
  children: ReactNode
  onClick?: () => void
}

export const SingleValueFilter: FC<SingleValueFilterProps> = ({ children, ...otherProps }) => {
  return <Container {...otherProps}>{children}</Container>
}
