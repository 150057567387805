import { urlMaker } from 'modules/utils/urlMaker'

export const commonEndpoints = {
  //
  //Auth
  login: () => '/auth/login/',
  getCode: () => '/auth/get_code/',
  refreshToken: () => '/auth/token-refresh/',

  //Positions
  positions: () => `/positions/`,
  totalsPositions: () => `/positions/totals/`,

  //
  //Geo
  countries: () => `/geo/countries/`,
  provinces: () => '/geo/provinces/',
  districts: () => '/geo/districts/',

  //
  //Events
  eventsCount: urlMaker('/events/count/'),
  getEventChannel: urlMaker('/events/get-channel/'),
  pusherAuthEndpoint: urlMaker('/events/auth/'),
  markEventAsRead: urlMaker('/events/object-events-viewed/'),

  //
  //Samples
  samples: urlMaker('/samples/'),
  samplesStatus: () => `/samples/statuses/`,
  sampleComments: (sampleId: string) => `/samples/${sampleId}/comments/`,
  sampleFiles: (sampleId: string, fileId?: string) => `/samples/${sampleId}/files/${fileId ? `${fileId}/` : ''}`,

  //
  //Taxes

  priceCalculator: () => `/taxes/grain_price_calculator/`,
  dealTaxCalculation: (dealId: string) => `/taxes/calculation/${dealId}`,

  //
  //Catalog

  seasonsList: (productId = '') => `/catalog/seasons/?product=${productId}`,

  //
  //Files
  files: urlMaker('/files/'),

  //
  //Logs

  logs: () => '/logs/contacts/',
}
