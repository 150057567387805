import { FC } from 'react'
import { useFormik } from 'formik'
import { endpoints } from 'modules/endpoints'
import { apiClient } from 'modules/utils/httpClient'
import { LogisticsKRUnloadingWarehouse } from 'views/pages/LogisticsKR/types'
import { BuyerWarehouseForm } from 'views/components/WarehouseModal/BuyerWarehouseForm'
import { useAFormHandler } from 'analytics/hooks'
import { refetchFunc } from 'modules/domain/common/hooks'

interface Props {
  onClose?: () => void
  setIsOpen: (val: boolean) => void
  unloading_warehouse: LogisticsKRUnloadingWarehouse
  refetch: refetchFunc
}

export const UnloadingAddressModal: FC<Props> = ({ onClose, setIsOpen, unloading_warehouse, refetch }) => {
  const { formProgress, formHandler } = useAFormHandler('editUnloadingWarehouse', {
    id: String(unloading_warehouse.id),
  })

  const handleClose = () => {
    onClose?.()
    setIsOpen(false)
  }

  const formik = useFormik({
    initialValues: {
      gates_height: unloading_warehouse.gates_height,
      unloading_type: unloading_warehouse.unloading_type,
      volume_per_day: unloading_warehouse.volume_per_day,
      vehicle_type: unloading_warehouse.vehicle_type,
      is_dump_truck: unloading_warehouse.is_dump_truck,
      overload: unloading_warehouse.overload,
      schedule_from: unloading_warehouse.schedule_from,
      schedule_to: unloading_warehouse.schedule_to,
      works_on_weekend: unloading_warehouse.works_on_weekend,
      car_delivery: unloading_warehouse.car_delivery,
      railway_delivery: unloading_warehouse.railway_delivery,
      weather_conditions: unloading_warehouse.weather_conditions,
    },
    onSubmit: formHandler(
      async () => await apiClient.put(endpoints.LogisticsKR.unloadingWarehouse(unloading_warehouse.id), formik.values),
      {
        onSuccess: async () => {
          await refetch()
          setIsOpen(false)
        },
        onError: (error) => {
          const { errors } = error
          formik.setErrors(errors)
        },
      },
    ),
  })

  return <BuyerWarehouseForm formik={formik} onClose={handleClose} progress={formProgress} boolDeliveryRadio />
}
