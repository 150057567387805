import { useProgress } from 'hooks/useProgress'
import getUsTopOffers, { UserOffer } from 'modules/domain/bid/getUsTopOffers'
import { Address } from 'modules/domain/types'
import { useEffect, useRef, useState } from 'react'

export const useGetOfferPrices = (
  currAddress: Address | undefined,
  rangeDate: { start_date: string; end_date: string },
  companyId?: string,
) => {
  const [offers, setOffers] = useState<UserOffer>({ best: [], closest: [] })

  const abortControllerRef = useRef<AbortController | null>(null)
  const [offersProgress, fetch] = useProgress(async () => {
    if (!currAddress) return

    /* it is necessary to cancel the previous request because of race condition */
    if (abortControllerRef.current) {
      abortControllerRef.current.abort()
    }
    abortControllerRef.current = new AbortController()

    const response = await getUsTopOffers(currAddress, rangeDate, abortControllerRef.current)
    abortControllerRef.current = null
    setOffers(response)
  })

  useEffect(() => {
    fetch()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currAddress, rangeDate, companyId])

  return { offers, offersProgress }
}
