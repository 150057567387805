import { MobileFilterContainer } from 'views/components/MobileComponents/Filters/MobileFilterWrapper'
import { FC } from 'react'
import { ListRequestParams } from 'modules/domain/types'
import { AAntDatePicker } from 'views/components/Analytics'
import { useMediaQuery } from 'react-responsive'
import { defaultTheme } from '@agro-club/agroclub-shared'
import { useTranslation } from 'react-i18next'

interface Props {
  listRequestParams: ListRequestParams
  updateFilterState: (filterObj: Record<string, any>, commaShieldingParam?: string) => void
}

export const DateFilter: FC<Props> = ({ listRequestParams, updateFilterState }) => {
  const { t } = useTranslation()
  const isMobile = useMediaQuery({
    query: `(max-width: ${defaultTheme.queries.mobile})`,
  })
  const FilterContainer = isMobile ? MobileFilterContainer : 'div'

  return (
    <FilterContainer label={t('common:day')}>
      <AAntDatePicker
        onChange={(date) => updateFilterState({ ...date })}
        value={listRequestParams.filter.date}
        id="dateFilter"
        fullWidth={isMobile}
        allowClear={false}
        size={isMobile ? 'large' : 'small'}
      />
    </FilterContainer>
  )
}
