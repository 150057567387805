import { FC } from 'react'

import { CompanyRuAdd } from './CompanyDetail/CompanyGeneral/CompanyRuAdd'
import { AddWorldCompany } from './WorldModal/AddWorldCompany'
import { isRussia } from 'env'

type Props = {
  setAddCompanyModalOpen: (payload: boolean) => void
  refreshCompanies: () => void
  parentCompanyId?: string
}

export const AddCompanyControl: FC<Props> = ({ setAddCompanyModalOpen, refreshCompanies, parentCompanyId }) => {
  if (isRussia) {
    return <CompanyRuAdd onClose={() => setAddCompanyModalOpen(false)} refreshCompanies={refreshCompanies} />
  }

  return (
    <AddWorldCompany
      onClose={() => setAddCompanyModalOpen(false)}
      refreshCompanies={refreshCompanies}
      parentCompanyId={parentCompanyId}
    />
  )
}
