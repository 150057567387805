import { urlMaker } from 'modules/utils/urlMaker'

export const dealsEndpoints = {
  deal: urlMaker('/deals/'),
  dealDocumentRequest: urlMaker('/deals/documents-requests/'),
  dealActual: urlMaker('/deals/actual/'),
  dealClosed: urlMaker('/deals/closed/'),
  dealStatuses: () => '/deals/statuses/',
  dealsCloseReasons: () => '/deals/closed_reasons/',
  dealTypes: () => '/deals/types/',
  calcLogisticParams: () => '/deals/logistics-parameters/',
  dispatchingStatuses: () => '/deals/dispatching/statuses/',
  dispatchingBanks: () => '/deals/dispatching-banks/',
  dispatchingLoans: () => '/deals/dispatching-loan-agreements/',
  dealWithoutDocumentRequest: (id: string) => `/deals/${id}/no-documents-request/`,
  dealDocumentRequestComments: (id: string) => `/deals/documents-requests/${id}/comments/`,
  deleteWithoutDocumentsRequest: (id: string) => `/deals/${id}/cancel-no-documents-request/`,
  documentsRequest: (id: string) => `/deals/documents-requests/${id}/`,
  dealDocumentRequestStatuses: () => `/deals/documents-requests/statuses/`,
  dealDocumentChangeStatuses: (id: string) => `/deals/documents-requests/${id}/change_status/`,
  bidCloning: (dealId: string) => `/deals/${dealId}/clone-deal/`,
  dealActualWithStatus: (status: string) => `/deals/actual/?status=${status}`,
  dealClosedWithStatus: (status: string) => `/deals/closed/?status=${status}`,
  dealShipment: (dealId: string, shipmentId?: string) =>
    `/deals/${dealId}/shipments/${shipmentId ? `${shipmentId}/` : ''}`,
  dealComment: (dealId: string) => `/deals/${dealId}/comments/`,
  dealStatus: (dealId: string) => `/deals/${dealId}/change_status/`,
  dealClose: (dealId: string) => `/deals/${dealId}/close/`,
  dealUpdatePurchaseBid: (dealId: string) => `/deals/${dealId}/update_purchase_bid/`,
  dealUpdateSaleBid: (dealId: string) => `/deals/${dealId}/update_sale_bid/`,
  dealFiles: (dealId: string, fileId?: string) => `/deals/${dealId}/files/${fileId ? `${fileId}/` : ''}`,
  dealFileList: (dealId: string) => `/deals/${dealId}/files/`,
  sellerSpecification: (dealId: string, specId?: string) =>
    `/deals/${dealId}/sale-specification/${specId ? `${specId}/` : ''}`,
  sellerSpecificationFiles: (dealId: string, specId: string, fileId?: string) =>
    `/deals/${dealId}/sale-specification/${specId}/files/${fileId ? `${fileId}/` : ''}`,
  specificationDealBinding: (dealId: string) => `/deals/${dealId}/attach-purchase-specification/`,
  dealChangeApprovalStatus: (dealId: string) => `/deals/${dealId}/change_approval_status/`,
  dealChangeApprovalFinStatus: (dealId: string) => `/deals/${dealId}/approve-by-financial-director/`,
  dealDeclaration: (dealId: string) => `/deals/${dealId}/declaration/`,
  dealDeclarationFiles: (dealId: string) => `/deals/${dealId}/declaration/files/`,
  dealRating: (dealId: string) => `/deals/${dealId}/ratings/`,
  dealChangeWithoutSpec: (dealId: string) => `/deals/${dealId}/no-purchase-specification/`,
  dealsContracts: urlMaker('deals/sale-contract-requests'),
  dealsContractStatuses: urlMaker('deals/sale-contract-requests/statuses'),
  dealContractChangeStatus: (contractId: string) => `/deals/sale-contract-requests/${contractId}/change_status/`,
  dealContractComments: (contractId: string) => `/deals/sale-contract-requests/${contractId}/comments/`,
  dealShipLoading: (dealId: string) => `/deals/${dealId}/ship-loads/`,
  dealFarmerLoading: (dealId: string) => `/deals/${dealId}/farmer-loads/`,
  dealShipLoadWorkTypes: (dealId: string) => `deals/${dealId}/ship-loads/work-types`,
  dealFarmerLoadWorkTypes: (dealId: string) => `deals/${dealId}/farmer-loads/work-types`,
  dealSingleShipLoading: (dealId: string, shipLoadId: string) => `/deals/${dealId}/ship-loads/${shipLoadId}/`,
  dealSingleFarmerLoading: (dealId: string, farmerLoadId: string) => `/deals/${dealId}/farmer-loads/${farmerLoadId}/`,
  dealHistory: (dealId: string) => `/deals/${dealId}/history/`,
  dealCreatePositions: (dealId: string) => `/deals/${dealId}/create-position/`,
  dealChangeQuantityLoadPerDay: (dealId: string, tripNormId: string) => `/deals/${dealId}/trip-count/${tripNormId}/`,
  dealConfirmBatch: (dealId: string) => `/deals/${dealId}/confirm-batch/`,
  dealCalendar: (dealId: string, normId?: string) =>
    `/deals/${dealId}/trip-count-calendar/${normId ? `${normId}/` : ''}`,
  dealsManual: () => `/deals/manual/`,
  dealsManualPreview: ({ sale_bid_id = '', purchase_bid_id = '' }) =>
    `/deals/manual/preview/?sale_bid_id=${sale_bid_id}&purchase_bid_id=${purchase_bid_id}`,
  dealsPartyChange: (dealId: string) => `/deals/${dealId}/party-change/`,
  executorsEdit: (id: string) => `/deals/${id}/executors/`,
  dispatching: (page_size?: number) => `/deals/dispatching/?page_size=${page_size}`,
  dispatchingClosed: () => `/deals/dispatching/closed/`,
  dispatchingItem: (id: string) => `/deals/dispatching/${id}/`,
  dispatchingTotals: () => `/deals/dispatching/totals/`,
  dispatchingDates: (currentDate: string) => `/deals/dispatching/dates/?requested-date=${currentDate}`,
  dealMargins: () => 'deals/margins/',
  dealGmvs: () => 'deals/gmvs/',
  attachContract: (id: string) => `deals/${id}/attach-export-contract/`,
}
