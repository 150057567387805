import { FC } from 'react'

import { t } from 'i18next'
import { TableFilterProps } from 'views/filters/types'
import { ASelectFilter } from '../Analytics'

interface options {
  value: string
  label: string
}

type Props = TableFilterProps & {
  isLoading?: boolean
  options: options[]
  disabled?: boolean
}

export const StatusFilter: FC<Props> = ({ listRequestParams, updateFilterState, options, disabled, isLoading }) => {
  return (
    <ASelectFilter
      onChange={(status) => updateFilterState({ status })}
      value={listRequestParams.filter.status}
      placeholder={t('bid:filterByStatuses')}
      options={options}
      showSearch={false}
      disabled={disabled ?? false}
      id="userStatusFilter"
      loading={isLoading}
    />
  )
}
