import { Icons, Text, Typography } from '@agro-club/agroclub-shared'
import useFormatDate from 'hooks/useFormatDate'
import { Call } from 'modules/domain/userCalls/types'
import { FC, useState } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { AButton } from 'views/components/Analytics'
import { ASharedModal } from 'views/components/SharedModal'

const HeaderWrapper = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 8px;
  margin-bottom: 24px;
`

const ContentWrapper = styled.div`
  ${Typography.bodyMedium}
  max-height: 600px;
  overflow-y: auto;
  white-space: pre-line;
`

type Props = {
  call: Call
}

export const CallTranscriptButton: FC<Props> = ({ call }) => {
  const { t } = useTranslation()
  const formatDate = useFormatDate()
  const [isOpen, setIsOpen] = useState(false)

  if (!call.record_transcription) return null

  return (
    <>
      <AButton
        id="parsedCall"
        color="secondary"
        size="small"
        Icon={Icons.IconSortDescending}
        onClick={() => setIsOpen(true)}
      />
      {isOpen && (
        <ASharedModal id="callTranscript" onClose={() => setIsOpen(false)}>
          <HeaderWrapper>
            <Text typography="titleH3" color="primary">
              {t('calls:callTranscript')}
            </Text>
            <Text color="secondary">
              {formatDate(call.call_start, true)}, {t('common:from')} {call.coordinator_name} {t('common:to')}{' '}
              {call.client_name}
            </Text>
          </HeaderWrapper>
          <ContentWrapper>{call.record_transcription}</ContentWrapper>
        </ASharedModal>
      )}
    </>
  )
}
