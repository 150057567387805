import { createContext, Dispatch, SetStateAction, useContext, useState } from 'react'
import { useParams } from 'react-router'
import { ListRequestParams } from 'modules/domain/types'
import { defaultListRequestParams } from 'views/components/TableFilters/data'
import { ACCORDION_ITEM_PAGE_SIZE } from 'modules/constants'
import { refetchFunc, useTableData } from 'modules/domain/common/hooks'
import { endpoints } from 'modules/endpoints'
import { TtnDocument } from 'views/pages/LogisticsKR/types'
import { Progress } from '@agro-club/agroclub-shared'

type TtnFilesContextType = {
  ttnList: TtnDocument[]
  ttnListParams: ListRequestParams
  setTtnListParams: Dispatch<SetStateAction<ListRequestParams>>
  ttnListProgress: Progress
  ttnListTotal: number
  ttnListRefetch: refetchFunc
  setDeletedFileId: Dispatch<SetStateAction<string>>
  deletedFileId: string
}

export const TtnFilesContext = createContext<TtnFilesContextType>({
  ttnList: [],
  ttnListParams: { ...defaultListRequestParams },
  setTtnListParams: function (): void {
    throw new Error('Function not implemented.')
  },
  ttnListProgress: Progress.IDLE,
  ttnListTotal: 0,
  ttnListRefetch: function (): Promise<void> {
    throw new Error('Function not implemented.')
  },
  setDeletedFileId: function (): void {
    throw new Error('Function not implemented.')
  },
  deletedFileId: '',
})

export const useTtnFilesContext = () => {
  const context = useContext(TtnFilesContext)
  return context
}

export const TtnFilesProvider = ({ children }: any) => {
  const { id } = useParams()
  const [deletedFileId, setDeletedFileId] = useState<string>('')
  const [ttnListParams, setTtnListParams] = useState<ListRequestParams>({
    ...defaultListRequestParams,
    pageSize: ACCORDION_ITEM_PAGE_SIZE,
  })
  const {
    progress,
    data = [],
    total,
    refetch,
  } = useTableData<TtnDocument>(endpoints.LogisticsKR.getRequestTtnList(id), ttnListParams, true)

  return (
    <TtnFilesContext.Provider
      value={{
        ttnList: data,
        ttnListParams,
        setTtnListParams,
        ttnListProgress: progress,
        ttnListTotal: total,
        ttnListRefetch: refetch,
        setDeletedFileId,
        deletedFileId,
      }}
    >
      {children}
    </TtnFilesContext.Provider>
  )
}
