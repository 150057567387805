import { MatchesPanel } from '../../Board/MatchesColumn/MatchesPanel'
import { AntCollapse, Progress } from '@agro-club/agroclub-shared'
import { WrapperCollapse } from 'views/components/Board/styled'
import { SlugLabelPair } from 'modules/domain/common/types'
import { UserDeal } from 'modules/domain/userBid/types'
import { Bid } from 'modules/domain/bid/types'
import { useTranslation } from 'react-i18next'
import { FC } from 'react'

type Props = {
  activeBid: Bid | undefined
  reasons: SlugLabelPair[]
  refetchMatches: () => void
  matches: UserDeal[]
  progress: Progress
}

export const MatchesColumn: FC<Props> = ({ activeBid, reasons, refetchMatches, matches, progress }) => {
  const { t } = useTranslation()

  return (
    <WrapperCollapse>
      <AntCollapse noMarginTop accordion defaultActiveKey={['matches']}>
        <MatchesPanel
          namePanel={t('user:list.matches')}
          onStatusChange={refetchMatches}
          activeBid={activeBid}
          colorCount="orange"
          progress={progress}
          matches={matches}
          reasons={reasons}
          key="matches"
        />
      </AntCollapse>
    </WrapperCollapse>
  )
}
