import { defaultTheme, Typography } from '@agro-club/agroclub-shared'
import styled from 'styled-components'

export const Topic = styled.div`
  ${Typography.titleH3}
  padding: 12px 0 16px 0;
`

export const InfoBlock = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 4px;
`

export const RelatedItem = styled.div`
  display: flex;
  align-items: center;
`
export const RelatedContent = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 8px;
`

export const RestrictionContainer = styled.div`
  max-width: 768px;
`

export const Priority = styled.div`
  display: flex;
  align-items: center;
  height: 22px;
`
export const ExecuteBlock = styled.div`
  width: 211px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: ${defaultTheme.color.secondaryPlaceholder};
  ${Typography.bodyLarge}
`
export const Completed = styled.div`
  color: ${defaultTheme.color.accentApprove600};
  ${Typography.bodyLarge}
  display: flex;
  align-items: center;

  svg > path {
    fill: ${defaultTheme.color.accentApprove600};
  }
`
