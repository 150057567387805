import { FC, useEffect, useMemo, useState } from 'react'
import { DealStatusModal } from 'views/components/ChangeDealStatus/DealStatusModal/DealStatusModal'
import { DealStatusTypes } from 'modules/domain/deal/types'
import { formatNumber, getMainParameter } from 'modules/utils/helpers'
import { formatQuantity } from 'modules/utils/numbers/formatQuantity'
import { AntTag, Icons } from '@agro-club/agroclub-shared'
import { Bid, BidStatus } from 'modules/domain/bid/types'
import formatPrice from 'modules/utils/numbers/formatPrice'
import { UserDeal } from 'modules/domain/userBid/types'
import { RemoveMatchModal } from './RemoveMatchModal'
import { apiClient } from 'modules/utils/httpClient'
import DealRoutes from 'views/pages/Deal/routes'
import { endpoints } from 'modules/endpoints'
import { generatePath } from 'react-router'
import {
  ItemCard as ItemCard,
  CardButtonWrapper,
  CardInfo,
  CompanyName,
  FooterCard,
  IconCheck,
  Location,
  MatchCardTags,
  TextCard,
} from 'views/styled/ItemCardStyles'
import { AButton, ASimpleLink } from 'views/components/Analytics'
import { useAProgress } from 'hooks/useAProgress'
import { DealCardPrice } from 'views/components/DealCard/DealCardPrice'
import { useTranslation } from 'react-i18next'
import { containsFilledDatePeriod, DatePeriodFormatted } from 'views/components/DatePeriodFormatted'
import { isPurchaseBid } from 'modules/domain/bid/utils'
import { isBrazil } from 'env'
import { DeliveryPeriodData } from 'views/components/DealCard/DeliveryPeriod'
import { CompanySizeTag } from '../../CompanySizeTag'
import { SlugLabelPair } from 'modules/domain/common/types'

type Props = {
  onStatusChange: () => void
  reasons: SlugLabelPair[]
  match: UserDeal
  activeBid: Bid
}

export const MatchCard: FC<Props> = ({ match, activeBid, reasons, onStatusChange }) => {
  const { t } = useTranslation(['user', 'deal'])
  const [visibilityChangeModal, setVisibilityChangeModal] = useState<boolean>(false)
  const [visibilityRemoveModal, setVisibilityRemoveModal] = useState<boolean>(false)
  const [currentReason, setCurrentReason] = useState<string>()
  const bidForMatch = useMemo(() => {
    if (isPurchaseBid(activeBid)) {
      return match.sale_bid
    }
    return match.purchase_bid
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeBid])

  useEffect(() => {
    if (reasons && match.closed_reason) {
      const rightReason = reasons.find((reason) => reason.slug === match.closed_reason)
      setCurrentReason(rightReason?.label)
    }
  }, [reasons, match, activeBid])

  const tagsColor = match.closed_at ? 'grey' : 'white'

  const tags = (
    <>
      <CompanySizeTag companySize={match.sale_bid.company.company_size} />
      {bidForMatch.owner_team && <AntTag color={tagsColor}>{bidForMatch.owner_team.name}</AntTag>}
      <AntTag color={tagsColor}>{t(`deal:statuses.${match.status}`)}</AntTag>
      {currentReason && <AntTag color="grey">{t('closedMatch', { currentReason })}</AntTag>}
    </>
  )

  const handlerChangeModal = (e) => {
    e.preventDefault()
    setVisibilityChangeModal(true)
  }

  const [progress, changeStatusDeal] = useAProgress(
    (id, status) => apiClient.post(endpoints.dealStatus(id), { new_status: status }),
    {
      eventName: 'changeDealStatus',
      onSuccess: onStatusChange,
    },
  )

  const handlerRemoveModal = (e) => {
    e.preventDefault()
    setVisibilityRemoveModal(true)
  }

  const hasDates = containsFilledDatePeriod(match)

  return (
    <div>
      {isBrazil && hasDates && <DatePeriodFormatted period={match} />}
      <ASimpleLink
        eventName="match"
        itemId={match.id}
        to={generatePath(DealRoutes.Details, {
          id: match.id,
        })}
      >
        <ItemCard status={match.closed_at && BidStatus.archived}>
          <MatchCardTags>{tags}</MatchCardTags>
          {match.status === DealStatusTypes.DRAFT && !match.closed_at && (
            <CardButtonWrapper>
              <AButton
                id="changeDealStatus"
                intent="white"
                onClick={(e) => handlerChangeModal(e)}
                Icon={Icons.IconArrowRightM}
              />
            </CardButtonWrapper>
          )}

          <DealCardPrice deal={match} isClosed={!!match.closed_at} />
          <CardInfo>
            {!isBrazil && <DeliveryPeriodData data={match} />}
            <TextCard>{formatQuantity(match.quantity)}, </TextCard>
            {getMainParameter(bidForMatch.parameters)}
          </CardInfo>
          <CardInfo>{`${formatPrice(match.margin, true)} (${formatNumber(match.margin_percent)}%)`}</CardInfo>
          <CompanyName>
            {`${bidForMatch.company.name}`}
            {bidForMatch.has_work_deals && <IconCheck />}
          </CompanyName>

          <FooterCard>
            <Location color="secondary">{bidForMatch.address.address}</Location>

            {match.status !== DealStatusTypes.DRAFT && !match.closed_at ? (
              <AButton id="removeMatch" intent="white" Icon={Icons.IconDelete} onClick={handlerRemoveModal} />
            ) : null}
          </FooterCard>
        </ItemCard>
      </ASimpleLink>
      {visibilityChangeModal && (
        <DealStatusModal
          onClose={() => setVisibilityChangeModal(false)}
          changeStatusAction={changeStatusDeal}
          changeStatusProgress={progress}
          dealId={match.id}
          nextStatus={{
            slug: DealStatusTypes.NEW,
            label: t('deal:statuses.new'),
          }}
          currentStatus={{
            slug: DealStatusTypes.DRAFT,
            label: t('deal:statuses.draft'),
          }}
        />
      )}

      {visibilityRemoveModal && (
        <RemoveMatchModal
          onStatusChange={onStatusChange}
          onClose={() => setVisibilityRemoveModal(false)}
          matchId={match?.id}
        />
      )}
    </div>
  )
}
