import { urlMaker } from 'modules/utils/urlMaker'

export const tasksEndpoints = {
  task: urlMaker('/tasks/'),
  taskDeals: urlMaker('/tasks/deals/'),
  taskAssignees: () => '/tasks/assignees/',
  taskDocsTeamAssignees: () => '/tasks/assignees/?team=9',
  completeTask: (taskId: string) => `/tasks/${taskId}/complete/`,
  removeTask: (taskId: string) => `/tasks/${taskId}/`,
  setTaskToWork: (taskId: string) => `/tasks/${taskId}/to-work/`,
  priorityTask: () => '/tasks/priorities/',
  typesTask: (type) => `/tasks/task-types/?set=${type}`,
  createTask: () => '/tasks/',
  createMyTask: () => '/tasks/my-tasks/',
  schemeTasksTable: () => '/tasks/my-tasks/?schema',
  tasksTableData: () => '/tasks/my-tasks/',
  getTask: (taskId) => `/tasks/${taskId}/`,
  myTasksFiles: (taskId) => `/tasks/my-tasks/${taskId}/files/`,
  removeTasksFile: (taskId, fileId) => `/tasks/my-tasks/${taskId}/files/${fileId}/`,
  tasksStatuses: urlMaker('/tasks/statuses/'),
  getUsersTaskStats: () => `/tasks/user-task-stats/`,
  getCoordinatorTaskStats: (assignee?: string) =>
    `/tasks/coordinator-task-stats/${assignee ? `?assignee=${assignee}` : ''}`,
  distributeTasks: () => `/tasks/distribute/`,
  reassignTasks: () => `/tasks/reassign/`,
  balanceTasks: () => `/tasks/balance/`,
  deassignTask: () => `/tasks/deassign/`,
  taskCallState: (taskId: string) => `/tasks/call-state/${taskId}/`,
}
