import { Team } from 'modules/domain/collection/types'
import { Company } from 'modules/domain/company/types'
import { ROLES } from 'types/entities'
import { Comment } from 'views/components/Comments/hooks'

export type UserListRequestFilter = {
  search?: string
  date_joined_after?: string
  date_joined_before?: string
  team?: string[]
  profile_role?: string
  profile_type?: string
  is_active?: boolean
  is_linked_to_active_task?: boolean
  region?: string[]
  district?: string[]
  is_verified_by_coordinator?: string
  is_verified_by_security?: string
  is_verified?: string
  coordinator_type?: string
}

export enum ProfileType {
  seller = 'seller',
  customer = 'customer',
  coordinator = 'coordinator',
  carrier = 'carrier',
  broker = 'broker',
  manager = 'manager',
}

// Technically it is the same field as ProfileType,
// but it is saparate enum for better track in company/user usage
// Profile.profile_type should not used in US in bids logic due to
// profile_type = Manager
export enum CompanyMembersTypes {
  seller = 'seller',
  customer = 'customer',
}

export enum ProfileTypeForAddUser {
  seller = 'seller',
  customer = 'customer',
  broker = 'broker',
  carrier = 'carrier',
  manager = 'manager',
}

export enum UserFeatureOptions {
  is_verified = 'is_verified',
  is_significant = 'is_significant',
  is_verified_by_security = 'is_verified_by_security',
  is_verified_by_coordinator = 'is_verified_by_coordinator',
  is_verified_by_kyc = 'is_verified_by_kyc',
  has_shipped_deals = 'has_shipped_deals',
  soy_restriction = 'soy_restriction',
}

export type Coordinator = {
  id: string
  full_name: string
  is_active: boolean
  phone?: string
}

export enum CompanySize {
  small_company = 'small',
  big_company = 'large',
}
export type Profile = {
  first_name: string
  last_name: string
  patronymic_name: string
  full_name: string
  profile_type: ProfileType
  inn?: string
  company?: Company
  role: ROLES
  team: null | Team
  recommended_margin_percent?: number
  credit_limit?: number
  forecast_credit_limit?: number
  is_significant?: boolean
  is_verified_by_coordinator: boolean
  is_verified_by_security: boolean
  is_verified?: boolean
  is_verified_by_kyc?: boolean
  has_shipped_deals?: boolean
  coordinator_type?: string
  product_tags?: ProductTag[]
  responsible_coordinator?: Coordinator
  do_not_call_reason: string
  company_size: CompanySize | null
  verified_by_coordinator_at: string | null
  verified_by_security_at: string | null
}

export type ProductTag = {
  id: string
  slug: string
  title: string
}
export type SmsNotificationSettingsType = {
  price_sms_enabled: boolean
  price_sms_phone: string
  price_sms_disabled_by_user: boolean
  price_sms_has_mobile_phone: boolean
  price_sms_products: {
    id: string
    slug: string
    title: string
  }[]
}

export type User = {
  id: string
  date_joined: string
  phone: string
  is_mobile_phone: boolean
  email: string
  is_active: boolean
  role: string
  signup_step: string
  permissions?: string[]
  profile: Profile | null
  rating: number
  comments: Comment[]
  days_since_last_comment: string
  sms_notification_settings: SmsNotificationSettingsType
}

export type UserListRequestSorting = {
  sort_field?: keyof User
  sort_reversed?: boolean
}

export type UsersListRequestParams = {
  filter: UserListRequestFilter
  sorting?: UserListRequestSorting
  page: number
}

export interface RatingByType {
  label: string
  rating_value: number
}

export enum BrokerType {
  seller = 'seller',
  customer = 'customer',
}

export enum SmsKeys {
  month_1_name = 'month_1_name',
  local_1_fob_cash = 'local_1_fob_cash',
  local_1_delivered_cash = 'local_1_delivered_cash',
  local_1_distance = 'local_1_distance',
  month_2_name = 'month_2_name',
  local_2_fob_cash = 'local_2_fob_cash',
  local_2_delivered_cash = 'local_2_delivered_cash',
  local_2_distance = 'local_2_distance',
  month_3_name = 'month_3_name',
  local_3_fob_cash = 'local_3_fob_cash',
  local_3_delivered_cash = 'local_3_delivered_cash',
  local_3_distance = 'local_3_distance',
  best_1_fob_cash = 'best_1_fob_cash',
  best_1_premium = 'best_1_premium',
  best_2_fob_cash = 'best_2_fob_cash',
  best_2_premium = 'best_2_premium',
  best_3_fob_cash = 'best_3_fob_cash',
  best_3_premium = 'best_3_premium',
}
export enum TemplateType {
  basis = 'basis',
  cash = 'cash',
  cash_best_bids_only = 'cash_best_bids_only',
  static = 'static',
}

export type ProductSms = {
  id: string
  created: string
  template_type: TemplateType
  product_title: string
  static_template_text: string
  statuses: {
    id: string
    created: string
    status_label: string
    failure_reason: string
  }[]
  message_parameters: {
    key: SmsKeys
    type: string
    value: string
  }[]
}
