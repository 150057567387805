import { FC } from 'react'
import { FiltersAndSearchWrapper, FiltersWrapper } from 'views/components/CommonTableComponents/CommonTableComponents'
import { useDisplayClearBtn } from 'hooks/useDisplayClearBtn'
import { TableFiltersProps } from 'modules/types'
import { MonthFilter } from 'views/components/MonthFilter/MonthFilter'
import { SearchFilter } from 'views/components/SearchFilter/SearchFilter'
import { RequestsTypeFilter } from './RequestsTypeFilter'
import { AClearFiltersButton, ASelectFilter } from 'views/components/Analytics'
import { RequestWithTripOfferFilter } from './RequestWithTripOfferFilter'
import { RequestCondition } from 'views/pages/LogisticsKR/types'
import { ADateRangePicker } from 'views/components/TableFilters/DateRangePicker/ADateRangePicker'
import { useTranslation } from 'react-i18next'
import { ABaseTableFilter } from 'views/components/TableFilters/ABaseTableFilter'
import { endpoints } from 'modules/endpoints'
import { ListRequestParams } from 'modules/domain/types'

type BoardFiltersProps = Omit<TableFiltersProps, 'setListRequestParams'> & {
  activeTab: string
  clearTabState: (params: Partial<ListRequestParams>) => void
}

export const RequestsBoardFilters: FC<BoardFiltersProps> = ({
  clearTabState,
  listRequestParams,
  updateFilterState,
  activeTab,
  defaultFilterValues,
}) => {
  const { t } = useTranslation('logisticsKR')
  const allowClear = activeTab !== RequestCondition.closed
  const displayClearBtn = useDisplayClearBtn({
    listRequestParams,
    defaultFilters: defaultFilterValues,
  })

  const ttnFilterOptions = [
    {
      value: 'true',
      label: t('common:thereIs'),
    },
    {
      value: 'false',
      label: t('common:no'),
    },
  ]

  return (
    <FiltersAndSearchWrapper>
      <FiltersWrapper>
        {activeTab === RequestCondition.without_ttn ? (
          <>
            <ADateRangePicker
              id="dateFilter"
              value={[
                listRequestParams.filter.load_unload_date_after,
                listRequestParams.filter.load_unload_date_before,
              ]}
              onChange={(value) =>
                updateFilterState({ load_unload_date_after: value.startDate, load_unload_date_before: value.endDate })
              }
            />
            <ASelectFilter
              onChange={(has_ttn1_files) => updateFilterState({ has_ttn1_files })}
              value={listRequestParams.filter.has_ttn1_files}
              placeholder={t('list.filters.has_ttn1_files')}
              options={ttnFilterOptions}
              showSearch={false}
              id="has_load_ttn"
            />
          </>
        ) : (
          <>
            <MonthFilter
              value={listRequestParams.filter.month}
              updateFilterState={updateFilterState}
              allowClear={allowClear}
            />
            <RequestsTypeFilter listRequestParams={listRequestParams} updateFilterState={updateFilterState} />
            <RequestWithTripOfferFilter listRequestParams={listRequestParams} updateFilterState={updateFilterState} />
          </>
        )}

        <ABaseTableFilter
          id="logisticianFilter"
          placeholder={t('list.filters.logisticians')}
          value={listRequestParams.filter.assignee}
          endpoint={endpoints.dispatchingLogisticians()}
          onChange={(assignee) => updateFilterState({ assignee })}
          getLabel={(dto) => dto.full_name}
          searchField="search"
          showSearch={true}
          multiple
        />

        {displayClearBtn && <AClearFiltersButton onClick={() => clearTabState({ ...defaultFilterValues })} />}
      </FiltersWrapper>
      <SearchFilter listRequestParams={listRequestParams} updateFilterState={updateFilterState} />
    </FiltersAndSearchWrapper>
  )
}
