import { useTranslation } from 'react-i18next'

import { ListRequestParams } from 'modules/domain/types'
import { FC } from 'react'
import { ACheckboxV2 } from 'views/components/Analytics'

type Props = {
  listRequestParams: ListRequestParams
  updateFilterState: (ListRequestParams) => void
}

export const MatchesGeneratedFilter: FC<Props> = ({ listRequestParams, updateFilterState }) => {
  const { t } = useTranslation('dashboard')

  const updateChecked = (val: boolean) => {
    updateFilterState({ exclude_dev_sale_bids: val ? true : undefined })
  }

  return (
    <ACheckboxV2
      onChange={(e) => updateChecked(e.target.checked)}
      checked={
        listRequestParams.filter.exclude_dev_sale_bids
          ? JSON.parse(listRequestParams.filter.exclude_dev_sale_bids)
          : undefined
      }
      label={t('autogeneratedFilter')}
      id="exclude_dev_sale_bids"
    />
  )
}
