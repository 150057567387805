import { Address } from '../types'
import { useTranslation } from 'react-i18next'
import formatNumber from 'modules/utils/numbers/formatNumber'
import { DASH } from 'modules/constants'

type Formatter = (a: Address) => string | undefined
type FormatterRecord = Partial<Record<keyof Address, Formatter> & { schedule: Formatter }>
export type AddressKey = keyof FormatterRecord
type AddressFormatter<T = AddressKey> = (a: Address, ...keys: T[]) => unknown[]

export const useAddressFormatter = ({ emptyString = DASH } = {}) => {
  const { t } = useTranslation('deal')

  const warehouseYNU = (value) => t(`warehouse:yesNoUnknown:${value}`)

  const formatters: FormatterRecord = {
    car_delivery: (a) => warehouseYNU(a.car_delivery),
    gates_height: (a) => (a.gates_height ? `${formatNumber(a.gates_height)} ${t('common:m')}` : emptyString),
    is_dump_truck: (a) => (a.is_dump_truck ? t('common:yes') : t('common:no')),
    loader_type: (a) => a.loader_type?.map((type) => t(`vehicle_type.${type}`)).join(', ') || emptyString,
    railway_delivery: (a) => warehouseYNU(a.railway_delivery),
    scales_capacity: (a) => (a.scales_capacity ? `${formatNumber(a.scales_capacity)} ${t('common:t')}` : emptyString),
    scales_length: (a) => (a.scales_length ? `${a.scales_length} ${t('common:m')}` : emptyString),
    scales_remoteness: (a) =>
      a.scales_remoteness ? `${formatNumber(a.scales_remoteness)} ${t('common:km')}` : emptyString,

    overload: (a) => t(`address:overLoadValues.${a.overload}`),
    schedule: (a) => (a.schedule_from && a.schedule_to ? `${a.schedule_from}-${a.schedule_to}` : emptyString),
    unloading_type: (a) => (a.unloading_type ? t(`directions.${a.unloading_type}`) : emptyString),
    vehicle_type: (a) => a.vehicle_type?.map((type) => t(`vehicle_type.${type}`)).join(', ') || emptyString,
    volume_per_day: (a) => (a.volume_per_day ? `${formatNumber(a.volume_per_day)} ${t('common:tDay')}` : emptyString),
    works_on_weekend: (a) => warehouseYNU(a.works_on_weekend),
    weather_conditions: (a) => warehouseYNU(a.weather_conditions),
  }

  const formatter: AddressFormatter = (a, ...props) =>
    props.map((key) => (key in formatters ? formatters[key]?.(a) : a[key]))

  return formatter
}
