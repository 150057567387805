import { FC } from 'react'
import { TableFiltersProps } from 'modules/types'
import { FiltersAndSearchWrapper, FiltersWrapper } from 'views/components/CommonTableComponents/CommonTableComponents'
import { t } from 'i18next'
import useDateFnsLocale from 'hooks/useDateFnsLocale'
import { getMonthList } from 'modules/utils/helpers'
import { format } from 'date-fns'
import { MONTH_NAME_YEAR, YEAR_MONTH } from 'modules/constants'
import { SpecificationStatus } from 'modules/domain/specification/types'
import { useDisplayClearBtn } from 'hooks/useDisplayClearBtn'
import { defaultListRequestParams } from 'views/components/TableFilters/data'
import { CropsFilter, StatusFilter, TeamsFilter } from 'views/components/TableFilters'
import { AClearFiltersButton, ASelectFilter } from 'views/components/Analytics'
import { SearchFilter } from 'views/components/SearchFilter/SearchFilter'

export const SpecificationFilters: FC<TableFiltersProps> = ({
  setListRequestParams,
  listRequestParams,
  updateFilterState,
}) => {
  const displayClearBtn = useDisplayClearBtn({
    listRequestParams,
  })
  const locale = useDateFnsLocale()
  const dateOptions = getMonthList().map((date) => ({
    value: format(date, YEAR_MONTH),
    label: format(date, MONTH_NAME_YEAR, { locale }),
  }))

  const statusOptions = Object.keys(SpecificationStatus).map((status) => ({
    value: status,
    label: t(`specification:statuses.${status}`),
  }))
  return (
    <FiltersAndSearchWrapper>
      <FiltersWrapper>
        <ASelectFilter
          id="monthFilter"
          placeholder={t('common:forEntirePeriod')}
          options={dateOptions}
          value={listRequestParams.filter.month}
          onChange={(month) => updateFilterState({ month })}
          showSearch={false}
        />
        <CropsFilter listRequestParams={listRequestParams} updateFilterState={updateFilterState} />
        <TeamsFilter listRequestParams={listRequestParams} updateFilterState={updateFilterState} />
        <StatusFilter
          listRequestParams={listRequestParams}
          updateFilterState={updateFilterState}
          options={statusOptions}
        />
        {displayClearBtn && (
          <AClearFiltersButton onClick={() => setListRequestParams({ ...defaultListRequestParams })} />
        )}
      </FiltersWrapper>

      <SearchFilter listRequestParams={listRequestParams} updateFilterState={updateFilterState} />
    </FiltersAndSearchWrapper>
  )
}
