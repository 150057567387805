import { AntTag } from '@agro-club/agroclub-shared'
import { User } from 'modules/domain/user/types'
import { getPersonalNumberLabel } from 'modules/utils/numbers/formatPersonalNumber'
import { FC, Fragment } from 'react'
import { useTranslation } from 'react-i18next'
import { MoratoriumTag } from 'views/components/Tags/CompanyTag'
import { CompanyCommercialTag } from 'views/pages/Company/CompanyCommercialTag'
import * as Header from 'views/ui/Header/Header'
import APhone from 'views/ui/Phone/APhone'
import { CompanySizeTag } from './CompanySizeTag'
import { UserCompanyLink } from './UserCompanyLink/UserCompanyLink'

type Props = {
  user: User
}
export const UserProfileTag = ({ user }: Props) => {
  const { t } = useTranslation('user')
  const profile = user.profile
  return (
    <AntTag color="white">{profile?.profile_type ? t(`profile_type.${profile.profile_type}`) : t('no_profile')}</AntTag>
  )
}

export const HeaderSubTitleValue: FC<Props> = ({ user }) => {
  const profile = user.profile
  const company = user.profile?.company

  const displayParts = [
    company?.id && <UserCompanyLink companyId={company.id} companyName={company.name} />,
    company?.inn && <span>{`${getPersonalNumberLabel()}: ${company.inn}`}</span>,
    user.email && <span>{user.email}</span>,
  ].filter(Boolean)

  return (
    <Header.InlineContainer>
      <CompanyCommercialTag commercial={user.profile?.company?.commercial} />
      <CompanySizeTag companySize={profile?.company_size} />
      <UserProfileTag user={user} />
      <MoratoriumTag isVisible={company?.soy_restriction} />
      <div>
        {displayParts.map((part, index) => (
          <Fragment key={index}>
            {index > 0 && ' / '}
            {part}
          </Fragment>
        ))}

        {user.phone && <APhone marginLeft="10px" phone={user.phone} isMobile={user.is_mobile_phone} />}
      </div>
    </Header.InlineContainer>
  )
}
