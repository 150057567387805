import { Progress } from '@agro-club/agroclub-shared'

import { refetchFunc } from 'modules/domain/common/hooks'
import { DashboardTask } from 'modules/domain/dashboard/types'
import { FC } from 'react'
import styled from 'styled-components'
import { Spinner } from 'views/components/CommonTableComponents/CommonTableComponents'
import { NoData } from 'views/components/NoData/NoData'
import { CustomScroll } from 'views/styled/common'
import { TaskDealItem } from '../Dashboard/components/Cards/CardItems/TaskDealItem'

type Props = {
  progress: Progress
  data: DashboardTask[] | undefined
  refetch: refetchFunc
}

export const SpinnerWrapper = styled.div`
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`

const Scrollable = styled.div`
  overflow-y: auto;
  height: 100%;
  ${CustomScroll}
`

export const CardsWrapper = styled.div`
  display: grid;
  grid-gap: 24px;
  grid-template-columns: repeat(auto-fit, 330px);
`

export const MatchesCardList: FC<Props> = ({ progress, data, refetch }) => {
  if (progress === Progress.WORK)
    return (
      <SpinnerWrapper>
        <Spinner />
      </SpinnerWrapper>
    )

  return !data?.length ? (
    <NoData />
  ) : (
    <Scrollable>
      <CardsWrapper>
        {data?.map((task) => (
          <TaskDealItem key={task.id} task={task} refetch={refetch} />
        ))}
      </CardsWrapper>
    </Scrollable>
  )
}
