import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { ABaseTableFilter } from '../TableFilters/ABaseTableFilter'
import { endpoints } from 'modules/endpoints'
import { Company } from 'modules/domain/company/types'

type Props = {
  value: string[]
  updateFilterState: (value: Record<string, unknown>) => void
}

export const CompaniesFilter: FC<Props> = ({ value, updateFilterState }) => {
  const { t } = useTranslation()

  return (
    <ABaseTableFilter
      id="companyFilter"
      onChange={(company: Company) => updateFilterState({ company })}
      value={value}
      placeholder={t('common:company')}
      getLabel={(company) => company.name}
      endpoint={endpoints.company()}
      searchField="search"
      multiple
    />
  )
}
