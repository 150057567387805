import { defaultTheme, Typography } from '@agro-club/agroclub-shared'
import { CommentType } from 'modules/domain/comment/types'
import { endpoints } from 'modules/endpoints'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import AComments from 'views/components/Comments/AComments'

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;

  padding: 24px 32px 24px 16px;
`

const Title = styled.div`
  margin-bottom: 12px;
  ${Typography.titleH4};
  color: ${defaultTheme.color.textPrimary900};
`

type Props = {
  dealId: string
  onSuccess: () => void
}

export const DrawerComments = ({ dealId, onSuccess }: Props) => {
  const { t } = useTranslation('dispatching')

  return (
    <Wrapper>
      <Title>{t('table.comments')}</Title>

      <AComments
        id={dealId}
        type={CommentType.deal}
        url={endpoints.dealComment(dealId)}
        onSuccess={onSuccess}
        maxHeight={550}
      />
    </Wrapper>
  )
}
