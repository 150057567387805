import { apiClient } from 'modules/utils/httpClient'
import { useEffect, useRef, useState } from 'react'
import { useProgress } from './useProgress'

export const useFetchDataWithAbort = <T>(endpoint: string, params: Record<string, any>, deps: any[]) => {
  const [data, setData] = useState<T>()
  const abortControllerRef = useRef<AbortController | null>(null)

  const [progress, fetch, error] = useProgress(async () => {
    if (abortControllerRef.current) {
      abortControllerRef.current.abort()
    }
    abortControllerRef.current = new AbortController()

    const response = await apiClient.get<T>(endpoint, params, { signal: abortControllerRef.current.signal })
    abortControllerRef.current = null
    setData(response)
  })

  useEffect(() => {
    fetch()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [...deps])

  return { data, progress, refetch: fetch, error }
}
