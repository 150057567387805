import { FiltersWrapper } from 'views/components/CommonTableComponents/CommonTableComponents'
import { CropsFilter } from 'views/components/TableFilters'
import { ASelectFilter } from 'views/components/Analytics'
import { ListRequestParams } from 'modules/domain/types'
import { BidTypes } from 'modules/domain/bid/types'
import { useTranslation } from 'react-i18next'
import { FC } from 'react'

type Props = {
  listRequestParams: ListRequestParams
  setListRequestParams: (v: ListRequestParams) => void
}

export const Filters: FC<Props> = ({ listRequestParams, setListRequestParams }) => {
  const { t } = useTranslation('bid')

  const options = [
    { value: BidTypes.purchase, label: t('bidTypes.purchase') },
    { value: BidTypes.sale, label: t('bidTypes.sale') },
  ]

  return (
    <FiltersWrapper>
      <ASelectFilter
        onChange={(bid_type) =>
          setListRequestParams({ ...listRequestParams, filter: { ...listRequestParams.filter, bid_type } })
        }
        placeholder={t('list.tableHeaders.status')}
        value={listRequestParams.filter.bid_type}
        options={options}
        showSearch={false}
        id="bid_type"
      />
      <CropsFilter
        listRequestParams={listRequestParams}
        updateFilterState={({ product_id }) =>
          setListRequestParams({ ...listRequestParams, filter: { ...listRequestParams.filter, product_id } })
        }
      />
    </FiltersWrapper>
  )
}
