import { DealStatusTypes, MarginsforDeals, RuMarginsforDeals } from 'modules/domain/deal/types'
import formatNumber from 'modules/utils/numbers/formatNumber'
import formatPrice from 'modules/utils/numbers/formatPrice'
import { AntTooltip, Text } from '@agro-club/agroclub-shared'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { FC } from 'react'

const Wrapper = styled.div`
  display: flex;
  gap: 0 4px;
`

const Row = styled.div`
  display: flex;
  gap: 16px;
`
const Column = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`

type Props = {
  margins: RuMarginsforDeals | MarginsforDeals
}

const SingleVal: FC<{ sum: number; percent: number; label: string }> = ({ sum, percent, label }) => {
  const { t } = useTranslation('deal')
  return (
    <div>
      <Text typography="bodyMedium" color="secondary">
        {label}
      </Text>
      <Text typography="bodyMedium">
        <Wrapper>
          <span>{formatPrice(Math.round(sum), true, false)}</span>
          <AntTooltip placement="top" variant="secondary" tooltipContent={t('weighted_average_percentage')}>
            / {formatNumber(percent)}
          </AntTooltip>
        </Wrapper>
      </Text>
    </div>
  )
}

export const RuMarginShipmentComplete: FC<Props> = ({ margins }) => {
  const { t } = useTranslation('deal')
  const ruMargins = margins as RuMarginsforDeals

  return (
    <Row>
      <Column>
        <SingleVal
          sum={ruMargins.margins[DealStatusTypes.SHIPMENT_COMPLETE]}
          percent={ruMargins.weighted_average_margins[DealStatusTypes.SHIPMENT_COMPLETE]}
          label={t('cotributionFact')}
        />
        <SingleVal
          sum={ruMargins.margins[DealStatusTypes.EXPECTED_MARGIN]}
          percent={ruMargins.weighted_average_margins[DealStatusTypes.EXPECTED_MARGIN]}
          label={t('contributionWas')}
        />
      </Column>
      <Column>
        <SingleVal
          sum={ruMargins.margins_no_credit[DealStatusTypes.SHIPMENT_COMPLETE]}
          percent={ruMargins.weighted_average_margins_no_credit[DealStatusTypes.SHIPMENT_COMPLETE]}
          label={t('marginFact')}
        />
        <SingleVal
          sum={ruMargins.margins_no_credit[DealStatusTypes.EXPECTED_MARGIN]}
          percent={ruMargins.weighted_average_margins_no_credit[DealStatusTypes.EXPECTED_MARGIN]}
          label={t('marginWas')}
        />
      </Column>
    </Row>
  )
}
