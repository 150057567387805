import { useTranslation } from 'react-i18next'

import { TableFilterProps } from './types'
import { endpoints } from 'modules/endpoints'

import { ABaseTableFilter } from 'views/components/TableFilters/ABaseTableFilter'
import { FC } from 'react'

export const CoordinatorTypeFilter: FC<TableFilterProps> = ({ updateFilterState, listRequestParams }) => {
  const { t } = useTranslation('user')

  return (
    <ABaseTableFilter
      onChange={(coordinator_type) => updateFilterState({ coordinator_type })}
      placeholder={t('list.tableHeaders.coordinator_type')}
      value={listRequestParams.filter.coordinator_type}
      showSearch={false}
      endpoint={endpoints.userCoordinatorTypes()}
      getLabel={(dto) => dto.label}
      simpleApiFormat
      id="coordinatorTypeFilter"
    />
  )
}
