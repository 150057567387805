import { MatchesPanel } from '../../Board/MatchesColumn/MatchesPanel'
import { AntCollapse, Progress } from '@agro-club/agroclub-shared'
import { WrapperCollapse } from 'views/components/Board/styled'
import { SlugLabelPair } from 'modules/domain/common/types'
import { UserDeal } from 'modules/domain/userBid/types'
import { apiClient } from 'modules/utils/httpClient'
import { FC, useEffect, useState } from 'react'
import { Bid } from 'modules/domain/bid/types'
import { useTranslation } from 'react-i18next'
import { endpoints } from 'modules/endpoints'
import axios from 'axios'

type Props = {
  activeBid: Bid | undefined
  reasons: SlugLabelPair[]
  refetchDeals: () => void
  deals: UserDeal[]
  progressDeals: Progress
}

export const DealsColumn: FC<Props> = ({ activeBid, reasons, refetchDeals, deals, progressDeals }) => {
  const { t } = useTranslation()

  const [closedDeals, setClosedDeals] = useState<UserDeal[]>([])
  const [completedDeals, setCompletedDeals] = useState<UserDeal[]>([])
  const [progress, setProgress] = useState(Progress.IDLE)

  const fetchDeals = () => {
    if (!activeBid?.id) return

    const controller = new AbortController()
    const signal = controller.signal
    setProgress(Progress.WORK)

    const respCompletedMatches = apiClient.get<UserDeal[]>(endpoints.completedMatchesForBids(activeBid.id), undefined, {
      signal,
    })
    const respClosedMatches = apiClient.get<UserDeal[]>(endpoints.closedMatchesForBids(activeBid.id), undefined, {
      signal,
    })

    Promise.all([respCompletedMatches, respClosedMatches])
      .then((values) => {
        setProgress(Progress.SUCCESS)

        setCompletedDeals(values[0])
        setClosedDeals(values[1])
      })
      .catch((e) => {
        if (!axios.isCancel(e)) {
          setProgress(Progress.ERROR)
        }
      })
    return controller
  }

  useEffect(() => {
    const controller = fetchDeals()

    return () => {
      controller?.abort()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeBid])

  return (
    <WrapperCollapse>
      <AntCollapse noMarginTop accordion defaultActiveKey={['deals']}>
        <MatchesPanel
          namePanel={t('deal:title')}
          onStatusChange={refetchDeals}
          activeBid={activeBid}
          progress={progressDeals}
          reasons={reasons}
          colorCount="grey"
          matches={deals}
          key="deals"
        />
        <MatchesPanel
          namePanel={t('user:list.completed_deals')}
          matches={completedDeals}
          onStatusChange={fetchDeals}
          activeBid={activeBid}
          progress={progress}
          reasons={reasons}
          colorCount="grey"
          key="completed"
        />
        <MatchesPanel
          namePanel={t('user:list.closed_deals')}
          onStatusChange={fetchDeals}
          matches={closedDeals}
          activeBid={activeBid}
          progress={progress}
          reasons={reasons}
          colorCount="grey"
          key="closed"
        />
      </AntCollapse>
    </WrapperCollapse>
  )
}
