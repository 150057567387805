export const YEAR_MONTH = 'yyyy-MM'
export const MONTH_NAME_YEAR = 'LLLL yyyy' // январь 2021

export const SERVER_DATE_FORMAT = 'yyyy-MM-dd'
export const SERVER_DATE_TIME_FORMAT = 'yyyy-MM-dd HH:mm:ss'
export const SERVER_DATE_TIME_FORMAT_WITH_TIME_ZONE = "yyyy-MM-dd'T'HH:mm:ss'Z'"
export const RU_DATE_FORMAT = 'dd.MM.yyyy'
export const RU_SHORT_DATE_FORMAT = 'dd.MM'
export const RU_DATE_FORMAT_TIME_FORMAT = 'dd.MM.yyyy HH:mm'
export const ANT_TIME_FORMAT = { format: 'HH:mm' }
export const LIST_PAGE_SIZE = 30
export const ACCORDION_ITEM_PAGE_SIZE = 5
export const PAGE_SIZE_1K = 1000
export const NOT_AVAILABLE_URL = '/404'
export const EXPANDABLE_COLUMN_WIDTH = 48

export const UI_TIMEOUT_200 = 200
export const UI_TIMEOUT_500 = 500
export const UI_TIMEOUT_1000 = 1000

export const MENU_COLLAPSE_KEY = 'menu_collapse'

// use this dash, it is longer than regular
export const DASH = '—'

export const PHONE_TABLE_CELL_WIDTH = 190
