import { Icons, useHelmet } from '@agro-club/agroclub-shared'
import { Tab, useTab, useTabsDataV2 } from 'hooks/useTabsDataV2'
import { AuthContext } from 'modules/context/AuthContext'
import { BidStatus, UIBidTypes } from 'modules/domain/bid/types'
import { endpoints } from 'modules/endpoints'
import { FC, useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { generatePath, useNavigate, useParams } from 'react-router-dom'
import { AAntdTabs, AButton } from 'views/components/Analytics'
import APageWrapper from 'views/components/PageWrapper/APageWrapper'
import { Header, PageName, TopHeader, WrapperContent } from 'views/layouts/NewLayout'
import { BidCreateModal } from 'views/pages/Bid/BidModals/BidCreateModal'
import BidTable from 'views/pages/Bid/BidTable/BidTable'
import BidRoutes from 'views/pages/Bid/routes'
import { AnalyticsPages, AnalyticsPlaces } from './analyticsPlaces'
import BidFilters from './BidFilters/BidFilters'
import { useBidPurchaseColumns } from './BidTable/useBidPurchaseColumns'
import { useBidSaleColumns } from './BidTable/useBidSaleColumns'
import { usePotentialBidColumns } from './BidTable/usePotentialBidColumns'
import { Breadcrumbs } from './styled'

const BidList: FC = () => {
  const { t } = useTranslation('bid')
  useHelmet({ title: t('bidMetaTitle') })

  const [isOpen, setIsOpen] = useState<boolean>(false)

  const navigate = useNavigate()
  const { profile: currentUser } = useContext(AuthContext)
  const { activeTab: activeTabParams } = useParams()
  const [activeTab, setActiveTab] = useState<string>(activeTabParams || UIBidTypes.purchase)

  const tabs: Record<string, Tab> = {}
  tabs[UIBidTypes.purchase] = useTab({
    label: t('bidTypes.purchase'),
    endpoint: endpoints.bidPurchase(),
    columnsFunc: useBidPurchaseColumns,
  })
  tabs[UIBidTypes.sale] = useTab({
    label: t('bidTypes.sale'),
    endpoint: endpoints.bidSale(),
    columnsFunc: useBidSaleColumns,
  })
  tabs[UIBidTypes.potential] = useTab({
    label: t('bidTypes.potential'),
    endpoint: endpoints.potentialBid(),
    columnsFunc: usePotentialBidColumns,
  })

  const { isFiltersLoaded, commonRequestParams, updateCommonFilterState, clearAllTabsState } = useTabsDataV2({
    activeTab,
    tabs,
    initialCommonRequestParams: {
      filter: {
        team: currentUser?.profile?.team?.id && String(currentUser?.profile?.team?.id),
        status: BidStatus.published,
        //For fix Warning: A component is changing an uncontrolled input to be controlled.
        radius: '',
      },
    },
    excludeFilters: {
      [UIBidTypes.potential]: [
        'start_date',
        'end_date',
        'status',
        'has_files',
        'has_samples',
        'address',
        'radius',
        'longitude',
        'latitude',
        'is_firm',
      ],
      [UIBidTypes.purchase]: ['has_samples', 'is_firm'],
    },
  })

  useEffect(() => {
    navigate(
      {
        pathname: generatePath(BidRoutes.ListWithTab, { activeTab: activeTab }),
        search: location.search,
      },
      { replace: true },
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeTab])

  const bidTabsItems = () => {
    const tabsJsx: any = []
    for (const key of Object.keys(tabs)) {
      tabsJsx.push({
        label: tabs[key].label,
        key,
        children: (
          <BidTable
            bidType={key as UIBidTypes}
            listRequestParams={tabs[key].params}
            isFiltersLoaded={isFiltersLoaded}
            setListRequestParams={tabs[key].setter}
            dataEndpoint={tabs[key].endpoint || ''}
            columnsFunc={tabs[key].columnsFunc}
          />
        ),
      })
    }
    return tabsJsx
  }

  return (
    <APageWrapper page={AnalyticsPages.BIDS} place={AnalyticsPlaces.LIST}>
      <WrapperContent>
        <Header>
          <TopHeader>
            <Breadcrumbs routes={[{ breadcrumbName: t('list.heading') }]} />
            <AButton id="add" Icon={Icons.IconAdd} intent="approve" size="medium" onClick={() => setIsOpen(true)}>
              {t('common:add')}
            </AButton>
          </TopHeader>

          <PageName>{t('list.heading')}</PageName>

          <BidFilters
            listRequestParams={commonRequestParams}
            updateFilterState={updateCommonFilterState}
            activeTab={activeTab}
            clearAllTabsState={clearAllTabsState}
          />

          {isOpen && <BidCreateModal onClose={() => setIsOpen(false)} />}
        </Header>

        <AAntdTabs items={bidTabsItems()} onChange={setActiveTab} activeKey={activeTab} id="tabs" />
      </WrapperContent>
    </APageWrapper>
  )
}

export default BidList
