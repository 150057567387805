import { hasBaseCreditData } from 'views/pages/Deal/helpers/hasBaseCreditData'
import { FormulaTooltip } from 'views/pages/Deal/components/FormulaTooltip'
import { formatTarif } from 'modules/utils/numbers/formatTarif'
import formatNumber from 'modules/utils/numbers/formatNumber'
import { KeyValue } from 'views/components/KeyValue/KeyValue'
import formatPrice from 'modules/utils/numbers/formatPrice'
import { Deal } from 'modules/domain/deal/types'
import { useTranslation } from 'react-i18next'
import { DASH } from 'modules/constants'
import { isVisibleCredit } from 'env'
import { FC } from 'react'

type Props = {
  deal: Deal
}

export const CreditInfo: FC<Props> = ({ deal }) => {
  const { t } = useTranslation('deal')
  const hasBaseCredData = hasBaseCreditData(deal)

  if (!isVisibleCredit) {
    return null
  }

  const actualContributionMargin = deal.actual_contribution_margin ? (
    <FormulaTooltip
      numberFormula={deal.actual_contribution_margin_formula}
      textFormula={deal.actual_contribution_margin_tooltip}
    >
      {formatPrice(deal.actual_contribution_margin, true)}
    </FormulaTooltip>
  ) : (
    DASH
  )
  const actualContributionMarginRate = deal.actual_contribution_margin_rate ? (
    <FormulaTooltip
      numberFormula={deal.actual_contribution_margin_rate_formula}
      textFormula={deal.actual_contribution_margin_rate_tooltip}
    >
      {`${formatNumber(deal.actual_contribution_margin_rate, true)} %`}
    </FormulaTooltip>
  ) : (
    DASH
  )
  const actualContributionMarginPerUnit = deal.actual_contribution_margin_per_unit ? (
    <FormulaTooltip
      numberFormula={deal.actual_contribution_margin_per_unit_formula}
      textFormula={deal.actual_contribution_margin_per_unit_tooltip}
    >
      {formatTarif(deal.actual_contribution_margin_per_unit)}
    </FormulaTooltip>
  ) : (
    DASH
  )

  const actualCreditCosts = deal.actual_credit_costs ? (
    <FormulaTooltip numberFormula={deal.actual_credit_costs_formula} textFormula={deal.actual_credit_costs_tooltip}>
      {formatPrice(deal.actual_credit_costs, true)}
    </FormulaTooltip>
  ) : (
    DASH
  )
  const actualCreditRate = deal.actual_credit_rate ? (
    <FormulaTooltip numberFormula={deal.actual_credit_rate_formula} textFormula={deal.actual_credit_rate_tooltip}>
      {`${formatNumber(deal.actual_credit_rate, true)} %`}
    </FormulaTooltip>
  ) : (
    DASH
  )
  const actualCreditCostPerUnit = deal.actual_credit_cost_per_unit ? (
    <FormulaTooltip
      numberFormula={deal.actual_credit_cost_per_unit_formula}
      textFormula={deal.actual_credit_cost_per_unit_tooltip}
    >
      {formatTarif(deal.actual_credit_cost_per_unit)}
    </FormulaTooltip>
  ) : (
    DASH
  )

  const contributionMargin = hasBaseCredData ? (
    <FormulaTooltip numberFormula={deal.contribution_margin_formula} textFormula={deal.contribution_margin_tooltip}>
      {formatPrice(deal.contribution_margin, true)}
    </FormulaTooltip>
  ) : (
    DASH
  )
  const contributionMarginRate = hasBaseCredData ? (
    <FormulaTooltip
      numberFormula={deal.contribution_margin_rate_formula}
      textFormula={deal.contribution_margin_rate_tooltip}
    >
      {`${formatNumber(deal.contribution_margin_rate)} %`}
    </FormulaTooltip>
  ) : (
    DASH
  )
  const contributionMarginPerUnit = hasBaseCredData ? (
    <FormulaTooltip
      numberFormula={deal.contribution_margin_per_unit_formula}
      textFormula={deal.contribution_margin_per_unit_tooltip}
    >
      {formatTarif(deal.contribution_margin_per_unit)}
    </FormulaTooltip>
  ) : (
    DASH
  )

  const creditCosts = hasBaseCredData ? (
    <FormulaTooltip numberFormula={deal.credit_costs_formula} textFormula={deal.credit_costs_tooltip}>
      {formatPrice(deal.credit_costs, true)}
    </FormulaTooltip>
  ) : (
    DASH
  )

  const creditRate = hasBaseCredData ? (
    <FormulaTooltip numberFormula={deal.credit_rate_formula} textFormula={deal.credit_rate_tooltip}>
      {`${formatNumber(deal.credit_rate)} %`}
    </FormulaTooltip>
  ) : (
    DASH
  )

  const costPerUnit = hasBaseCredData ? (
    <FormulaTooltip numberFormula={deal.cost_per_unit_formula} textFormula={deal.cost_per_unit_tooltip}>
      {formatTarif(deal.cost_per_unit)}
    </FormulaTooltip>
  ) : (
    DASH
  )
  return (
    <>
      <KeyValue label={t('creditPlan')}>
        <div>{creditCosts}</div>
        <div>{creditRate}</div>
        <div>{costPerUnit}</div>
      </KeyValue>

      <KeyValue label={t('creditActual')}>
        <div>{actualCreditCosts}</div>
        <div>{actualCreditRate}</div>
        <div>{actualCreditCostPerUnit}</div>
      </KeyValue>

      <KeyValue label={t('contributionPlan')}>
        <div>{contributionMargin}</div>
        <div>{contributionMarginRate}</div>
        <div>{contributionMarginPerUnit}</div>
      </KeyValue>

      <KeyValue label={t('contributionActual')}>
        <div>{actualContributionMargin}</div>
        <div>{actualContributionMarginRate}</div>
        <div>{actualContributionMarginPerUnit}</div>
      </KeyValue>

      <KeyValue label={t('daily_cost')}>
        <div>{hasBaseCredData ? formatPrice(deal.daily_cost, true) : DASH}</div>
        <div>{hasBaseCredData ? formatTarif(deal.daily_cost_per_unit) : DASH}</div>
      </KeyValue>
    </>
  )
}
