import { useTranslation } from 'react-i18next'

import { SelectProps } from '@agro-club/agroclub-shared'
import { ASelectFilter } from '../Analytics'
import { FC } from 'react'

export const BooleanFilter: FC<SelectProps> = (props) => {
  const { t } = useTranslation('common')

  const statusOptions = [
    { value: 'true', label: t('yes') },
    { value: 'false', label: t('no') },
  ]

  return <ASelectFilter id="booleanFilter" options={statusOptions} showSearch={false} {...props} />
}
