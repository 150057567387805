import { Icons, Progress } from '@agro-club/agroclub-shared'
import { ACCORDION_ITEM_PAGE_SIZE } from 'modules/constants'
import { usePagination } from 'modules/domain/common/hooks'
import { FarmerLoad, ShipLoad } from 'modules/domain/deal/types'
import { ColumnData } from 'modules/types'
import { FC, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { AButton } from 'views/components/Analytics'
import { Pagination } from 'views/components/Pagination/Pagination'
import PermissionCheck from 'views/components/PermissionCheck/PermissionCheck'
import { ATableFullPageWithoutData } from 'views/components/Table/ATableFullPageWithoutData'
import { defaultListRequestParams } from 'views/components/TableFilters/data'
import { ListFooterWrapper } from 'views/styled/common'

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 12px;
`

type Props = {
  setLoadSummary: (summary: number) => void
  setModalIsOpen: (arg: boolean) => void
  loadItems: ShipLoad[] | FarmerLoad[]
  columns: ColumnData[]
  progress: Progress
  noDataText: string
  addEventId: string
}

export const Loading: FC<Props> = ({
  setLoadSummary,
  loadItems,
  setModalIsOpen,
  progress,
  columns,
  noDataText,
  addEventId,
}) => {
  const { t } = useTranslation('deal')
  const [currPage, setCurrPage, items] = usePagination<ShipLoad | FarmerLoad>(loadItems, ACCORDION_ITEM_PAGE_SIZE)

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => setLoadSummary(loadItems.length), [loadItems])

  return (
    <Wrapper>
      <ATableFullPageWithoutData
        columns={columns}
        data={items}
        listParamsUpdated={setCurrPage}
        listRequestParams={defaultListRequestParams}
        pageSize={5}
        progress={progress}
        total={loadItems.length}
        disablePagination
        noDataText={noDataText}
        outerBordered
        disableScrollY
      />
      <ListFooterWrapper>
        <PermissionCheck scope={['deals.add_regionalactivity']}>
          <AButton id={addEventId} intent="approve" Icon={Icons.IconAdd} onClick={() => setModalIsOpen(true)}>
            {t('common:add')}
          </AButton>
        </PermissionCheck>
        <Pagination disableMargin total={loadItems.length} currPage={currPage} setCurrPage={setCurrPage} />
      </ListFooterWrapper>
    </Wrapper>
  )
}
