import { defaultTheme, Icons } from '@agro-club/agroclub-shared'
import { useAProgress } from 'hooks/useAProgress'
import useFormatDate from 'hooks/useFormatDate'
import { DASH } from 'modules/constants'
import { refetchFunc } from 'modules/domain/common/hooks'
import * as managers from 'modules/domain/common/managers'
import { Export, ExportDocuments, ExportDocumentType } from 'modules/domain/export/types'
import { DocumentType, DocumentWithTypeFile } from 'modules/domain/types'
import { endpoints } from 'modules/endpoints'
import { apiClient } from 'modules/utils/httpClient'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import styled from 'styled-components'
import { AButton } from 'views/components/Analytics'
import { Card } from 'views/components/Card/Card'
import { KeyValue } from 'views/components/KeyValue/KeyValue'
import { AUploadFileButton } from 'views/components/UploadFileButton/AUploadFileButton'
import { FileItem } from '../../components/FileItem'

type Props = {
  exportData: Export
  docsRefetch: refetchFunc
  exportDocs: ExportDocuments[]
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 4px;
  border-bottom: 1px solid ${defaultTheme.color.secondary50};
  padding-bottom: 12px;
`

const BlockWithDoc = styled.div`
  display: flex;
  justify-content: space-between;
`

const FileItemWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 0 4px;
`

const DocumentBlock: FC<{
  label: string
  value: string
  files: ExportDocuments[]
  docType: ExportDocumentType
  exportId: string
  docsRefetch: refetchFunc
}> = ({ label, value, files, exportId, docType, docsRefetch }) => {
  const [uploadProgress, upload] = useAProgress<DocumentWithTypeFile>(
    (documentType: DocumentType['slug'], file: File) =>
      managers.uploadDocuments(endpoints.exportDocuments(exportId), documentType, file),
    {
      eventName: 'uploadDocument',
      onSuccess: () => docsRefetch(),
    },
  )

  const [deleteProgress, deleteDoc] = useAProgress<unknown>(
    (documentId: string) => apiClient.delete(endpoints.exportDeleteDocument(exportId, documentId)),
    {
      eventName: 'deleteDocument',
      onSuccess: () => docsRefetch(),
    },
  )

  const filteredFiles = files.filter((doc) => doc.document_type === docType)

  return (
    <Wrapper>
      <BlockWithDoc>
        <KeyValue columnGap="small" horizontal valueTypo="bodyMedium" label={label}>
          {value}
        </KeyValue>
        <AUploadFileButton
          eventName="addFileByType"
          icon={Icons.IconClip}
          color="approve"
          size="small"
          progress={uploadProgress}
          onUpload={(file) => upload(docType, file)}
        />
      </BlockWithDoc>
      {filteredFiles.map((file, index) => (
        <FileItemWrapper key={index}>
          <FileItem file={file} maxWidthLink="450px" />

          <AButton
            id="deleteFile"
            Icon={Icons.IconDelete}
            color="secondary"
            background="none"
            size="small"
            progress={deleteProgress}
            onClick={() => deleteDoc(file.id)}
          />
        </FileItemWrapper>
      ))}
    </Wrapper>
  )
}

export const CommonInfo: FC<Props> = ({ exportData, docsRefetch, exportDocs }) => {
  const { t } = useTranslation('export')
  const { id } = useParams()
  const formatDate = useFormatDate()

  const commonProps = {
    files: exportDocs,
    exportId: id,
    docsRefetch,
  }

  return (
    <Card.GapableContent>
      <Card.Title>{t('commonInfo')}</Card.Title>
      <Card.Grid>
        <DocumentBlock
          value={exportData.number || DASH}
          label={t('contractNumber')}
          docType={ExportDocumentType.CONTRACT}
          {...commonProps}
        />
        <DocumentBlock
          value={exportData.unique_contract_number || DASH}
          label={t('unk')}
          docType={ExportDocumentType.UNK}
          {...commonProps}
        />
        <DocumentBlock
          value={exportData.ship_owner?.name || DASH}
          label={t('shipowner')}
          docType={ExportDocumentType.SHIP_OWNER}
          {...commonProps}
        />
        <DocumentBlock
          value={exportData.export_broker?.name || DASH}
          label={t('exportBroker')}
          docType={ExportDocumentType.EXPORT_BROKER}
          {...commonProps}
        />
        <DocumentBlock
          value={exportData.freight_broker?.name || DASH}
          label={t('freightBroker')}
          docType={ExportDocumentType.FREIGHT_BROKER}
          {...commonProps}
        />
        <DocumentBlock
          value={exportData.insurance?.name || DASH}
          label={t('insurance')}
          docType={ExportDocumentType.INSURANCE}
          {...commonProps}
        />
        <DocumentBlock
          value={exportData.surveyor?.name || DASH}
          label={t('surveyor')}
          docType={ExportDocumentType.SURVEYOR}
          {...commonProps}
        />
        <Card.Grid cols={3} maxColumnWidth={'167px'}>
          <KeyValue label={t('contractDate')} valueTypo="bodyMedium">
            {formatDate(exportData.contract_date) || DASH}
          </KeyValue>
          <KeyValue label={t('consignmentDate')} valueTypo="bodyMedium">
            {formatDate(exportData.consignment_date) || DASH}
          </KeyValue>
          <KeyValue label={t('viaDubai')} valueTypo="bodyMedium">
            {exportData.through_dubai ? t('common:yes') : t('common:no')}
          </KeyValue>
        </Card.Grid>
        <KeyValue label={t('buyer')} valueTypo="bodyMedium">
          {exportData?.purchaser?.name || DASH}
        </KeyValue>

        <Card.Grid cols={2} maxColumnWidth={'263px'}>
          <KeyValue label={t('vesselName')} valueTypo="bodyMedium">
            {exportData?.ship?.name || DASH}
          </KeyValue>
          <KeyValue label={t('transshipment')} valueTypo="bodyMedium">
            {exportData.warehouse?.title || DASH}
          </KeyValue>
        </Card.Grid>
        <Card.Grid cols={2} maxColumnWidth={'263px'}>
          <KeyValue label={t('forwarder')} valueTypo="bodyMedium">
            {exportData?.forwarder?.name || DASH}
          </KeyValue>
          <KeyValue label={t('otherMark')} valueTypo="bodyMedium">
            {exportData.other_mark?.name || DASH}
          </KeyValue>
        </Card.Grid>
      </Card.Grid>
    </Card.GapableContent>
  )
}
