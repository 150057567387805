import formatNumber from 'modules/utils/numbers/formatNumber'
import formatPrice from 'modules/utils/numbers/formatPrice'
import { t } from 'i18next'
import { formatBool } from 'helpers/formatBool'
import { DateFormatted } from '../DateFormatted'
import { DASH } from 'modules/constants'
import { Icons } from '@agro-club/agroclub-shared'

export const priority = (priority: number) => {
  switch (priority) {
    case 1:
      return <Icons.IconHighPrior />
    case 2:
      return <Icons.IconMediumPrior />
    case 3:
      return <Icons.IconLowPrior />
    default:
      return priority
  }
}

export const number = (number: number) => formatNumber(number)

export const price = (price) => {
  return price ? formatPrice(price) : DASH
}

export const haveOrNot = (value?: boolean) => formatBool(value, t('common:thereIs'))
export const YesNo = (value?: boolean) => formatBool(value)

export const date = (date) => (date ? <DateFormatted date={date} /> : DASH)

export const CellRenders = {
  priority,
  number,
  price,
  haveOrNot,
  YesNo,
  date,
}
