import { useEffect, useState } from 'react'
import { NavigateFunction, useLocation, useNavigate } from 'react-router-dom'

import { Search, Location } from 'history'
import queryString from 'query-string'

import { ListRequestParams } from 'modules/domain/types'

interface Params {
  listRequestParams: ListRequestParams
  setListRequestParams: (params: ListRequestParams) => void
}

/**
 * Hook to update url query parametrs based on the query provided
 */

export const updateLocationQuery = (
  oldQuery: Search,
  { filter, page, sort, ...otherListRequestParams }: ListRequestParams,
  navigate: NavigateFunction,
  currentLocationState: unknown,
): void => {
  const allListRequestParams: Record<string, unknown> = {
    ...otherListRequestParams,
    ...filter,
  }
  delete allListRequestParams.transformBeforeSend
  delete allListRequestParams.loadStep

  if (page > 1) {
    allListRequestParams.page = page
  }

  if (sort?.length) {
    allListRequestParams.sort = sort
  }

  const newQuery = `?${queryString.stringify(allListRequestParams, {
    arrayFormat: 'comma',
    skipEmptyString: true,
    skipNull: true,
  })}`

  // Prevent unnecessary navigation
  if (oldQuery !== newQuery) {
    navigate(newQuery, { replace: true, state: currentLocationState })
  }
}

/**
 * Parse query params from url and pass them to callback
 */
const setRequestParamsFromUrl = (
  currentLocation: Location,
  listRequestParams: ListRequestParams,
  setListRequestParams: (params: ListRequestParams) => void,
): void => {
  if (!currentLocation.search.length) return

  const { page, sort, pageSize, ...parsedQueryParams } = queryString.parse(currentLocation.search, {
    arrayFormat: 'comma',
  })

  const newListRequestParams: ListRequestParams = {
    ...listRequestParams,
    filter: {
      ...listRequestParams.filter,
      ...parsedQueryParams,
    },
    sort: sort as any,
  }

  if (page && +page > 1) {
    newListRequestParams.page = +page
  }
  if (pageSize) {
    newListRequestParams.pageSize = Number(pageSize)
  }

  setListRequestParams(newListRequestParams)
}

/**
 * Check if list request params are empty
 */
const areListRequestParamsEmpty = ({ filter, page, sort }: ListRequestParams): boolean => {
  const parsedFilters = queryString.stringify(filter, {
    arrayFormat: 'comma',
    skipEmptyString: true,
    skipNull: true,
  })

  return !parsedFilters.length && page === 1 && !sort?.length
}

const useUpdateLocationQuery = ({ listRequestParams, setListRequestParams }: Params) => {
  const currentLocation = useLocation()
  const navigate = useNavigate()
  const [isFiltersLoaded, setIsFiltersLoaded] = useState(false)

  useEffect(() => {
    // Check the listRequestParams in store if they are empty, so update them from URL only list request params
    // Updating on every filter change will cause problems with clearing all filters from both URL and store
    if (listRequestParams && areListRequestParamsEmpty(listRequestParams)) {
      setRequestParamsFromUrl(currentLocation, listRequestParams, setListRequestParams)
    } else {
      setIsFiltersLoaded(true)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (!isFiltersLoaded) {
      setIsFiltersLoaded(true)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [listRequestParams])

  useEffect(() => {
    updateLocationQuery(currentLocation.search, listRequestParams, navigate, currentLocation.state)

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [listRequestParams])

  return isFiltersLoaded
}

export default useUpdateLocationQuery
