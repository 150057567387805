import { Icons, useHelmet } from '@agro-club/agroclub-shared'
import { format } from 'date-fns'
import { useFilters } from 'hooks/useFilter'
import { YEAR_MONTH } from 'modules/constants'
import { ListRequestParams } from 'modules/domain/types'
import { endpoints } from 'modules/endpoints'
import { getMonthList } from 'modules/utils/helpers'
import { FC, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { AButton } from 'views/components/Analytics'
import APageWrapper from 'views/components/PageWrapper/APageWrapper'
import ATableFullPage from 'views/components/Table/ATableFullPage'
import { defaultListRequestParams } from 'views/components/TableFilters/data'
import * as Layout from 'views/layouts/NewLayout'
import { AddExport } from '../components/modals/AddExport'
import { ExportFilters } from './ExportFilters'
import { FileSearchModal } from './FileSearchModal/FileSearchModal'
import useColumns from './useColumns'

export const ExportList: FC = () => {
  const { t } = useTranslation('export')
  useHelmet({ title: t('title') })

  const [listRequestParams, setListRequestParams] = useState<ListRequestParams>({
    ...defaultListRequestParams,
  })
  const [isOpenModal, setIsOpenModal] = useState(false)
  const [fileSearchModal, setFileSearchModal] = useState(false)
  const handlerModal = () => setIsOpenModal((isOpen) => !isOpen)
  const { isFiltersLoaded, updateFilterState } = useFilters({
    listRequestParams,
    setListRequestParams,
    defaultParams: { month: format(getMonthList()[0], YEAR_MONTH) },
  })

  const columns = useColumns()

  return (
    <APageWrapper page="Export" place="ExportList">
      <Layout.WrapperContent>
        <Layout.Header>
          <Layout.TopHeader>
            <Layout.Breadcrumbs routes={[{ breadcrumbName: t('title') }]} />
            <Layout.HeaderWrapBtns>
              <AButton id="search-documents" color="secondary" size="medium" onClick={() => setFileSearchModal(true)}>
                {t('fileSearch')}
              </AButton>
              <AButton id="add" color="approve" Icon={Icons.IconAdd} size="medium" onClick={handlerModal}>
                {t('add')}
              </AButton>
            </Layout.HeaderWrapBtns>
          </Layout.TopHeader>

          <Layout.PageName>{t('title')}</Layout.PageName>

          <ExportFilters
            setListRequestParams={setListRequestParams}
            updateFilterState={updateFilterState}
            listRequestParams={listRequestParams}
            isFiltersLoaded={isFiltersLoaded}
          />
        </Layout.Header>

        <ATableFullPage
          dataEndpoint={endpoints.export()}
          listParamsUpdated={setListRequestParams}
          listRequestParams={listRequestParams}
          isFiltersLoaded={isFiltersLoaded}
          columns={columns}
          rowHoverGray
        />

        {isOpenModal && (
          <AddExport onClose={handlerModal} refetch={() => setListRequestParams({ ...listRequestParams })} />
        )}
        {fileSearchModal && <FileSearchModal onClose={() => setFileSearchModal(false)} />}
      </Layout.WrapperContent>
    </APageWrapper>
  )
}
