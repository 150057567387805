import { defaultTheme, Progress, Spinner, Text } from '@agro-club/agroclub-shared'
import useFormatDate from 'hooks/useFormatDate'
import { ACCORDION_ITEM_PAGE_SIZE } from 'modules/constants'
import { useTableData } from 'modules/domain/common/hooks'
import { ListRequestParams } from 'modules/domain/types'
import { endpoints } from 'modules/endpoints'
import { FC, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import styled, { css } from 'styled-components'
import { BorderedItem } from 'views/components/BorderedItem/BorderedItem'
import { Pagination } from 'views/components/Pagination/Pagination'
import Player from 'views/components/Player/Player'
import PlayerProvider from 'views/components/Player/PlayerProvider'
import { defaultListRequestParams } from 'views/components/TableFilters/data'
import { StyledIconPhoneIn, StyledIconPhoneMissed, StyledIconPhoneOut } from 'views/pages/Calls/CallsTable/styled'
import { SpinnerWrapper } from 'views/styled/common'
import APhone from 'views/ui/Phone/APhone'

const phoneIcon = {
  in: <StyledIconPhoneIn />,
  out: <StyledIconPhoneOut />,
  missed: <StyledIconPhoneMissed />,
}

const commonStyles = css`
  padding: 10px 12px;
`

const IconCell = styled.div`
  ${commonStyles};
  display: flex;
  align-items: center;

  svg {
    width: 16px;
    height: 16px;
  }
`

const InfoCell = styled.div`
  ${commonStyles};
  min-width: 349px;
`

const PlayerCell = styled.div`
  ${commonStyles};
  width: 100%;
`

type Props = {
  setCount: (count: number | undefined) => void
  count?: number
}

const NoCalls = styled.div`
  color: ${defaultTheme.color.secondaryPlaceholder};
`

export const Calls: FC<Props> = ({ setCount, count }) => {
  const { t } = useTranslation('calls')
  const { id } = useParams()
  const formatDate = useFormatDate()

  const [listRequestParams, setListRequestParams] = useState<ListRequestParams>({
    ...defaultListRequestParams,
    page: 1,
    pageSize: ACCORDION_ITEM_PAGE_SIZE,
  })

  const { progress, data = [], total } = useTableData(endpoints.userCalls(id), listRequestParams, true)

  useEffect(() => {
    if (data.length) {
      setCount(data.length)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data])

  if (!data.length) {
    return null
  }

  return (
    <>
      {progress === Progress.WORK ? (
        <SpinnerWrapper style={{ height: '330px' }}>
          <Spinner />
        </SpinnerWrapper>
      ) : (
        <div>
          {data.map((call) => (
            <BorderedItem key={call.id} padding={false} hover justifyContent="normal" columnGap="0">
              <IconCell>{phoneIcon[call.call_type]}</IconCell>
              <InfoCell>
                <Text typography="bodyLarge">{`${formatDate(call?.call_start, true)}, ${call?.coordinator_name}`}</Text>
                <APhone phone={call.client_phone} />
              </InfoCell>
              <PlayerCell>
                <PlayerProvider>
                  <Player file={call.record_link} duration={call.conversation_duration} itemId={call.id} />
                </PlayerProvider>
              </PlayerCell>
            </BorderedItem>
          ))}
        </div>
      )}
      <Pagination
        total={total}
        currPage={listRequestParams.page}
        setCurrPage={(page) => setListRequestParams((prev) => ({ ...prev, page }))}
      />
      {!count && <NoCalls>{t('no_calls')}</NoCalls>}
    </>
  )
}
