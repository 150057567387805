import { FormGroup, Icons, Input, Select } from '@agro-club/agroclub-shared'
import { FormikFieldsType } from 'modules/domain/types'
import styled from 'styled-components'
import { FC } from 'react'
import { CurrencyCode } from 'modules/domain/export/types'

const WrapperOption = styled.div`
  display: flex;
  align-items: center;
  gap: 0 4px;
`
const Controls = styled.div`
  display: grid;
  grid-template-columns: 94px 160px;
  gap: 8px;
`

export enum PriceType {
  USD = 'USD',
  EUR = 'EUR',
  RUB = 'RUB',
  AED = 'AED',
  CNY = 'CNY',
}

type Props = {
  formik: FormikFieldsType
  inputField: string
  currencyField: string
}

export const PriceUnitSelect: FC<Props> = ({ formik, inputField, currencyField }) => {
  const CustomOption = ({ option }) => {
    const icon = () => {
      const flagIcons = {
        [CurrencyCode.USD]: <Icons.IconFlagus />,
        [CurrencyCode.EUR]: <Icons.IconFlageu />,
        [CurrencyCode.AED]: <Icons.IconFlaguae />,
        [CurrencyCode.CNY]: <Icons.IconFlagcn />,
        [CurrencyCode.RUB]: <Icons.IconFlagRussia />,
      }

      return flagIcons[option.value]
    }

    return (
      <WrapperOption>
        {icon()}
        <div> {option.label}</div>
      </WrapperOption>
    )
  }

  const options = [
    {
      value: CurrencyCode.USD,
      label: 'USD, $/т',
    },
    {
      value: CurrencyCode.EUR,
      label: 'EUR, €/т',
    },
    {
      value: CurrencyCode.RUB,
      label: 'RUB, ₽/т',
    },

    {
      value: CurrencyCode.AED,
      label: 'AED, DN/т',
    },
    {
      value: CurrencyCode.CNY,
      label: 'CNY, ¥/т',
    },
  ]
  return (
    <Controls>
      <Input type="number" {...formik.getFieldProps(inputField)} />
      <FormGroup error={formik.errors[currencyField]}>
        <Select
          onChange={(val) => formik.setFieldValue(currencyField, val)}
          value={formik.values[currencyField]?.toString()}
          OptionComponent={CustomOption}
          allowClear={false}
          showSearch={false}
          options={options}
          fullWidth
          status={formik.errors[currencyField] ? 'error' : ''}
        />
      </FormGroup>
    </Controls>
  )
}
