import { endpoints } from 'modules/endpoints'

import { useTranslation } from 'react-i18next'
import { ABaseTableFilter } from '../TableFilters/ABaseTableFilter'
import { FC } from 'react'

type Props = {
  value: string[]
  updateFilterState: (value: Record<string, string[]>) => void
}

export const AssigneesFilter: FC<Props> = ({ value, updateFilterState }) => {
  const { t } = useTranslation()

  return (
    <ABaseTableFilter
      id="assigneeFilter"
      onChange={(assignee) => updateFilterState({ assignee })}
      value={value}
      placeholder={t('common:assignee')}
      getLabel={(label) => label.full_name}
      endpoint={endpoints.taskAssignees()}
      searchField="search"
      simpleApiFormat
      multiple
    />
  )
}
