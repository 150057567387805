export enum CommentType {
  export = 'export',
  deal = 'deal',
  user = 'user',
  company = 'company',
  trip = 'trip',
  specification = 'specification',
  documentsRequest = 'documentsRequest',
  contract = 'contract',
}
