import { VFC } from 'react'
import { useTranslation } from 'react-i18next'
import { FormikErrors, FormikProps } from 'formik'
import { BidAddFields, BidEditableFields, BidParameterDTO } from 'modules/domain/bid/types'
import { Product } from 'modules/domain/collection/types'
import { CropsParam } from 'views/pages/Bid/BidModals/CropsParam'
import { FormGroup, Icons, defaultTheme } from '@agro-club/agroclub-shared'
import { PotentialBidAddFields } from 'modules/domain/potentialBid/types'
import { AButton } from 'views/components/Analytics'
import { useMediaQuery } from 'react-responsive'
import styled from 'styled-components'

const isRegualarBidValues = (
  values: BidAddFields | PotentialBidAddFields | BidEditableFields,
): values is BidAddFields => {
  return (values as BidAddFields).mainParameter !== undefined
}
const AddButtons = styled(AButton)`
  @media (max-width: ${defaultTheme.queries.mobile}) {
    width: 100%;
  }
`

type Props = {
  separateMainParam?: boolean
  product: Product
  formik: FormikProps<BidAddFields> | FormikProps<BidEditableFields> | FormikProps<PotentialBidAddFields>
  formikErrors?: FormikErrors<BidParameterDTO>[]
}

export const Params: VFC<Props> = (props) => {
  const { product, formik, formikErrors, separateMainParam } = props
  const { t } = useTranslation(['bid'])
  const formikParams = formik.values.parameters
  let mainParam: string | undefined
  if (isRegualarBidValues(formik.values)) {
    mainParam = formik.values.mainParameter?.parameter
  } else {
    mainParam = undefined
  }
  const usedParams = formikParams.map((param) => param.parameter)
  const availableParams = product.parameters.filter((param) => param.id !== mainParam && !usedParams.includes(param.id))
  const separateMainParameter = separateMainParam ? product.parameters.slice(1) : product.parameters
  const paramsLength = product.parameters.length - (separateMainParam ? 1 : 0)

  const isMobile = useMediaQuery({
    query: `(max-width: ${defaultTheme.queries.mobile})`,
  })

  return (
    <FormGroup>
      {formikParams.map((p, i) => (
        <CropsParam
          allParams={separateMainParameter}
          formikError={formikErrors && formikErrors[i]}
          onChange={(values) => formik.setFieldValue(`parameters[${i}]`, values)}
          onRemove={() =>
            formik.setFieldValue(
              'parameters',
              formik.values.parameters.filter((_, index) => i !== index),
            )
          }
          key={i}
          availableParams={availableParams}
          parameter={p}
        />
      ))}
      {formik.values.parameters.length < paramsLength ? (
        <AddButtons
          size={isMobile ? 'biggest' : 'medium'}
          id="addParam"
          onClick={() =>
            formik.setFieldValue('parameters', [
              ...formik.values.parameters,
              {
                parameter: undefined,
                parameter_value_from: undefined,
                parameter_value_to: undefined,
              },
            ])
          }
          intent="approve"
          Icon={Icons.IconAdd}
        >
          {t('form.addParam')}
        </AddButtons>
      ) : null}
    </FormGroup>
  )
}
