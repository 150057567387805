import { Route, Routes } from 'react-router'
import { SdizList } from './SdisList'

function SdizPage() {
  return (
    <Routes>
      <Route index element={<SdizList />} />
    </Routes>
  )
}

export default SdizPage
