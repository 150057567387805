import { urlMaker } from 'modules/utils/urlMaker'

export const exportEndpoints = {
  export: urlMaker('/export/'),
  exportShortList: () => '/export/short-list/',
  exportStatuses: () => `/export/statuses/`,
  exportCurrencies: () => `/export/currencies/`,
  deliveryBasis: () => `/export/basis-types/`,
  exportComments: (id: string) => `/export/${id}/comments/`,
  exportHistory: (id: string) => `/export/${id}/history/`,
  exportFiles: (exportContractId: string, fileId?: string) =>
    `/export/${exportContractId}/files/${fileId ? `${fileId}/` : ''}`,
  exportDutyTypes: () => `/export/duty-types/`,
  exportPurchasers: () => `/export/purchasers/`,
  exportShips: () => `/export/ships/`,
  exportShipOwners: () => `/export/ship-owners/`,
  exportFreightBrokers: () => `/export/freight-brokers/`,
  exportBrokers: () => `/export/export-brokers/`,
  exportInsurances: () => `/export/insurances/`,
  exportSurveyors: () => `/export/surveyors/`,
  exportForwarders: () => `/export/forwarders/`,
  exportMarks: () => `/export/other-marks/`,
  exportFulfill: (id: string) => `/export/${id}/fulfill/`,
  exportClose: (id: string) => `/export/${id}/close/`,
  exportDeals: (id: string) => `/export/${id}/deals/`,
  exportDocumentsTypes: (id: string) => `/export/${id}/document_types/`,
  exportAllDocs: () => '/export/documents/',
  exportDocuments: (id: string) => `/export/${id}/documents/`,
  exportDeleteDocument: (id: string, documentId: string) => `/export/${id}/documents/${documentId}/`,
}
