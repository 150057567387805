import { urlMaker } from 'modules/utils/urlMaker'

export const bidsEndpoints = {
  bid: urlMaker('/bids/'),
  bidPurchase: () => '/bids/?bid_type=purchase',
  bidSale: () => '/bids/?bid_type=sale',
  bidFiles: (id: string) => `/bids/${id}/files/`,
  deleteBidFiles: (bidId: string, fileId: string) => `/bids/${bidId}/files/${fileId}/`,
  bidHistory: (id: string) => `/bids/${id}/history/`,
  removeBidFile: (bidId: string, fileId: string) => `/bids/${bidId}/files/${fileId}/`,
  bidChangeStatus: (id: string) => `/bids/${id}/change_status/`,
  deals: (bidId: string) => `/bids/${bidId}/deals/`,
  matchesForBids: (bidId: string) => `/bids/${bidId}/matches/`,
  closedMatchesForBids: (bidId: string) => `/bids/${bidId}/matches/closed/`,
  completedMatchesForBids: (bidId: string) => `/bids/${bidId}/matches/completed/`,
  responsesForBids: (bidId: string) => `/bids/${bidId}/responses/`,
  closedResponsesForBids: (bidId: string) => `/bids/${bidId}/responses/closed/`,
  publishedBidsForCompany: (companyId: string) => `/bids/?company_id=${companyId}&status=published`,
  archivedBidsForCompany: (companyId: string) => `/bids/?company_id=${companyId}&status=archived`,
  samplesForBid: (id: string) => `/bids/${id}/samples/`,
}
