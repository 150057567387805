import { FC } from 'react'
import { ListRequestParams } from 'modules/domain/types'
import { endpoints } from 'modules/endpoints'
import { useRegularColumns } from './useRegularColumns'
import { LogisticRequest } from 'modules/domain/logisticsKR/types'
import ATableFullPage from 'views/components/Table/ATableFullPage'
import { useTranslation } from 'react-i18next'

interface Props {
  request: LogisticRequest
  listRequestParams: ListRequestParams
  setListRequestParams: (params: ListRequestParams) => void
  refetchApprovingTable: () => void
  refetchTripsTable: () => void
}

export const RegularTripsTable: FC<Props> = ({
  request,
  listRequestParams,
  setListRequestParams,
  refetchApprovingTable,
  refetchTripsTable,
}) => {
  const { t } = useTranslation('tableNoDataText')

  const columns = useRegularColumns(request, refetchApprovingTable, refetchTripsTable)

  return (
    <ATableFullPage
      columns={columns}
      dataEndpoint={endpoints.LogisticsKR.tripsForDeal(request.id)}
      listRequestParams={listRequestParams}
      listParamsUpdated={setListRequestParams}
      rowHoverGray
      isFiltersLoaded={true}
      maxHeight={736}
      outerBordered
      noDataText={t('trips')}
    />
  )
}
