import { FC } from 'react'
import { PreloadCard, PreloadCount } from 'views/components/Board/Preload'
import { AntTagColor, Panel, Progress } from '@agro-club/agroclub-shared'
import { Count, WrapperCards } from 'views/components/Board/styled'
import { MatchCard } from './MatchCard'
import { NoDataCard } from 'views/components/Board/NoDataCard'
import { UserDeal } from 'modules/domain/userBid/types'
import { Bid } from 'modules/domain/bid/types'
import { SlugLabelPair } from 'modules/domain/common/types'

type Props = {
  onStatusChange: () => void
  colorCount: AntTagColor
  reasons: SlugLabelPair[]
  matches: UserDeal[]
  progress: Progress
  namePanel: string
  activeBid?: Bid
  key: string
}

export const MatchesPanel: FC<Props> = ({
  onStatusChange,
  colorCount,
  activeBid,
  namePanel,
  progress,
  matches,
  reasons,
  ...props
}) => {
  const preloadWrapper = progress === Progress.WORK && <PreloadCard />
  const preloadCount = progress === Progress.WORK && <PreloadCount />
  return (
    <Panel
      {...props}
      header={
        <>
          {namePanel}
          {preloadCount || <Count color={colorCount}>{matches.length || 0}</Count>}
        </>
      }
    >
      <WrapperCards>
        {activeBid &&
          progress !== Progress.WORK &&
          matches.map((match) => {
            return (
              <MatchCard
                onStatusChange={onStatusChange}
                activeBid={activeBid}
                reasons={reasons}
                key={match.id}
                match={match}
              />
            )
          })}
        {preloadWrapper}
        {!matches.length && !preloadWrapper && <NoDataCard />}
      </WrapperCards>
    </Panel>
  )
}
