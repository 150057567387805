import { defaultTheme, Text } from '@agro-club/agroclub-shared'
import styled from 'styled-components'

export const TableWrapper = styled.div`
  border: 1px solid ${defaultTheme.color.secondary50};
  border-radius: 8px;
  margin-top: 4px;
  margin-bottom: 12px;
`

export const HeaderWrapper = styled.div`
  padding: 10px 16px;
  border-bottom: 1px solid ${defaultTheme.color.secondary50};
`

export const HeaderGridRow = styled.div<{ columnsWidth: string; columnGap: string }>`
  display: grid;
  grid-template-columns: ${({ columnsWidth }) => columnsWidth};
  column-gap: ${({ columnGap }) => columnGap};
  align-items: flex-end;
`

export const DoubleRows = styled.div`
  padding: 4px 8px 0 8px;

  &:last-child {
    padding-bottom: 8px;
  }
`

export const FirstRow = styled.div`
  padding: 8px;
`

export const SecondRow = styled(HeaderGridRow)`
  padding: 8px;
  align-items: center;
  word-wrap: break-word;
  background-color: ${defaultTheme.color.secondary8a};
  border-radius: 8px;
  border-bottom: none;
`
export const NoOffers = styled(Text).attrs({ typography: 'bodyMedium', color: 'primary' })`
  display: flex;
  height: 100px;
  align-items: center;
  justify-content: center;
`

export const TableText = styled(Text).attrs({ typography: 'bodyMedium', color: 'primary' })``
