import { FC } from 'react'
import { FiltersAndSearchWrapper, FiltersWrapper } from 'views/components/CommonTableComponents/CommonTableComponents'
import { useDisplayClearBtn } from 'hooks/useDisplayClearBtn'

import { TableFiltersProps } from 'modules/types'
import { SearchFilter } from 'views/components/SearchFilter/SearchFilter'
import { ADateRangePicker } from 'views/components/TableFilters/DateRangePicker/ADateRangePicker'
import { AClearFiltersButton } from 'views/components/Analytics'

export const CustomersKRFilters: FC<TableFiltersProps> = ({
  setListRequestParams,
  updateFilterState,
  listRequestParams,
}) => {
  const displayClearBtn = useDisplayClearBtn({
    listRequestParams,
  })

  return (
    <FiltersAndSearchWrapper>
      <FiltersWrapper>
        <ADateRangePicker
          id="dateFilter"
          value={[listRequestParams.filter.date_joined_after, listRequestParams.filter.date_joined_before]}
          onChange={(value) =>
            updateFilterState({ date_joined_after: value.startDate, date_joined_before: value.endDate })
          }
        />

        {displayClearBtn && (
          <AClearFiltersButton
            onClick={() => setListRequestParams && setListRequestParams({ ...listRequestParams, filter: {} })}
          />
        )}
      </FiltersWrapper>
      <SearchFilter updateFilterState={updateFilterState} listRequestParams={listRequestParams} />
    </FiltersAndSearchWrapper>
  )
}
