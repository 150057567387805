import { useAProgress } from 'hooks/useAProgress'
import { endpoints } from 'modules/endpoints'
import { apiClient } from 'modules/utils/httpClient'
import { FC, useState } from 'react'
import { useTranslation } from 'react-i18next'
import ADefaultModalFooter from 'views/components/DefaultModalFooter/ADefaultModalFooter'
import { SignificantVerifyAction } from 'views/components/EntityVerifyActions/SignificantVerifyAction'
import { ASharedModal, SharedModalContent, SharedModalTitle } from 'views/components/SharedModal'

type Props = {
  isVerified: boolean
  companyId: string
  refetchCompany: () => void
}

export const KeyCompanyAction: FC<Props> = ({ isVerified, companyId, refetchCompany }) => {
  const { t } = useTranslation('company')
  const [confirmModal, setConfirmModal] = useState(false)
  const [progress, send] = useAProgress(() => apiClient.post(endpoints.keyCompany(companyId)), {
    eventName: 'keyCompany',
    onSuccess: () => {
      refetchCompany()
      setConfirmModal(false)
    },
  })

  return (
    <>
      <SignificantVerifyAction isVerified={isVerified} onClick={() => setConfirmModal(true)} />
      {confirmModal && (
        <ASharedModal id="keyCompany" onClose={() => setConfirmModal(false)} size="small">
          <SharedModalTitle>{t('significant')}</SharedModalTitle>
          <SharedModalContent>{t('significantConfirm')}</SharedModalContent>
          <ADefaultModalFooter onClose={() => setConfirmModal(false)} onConfirm={send} progress={progress} />
        </ASharedModal>
      )}
    </>
  )
}
