import { SelectFilter } from '@agro-club/agroclub-shared'
import { useAnalyticsChangeHandler } from 'analytics/hooks'
import { ListRequestParams } from 'modules/domain/types'
import { Dispatch, FC, SetStateAction } from 'react'
import { useTranslation } from 'react-i18next'

type Props = {
  listRequestParams: ListRequestParams
  setListRequestParams: Dispatch<SetStateAction<ListRequestParams>>
}

export const MatchesSortFilter: FC<Props> = ({ listRequestParams, setListRequestParams }) => {
  const { t } = useTranslation('dashboard')
  const analyticsChangeHandler = useAnalyticsChangeHandler('dashboardSortFilter')

  const options = [
    {
      value: 'deal_margin_percent',
      label: `${t('sortFilter.deal_margin_percent')}, ${t('common:asc')}`,
    },
    {
      value: '-deal_margin_percent',
      label: `${t('sortFilter.deal_margin_percent')}, ${t('common:desc')}`,
    },
  ]

  const value = listRequestParams.sort

  const onChange = analyticsChangeHandler(
    (sort) => {
      setListRequestParams({ ...listRequestParams, sort })
    },
    {
      value: { from: value },
    },
  )

  return (
    <SelectFilter
      placeholder={t('sortFilter.placeholder')}
      options={options}
      onChange={onChange}
      value={value}
      showSearch={false}
    />
  )
}
