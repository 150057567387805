import { ColumnsType } from '@agro-club/agroclub-shared'
import useFormatDate from 'hooks/useFormatDate'
import { DASH } from 'modules/constants'
import { AccountManagement } from 'modules/domain/accountManagement/types'
import { convertSortingToAntDFormat } from 'modules/domain/common/tableUtils'
import { ListRequestParams } from 'modules/domain/types'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { generatePath } from 'react-router'
import styled from 'styled-components'
import { AExternalLink } from 'views/components/Analytics'
import { PhoneButtonDropdown } from 'views/components/PhoneButtonDropdown/PhoneButtonDropdown'
import { USCompanyIrelyIcon } from 'views/components/TooltipIcons/TooltipIcons'
import CompanyRoutes from 'views/pages/Company/routes'
import UserRoutes from 'views/pages/User/routes'
import { TableTextLink } from 'views/ui/TableTextLink/TableTextLink'
import { CallsComments } from './CommentsCell'

const CompanyLinkWrapper = styled.div`
  display: flex;
  align-items: center;
  column-gap: 4px;
`

export const useColumns = (sortParam: ListRequestParams['sort']): ColumnsType<AccountManagement> => {
  const { t } = useTranslation('common')
  const formatDate = useFormatDate()

  return useMemo(
    () => [
      {
        title: `${t('company')} / ${t('address')}`,
        dataIndex: 'name',
        autoWidth: 337,
        sorter: true,
        sortOrder: convertSortingToAntDFormat('name', sortParam),
        render: (_, data) => (
          <div>
            <CompanyLinkWrapper>
              <TableTextLink
                onClick={(e) => e.stopPropagation()}
                id="company"
                to={generatePath(CompanyRoutes.Details, { id: data.id })}
                target="_blank"
              >
                {data?.name || DASH}
              </TableTextLink>
              <USCompanyIrelyIcon isVisible={data.synced_with_accounting} />
            </CompanyLinkWrapper>
            <div>{data?.main_address?.address || DASH}</div>
          </div>
        ),
      },
      {
        title: `${t('contactName')} / ${t('email')}`,
        dataIndex: 'contacts',
        width: 200,
        render: (_, data) => (
          <>
            {data.contacts.length ? (
              <div>
                <TableTextLink
                  onClick={(e) => e.stopPropagation()}
                  id="contact"
                  to={generatePath(UserRoutes.Details, { id: data.contacts[0].user_id })}
                  target="_blank"
                >
                  {data.contacts[0].fullname.trim() || DASH}
                </TableTextLink>
                <div>
                  {data.contacts[0].email ? (
                    <AExternalLink
                      onClick={(e) => e.stopPropagation()}
                      eventName="email"
                      href={`mailto:${data.contacts[0].email}`}
                    >
                      {data.contacts[0].email}
                    </AExternalLink>
                  ) : (
                    DASH
                  )}
                </div>
              </div>
            ) : (
              DASH
            )}
          </>
        ),
      },
      {
        title: t('phone'),
        dataIndex: 'phone',
        width: 212,
        render: (_, data) => (
          <>
            {data.contacts.length ? (
              <div onClick={(e) => e.stopPropagation()}>
                <PhoneButtonDropdown companyId={data.id} contacts={data.contacts} />
              </div>
            ) : (
              DASH
            )}
          </>
        ),
      },
      {
        title: t('lastContact'),
        dataIndex: 'last_comment_date',
        width: 162,
        render: (last_comment_date) => formatDate(last_comment_date, true),
      },
      {
        title: t('days'),
        dataIndex: 'days_since_last_comment',
        align: 'right',
        sorter: true,
        sortOrder: convertSortingToAntDFormat('days_since_last_comment', sortParam),
        width: 71,
      },
      {
        title: t('comments'),
        dataIndex: 'comments',
        width: 337,
        render: (comments) => <CallsComments lastComment={comments?.[0]?.comment} />,
      },
      {
        title: t('assignee'),
        dataIndex: 'responsible_coordinator',
        width: 168,
        render: (responsible_coordinator) => responsible_coordinator?.full_name,
      },
    ],
    [formatDate, sortParam, t],
  )
}
