import { AntCollapse, Progress } from '@agro-club/agroclub-shared'
import { WrapperCollapse } from 'views/components/Board/styled'
import { MatchesPanel } from '../MatchesColumn/MatchesPanel'
import { SlugLabelPair } from 'modules/domain/common/types'
import { UserDeal } from 'modules/domain/userBid/types'
import { FC, useEffect, useState } from 'react'
import { apiClient } from 'modules/utils/httpClient'
import { Bid } from 'modules/domain/bid/types'
import { useTranslation } from 'react-i18next'
import { endpoints } from 'modules/endpoints'
import { ListResponse } from 'types/api'

type Props = {
  activeBid?: Bid | undefined
  reasons: SlugLabelPair[]
}

export const ResponsesColumn: FC<Props> = ({ activeBid, reasons }) => {
  const [closedResponses, setClosedResponses] = useState<UserDeal[]>([])
  const [responses, setResponses] = useState<UserDeal[]>([])
  const [progress, setProgress] = useState(Progress.IDLE)
  const { t } = useTranslation()

  const getDeals = async () => {
    if (activeBid?.id) {
      setProgress(Progress.WORK)
      setClosedResponses([])
      setResponses([])
      const respResponses = apiClient.get<ListResponse<UserDeal>>(endpoints.responsesForBids(activeBid.id))
      const respClosedResponses = apiClient.get<UserDeal[]>(endpoints.closedResponsesForBids(activeBid.id))
      Promise.all([respResponses, respClosedResponses])
        .then((values) => {
          setProgress(Progress.SUCCESS)
          setResponses(values[0].results)
          setClosedResponses(values[1])
        })
        .catch((e) => {
          setProgress(Progress.ERROR)
          console.error(e)
        })
    }
  }

  useEffect(() => {
    getDeals()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeBid])

  return (
    <WrapperCollapse>
      <AntCollapse noMarginTop accordion defaultActiveKey={['responses']}>
        <MatchesPanel
          namePanel={t('user:list.responses')}
          onStatusChange={getDeals}
          activeBid={activeBid}
          colorCount="orange"
          progress={progress}
          matches={responses}
          reasons={reasons}
          key="responses"
        />
        <MatchesPanel
          namePanel={t('user:list.closed_deals')}
          matches={closedResponses}
          onStatusChange={getDeals}
          activeBid={activeBid}
          progress={progress}
          reasons={reasons}
          colorCount="grey"
          key="closed"
        />
      </AntCollapse>
    </WrapperCollapse>
  )
}
