import { useAFormHandler } from 'analytics/hooks'
import { isBrazil, isUS } from 'env'
import { useFormik } from 'formik'
import { CompanyCommercial, Prepayment } from 'modules/domain/company/types'
import { endpoints } from 'modules/endpoints'
import { apiClient } from 'modules/utils/httpClient'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import ADefaultModalFooter from 'views/components/DefaultModalFooter/ADefaultModalFooter'
import { ASharedModal, SharedModalContent, SharedModalInputs, SharedModalTitle } from 'views/components/SharedModal'
import { AnalyticsPlaces } from '../analyticsPlaces'
import { CommonFields } from './CommonFields'

type Props = {
  onClose: () => void
  refreshCompanies: (silent: boolean) => void
  parentCompanyId?: string
}

export type CompanyWorldFields = {
  prepayment?: keyof typeof Prepayment
  state_reg_number?: string
  payment_delay_days: number
  name: string | null
  inn: string | null
  created?: string
  id?: number
  soy_restriction?: boolean
  commercial: CompanyCommercial
  parent?: string
  sync_with_accounting?: boolean
}

export const AddWorldCompany: FC<Props> = ({ onClose, refreshCompanies, parentCompanyId }) => {
  const { t } = useTranslation('company')
  const { formProgress, formHandler } = useAFormHandler()

  const formik = useFormik<CompanyWorldFields>({
    initialValues: {
      prepayment: Prepayment.unknown,
      state_reg_number: '',
      payment_delay_days: 0,
      name: null,
      inn: null,
      soy_restriction: false,
      commercial: CompanyCommercial.unknown,
      parent: parentCompanyId,
    },

    onSubmit: formHandler(async () => await apiClient.post<CompanyWorldFields>(endpoints.company(), formik.values), {
      onSuccess: async () => {
        await refreshCompanies(true)
        onClose()
      },
      onError: (error) => {
        const { errors } = error
        formik.setErrors(errors)
      },
    }),
  })

  return (
    <ASharedModal id={AnalyticsPlaces.DETAILS_MODAL} onClose={onClose}>
      <SharedModalTitle>{t('commonInfo')}</SharedModalTitle>
      <SharedModalContent>
        <SharedModalInputs>
          <CommonFields.NameCompany formik={formik} />
          <CommonFields.PersonalNumber formik={formik} />
          {isBrazil && <CommonFields.RegNumber formik={formik} />}
          <CommonFields.PrepaymentState formik={formik} />
          <CommonFields.PaymentDelayDays formik={formik} />
          {isBrazil && <CommonFields.SoyRestriction formik={formik} />}
          {isUS && <CommonFields.CommercialField formik={formik} />}
        </SharedModalInputs>
      </SharedModalContent>

      <ADefaultModalFooter
        confirmButtonText={t('common:save')}
        onConfirm={formik.handleSubmit}
        progress={formProgress}
        onClose={onClose}
      />
    </ASharedModal>
  )
}
