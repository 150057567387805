import { urlMaker } from 'modules/utils/urlMaker'

/* TODO: возмжно нужно избавиться от понятия "KR" */
export const LogisticsKR = {
  requests: urlMaker('/new-logistics/requests/'),
  requestStatus: (requestId: string) => `/new-logistics/requests/${requestId}/change_status/`,
  tripsForDeal: (requestId: string) => `/new-logistics/trips/?request=${requestId}`,
  trips: urlMaker('/new-logistics/trips/'),
  getRequestTtnList: (requestId: string) => `/new-logistics/requests/${requestId}/trip-ttn-documents/`,
  downloadAllTtn: (requestId: string) => `/new-logistics/requests/${requestId}/download-ttn-documents/`,
  tripsWithoutTtn: () => '/new-logistics/trips/ttn-not-uploaded/',
  draftTrips: (bidId?: string) => (bidId ? `new-logistics/trips/?status=on_approval&request=${bidId}` : ''),
  approvedTrips: (bidId?: string) => (bidId ? `new-logistics/trips/?status=approved&request=${bidId}` : ''),
  tripDocuments: (tripId: string, documentType?: 'ttn_1' | 'ttn_2') =>
    `/new-logistics/trips/${tripId}/documents/${documentType ? `?document_type=${documentType}` : ''}`,
  tripsDocument: (tripId: string, docId: string) => `/new-logistics/trips/${tripId}/documents/${docId}/`,
  products: urlMaker('/new-logistics/products/'),
  loadingWarehouse: urlMaker('/new-logistics/warehouses/loading/'),
  unloadingWarehouse: urlMaker('/new-logistics/warehouses/unloading/'),
  trailersByCarrier: (carrierId) => `/new-logistics/trailers/?carrier=${carrierId}`,
  activeTrailers: () => `/new-logistics/trailers/?status=active`,
  trailers: urlMaker(`/new-logistics/trailers/`),
  trailerChangeStatus: (id) => `/new-logistics/trailers/${id}/change_status/`,
  driversByCarrier: (carrierId: string, carId?: string) =>
    `/new-logistics/drivers/?carrier=${carrierId}${carId ? `&car=${carId}` : ''}`,
  drivers: urlMaker('/new-logistics/drivers/'),
  driverDocuments: (driverId: number) => `/new-logistics/drivers/${driverId}/documents/`,
  driverDocument: (driverId: number, documentId: string) =>
    `/new-logistics/drivers/${driverId}/documents/${documentId}/`,
  driverDocumentTypes: (driverId: number) => `/new-logistics/drivers/${driverId}/document_types/`,
  car: urlMaker('/new-logistics/cars/'),
  carStatus: (carId) => `new-logistics/cars/${carId}/change_status/`,
  carFiles: (carId: string, fileId?: string) => `/logistics/cars/${carId}/files/${fileId ? `${fileId}/` : ''}`,
  carrierTrailers: (carrierId: string) => `/logistics/carriers/${carrierId}/trailers/`,
  carriers: urlMaker('/new-logistics/carriers/'),
  carrierComments: (carrierId: string) => `/new-logistics/carriers/${carrierId}/comments/`,
  findCarrierByInn: (inn: string) => `/new-logistics/carriers/?search=${inn}`,
  carrierCars: (carrierId: string) => `/new-logistics/cars/?carrier=${carrierId}`,
  carrierTrailersV2: (carrierId: string, carId?: string) =>
    `/new-logistics/trailers/?carrier=${carrierId}${carId ? `&car=${carId}` : ''}`,
  carrierStatus: (carrierId: string) => `/new-logistics/carriers/${carrierId}/change_status/`,
  carrierDocuments: (carrierId: string) => `/new-logistics/carriers/${carrierId}/documents/`,
  carrierDocument: (carrierId: string, documentId: string) =>
    `/new-logistics/carriers/${carrierId}/documents/${documentId}/`,
  carrierDocumentsTypes: (carrierId: string) => `/new-logistics/carriers/${carrierId}/document_types/`,
  carrierFiles: (carrierId: string) => `/new-logistics/carriers/${carrierId}/files/`,
  carrierFile: (carrierId: string, fileId: string) => `/new-logistics/carriers/${carrierId}/files/${fileId}/`,
  carrierEmployees: (carrierId: string) => `/new-logistics/carriers/${carrierId}/employees/`,

  customers: urlMaker('/new-logistics/customers/'),
  requestsWithStatus: (status: string) => `/new-logistics/requests/?logistics_status=${status}`,
  involvedCars: (requestId: string) => `/new-logistics/requests/${requestId}/involved-cars/`,
  relevantCars: (requestId?: string) => (requestId ? `/new-logistics/requests/${requestId}/relevant-cars/` : ''),
  activeBidTripOffers: (bidId?: string) => `/new-logistics/trip-offers/?request=${bidId}`,
  tripOffers: () => '/new-logistics/trip-offers/',
  tripOfferClose: (tripOfferId: string) => `/new-logistics/trip-offers/${tripOfferId}/close/`,
  ratesWithRegion: () => '/logistics/rates/rates-with-region/',
  ratesWithoutRegion: () => '/logistics/rates/rates-without-region/',
  ratesWithoutRegionAndProductType: urlMaker('/logistics/rates/rates-without-region-and-product-type/'),
  relatedRates: () => '/logistics/rates/related-logistics-rates/',
  averageRates: () => '/logistics/rates/rates-without-region-and-product-type/default-logistics-rates/',
  addCompanyEmployee: (companyId: string) => `/new-logistics/carriers/${companyId}/employees/`,

  activePaymentOrders: () => `/new-logistics/payment-orders/?order_type=active`,
  archivedPaymentOrders: () => `/new-logistics/payment-orders/?order_type=archived`,
}
