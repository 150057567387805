import { AntdTabsProps, Text } from '@agro-club/agroclub-shared'
import { CommentType } from 'modules/domain/comment/types'
import { Address, ListRequestParams } from 'modules/domain/types'
import { endpoints } from 'modules/endpoints'
import { Dispatch, FC, SetStateAction } from 'react'
import { useTranslation } from 'react-i18next'
import { AAntdTabs } from 'views/components/Analytics'
import AComments from 'views/components/Comments/AComments'
import { NotFound } from '../components/NotFound'
import { CallHistory } from './CallHistory/CallHistory'
import { Offers } from './Offers/Offers'
import { ShowPrices } from './ShowPrices/ShowPrices'

type Props = {
  companyAddress: Address
  companyId: string
  companyMemberTypes: string[]
  listParamsUpdated: Dispatch<SetStateAction<ListRequestParams>>
  listRequestParams: ListRequestParams
}

export const Tabs: FC<Props> = ({
  companyAddress,
  companyId,
  companyMemberTypes,
  listParamsUpdated,
  listRequestParams,
}) => {
  const { t } = useTranslation(['common', 'bids', 'accountManagement'])
  const hasSeller = companyMemberTypes.includes('seller')
  const items: AntdTabsProps['items'] = [
    {
      label: t('common:comments'),
      key: 'comments',
      children: (
        <AComments
          onSuccess={() => listParamsUpdated({ ...listRequestParams })}
          url={endpoints.companyComments(companyId)}
          type={CommentType.company}
          maxHeight={400}
          id="company"
        />
      ),
    },
    {
      label: t('bid:bids'),
      key: 'offers',
      children: <Offers companyId={companyId} />,
    },
    {
      label: t('user:showPrices'),
      key: 'showPrices',
      children: companyAddress ? (
        <ShowPrices companyId={companyId} address={companyAddress} />
      ) : (
        <NotFound>
          <Text typography="bodyLarge">{t('accountManagement:drawer.companyNoAddress')}</Text>
        </NotFound>
      ),
    },
    {
      label: t('accountManagement:drawer.callHistory'),
      key: 'calls',
      children: <CallHistory companyId={companyId} />,
    },
  ]

  const resultItems = hasSeller ? items : items.filter((item) => item.key !== 'showPrices')

  return <AAntdTabs id="account-manager-drawer-tabs" items={resultItems} defaultActiveKey="comments" />
}
