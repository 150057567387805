import { useDisplayClearBtn } from 'hooks/useDisplayClearBtn'
import { PAGE_SIZE_1K } from 'modules/constants'
import { ListRequestParams } from 'modules/domain/types'
import { Dispatch, FC, SetStateAction } from 'react'
import { AClearFiltersButton } from 'views/components/Analytics'
import { AssigneesFilter } from 'views/components/AssigneesFilter/AssigneesFilter'
import { FiltersWrapper } from 'views/components/CommonTableComponents/CommonTableComponents'
import { CompaniesFilter } from 'views/components/CompaniesFilter/CompaniesFilter'
import { defaultListRequestParams } from 'views/components/TableFilters/data'
import { NewTeamFilter } from 'views/components/TeamFilter/NewTeamFilter'
import { MatchesGeneratedFilter } from './components/MatchesGeneratedFilter'
import { MatchesRegionFilter } from './components/MatchesRegionFilter'
import { MatchesSortFilter } from './components/MatchesSortFilter'
import { MatchesUserStatusFilter } from './components/MatchesUserStatusFilter'

type Props = {
  listRequestParams: ListRequestParams
  updateFilterState: (filterObj: Record<string, any>) => void
  setListRequestParams: Dispatch<SetStateAction<ListRequestParams>>
}

export const MatchesFilters: FC<Props> = ({ listRequestParams, updateFilterState, setListRequestParams }) => {
  const displayClearBtn = useDisplayClearBtn({ listRequestParams })

  return (
    <FiltersWrapper>
      <NewTeamFilter value={listRequestParams.filter.team} updateFilterState={updateFilterState} />
      <MatchesRegionFilter listRequestParams={listRequestParams} updateFilterState={updateFilterState} />
      <AssigneesFilter value={listRequestParams.filter.assignee} updateFilterState={updateFilterState} />
      <CompaniesFilter value={listRequestParams.filter.company} updateFilterState={updateFilterState} />
      <MatchesSortFilter listRequestParams={listRequestParams} setListRequestParams={setListRequestParams} />
      <MatchesUserStatusFilter
        filterKey="is_user_significant"
        listRequestParams={listRequestParams}
        updateFilterState={updateFilterState}
      />
      <MatchesUserStatusFilter
        filterKey="is_seller_significant"
        listRequestParams={listRequestParams}
        updateFilterState={updateFilterState}
      />
      <MatchesGeneratedFilter listRequestParams={listRequestParams} updateFilterState={updateFilterState} />

      {displayClearBtn && (
        <AClearFiltersButton
          onClick={() => setListRequestParams({ ...defaultListRequestParams, pageSize: PAGE_SIZE_1K })}
        />
      )}
    </FiltersWrapper>
  )
}
