import { AntTag, Text } from '@agro-club/agroclub-shared'
import { isBidContractsEnabled, isRussia, isUS } from 'env'
import useFormatDate from 'hooks/useFormatDate'
import { DealStatusTypes, ListDeal, ListDealBid } from 'modules/domain/deal/types'
import { CompanyMembersTypes } from 'modules/domain/user/types'
import formatPrice from 'modules/utils/numbers/formatPrice'
import { formatQuantity } from 'modules/utils/numbers/formatQuantity'
import { FC, ReactNode } from 'react'
import { useTranslation } from 'react-i18next'
import { generatePath, useNavigate, useParams } from 'react-router'
import styled from 'styled-components'
import { DeliveryPeriodData } from 'views/components/DealCard/DeliveryPeriod'
import { DefaultTooltip } from 'views/components/DefaultTooltip/DefaultTooltip'
import {
  CompanyFactoringIcon,
  CoordinatorVerifiedUserIcon,
  KeyEntityIcon,
  KycVerifiedUserIcon,
  SecurityVerifiedUserIcon,
  UserHasDealsIcon,
  VerifiedUserIcon,
} from 'views/components/TooltipIcons/TooltipIcons'
import { FormulaTooltip } from '../components/FormulaTooltip'
import { DealTags } from '../DealDetail/DealTags/DealTags'
import DealRoutes from '../routes'
import {
  ActualMargin,
  CardWrapper,
  Company,
  CompanyWrapper,
  FixedMargin,
  Link,
  MarginOnWay,
  NoBid,
  PriceWrapper,
  Product,
  SignedMargin,
  StatusesWrapper,
  StatusIcons,
} from './styled'

type Props = {
  deal: ListDeal
  activeTab: string
}

const NoBidBlock = ({ children }) => {
  if (!isBidContractsEnabled) return null
  return <NoBid>{children}</NoBid>
}

export const CompanyTooltip = styled(DefaultTooltip).attrs({ overlayInnerStyle: { maxWidth: '320px' } })(() => '')

export const StatusCompany = (bid: ListDealBid, profileType: CompanyMembersTypes) => {
  return (
    <StatusIcons>
      <KeyEntityIcon isVisible={bid.owner_is_significant} />
      <VerifiedUserIcon isVisible={bid.owner_is_verified} />
      <CoordinatorVerifiedUserIcon isVisible={bid.owner_is_verified_by_coordinator && !bid.owner_is_verified} />
      <SecurityVerifiedUserIcon isVisible={bid.owner_is_verified_by_security && !bid.owner_is_verified} />
      <KycVerifiedUserIcon isVisible={bid.owner_is_verified_by_kyc && !bid.owner_is_verified} />
      <UserHasDealsIcon isVisible={bid.owner_has_shipped_deals} />
      <CompanyFactoringIcon company={bid.company} profileType={profileType} />
    </StatusIcons>
  )
}
export const DealCard: FC<Props> = ({ deal, activeTab }) => {
  const { t } = useTranslation('deal')
  const formatDate = useFormatDate()

  const isFixedMarginVisible = [
    DealStatusTypes.DOCUMENTS_ACCEPTED,
    DealStatusTypes.SHIPMENT_COMPLETE,
    DealStatusTypes.PAYMENT_SHIPMENT,
    DealStatusTypes.PAYMENT_COMPLETE,
    DealStatusTypes.PICK_UP_COMPLETE,
    DealStatusTypes.CONTRACT_SIGNED,
  ].includes(deal.status as DealStatusTypes)

  const navigate = useNavigate()

  // write to the browser history url with the id of selected deal
  const addIdActiveDealToUrl = () => {
    navigate(
      {
        pathname: generatePath(DealRoutes.ListWithTabAndDeal, { activeTab: activeTab, selectedDeal: deal.id }),
        search: location.search,
      },
      { replace: true },
    )
  }

  let reasonTag: ReactNode = null
  if (deal.closed_reason) {
    reasonTag = (
      <AntTag style={{ marginBottom: 8, alignSelf: 'flex-start' }} color="grey">
        {`${t('isClosed')} / ${t(`closed_reasons.${deal.closed_reason}`)}`}
      </AntTag>
    )
  }

  const { selectedDeal: selectedDealParams } = useParams()
  const activedeal = Number(selectedDealParams) === Number(deal.id)

  return (
    <Link
      eventName="deal"
      itemId={deal.id}
      to={generatePath(DealRoutes.Details, { id: deal.id })}
      onClick={addIdActiveDealToUrl}
    >
      <CardWrapper
        id={activedeal ? `${selectedDealParams}active` : ''}
        activeCard={activedeal}
        isExport={!!deal.export_contract_id}
      >
        {reasonTag}
        {deal.sale_bid ? (
          <CompanyWrapper>
            <CompanyTooltip tooltipContent={`${t('seller')} ${deal.sale_bid.company.name}`}>
              <Company>{deal.sale_bid.company.name}</Company>
            </CompanyTooltip>
            <StatusesWrapper>
              {StatusCompany(deal.sale_bid, CompanyMembersTypes.seller)}
              {deal.sale_contract && (
                <Text color="secondary" typography="bodyMedium">{`№${deal.sale_contract.number}`}</Text>
              )}
            </StatusesWrapper>
            {deal.sale_contract && (
              <Text color="secondary" typography="bodyMedium">
                {`${t('bid:contracts.sellerPaymentDate')} ${formatDate(deal.sale_contract.payment_due_date)}`}
              </Text>
            )}
          </CompanyWrapper>
        ) : (
          <NoBidBlock>{t('rematch.noSeller')}</NoBidBlock>
        )}
        {deal.purchase_bid ? (
          <CompanyWrapper>
            <CompanyTooltip tooltipContent={`${t('buyer')} ${deal.purchase_bid.company.name}`}>
              <Company>{deal.purchase_bid.company.name}</Company>
            </CompanyTooltip>
            <StatusesWrapper>
              {StatusCompany(deal.purchase_bid, CompanyMembersTypes.customer)}
              {deal.purchase_contract && (
                <Text color="secondary" typography="bodyMedium">{`№${deal.purchase_contract.number}`}</Text>
              )}
            </StatusesWrapper>
            {deal.purchase_contract && (
              <Text color="secondary" typography="bodyMedium">
                {`${t('bid:contracts.purchasePaymentDate')} ${formatDate(deal.purchase_contract.payment_due_date)}`}
              </Text>
            )}
          </CompanyWrapper>
        ) : (
          <NoBidBlock>{t('rematch.noBuyer')}</NoBidBlock>
        )}

        <PriceWrapper>
          <FormulaTooltip
            title={t('expected_margin')}
            numberFormula={deal.margin_remaining_formula}
            textFormula={deal.margin_remaining_tooltip}
          >
            <SignedMargin isRed={deal.margin_percent < deal.target_margin_percent}>
              {`${formatPrice(deal.margin_remaining, true, false)} / ${deal.margin_percent}%`}
            </SignedMargin>
          </FormulaTooltip>
          <FormulaTooltip
            title={t('margin_on_way')}
            // numberFormula={deal.margin_on_way_formula}
            textFormula={deal.margin_on_way_tooltip}
          >
            <MarginOnWay>{formatPrice(deal.margin_on_way, true, false)}</MarginOnWay>
          </FormulaTooltip>

          {isRussia ? (
            <DefaultTooltip tooltipContent={t('contributionActual')}>
              <ActualMargin>
                {`${formatPrice(deal.actual_contribution_margin ?? 0, true, false)} / ${
                  deal.actual_contribution_margin_rate ?? 0
                }%`}
              </ActualMargin>
            </DefaultTooltip>
          ) : (
            <DefaultTooltip tooltipContent={t('actual_margin')}>
              <ActualMargin>
                {`${formatPrice(deal.actual_margin, true, false)} / ${deal.actual_margin_percent}%`}
              </ActualMargin>
            </DefaultTooltip>
          )}

          {isFixedMarginVisible && (
            <FormulaTooltip
              title={t('was_in_contract')}
              numberFormula={deal.margin_on_contract_signed_formula}
              textFormula={deal.margin_on_contract_signed_tooltip}
            >
              <FixedMargin>
                {`${formatPrice(deal.margin_on_contract_signed, true, false)} / ${
                  deal.margin_percent_on_contract_signed
                }%`}
              </FixedMargin>
            </FormulaTooltip>
          )}
        </PriceWrapper>

        <DefaultTooltip
          tooltipContent={[t('product_volume'), deal.season ? t('season').toLowerCase() : null]
            .filter(Boolean)
            .join(', ')}
        >
          <Product>
            {`${deal.product.title}, ${formatQuantity(deal.quantity)}`}
            {deal.season && `, ${deal.season.name}`}
          </Product>
        </DefaultTooltip>
        {isUS && (
          <DefaultTooltip tooltipContent={t('fields.deliveryPeriod')}>
            <DeliveryPeriodData data={deal} />
          </DefaultTooltip>
        )}
        <DealTags deal={deal} />
      </CardWrapper>
    </Link>
  )
}
