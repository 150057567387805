import { useHelmet } from '@agro-club/agroclub-shared'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import APageWrapper from 'views/components/PageWrapper/APageWrapper'
import * as Layout from 'views/layouts/NewLayout'

const Iframe = styled.iframe`
  height: 100vh;
  border: 0px;
`

export const ReportPage: FC<{ reportUrl: string }> = ({ reportUrl }) => {
  const { t } = useTranslation(['menu'])
  useHelmet({ title: t('dpr') })
  return (
    <APageWrapper page="matches" place="Matches">
      <Layout.WrapperContent>
        <Iframe title={'Mode report'} src={reportUrl} width="100%" />
      </Layout.WrapperContent>
    </APageWrapper>
  )
}
