import { useAProgress } from 'hooks/useAProgress'
import { refetchFunc, useSingleEntity } from 'modules/domain/common/hooks'
import * as managers from 'modules/domain/common/managers'
import { DocumentFile, DocumentType, DocumentWithTypeFile } from 'modules/domain/types'
import { endpoints } from 'modules/endpoints'
import { apiClient } from 'modules/utils/httpClient'
import { FC } from 'react'
import { AFilesByTypes } from 'views/components/FilesByTypes/AFilesByTypes'

interface Props {
  exportDocsRefetch: refetchFunc
  exportDocuments: DocumentFile[]
  exportId: string
}

export const Documents: FC<Props> = ({ exportId, exportDocuments, exportDocsRefetch }) => {
  const [, exportDocumentTypes = []] = useSingleEntity<DocumentType[]>(endpoints.exportDocumentsTypes(exportId))

  const [uploadProgress, upload] = useAProgress<DocumentWithTypeFile>(
    (documentType: DocumentType['slug'], file: File) =>
      managers.uploadDocuments(endpoints.exportDocuments(exportId), documentType, file),
    {
      eventName: 'uploadDocument',
      onSuccess: () => exportDocsRefetch(),
    },
  )

  const [deleteProgress, deleteDoc] = useAProgress<unknown>(
    (documentId: string) => apiClient.delete(endpoints.exportDeleteDocument(exportId, documentId)),
    {
      eventName: 'deleteDocument',
      onSuccess: () => exportDocsRefetch(),
    },
  )

  return (
    <AFilesByTypes
      types={exportDocumentTypes}
      files={exportDocuments}
      onUpload={upload}
      onDelete={deleteDoc}
      uploadProgress={uploadProgress}
      deleteProgress={deleteProgress}
    />
  )
}
