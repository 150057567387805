import { getUserName, getUserPhone } from 'modules/domain/user/utils'
import { WarehouseMovement } from 'modules/domain/warehouses/types'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { generatePath } from 'react-router-dom'
import { ARouterLink } from 'views/components/Analytics'
import { KeyValue } from 'views/components/KeyValue/KeyValue'
import {
  CompanyFactoringIcon,
  KeyEntityIcon,
  UserHasDealsIcon,
  VerifiedUserIcon,
} from 'views/components/TooltipIcons/TooltipIcons'
import { TruncatedValue } from 'views/components/TruncatedValue/TruncatedValue'
import CompanyRoutes from 'views/pages/Company/routes'
import UserRoutes from 'views/pages/User/routes'
import { EntityWithIconsWrapper } from 'views/styled/common'
import APhone from 'views/ui/Phone/APhone'

type Props = {
  whMovement: WarehouseMovement
}

export const WhMovementDetailCompanyUser: FC<Props> = ({ whMovement }) => {
  const { t } = useTranslation('warehouses')
  const company = whMovement.warehouse.owner.profile?.company
  if (!company) return
  const companyPersonalNumber = `${t('common:inn')} ${company.inn || t('common:notSpecified')}`
  return (
    <KeyValue label={t('customerWarehouse')}>
      <EntityWithIconsWrapper>
        <TruncatedValue hint={company.name}>
          <ARouterLink
            eventName="company"
            itemId={company.id}
            to={generatePath(CompanyRoutes.Details, { id: company.id })}
          >
            {company.name}
          </ARouterLink>
        </TruncatedValue>
        <CompanyFactoringIcon company={company} />
      </EntityWithIconsWrapper>
      <div>{companyPersonalNumber}</div>

      <EntityWithIconsWrapper>
        <TruncatedValue hint={getUserName(whMovement.warehouse.owner)}>
          <ARouterLink
            eventName="user"
            itemId={whMovement.warehouse.owner.id}
            to={generatePath(UserRoutes.Details, { id: String(whMovement.warehouse.owner.id) })}
          >
            {getUserName(whMovement.warehouse.owner)}
          </ARouterLink>
        </TruncatedValue>
        <KeyEntityIcon isVisible={whMovement.warehouse.owner.profile?.is_significant} />
        <VerifiedUserIcon isVisible={whMovement.warehouse.owner.profile?.is_verified} />
        <UserHasDealsIcon isVisible={whMovement.warehouse.owner.profile?.has_shipped_deals} />
      </EntityWithIconsWrapper>

      <APhone phone={getUserPhone(whMovement.warehouse.owner)} />
    </KeyValue>
  )
}
