import { useHelmet } from '@agro-club/agroclub-shared'
import { useFilters } from 'hooks/useFilter'
import { PAGE_SIZE_1K } from 'modules/constants'
import { useTableData } from 'modules/domain/common/hooks'
import { DashboardTask } from 'modules/domain/dashboard/types'
import { ListRequestParams } from 'modules/domain/types'
import { endpoints } from 'modules/endpoints'
import { FC, useState } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { Count } from 'views/components/Board/styled'
import APageWrapper from 'views/components/PageWrapper/APageWrapper'
import { defaultListRequestParams } from 'views/components/TableFilters/data'
import * as Layout from 'views/layouts/NewLayout'
import * as Header from 'views/ui/Header/Header'
import { MatchesCardList } from './MatchesCardList'
import { MatchesFilters } from './MatchesFilters'

const TitleWithCountWrapper = styled.div`
  display: flex;
  align-items: center;
  column-gap: 4px;
`

export const MatchesList: FC = () => {
  const { t } = useTranslation('matches')
  const [listRequestParams, setListRequestParams] = useState<ListRequestParams>({
    ...defaultListRequestParams,
    pageSize: PAGE_SIZE_1K,
  })

  const { updateFilterState, isFiltersLoaded } = useFilters({
    listRequestParams,
    setListRequestParams,
    defaultParams: { is_user_significant: true, is_seller_significant: true },
  })

  const { data, progress, total, refetch } = useTableData<DashboardTask>(
    endpoints.dashboardMatches(),
    listRequestParams,
    isFiltersLoaded,
  )

  useHelmet({ title: t('title') })

  return (
    <APageWrapper page="matches" place="Matches">
      <Layout.WrapperContent>
        <Layout.Header>
          <Layout.TopHeader>
            <Header.Breadcrumbs
              routes={[
                {
                  breadcrumbName: t('title'),
                },
              ]}
            />
          </Layout.TopHeader>
          <Layout.PageName>
            <TitleWithCountWrapper>
              {t('title')} <Count color="orange">{total}</Count>
            </TitleWithCountWrapper>
          </Layout.PageName>
          <MatchesFilters
            listRequestParams={listRequestParams}
            updateFilterState={updateFilterState}
            setListRequestParams={setListRequestParams}
          />
        </Layout.Header>
        <MatchesCardList progress={progress} data={data} refetch={refetch} />
      </Layout.WrapperContent>
    </APageWrapper>
  )
}
