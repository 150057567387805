import { User } from 'modules/domain/user/types'
import {
  CoordinatorVerifiedUserIcon,
  KeyEntityIcon,
  KycVerifiedUserIcon,
  SecurityVerifiedUserIcon,
  UserHasDealsIcon,
  VerifiedUserIcon,
} from 'views/components/TooltipIcons/TooltipIcons'
import { TruncatedValue } from 'views/components/TruncatedValue/TruncatedValue'
import { UserLink } from 'views/components/UserLink/UserLink'
import { EntityWithIconsWrapper } from 'views/styled/common'

type Props = {
  user: User
  to?: string
  isSignificant?: boolean
  isVerifiedByCoordinator?: boolean
  isVerifiedBySecurity?: boolean
  isVerified?: boolean
  isVerifiedByKyc?: boolean
  hasShippedDeals?: boolean
}

export const UserLinkWithIcons = ({
  user,
  to,
  isSignificant,
  isVerifiedByCoordinator,
  isVerifiedByKyc,
  isVerifiedBySecurity,
  isVerified,
  hasShippedDeals,
}: Props) => {
  return (
    <EntityWithIconsWrapper>
      <TruncatedValue hint={user.profile?.full_name}>
        <UserLink user={user} to={to} />
      </TruncatedValue>
      <VerifiedUserIcon isVisible={isVerified} />
      <CoordinatorVerifiedUserIcon isVisible={isVerifiedByCoordinator} />
      <SecurityVerifiedUserIcon isVisible={isVerifiedBySecurity} />
      <UserHasDealsIcon isVisible={hasShippedDeals} />
      <KycVerifiedUserIcon isVisible={isVerifiedByKyc} />
      <KeyEntityIcon isVisible={isSignificant} />
    </EntityWithIconsWrapper>
  )
}
