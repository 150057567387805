import { refetchFunc } from 'modules/domain/common/hooks'
import { User } from 'modules/domain/user/types'
import { useState } from 'react'
import { SignificantVerifyAction } from 'views/components/EntityVerifyActions/SignificantVerifyAction'
import { SignificantConfirmModal } from './ConfirmModals/SignificantConfirmModal'

type Props = {
  userRefetch: refetchFunc
  user: User
}

export const KeyUserVerification = ({ userRefetch, user }: Props) => {
  const [confirmSignificantModal, setConfirmSignificantModal] = useState(false)

  if (!user.profile || user.profile.is_significant === undefined) return null

  return (
    <>
      <SignificantVerifyAction
        isVerified={user.profile.is_significant}
        onClick={() => setConfirmSignificantModal(true)}
      />

      {confirmSignificantModal && (
        <SignificantConfirmModal
          onClose={() => setConfirmSignificantModal(false)}
          refetch={userRefetch}
          userId={user.id}
        />
      )}
    </>
  )
}
