import { Progress } from '@agro-club/agroclub-shared'

import { FC } from 'react'
import styled from 'styled-components'
import { Spinner } from 'views/components/CommonTableComponents/CommonTableComponents'
import { NoData } from 'views/components/NoData/NoData'
import { TaskBidItem } from 'views/pages/Dashboard/components/Cards/CardItems/TaskBidItem'
import { CustomScroll } from 'views/styled/common'
import { DashboardTabItemProps } from '../../types'
import { TaskDealItem } from './CardItems/TaskDealItem'

interface Props extends DashboardTabItemProps {
  variant: 'bid' | 'deal'
}

export const SpinnerWrapper = styled.div`
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`

const Scrollable = styled.div`
  overflow-y: auto;
  height: calc(100dvh - 230px);
  ${CustomScroll}
`

export const CardsWrapper = styled.div`
  display: grid;
  grid-gap: 24px;
  grid-template-columns: repeat(auto-fit, 330px);
`

export const CardList: FC<Props> = ({ progress, data, variant, fetchFunc: refetch }) => {
  const Component = variant === 'bid' ? TaskBidItem : TaskDealItem

  if (progress === Progress.WORK)
    return (
      <SpinnerWrapper>
        <Spinner />
      </SpinnerWrapper>
    )

  return !data?.length ? (
    <NoData />
  ) : (
    <Scrollable>
      <CardsWrapper>
        {data?.map((task) => (
          <Component key={task.id} task={task} refetch={refetch} />
        ))}
      </CardsWrapper>
    </Scrollable>
  )
}
