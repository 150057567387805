import ATableFullPage from 'views/components/Table/ATableFullPage'
import { endpoints } from 'modules/endpoints'
import { useParams } from 'react-router-dom'
import { useTripOffersColumns } from 'views/pages/LogisticsKR/LogisticRequests/RequestDetails/components/RequestTripsTables/TripOffersSection/useTripOffersColumns'
import { ListRequestParams } from 'modules/domain/types'
import { LogisticRequest } from 'modules/domain/logisticsKR/types'
import { defaultListRequestParams } from 'views/components/TableFilters/data'
import { TripOfferStatuses } from 'views/pages/LogisticsKR/types'
import { useCallback } from 'react'
import { useTranslation } from 'react-i18next'

type Props = {
  draftTripsRefetch: () => void
  request: LogisticRequest
  listRequestParams: ListRequestParams
  setListRequestParams: (params: ListRequestParams) => void
}

export const TripOffersTable = ({ listRequestParams, setListRequestParams, request, draftTripsRefetch }: Props) => {
  const { id } = useParams()
  const { t } = useTranslation('tableNoDataText')

  const tripOffersRefetch = useCallback(() => {
    setListRequestParams({
      ...defaultListRequestParams,
      filter: { status: TripOfferStatuses.new },
    })
  }, [setListRequestParams])

  const columns = useTripOffersColumns({ request, tripOffersRefetch, draftTripsRefetch })

  return (
    <ATableFullPage
      columns={columns}
      dataEndpoint={endpoints.LogisticsKR.activeBidTripOffers(id)}
      listRequestParams={listRequestParams}
      listParamsUpdated={setListRequestParams}
      rowHoverGray
      isFiltersLoaded={true}
      maxHeight={736}
      outerBordered
      noDataText={t('trip_offers')}
    />
  )
}
