const main = 'companies'
const relativeRoutes = {
  relativePage: `${main}/*`,
  relativeList: `${main}`,
  relativeAdd: 'add',
  relativeDetail: ':id',
  relativeEdit: ':id/edit',
  relativeDetailWithBranch: ':id/:branchId',
}

const routes = {
  List: `/${main}`,
  Details: `/${main}/${relativeRoutes.relativeDetail}`,
  DetailsWithBranch: `/${main}/${relativeRoutes.relativeDetailWithBranch}`,
  Edit: `/${main}/${relativeRoutes.relativeEdit}`,
}

const CompanyRoutes = { ...relativeRoutes, ...routes }

export default CompanyRoutes
