import { Icons } from '@agro-club/agroclub-shared'
import { useAnalyticsContext } from 'analytics/hooks'
import { Tab, useTab, useTabsDataV2 } from 'hooks/useTabsDataV2'
import { WarehousesCondition } from 'modules/domain/warehouses/types'
import { endpoints } from 'modules/endpoints'
import { FC, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { generatePath, useNavigate } from 'react-router'
import { useParams } from 'react-router-dom'
import { AAntdTabs, AButton } from 'views/components/Analytics'
import APageWrapper from 'views/components/PageWrapper/APageWrapper'
import ATableFullPage from 'views/components/Table/ATableFullPage'
import * as Layout from 'views/layouts/NewLayout'
import { CreateWarehouseModal } from 'views/pages/Warehouses/List/CreateWarehouseModal'
import WarehousesRoutes from 'views/pages/Warehouses/routes'
import * as Header from 'views/ui/Header/Header'
import { WarehousesFilters } from '../Filters/WarehousesFilters'
import { useColumns } from './useColumns'

export const WarehousesList: FC = () => {
  const { t } = useTranslation('warehouses')
  const navigate = useNavigate()
  const { setOptions } = useAnalyticsContext()
  const { activeTab: activeTabParams } = useParams()
  const [activeTab, setActiveTab] = useState<string>(activeTabParams || WarehousesCondition.opened)
  const [addWarehouse, setAddWarehouse] = useState(false)

  const tabs: Record<string, Tab> = {}

  tabs[WarehousesCondition.opened] = useTab({
    label: t('opened'),
    endpoint: endpoints.warehousesActive(),
    columnsFunc: useColumns(),
    nonEditableParams: { filter: { sort: '-created' } },
  })

  tabs[WarehousesCondition.closed] = useTab({
    label: t('closed'),
    endpoint: endpoints.warehousesArchived(),
    columnsFunc: useColumns(),
    nonEditableParams: { filter: { sort: '-created' } },
  })

  const { isFiltersLoaded, commonRequestParams, updateCommonFilterState, clearAllTabsState } = useTabsDataV2({
    activeTab,
    tabs,
    //For fix Warning: A component is changing an uncontrolled input to be controlled.
    initialCommonRequestParams: { filter: { radius: '' } },
  })

  const tabsItems = () => {
    const tabsJsx: any = []
    for (const key of Object.keys(tabs)) {
      tabsJsx.push({
        label: tabs[key].label,
        key,
        children: (
          <ATableFullPage
            listParamsUpdated={tabs[key].setter}
            listRequestParams={tabs[key].params}
            isFiltersLoaded={isFiltersLoaded}
            dataEndpoint={tabs[key].endpoint || ''}
            columns={tabs[key].columnsFunc}
            rowHoverGray
          />
        ),
      })
    }
    return tabsJsx
  }

  const changeRoutes = () => {
    navigate(
      {
        pathname: generatePath(WarehousesRoutes.ListWithTab, { activeTab: activeTab }),
        search: location.search,
      },
      { replace: true },
    )

    setOptions({ place: activeTab })
  }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => changeRoutes(), [activeTab])

  const addModalRefetchHandler = () => updateCommonFilterState(commonRequestParams.filter)

  return (
    <>
      <APageWrapper page="warehousesPage" place="warehousesList">
        <Layout.WrapperContent>
          <Layout.Header>
            <Layout.TopHeader>
              <Header.Breadcrumbs routes={[{ breadcrumbName: t('title') }]} />

              <AButton id="add" intent="approve" Icon={Icons.IconAdd} onClick={() => setAddWarehouse(true)}>
                {t('common:add')}
              </AButton>
            </Layout.TopHeader>

            <Layout.PageName>{t('title')}</Layout.PageName>

            <WarehousesFilters
              listRequestParams={commonRequestParams}
              updateFilterState={updateCommonFilterState}
              clearAllTabsState={clearAllTabsState}
            />
          </Layout.Header>
          <AAntdTabs items={tabsItems()} onChange={setActiveTab} activeKey={activeTab} id="tabs" />
        </Layout.WrapperContent>
      </APageWrapper>
      {addWarehouse && (
        <CreateWarehouseModal onSuccess={addModalRefetchHandler} onClose={() => setAddWarehouse(false)} />
      )}
    </>
  )
}
