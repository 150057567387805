import { Icons, Progress } from '@agro-club/agroclub-shared'
import { DASH } from 'modules/constants'
import { useSingleEntity } from 'modules/domain/common/hooks'
import { Company } from 'modules/domain/company/types'
import { User } from 'modules/domain/user/types'
import { endpoints } from 'modules/endpoints'
import { FC, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { generatePath } from 'react-router'
import styled from 'styled-components'
import { AButton, AExternalLink, ARouterLink } from 'views/components/Analytics'
import { Card } from 'views/components/Card/Card'
import { KeyValue } from 'views/components/KeyValue/KeyValue'
import { UserLink } from 'views/components/UserLink/UserLink'
import UserRoutes from 'views/pages/User/routes'
import { UserProfileTag } from 'views/pages/User/UserDetail/HeaderSubTitleValue'
import { VatComponent } from '../../CompanyDetail/CompanyGeneral/CompanyGeneral'
import { EditCompanyControl } from '../../CompanyDetail/EditCompanyControl'
import { CompanySpinner } from '../BranchList'
import { AddUserModal } from './AddUserModal'
import { KeyCompanyAction } from './VerifyActions/KeyCompanyAction'

type Props = {
  company: Company
  refetch: () => void
}

const Title = styled(Card.Title)`
  margin-bottom: 16px;
`
const HeaderCard = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`
const ContentCard = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`

const AddUserButton = styled(AButton)`
  margin: 16px auto 0 0;
`

const StyledHorizontalDivider = styled(Card.HorizontalDivider)`
  margin: 16px 0;
`

const VerifyActionsWrapper = styled.div`
  display: flex;
  column-gap: 24px;
`

export const GeneralInfo: FC<Props> = ({ company, refetch }) => {
  const { t } = useTranslation(['company', 'user'])

  const [companyEditModalOpen, setCompanyEditModalOpen] = useState(false)
  const [addUserModalOpen, setAddUserModalOpen] = useState(false)

  const [userProgress, users = [], refetchUsers] = useSingleEntity<User[]>(endpoints.activeEmployees(company.id))
  const allRefetch = () => {
    refetch()
    refetchUsers()
  }
  return (
    <>
      <Card.GapableContent>
        <Card.Container bordered>
          <Title>{t('commonInfo')}</Title>
          <Card.Grid cols={5} columnGap={16} maxColumnWidth="auto">
            <KeyValue label={t('common:inn')}>{company.inn || DASH}</KeyValue>
            <VatComponent company={company} />
            <KeyValue label={t('form.fields.prepayment')}>{t(`form.prepaymentValues.${company.prepayment}`)}</KeyValue>
            <KeyValue label={t('form.fields.payment_delay_days')}>{company.payment_delay_days}</KeyValue>
            <KeyValue label={t('commercial')}>{t(`commercialStatus.${company.commercial}`)}</KeyValue>
            <KeyValue label={t('responsibleCoordinator')}>
              {company.responsible_coordinator?.full_name ? (
                <ARouterLink
                  eventName="user_responsible_coordinator"
                  itemId={String(company.responsible_coordinator?.id)}
                  to={generatePath(UserRoutes.Details, {
                    id: String(company.responsible_coordinator?.id),
                  })}
                >
                  {company.responsible_coordinator?.full_name || DASH}
                </ARouterLink>
              ) : (
                DASH
              )}
            </KeyValue>
          </Card.Grid>
          <Card.EditButton onClick={() => setCompanyEditModalOpen(true)} />

          <StyledHorizontalDivider />

          <VerifyActionsWrapper>
            <KeyValue label={t('user:feature')}>
              <KeyCompanyAction companyId={company.id} isVerified={company.is_significant} refetchCompany={refetch} />
            </KeyValue>

            {/* Ждем бэк */}
            {/* <KeyValue label={t('irely')}>
              <IrelyAction isVerified={company.synced_with_accounting} />
            </KeyValue> */}
          </VerifyActionsWrapper>
        </Card.Container>

        {userProgress === Progress.WORK || !users ? (
          <CompanySpinner />
        ) : (
          <Card.Container bordered>
            <Title>{t('user:list.heading')}</Title>
            <Card.Grid cols={3} columnGap={16} maxColumnWidth="1fr">
              {users.map((user) => (
                <Card.Container key={user.id} bordered padding="compact">
                  <ContentCard>
                    <HeaderCard>
                      <UserProfileTag user={user} />
                    </HeaderCard>
                    <UserLink user={user} />
                    <AExternalLink eventName="viewUserPhone" href={`tel:${user.phone}`}>
                      {user.phone}
                    </AExternalLink>
                  </ContentCard>
                </Card.Container>
              ))}
            </Card.Grid>

            <AddUserButton id="addUser" color="approve" Icon={Icons.IconPlus} onClick={() => setAddUserModalOpen(true)}>
              {t('addUser')}
            </AddUserButton>
          </Card.Container>
        )}
      </Card.GapableContent>

      {addUserModalOpen && (
        <AddUserModal onClose={() => setAddUserModalOpen(false)} refetch={allRefetch} companyId={company.id} />
      )}

      {companyEditModalOpen && (
        <EditCompanyControl
          setCompanyEditModalOpen={setCompanyEditModalOpen}
          refreshCompany={refetch}
          company={company}
        />
      )}
    </>
  )
}
