import { ResponsiveModal } from 'views/components/ResponsiveModal/ResponsiveModal'
import { SharedModalContent } from 'views/components/SharedModal'
import FormSelectShared from 'views/components/FormSelectShared'
import { apiClient } from 'modules/utils/httpClient'
import { useAFormHandler } from 'analytics/hooks'
import { useTranslation } from 'react-i18next'
import { endpoints } from 'modules/endpoints'
import { useFormik } from 'formik'
import { FC } from 'react'

type Props = {
  onClose: () => void
  refetch: () => void
  companyId: string
}

export const AddUserModal: FC<Props> = ({ onClose, refetch, companyId }) => {
  const { t } = useTranslation('company')

  const { formProgress, formHandler } = useAFormHandler('addExportContract')

  const formik = useFormik<{ user: string }>({
    initialValues: {
      user: '',
    },

    onSubmit: formHandler(
      async () => {
        await apiClient.post(endpoints.addUserToCompany(formik.values.user), { company: companyId })
      },
      {
        onSuccess: () => {
          refetch()
          onClose()
        },
        onError: (error) => {
          const { errors } = error
          formik.setErrors(errors)
        },
      },
    ),
  })

  return (
    <ResponsiveModal
      onConfirm={() => formik.submitForm()}
      confirmButtonText={t('common:save')}
      disabled={!formik.values.user}
      progress={formProgress}
      title={t('addUser')}
      onClose={onClose}
      id="addUser"
      size="small"
    >
      <SharedModalContent>
        <FormSelectShared
          getLabel={(dto) => dto.profile.full_name || t('common:withoutName')}
          endpoint={endpoints.users()}
          searchField="search"
          formik={formik}
          fieldName="user"
          required
          hideLabel
        />
      </SharedModalContent>
    </ResponsiveModal>
  )
}
