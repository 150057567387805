import { AntdTable } from '@agro-club/agroclub-shared'
import { ACCORDION_ITEM_PAGE_SIZE } from 'modules/constants'
import { useTableData } from 'modules/domain/common/hooks'
import { ListRequestParams } from 'modules/domain/types'
import { Call, CallType } from 'modules/domain/userCalls/types'
import { endpoints } from 'modules/endpoints'
import { FC, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { ASelectFilter } from 'views/components/Analytics'
import { defaultListRequestParams } from 'views/components/TableFilters/data'
import { ADateRangePicker } from 'views/components/TableFilters/DateRangePicker/ADateRangePicker'
import { useColumns } from './useColumns'

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`

const FiltersWrapper = styled.div`
  display: flex;
  column-gap: 12px;
`

type Props = {
  companyId: string
}

export const CallHistory: FC<Props> = ({ companyId }) => {
  const { t } = useTranslation('calls')
  const [listRequestParams, setListRequestParams] = useState<ListRequestParams>({
    ...defaultListRequestParams,
    pageSize: ACCORDION_ITEM_PAGE_SIZE,
    filter: { company_id: companyId },
  })
  const { data = [], progress, total } = useTableData<Call>(endpoints.calls(), listRequestParams, true)
  const options = useMemo(
    () =>
      Object.keys(CallType).map((type) => ({
        value: type,
        label: t(`callTypeFilter.${type}`),
      })),
    [t],
  )

  const datePickerOnChange = (value) =>
    setListRequestParams((prev) => ({
      ...prev,
      filter: {
        ...prev.filter,
        call_start_after: value.startDate,
        call_start_before: value.endDate,
      },
    }))

  const callTypeOnChange = (call_type) =>
    setListRequestParams((prev) => ({
      ...prev,
      filter: { ...prev.filter, call_type },
    }))

  const columns = useColumns()
  return (
    <Wrapper>
      <FiltersWrapper>
        <ADateRangePicker
          value={[listRequestParams.filter.call_start_after, listRequestParams.filter.call_start_before]}
          onChange={datePickerOnChange}
          id="dateFilter"
          allowEmpty={[true, true]}
          place="ShowPrice"
        />
        <ASelectFilter
          id="callTypeFilter"
          options={options}
          placeholder={t('call_type')}
          value={listRequestParams.filter.call_type}
          onChange={callTypeOnChange}
          showSearch={false}
        />
      </FiltersWrapper>
      <AntdTable
        progress={progress}
        columns={columns}
        data={data}
        page={listRequestParams.page}
        pageSize={listRequestParams.pageSize}
        total={total}
        pagination={{ size: 'small', showTotal: undefined }}
        onPageChange={(page) => setListRequestParams((prev) => ({ ...prev, page }))}
        outerBordered
        rowHoverGray
      />
    </Wrapper>
  )
}
