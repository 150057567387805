import { useDisplayClearBtn } from 'hooks/useDisplayClearBtn'
import { endpoints } from 'modules/endpoints'
import { TableFiltersProps } from 'modules/types'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { AClearFiltersButton } from 'views/components/Analytics'
import { FiltersAndSearchWrapper, FiltersWrapper } from 'views/components/CommonTableComponents/CommonTableComponents'
import { InputFilter } from 'views/components/InputFilter/InputFilter'
import { SearchFilter } from 'views/components/SearchFilter/SearchFilter'
import { ABaseTableFilter } from 'views/components/TableFilters/ABaseTableFilter'
import { defaultListRequestParams } from 'views/components/TableFilters/data'
import { MarketZoneFilter } from 'views/components/TableFilters/MarketZoneFilter'
import { CommonRegionFilter } from 'views/components/TableFilters/RegionsFilters/CommonRegionFilter'
import { ACC_MANAGEMENT_PAGE_SIZE } from './AccountManagementList'

export const AccountManagementFilters: FC<TableFiltersProps> = ({
  listRequestParams,
  setListRequestParams,
  updateFilterState,
}) => {
  const { t } = useTranslation()

  const displayClearBtn = useDisplayClearBtn({
    listRequestParams,
  })

  return (
    <FiltersAndSearchWrapper>
      <FiltersWrapper>
        <ABaseTableFilter
          id="assigneeFilter"
          onChange={(responsible_coordinator) => updateFilterState({ responsible_coordinator })}
          value={listRequestParams.filter.responsible_coordinator}
          placeholder={t('common:assignee')}
          getLabel={(label) => label.full_name}
          endpoint={endpoints.taskAssignees()}
          searchField="search"
          simpleApiFormat
          multiple
        />
        <InputFilter
          id="daysFilter"
          onChange={(days_since_last_comment) => updateFilterState({ days_since_last_comment })}
          value={listRequestParams.filter.days_since_last_comment}
          suffix={t('user:short_day')}
          placeholder={t('user:days')}
          prefix={t('user:more')}
          type="number"
        />
        <CommonRegionFilter listRequestParams={listRequestParams} updateFilterState={updateFilterState} />
        <ABaseTableFilter
          id="companyFilter"
          onChange={(company_id) => updateFilterState({ company_id })}
          value={listRequestParams.filter.company_id}
          placeholder={t('common:company')}
          getLabel={(company) => company.name}
          endpoint={endpoints.company()}
          searchField="search"
          multiple
        />
        <MarketZoneFilter listRequestParams={listRequestParams} updateFilterState={updateFilterState} />

        {displayClearBtn && (
          <AClearFiltersButton
            onClick={() => setListRequestParams({ ...defaultListRequestParams, pageSize: ACC_MANAGEMENT_PAGE_SIZE })}
          />
        )}
      </FiltersWrapper>
      <SearchFilter listRequestParams={listRequestParams} updateFilterState={updateFilterState} />
    </FiltersAndSearchWrapper>
  )
}
