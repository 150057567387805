import { Text } from '@agro-club/agroclub-shared'
import { ListRequestParams } from 'modules/domain/types'
import { FC, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { ASharedModal, SharedModalInputs } from 'views/components/SharedModal'
import { defaultListRequestParams } from 'views/components/TableFilters/data'
import { ExportFileSearchFilters } from './ExportFileSearchFilters'
import { FileList } from './FileList'

type Props = {
  onClose: () => void
}

export const FileSearchModal: FC<Props> = ({ onClose }) => {
  const { t } = useTranslation('export')
  const [listRequestParams, setListRequestParams] = useState<ListRequestParams>(defaultListRequestParams)

  return (
    <ASharedModal id="fileSearchModal" size="wide" onClose={onClose}>
      <SharedModalInputs>
        <Text typography="titleH3">{t('exportFileSearch')}</Text>

        <ExportFileSearchFilters listRequestParams={listRequestParams} setListRequestParams={setListRequestParams} />

        <FileList listRequestParams={listRequestParams} />
      </SharedModalInputs>
    </ASharedModal>
  )
}
