import { DeliveryPeriodData } from 'views/components/DealCard/DeliveryPeriod'
import { formatQuantity } from 'modules/utils/numbers/formatQuantity'
import { labelTarif } from 'modules/utils/numbers/formatTarif'
import formatPrice from 'modules/utils/numbers/formatPrice'
import { USBid, BidPrice } from 'modules/domain/bid/types'
import { Text } from '@agro-club/agroclub-shared'
import { Card } from 'views/components/Card/Card'
import { useTranslation } from 'react-i18next'
import { DASH } from 'modules/constants'
import styled from 'styled-components'

const RowItemWrapper = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 2px;
`

type Props = {
  bid: USBid
}

export const USBidCardInfo = ({ bid }: Props) => {
  const { t } = useTranslation('bid')

  const isDelivered = bid.price_type === BidPrice.CPT
  const deliveredDistance = isDelivered
    ? `, ${t('bid:form:us_distance', { distance: bid.owner_delivery_distance })}`
    : ''

  const productAndSeason = [bid.product.title, !!bid.season && bid.season?.name].filter(Boolean).join(', ')
  const symbolAndQuantity = [bid?.symbol, formatQuantity(bid.quantity)].filter(Boolean).join(', ')

  return (
    <>
      <div>
        <Text typography="accentLarge">{productAndSeason}</Text>
        <Text typography="accentLarge">{symbolAndQuantity}</Text>
      </div>
      <Card.Grid cols={3}>
        <RowItemWrapper>
          <Text typography="bodySmall">{labelTarif(t('bid:form:basis'))}</Text>
          <Text typography="accentLarge">{formatPrice(bid.basis ?? DASH)}</Text>
        </RowItemWrapper>
        <RowItemWrapper>
          <Text typography="bodySmall">{labelTarif(t('bid:form:futures'))}</Text>
          <Text typography="accentLarge">{formatPrice(bid.futures_price ?? DASH)}</Text>
        </RowItemWrapper>
        <RowItemWrapper>
          <Text typography="bodySmall">{labelTarif(t('bid:form:cash'))}</Text>
          <Text typography="accentLarge">{formatPrice(bid.price ?? DASH)}</Text>
        </RowItemWrapper>
      </Card.Grid>
      {bid.price_type && <Text>{`${t(`bid:usDeliveryOptions:${bid.price_type}`)}${deliveredDistance}`}</Text>}
      <DeliveryPeriodData data={bid} />
      <Text>{bid.address.address}</Text>
      {bid.has_samples && <div>{t('samples:therefAreSapmples')}</div>}
    </>
  )
}
