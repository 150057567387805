import { FC } from 'react'

import { AntdTableProps } from '@agro-club/agroclub-shared'

import { useTableData } from 'modules/domain/common/hooks'
import { ListRequestParams } from 'modules/domain/types'
import { InternalTableFullPageWithoutData } from './InternalTableFullPageWithoutData'

export interface InternalTableFullPageProps extends Partial<AntdTableProps> {
  listRequestParams: ListRequestParams
  dataEndpoint: string
  disableScrollY?: boolean
  listParamsUpdated: (ListRequestParams) => void
  tableWidth?: number
  isFiltersLoaded?: boolean
  maxHeight?: number
}

const InternalTableFullPage: FC<InternalTableFullPageProps> = (props) => {
  const { listRequestParams, dataEndpoint, isFiltersLoaded = false } = props
  const { progress, data = [], total, pageSize } = useTableData(dataEndpoint, listRequestParams, isFiltersLoaded)

  return (
    <InternalTableFullPageWithoutData {...props} progress={progress} data={data} total={total} pageSize={pageSize} />
  )
}

export default InternalTableFullPage
